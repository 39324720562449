import { takeEvery, call, put } from 'redux-saga/effects';
import { codingbarApi } from 'codingbar-api';

import * as cons from '../constants/notification';

export function* watchGetNotification() {
	// console.log('[SAGAS] watchGetNotification');
	yield takeEvery(cons.SAGA_GET_NOTIFICATION, function*({ payload = {} }) {
		// console.log('[SAGAS] watchGetNotification#payload', payload);
		try {
			const notificationService = codingbarApi.getNotificationService();
			yield put({ type: cons.NOTIFICATION_INIT });
			const notifications = yield call([notificationService, 'getNotifications']);
			// console.log('[SAGAS] watchGetNotification#notifications', notifications);

			yield put({ type: cons.NOTIFICATION_INIT_SUC, notifications });
		} catch (error) {
			yield put({ type: cons.NOTIFICATION_INIT_ERR, error });
			throw error;
		}
	});
}

export function* watchListenNotification() {
	// console.log('[SAGAS] watchListenNotification');
	yield takeEvery(cons.SAGA_LISTEN_UNREAD_NOTIFICATION, function*({ payload = {} }) {
		// console.log('[SAGAS] watchGetNotification#payload', payload);
		try {
			const notificationService = codingbarApi.getNotificationService();
			yield put({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT });
			const notifications = yield call([notificationService, 'listenUnreadNotifications']);
			// console.log('[SAGAS] watchGetNotification#notifications', notifications);

			yield put({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT_SUC, notifications });
		} catch (error) {
			yield put({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT_ERR, error });
			throw error;
		}
	});
}

export default [watchGetNotification, watchListenNotification];
