const common = {
	'welcome.message': '<p>{{name}}, 欢迎使用</p><p></p><p>CodingBar 程式语言智慧学习系统</p>',
	'common.edit': '编辑',
	'common.delete': '删除',
	'common.deleteThis': '删除此項目',
	'common.cancel': '取消',
	'common.return': '返回',
	'common.confirm': '确认',
	'common.confirm.submission': '确认送出',
	'common.confirmEdit': '确认修改',
	'common.confirmChanges': '确认变更',
	'common.confirmNew': '确认新增',
	'common.confirmAction': '请确认是否要执行此操作',
	'common.share': '分享',
	'common.previous': '上一步',
	'common.next': '下一步',
	'common.preview': '预览',
	'common.loading': '载入中...',
	'common.success': '成功！',
	'common.update': '更新',
	'common.opened': '已开放',
	'common.notOpen': '尚未开放',
	'common.opening': '开放中',
	'common.commingsoon': '即将到来',
	'common.ended': '已结束',
	'common.finished': '已完成',
	'common.save.button': '储存',
	'common.saveSuc': '储存成功!',
	'common.saveFail': '储存失败!',
	'common.save': '储存变更',
	'common.this': '此',
	'common.share.unit': '分享给同单位的人',
	'common.share.all': '分享给所有人',
	'common.copytoresource': '复制到资源库',
	'common.remove': '移除',
	'common.error': '发生错误',
	'common.days': '周/时',
	'common.hours': '时',
	'common.minutes': '分',
	'common.seconds': '秒',
	'common.monday': '星期一',
	'common.tuesday': '星期二',
	'common.wednesday': '星期三',
	'common.thursday': '星期四',
	'common.friday': '星期五',
	'common.saturday': '星期六',
	'common.sunday': '星期日',
	'common.abbrev.monday': '星期一',
	'common.abbrev.tuesday': '星期二',
	'common.abbrev.wednesday': '星期三',
	'common.abbrev.thursday': '星期四',
	'common.abbrev.friday': '星期五',
	'common.abbrev.saturday': '星期六',
	'common.abbrev.sunday': '星期日',
	'common.Wmonday': '周一',
	'common.Wtuesday': '周二',
	'common.Wwednesday': '周三',
	'common.Wthursday': '周四',
	'common.Wfriday': '周五',
	'common.Wsaturday': '周六',
	'common.Wsunday': '周日',
	'common.month': '月',
	'common.day': '日',
	'common.status': '状态',
	'common.time': '时间',
	'common.score': '分',
	'common.nextWeek': '下一周',
	'common.prevWeek': '前一周',
	'common.nowWeek': '回当周',
	'common.24/7': '一天24小时',
	'common.loadingTime': '时间载入中...',
	'common.loadmore': '显示更多',
	'common.unfinished': '未完成项目',
	'common.solved': '已完成项目',
	'common.personalInfo': '个人资讯',
	'common.belongUnit': '单位',
	'common.belongUnits': '单位列表(单位之间用,隔开)',
	'common.logout': '登出',
	'common.version': '版本',
	'common.submit': '送出',
	'common.home': '首页',
	'common.copySuccess': '复制成功',
	'common.copyFail': '复制失败，请重新尝试',
	'common.info': '资料',
	'common.name': '姓名',
	'common.optional': '(选填)',
	'common.title': '标题',
	'common.content': '内容',
	'common.pleaseFill': '请填写',
	'common.close': '关闭',
	'common.known': '我知道了',
	'common.notnull': '此栏位不能为空值',
	'common.continue': '继续',
	'common.minutes': '分钟',
	'common.10min': '10分钟',
	'common.15min': '15分钟',
	'common.20min': '20分钟',
	'common.30min': '30分钟',
	'common.40min': '40分钟',
	'common.45min': '45分钟',
	'common.50min': '50分钟',
	'common.60min': '60分钟',
	'common.70min': '70分钟',
	'common.80min': '80分钟',
	'common.90min': '90分钟',
	'common.120min': '120分钟',
	'common.180min': '180分钟',
	'common.new': '新增',
	'common.error': '错误',
	'common.sendMsg': '传送讯息',
	'common.create': '建立',
	'common.privacy': '隐私权',
	'common.and': '与',
	'common.perPage': '每页显示',
	'common.page': '笔',
	'common.thisPage': '此页',
	'common.times': '次',
	'common.open': '开启',
	'common.executionEnvironment': '执行环境',
	'common.search': '搜寻',
	'common.errorOccured': '发生错误...',
	'common.disabledFunctionality': '此功能未开放！',
	'common.preparing': '准备中',
	'common.refresh': '重新整理视窗',
	'common.pleaseWait': '请稍候...',
	'common.noData': '尚无资料',
	'common.noFill': '未填写',

	'common.not-empty': '不可为空',
	'common.final.edit': '最后编辑',

	'common.yes': '是',
	'common.no': '否',
	'common.save.setting': '储存设定',
	'common.leave': '离开',
	'common.hour': '小时',

	'common.fillInNumber': '请输入数字',
	'common.slowQuery': '目前与伺服器连线速度过慢，资料读取中...',

	'common.airabbi': '瑞比智慧科技股份有限公司',
	'common.selectAll': '全选',
	'common.startDate': '起始日期',
	'common.startTime': '起始时间',
	'common.cutoffDate': '截止日期',
	'common.cutoffTime': '截止时间',
	'common.createdAt': '启用日',
	'common.reading.data': '资料读取中...',

	'common.comma': '、',
	'common.add': '加入',
	'common.line.break': '\n',

	'common.unlimited': '无上限',
	'common.StepsTab.Title.Date': '日程設定',
	'common.undefined': '查无资料',
	'common.download': '下载',
	'common.mode': '模式',
	'common.updating': '更新中...',
	'chooseOne.to.select': '择一进行搜寻：{{selectOptions}}',
};

const header = {
	'header.notification': '通知',
	'header.announcement': '公告',
	'header.notes': '笔记',
	'header.zoom': '视讯',
	'header.bugReport': '回报Bug',
	'header.freecode': 'FreeCode',
	'header.logOut': '登出',

	'header.account': '我的帐户',
	'header.preferences': '系统偏好设定',
	'header.account.and.setting': '帐号与设定',
	// 'header.AImate.plan': '计画方案',
	'header.AImate.plan': '订阅专业版',
	'header.AImate.bill': '付款与帐单',
	'header.AImate.coin': 'i 币奖励金',
	'header.AImate.notification': '通知中心',
};

const teacherExercise = {
	'teacher.exercise.open.exercise': '开放题目',
	'teacher.exercise.open.all': '开放所有的练习？',
	'teacher.exercise.open.all2': '您即将开放所有的练习题目',
	'teacher.exercise.close.exercise': '关闭题目',
	'teacher.exercise.open.exercise.opened': '题目已开放',
	'teacher.exercise.open.hint': '开放提示',
	'exercise.nohint': '尚无提示',
	'exercise.provideans': '请透过左方按钮提供课程答案',
	'exercise.providehint': '请透过左方按钮提供课程提示',
	'exercise.hint.noonecheck': '无人查看',
	'exercise.read': '已查看的学生',
	'exercise.betterAns': '建议CodingBar更好的答案',
	'exercise.betterTips': '建议CodingBar更多合适的提示',
	'teacher.exercise.close.hint': '关闭提示',
	'teacher.exercise.open.hint.opened': '提示已开放',
	'teacher.exercise.open.answer': '开放解答',
	'teacher.exercise.close.answer': '关闭解答',
	'teacher.exercise.open.answer.opened': '解答已开放',
	'teacher.exercise.moveUp': '上移此题',
	'teacher.exercise.moveDown': '下移此题',
	'teacher.exercise.mostRecentAnswer': '最后作答结果',
	'teacher.exercise.firstAnswer': '第一次作答结果',
	'teacher.exercise.sortResult': '您的排序结果',
	'teacher.exercise.addToObserveList': '标记为重点观察名单',
	'teacher.exercise.removeFromObserveList': '取消标记为重点观察名单',

	'teacher.exercise.lastSubmit': '最后一次提交状态',
};

const sidebar = {
	'teacher.main.sidebar.welcomeMessage': '老师您好, 欢迎回来！',
	'teacher.main.sidebar.course': '课程管理',
	'teacher.main.sidebar.cir': '学生管理',
	'teacher.main.sidebar.tri': '教学资源库',
	'teacher.main.sidebar.studentQ': '学生讯息',
	'teacher.main.sidebar.liveCourse': '开课中',
	'teacher.main.sidebar.alert': '【旧版】通知中心',
	'teacher.main.sidebar.heart': '【旧版】个人资料',
	'teacher.main.sidebar.share': '【旧版】教学资源库-共享区',

	'student.main.sidebar.welcomeMessage': 'Hi, 欢迎回来！',
	'student.main.sidebar.course': '我的课程',
	'student.main.sidebar.question': '我的问题集',
	'student.main.sidebar.notes': '我的笔记',
	'student.main.sidebar.liveCourse': '开课中',
	'student.main.sidebar.learningResults': '学习成果',
	'teacher.main.sidebar.tutorial': '系统教学目录',
	'student.main.sidebar.globalexam': '模拟考(测试版)',
	'teacher.main.sidebar.importexercise': '汇入习题',
	'teacher.main.sidebar.question-label': '问题分类标注',
	'teacher.main.sidebar.exercise-label': '习题错误标注',
	'teacher.main.sidebar.question': '习题',
};

const teacherCourseDetail = {
	'course.sidebar.course': '课程管理',
	'course.sidebar.live': '线上教室',
	'course.sidebar.lesson': '单元列表',
	'course.sidebar.test': '试卷列表',
	'course.sidebar.student': '课堂学生',
	'course.sidebar.score': '学生成绩',
	'course.sidebar.exercise': '练习题目',
	'course.sidebar.studentamount': '使用人数',
	'teacher.sidebarFSH.useUnLimit': '无限制',
};

const InformationPage = {
	'information.account.title': '帐号与密码',
	'information.account.account': '帐号',

	'information.account.certificate.title': '帐号凭证',
	'information.account.certificate.placeholder': '尚未汇入凭证',
	'information.account.certificate.btn': '汇入凭证',
	'information.account.certificate.noCertificate': '凭证无效，请汇入有效凭证',
	'information.account.certificate.deadtime': '凭证到期日',
	'information.account.certificate.overtime': '凭证已到期',

	'information.account.certificate.useLimit': '凭证人数',
	'information.account.certificate.toastAlert.alreadyCertification': '已有凭证！请于凭证到期后再更新凭证',
	'information.account.certificate.toastAlert.alreadyPast': '此凭证已过期！请确认汇入凭证档案',
	'information.account.certificate.toastAlert.notActive': '此凭证尚未有效！请于凭证时间 {{beginDate}} 后再汇入',
	'information.account.certificate.toastAlert.update': '已更新凭证！请确认凭证资讯',
	'information.account.certificate.toastAlert.error': '凭证错误！此凭证已被其他人使用',
	'information.account.certificate.toastAlert.import': '已汇入凭证，未变更凭证资讯',

	'information.account.password': '密码', //NOSONAR
	'information.account.pre.password': '预设密码', //NOSONAR
	'information.account.avatar': '个人头像',
	'information.account.button.changePW': '变更密码',
	'information.account.button.newPW': '新密码',
	'information.account.button.confirmPW': '确定变更',
	'information.account.button.cancelNewPW': '取消',
	'information.account.avatar.change': '编辑',
	'information.account.avatar.chooseImage': '选择照片',
	'information.account.avatar.chooseColor': '变更颜色',

	'information.socialMediaConnection': '社群帐号连结',
	'information.socialMedia.connectAccount': '连结帐号',

	'information.classnum': '班级与座号',
	'information.school': '就读学校',
	'information.grade': '就读年级',
	'information.class': '就读班级',
	'information.num': '座号',
	'information.numPlaceholder': '输入座号',
	'information.num2': '学号',
	'information.parent': '填写家长资料',
	'information.parent.name': '家长姓名',

	'information.basic.title': '基本资料',
	'information.basic.modify': '编辑资料',
	'information.basic.school': '学校单位',
	'information.basic.name': '名字',
	'information.basic.class': '班级',
	'information.basic.nickname': '昵称',
	'information.basic.noNickname': '尚无昵称',
	'information.basic.tel': '电话',
	'information.basic.email': '电子邮件',
	'information.basic.availableItems': '可授课项目 (复选)',
	'information.basic.noAvailableItems': '尚无选择授课项目',

	'information.relationContact': '关系联络人',
	'information.relation': '关系',

	'information.settings.title': '设定',
	'information.settings.lang': '切换语言',

	'information.notset': '尚未输入',
	'information.noNum': '尚未有座号',
	'information.noClass': '尚未有班级',

	'information.alertSchool': '学校单位不可为空!',
	'information.alertUnit': '单位不可为空!',
	'information.alertEmail': 'Email不可为空!',
	'information.alertEmail2': 'Email格式不正确!',
	'information.alertName': '姓名不可为空!',
	'information.alertGender': '性别不可为空!',
	'information.alertPW': '密码不可为空!',
	'information.alertTel': '电话不可为空!',
	'information.alertTel2': '电话格式不正确!',
	'information.alertExistTeacher': '不可以覆盖已存在的老师帐号!',
	'information.alertExistAccount': '不可以复盖已存在的 {{role}} 帐号!',
	'information.alertExistAccount.other.unit': '不可以覆盖已存在其他单位的帐号!',
	'information.alertExistAccount.cannot.create.other.unit': '无法建立，此帐号{{role}}已存在于 {{unit}} 单位中',
	'information.alertExistAccount.cannot.create.current.unit':
		'无法建立，此帐{{role}}号已存在于 {{unit}} 单位中，请前往编辑。 ',

	'information.correctPW': '请输入正确的密码!',

	'information.modifyTitle': '编辑个人资料',
	'information.modifySchool': '学校单位(不可修改)',
	'information.saveSuc': '个人资料已成功更新',
	'information.upload': '上传档案',
	'information.fileLink': '档案连结',
	'information.avatarPlaceholder': '上传档案后将自动填入',
	'information.uploadFin': '上传完毕',
	'information.uploadProgress': '上传进度',

	'information.modifyNumber': '编辑座号',
	'information.confitmEnter': '确认加入班级',
	'information.newNumber': '请输入新的座号。提醒您输入错误的座号，可能会导致成绩错误。',
	'information.confirmClass': '请确认您欲申请加入的班级，并输入您在该班级的座号。',

	'information.passwordWarning': '警告！ 需要登出后，再重新登入才可以变更密码!', //NOSONAR
	'password.new': '请输入新密码!', //NOSONAR
	'password.notStrong': '密码不够强，容易被窃取!', //NOSONAR
	'password.changeSuccess': '变更密码成功! 请用新密码重新登入！', //NOSONAR

	'password.resetByEmail': '请输入 Email 来重设密码!', //NOSONAR
	'password.checkMail': '邮件已经送出。 请到 {{email}} 重设密码!', //NOSONAR
	'password.failtoRest': '重设密码失败', //NOSONAR
	'password.entermail': '请输入您的信箱', //NOSONAR
	'password.sendtoMail': '更改密码流程将寄至您的信箱。', //NOSONAR
	'password.yourAccount': '您的帐号', //NOSONAR

	'information.changeSuccess': '更新学生资料成功!',
	'information.changeFailed': '更新学生资料失败!',
	'student.contactInfo': '联络人资料',
	'information.loginpage.displayName': '请输入家长姓名',
	'information.loginpage.Phone': '请输入联络电话',

	'information.account.avatar.changeSuccess': '更新头像成功',
	'information.account.avatar.changeFail': '更新头像失败',
};

const notificationPages = {
	'all.notification.noMessages': '目前无通知讯息',
	'all.notification.unread': '未读通知',

	'notification.sent': '讯息已经发送给使用者！',
	'notification.sentAll': '讯息已经发送全部使用者！',
	'notification.sending': '讯息正在发送中!',
	'notification.activity': '活动',
	'notification.challenge': '挑战赛',
	'notification.greeting': '问候',
	'notification.news': '新知',
	'notification.exercise': '课后练习',

	'notification.modalTitle': '发送通知讯息',
	'notification.sendto': '传给',
	'notification.resetSuccess': '重置所有的通知完成!',
	'notification.notifications': '通知讯息',
	'notification.title': '标题:',
	'notification.time': '时间:',
	'notification.message': '讯息:',

	'fillinTitle': '填写标题',
	'titleConnotBeNull': '标题不能为空值',
	'Subtitle': '副标题(100字)',
	'fillin.subtitle': '填写副标题',
	'subtitleCannotBeNull': '副标题不能为空值',
	'contentCannotBeNull': '内容不能为空值',
};

const teacherResourcePage = {
	'teacher.resources.header.title': '教学资源库',
	'teacher.resources.tab.sorting': '排序',
	'teacher.resources.tab.sorting.name': '名称',
	'teacher.resources.tab.sorting.time': '修改时间',
	'teacher.resources.tab.searchPlaceholder': '输入关键字',
	'teacher.resources.tabTitle.handout': '讲义 ',
	'teacher.resources.tabTitle.exercise': '习题 ',
	'teacher.resources.tabTitle.test': '试卷 ',

	'teacher.resources.tabTitle.questionGroup': '题组',
	'teacher.resources.tab.sorting.questionlength': '题目数',
	'teacher.resources.tab.sorting.hashtag': '标签',

	'teacher.resources.tab.sorting.displayName': '学生姓名',
	'teacher.resources.tab.sorting.seatNo': '座号',
	'teacher.resources.tab.sorting.completepercent': '完成率',
	'teacher.resources.tab.sorting.lastlogin': '登入时间',
	'teacher.resources.tab.sorting.studentuid': '帐号名称',

	'teacher.resources.newResource.iconTips': '新增资源',
	'teacher.resources.newResource.newHandout': '新增上课讲义',
	'teacher.resources.newResource.newExercise': '新增习题',
	'teacher.resources.newResource.newExerciseOrVideo': '加入习题或影片',
	'teacher.resources.newResource.newTest': '新增试卷',
	'teacher.resources.newResource.newLesson': '新增空白单元',

	'teacher.resources.handout.category': '标准教材',

	'components.handoutManageRectangle.upload': '上传',
	'components.handoutManageRectangle.download': '下载讲义',
	'components.handoutManageRectangle.rename': '重新命名',
	'components.handoutManageRectangle.delete': '删除讲义',
	'components.handoutManageRectangle.deletePopup.title': '移除讲义？',
	'components.handoutManageRectangle.deletePopup.revokable': '讲义将从此课程移除, 您仍然可以在教学资源中心找到此讲义',
	'components.handoutManageRectangle.deletePopup.irrevokable': '移除讲义后将无法再复原',
	'components.exercise.deletePopup.revokable': '练习将从课程中移除, 您仍然可以在教学资源中心找到此练习题',
	'components.exercise.deletePopup': '练习删除后将无法再复原',
	'confirmation.title': '确认讯息标题',
	'confirmation.content': '确认讯息',

	'teacher.resources.mine': '我的资源库',
	'teacher.resources.unit': '单位共享区',
	'teacher.resources.global': '全域共享区',

	'teacher.resources.nohandout': '您尚未添加上课讲义',
	'teacher.resources.noexercise': '尚无自建的习题',
	'teacher.resources.notest': '您尚未添加测验试卷',

	'teacher.nomatch.handout': '没有符合搜寻的上课讲义',
	'teacherResourcePage.syncToCourse': '同步至自己所有课程与试卷',
	'teacherResourcePage.syncToOtherUnitCourse': '同步至所有单位课程与试卷',
	'resource.shareSuc': '分享成功!',
	'resource.shareFail': '分享失败!',
	'resource.copySuc': '复制成功!',
	'resource.syncSuc': '同步完成',
	'resource.syncFail': '同步失败，请稍后再试',
	'resource.copySucc': '复制 {{target}}{{title}} 成功!',
	'resource.copyFailed': '复制 {{target}}{{title}} 发生错误：{{err}}',
	'resource.delSuc': '成功删除: {{title}} 试卷',
	'resource.delFailed': '删除试卷 {{title}} 发生错误: {{err}}',

	'teacher.studentMg': '学生管理',
	'teacher.studentMg.addStudent': '新增学生资料',
	'teacher.studentMg.viewStudent': '检视学生资料',

	'teacher.resources.header.subtitle':
		'我们推出了新版的教学资源库，你可以更轻松地在 CodingBar 里建立各种题型的题目，并上传讲义素材，\n还可从单位/公共资源库下载更多的资源！快来试看看吧！',
	'teacher.resources.fileName': '档案名称',

	'teacher.resources.tab.sorting.uploadBy': '上传者',
	'teacher.resources.tab.sorting.useLimit': '人数上限',
	'teacher.resources.tab.sorting.currentUse': '已用人数',
	'teacher.resources.tab.sorting.remainingUse': '可用余额',
	'teacher.resources.tab.sorting.expiration': '到期日',
	'teacher.resources.tab.sorting.tags': '分类标签',
	'teacher.resources.tab.sorting.materials': '内容',

	'teacher.resources.tabTitle.package': '套装课程 ',
	'teacher.resources.package.edit': '编辑套装课程',
	'teacher.resources.package.delete': '删除套装课程',
	'teacher.resources.package.deleteSuccess': '成功删除套装课程',
	'teacher.resources.package.deleteFail': '删除套装课程失败',
	'teacher.resources.package.deletePopup.title': '删除套装课程？',
	'teacher.resources.package.deletePopup.content': '移除套装课程后，将无法再复原',

	'teacher.resources.newResource.newCoursePackage': '新增套装课程',
	'teacher.resources.year': '/',
	'teacher.resources.month': '/',
	'teacher.resources.day': '',

	'teacher.studentMg.deleteStudent': '移除此学生',
	'teacher.resources.delete.success': '删除成功',
	'teacher.resources.delete.fail': '删除失败',

	'teacher.rectangle.useLimit': '个人免费上限',
	'teacher.rectangle.unitUseLimit': '单位共享上限',
};

const exerciseManageRectangle = {
	'components.exerciseManageRectangle.deleteTitle': '删除习题？',
	'components.exerciseManageRectangle.deleteMsg': '习题删除后将无法再复原!',
	'exercise.deleteSuc': '成功删除习题',
	'components.exerciseManageRectangle.btn.edit': '编辑',
	'components.exerciseManageRectangle.btn.delete': '删除此习题',
	'components.exerciseManageRectangle.btn.copy': '复制此习题',
};

const questionGroupManageRectangle = {
	'components.questionGroupManageRectangle.btn.edit': '编辑题组',
	'components.questionGroupManageRectangle.btn.delete': '删除题组',
	'components.questionGroupManageRectangle.btn.remove': '移除题组',

	'components.QuestionGroupManageRectangle.deleteTitle': '删除题组',
	'components.QuestionGroupManageRectangle.deleteCheck.irrevokable': '删除题组后将无法再复原',
	'components.QuestionGroupManageRectangle.deleteCheck.revokable':
		'题组从此课程移除, 您仍然可以在教学资源中心找到此题组',

	'components.QuestionGroupManageRectangle.btn.copy': '复制此题组',
};

const testManageRectangle = {
	'components.testManageRectangle.btn.edit': '编辑',
	'components.testManageRectangle.btn.delete': '删除',
	'components.testManageRectangle.btn.remove': '移除试卷',
	'components.testManageRectangle.btn.copy': '复制此试卷',
	'components.testManageRectangle.exerciseCount': '题数：',

	'components.testManageRectangle.testDate': '测验日: ',
	'components.testManageRectangle.notTestYet': '尚未测验',

	'components.testManageRectangle.deleteTitle': '移除测验？',
	'components.testManageRectangle.deleteCheck.irrevokable': '删除试卷后将无法再复原',
	'components.testManageRectangle.deleteCheck.revokable': '试卷从此课程移除, 您仍然可以在教学资源中心找到此试卷',

	'components.testManageRectangle.editTitle': '编辑测验？',
	'components.testManageRectangle.editTrue': '在教学资源中心更改后，(拥有此试卷的课堂会一并更新?)。',
	'components.testManageRectangle.editFalse': '在此更改后，教学资源中心将同步更新。',
};

const materialManage = {
	'components.materialManage.title': '建立讲义',
	'components.materialManage.add': '加入讲义',
};

const materialUploadFile = {
	'components.materialUploadFile.title': '新增讲义 （上传档案）',
	'components.materialUploadFile.selectFile': '选择档案...',
	'components.materialUploadFile.setTitle': '讲义标题',
	'components.materialUploadFile.lang': '程式语言',
	'components.materialUploadFile.href': '讲义超连结',
	'components.materialUploadFile.confirm': '新增',

	'components.materialUploadFile.selected': '选择的档案',
	'components.materialUploadFile.loading': '上传进度',
	'components.materialUploadFile.titlePlaceholder': '请输入讲义名称',
	'components.materialUploadFile.autoFillPlaceholder': '上传档案后将自动填入',

	'components.materialUploadFile.addMaterialSuccess': '成功新增 {{title}}',
	'components.materialUploadFile.addMaterialFail': '新增 {{title}} 失败. 错误资讯: {{err}}',
	'components.materialUploadFile.checkMaterialLink': '请检查您的讲义连结格式',
	'components.materialUploadFile.addMaterial': '新增讲义',
	'components.materialUploadFile.addMaterialTitle': '填写讲义标题',
	'components.materialUploadFile.href': '讲义超连结',
	'components.materialUploadFile.addMaterialLink': '填写讲义网址',
};

const exerciseManage = {
	'component.exerciseManage.description': '题目概述',
	'component.exerciseManage.title.modify': '修改练习题',
	'component.exerciseManage.title.create': '建立练习题',
	'component.exerciseManage.title.preview': '预览练习题',
	'component.exerciseManage.title.homework': '建立课后作业',
	'component.exerciseManage.tab.title': '标题内容',
	'component.exerciseManage.tab.freeCode': '程式题',
	'component.exerciseManage.tab.video': '看影片',
	'component.exerciseManage.tab.fillin': '填空题',
	'component.exerciseManage.tab.cloze': '克漏字填空',
	'component.exerciseManage.tab.dnd': '拖曳题',
	'component.exerciseManage.tab.choice': '选择题',
	'component.exerciseManage.tab.rearrangement': '排序题',
	'component.exerciseManage.tab.step': '步骤题',
	'component.exerciseManage.tab.splitTips': '拆解提示',
	'component.exerciseManage.tab.answer': '解答范例',
	'component.exerciseManage.tab.test': '测试资料',
	'component.exerciseManage.tab.preview': '发布预览',

	'component.exerciseManage.modal.previous': '上一步',
	'component.exerciseManage.modal.next': '下一步',
	'component.exerciseManage.modal.confirmEdit': '修改资料',
	'component.exerciseManage.modal.confirmCreate': '新增资料',

	'component.exerciseManage.fraction.exercise': '习题',
	'component.exerciseManage.fraction.editSuccess': '更新成功！',
	'component.exerciseManage.fraction.editFail': '更新失败！',
	'component.exerciseManage.fraction.edit': '修改习题',
	'component.exerciseManage.fraction.fail': '失败！',
	'component.exerciseManage.fraction.createSuccess': '建立成功！',
	'component.exerciseManage.fraction.createFail': '建立失败！',
	'component.exerciseManage.fraction.try.again': '请再试一次。',
	'component.exerciseManage.fraction.create': '创建习题',
	'component.exerciseManage.alert.title': '请输入习题标题',
	'component.exerciseManage.alert.execute.frequently': '请勿频繁送出程式码',

	'component.exerciseManage.alert.choiceNoAnswer': '选择题未指定正确答案',
	'component.exerciseManage.alert.choiceEmpty': '选择题不可含空白选项',

	'component.exerciseManage.alert.choiceChooseAnswer': '用滑鼠点选选项为答案选项',
	'component.exerciseManage.alert.addChoice': '新增选项',
	'component.exerciseManage.alert.choiceChooseAlert': '您必须选择至少一个选项为正确答案',
	'component.exerciseManage.codeSettingsNotSet': '还没有设定 Code',

	'component.exerciseManage.alert.emptyVideoUrl': '影片网址不得为空白',
	'component.exerciseManage.alert.ErrorVideoUrl': '影片网址错误，无法辨识',

	'component.exerciseManage.alert.emptyChoice': '选择题未设定选项',
	'component.exerciseManage.alert.nullChoice': '选择题不可含空白选项',

	'component.exerciseManage.alert.emptyStep': '步骤题未设定步骤',
	'component.exerciseManage.addStep': '新增引导解题步骤',
	'component.exerciseManage.confirmAddStep': '新增步骤',

	'component.exerciseManage.correctAnswer': '完全正确',
	'component.exerciseManage.wrongAnswer': '答错了!',
	'component.exerciseManage.previewAnswer': '预览',

	'component.exerciseManage.isSlotFilling': '此题为填空题，请点选下方「填空题」按钮',
	'component.exerciseManage.setSlotFilling.placeholder':
		'填空题设定方式：\n请将完整程序码贴于此文字编辑栏位\n请勿设计 无法执行/非程序码 的内容，如有其馀问答需求建议使用克漏字填空题\n请将想要「挖空」的字串，以 ${} 包住\n范例说明\n程序码正确解答： print("hello world")\n\n若想将 print 变成填空题空格，请将 print 以 ${print} 取代： ${}(“hello world”)\n大括号内可允许空格，例如 ${ }，空格越多，题目显示的填空长度就越长',
	'component.exerciseManage.alert.completeStep': '请先完成这一步骤',
	'component.exerciseManage.alert.lastStep': '没有下一步骤了！',
	'component.exerciseManage.setStepTip':
		'可将此步骤的提示与引导内容内容 python注解语法形式，填写于此 IDE编辑框内，做为预设的提示资讯 此段文字请于发布前删除，并替换成您所要设定的题目程式语法',
	'component.exerciseManage.setFreecodeContent': '设定Free Code题目内容',

	'component.exerciseManage.loadingLonger': '载入中，当题目较多时，可能需要较长时间...',
	'component.exerciseManage.exerciseContent': '题目内容',
	'component.exerciseManage.exerciseDesc': '习题概述',
	'component.exerciseManage.select': '选择',
	'component.exerciseManage.selectVideo': '选择影片',
	'component.exerciseManage.selectExerciseType': '题型选择',
	'component.exerciseManage.exerciseTitle': '习题标题',
	'component.exerciseManage.alert.lastQuestion': '没有下一题了！',
	'component.exerciseManage.openFiles': '工作目录',
	'component.exerciseManage.dragDrop.placeOption': '放入选项',
	'component.exerciseManage.noErrors': '没有错误',
	'component.exerciseManage.alert.noCode': '程式码不可为空!',
	'component.exerciseManage.alert.incorrectData': '资料不正确!',
	'component.exerciseManage.alert.frequencySubmit': '请勿频繁执行/提交!!',
	'component.exerciseManage.alert.executing': '正在执行!!',
	'component.exerciseManage.alert.other.code.executing': '其他程式码正在执行中!!',

	'component.exerciseManage.checkDesc': '查看说明',
	'component.exerciseManage.previousPage': '回到上页',
	'component.exerciseManage.checkHints': '查看提示',
	'component.exerciseManage.moreHints': '找不到哪边错吗？来看看这些可能的问题吧！',
	'component.exerciseManage.exerciseNotAvailable': 'Sorry 目前题目尚未开放...',
	'component.exerciseManage.test.fail': '测试失败',
	'component.exerciseManage.executionFailed': '执行失败',
	'component.exerciseManage.executionSuccess': '测试通过',
	'component.exerciseManage.reorder': '您的排序结果',

	'component.exerciseManage.configSetting': '请使用 config 方式设定题目，设定方式如下：',
	'component.exerciseManage.cloze.slot': '设定克漏字填空挖空处，slot tag 中间文字为正确答案 ',
	'component.exerciseManage.cloze.select': '设定下拉式选单挖空处与选项，select tag 中间文字为正确答案 ',
	'component.exerciseManage.jsonFormat': '其中，下拉选项的双引号内文字（ex. "option 1"）请用JSON格式输入特殊符号：',
	'component.exerciseManage.jsonFormatExample':
		'双引号(")请输入："，反斜线()请输入：\\；下拉选项的正确答案则无需输入反斜线',
	'component.exerciseManage.dragDrop.option': '设定拖曳选项，冒号与文字中间无空白；个别选项请各自设定一个#option: ',
	'component.exerciseManage.dragDrop.slot': '设定下拉式选单挖空处与选项，select tag 中间文字为正确答案 ',

	'component.exerciseManage.dragDrop.resetContent1': '警告，应于莫名无法拖曳时使用，',
	'component.exerciseManage.dragDrop.resetContent2': '点击将重置此题作答，确认重置？',

	'component.exerciseManage.alert.somethingWrong': '有东西出错了！若重复发生，请填写问题与反馈',

	'component.exerciseManage.alert.slotFillingContent': '填空题内容应为程序码\n请写入正确程序语言的程序码',
};

const exerciseAndTest = {
	'component.exerciseMainInfo.lang': '习题语言',
	'component.exerciseMainInfo.testlang': '试卷语言',
	'component.exerciseMainInfo.title': '习题标题',
	'component.exerciseMainInfo.testTitle': '试卷标题',
	'component.exerciseMainInfo.hashtag': '自订标签',
	'component.exerciseMainInfo.link': '影片网址',
	'component.exerciseMainInfo.content': '习题内容（图片大小上传前须自行调整）',
	'component.exerciseMainInfo.contentShort': '习题内容',
	'component.exerciseMainInfo.testExplainPlaceholder': '填写试卷说明',
	'component.exerciseMainInfo.titlePlaceholder': '输入习题标题',
	'component.exerciseMainInfo.testTitlePlaceholder': '输入试卷标题',
	// 'component.exerciseMainInfo.hashtagPlaceholder': '请用#分开不同标签',
	'component.exerciseMainInfo.hashtagPlaceholder': '请用半形#字号分隔不同标签，请勿输入空格',

	'component.exerciseMainInfo.videoSource': '影片来源',
	'component.exerciseMainInfo.linkInput': '请输入影片网址 (目前支援 Youtube, Vimeo 影音平台网)',
	'component.exerciseMainInfo.linkSelectFromResource': '或，从既有教学资源库中选择影片',
	'component.exerciseMainInfo.insertVideo': '插入影片',
};

const exerciseTipInfo = {
	'component.exerciseTipInfo.new': '新增拆解提示',
	'component.exerciseTipInfo.title': '提示标题',
	'component.exerciseTipInfo.content': '提示内容',
	'component.exerciseTipInfo.titlePlaceholder': '填写提示标题',
	'component.exerciseTipInfo.contentPlaceholder': '填写提示内容',
	'component.exerciseTipInfo.confirmNew': '新增提示',
};

const exerciseAnswerInfo = {
	'component.exerciseAnswerInfo.ans': '新增解答范例',
	'component.exerciseAnswerInfo.title': '解答范例标题',
	'component.exerciseAnswerInfo.titlePlaceholder': '填写解答范例标题',
	'component.exerciseAnswerInfo.content': '解答范例内容',
	'component.exerciseAnswerInfo.contentPlaceholder': '填写解答范例内容',
	'component.exerciseAnswerInfo.confirmNew': '新增解答范例',
};

const exerciseTestInfo = {
	'component.exerciseTestInfo.title': '新增测试资料',
	'component.exerciseTestInfo.percentage': '分数百分比',
	'component.exerciseTestInfo.input': '输入资料 (Input)',
	'component.exerciseTestInfo.inputPlaceholder': '填写输入资料',
	'component.exerciseTestInfo.output': '输出资料 (Output)',
	'component.exerciseTestInfo.outputPlaceholder': '填写输出资料',
	'component.exerciseTestInfo.confirmNew': '新增测试资料',
};

const testManage = {
	'component.testManage.alert.title': '请输入试卷标题',
	'component.testManage.alert.content': '请输入试卷内容',
	'component.testManage.alert.option': '请选择试卷题目',
	'component.testManage.alert.portion': '试卷题目总分不为100, 请调整题目比分',
	'component.testManage.alert.score': '请选择试卷比分',
	'component.testManage.test': '试卷',
	'component.testManage.createTest': '建立试卷',
	'component.testManage.edit': '修改测验试卷',
	'component.testManage.create': '新增试卷',

	'component.testManage.info': '试卷资料',
	'component.testManage.question': '试卷题目',
	'component.testManage.scoring': '分数设定',
	'component.testManage.preview': '预览试卷',

	'component.testManage.confirmEdit': '修改试卷',
	'component.testManage.confirmNew': '新增试卷',

	'testManage.previewExercise': '练习题预览',
	'testManage.closePreview': '关闭预览',
	'testManage.selectFrom': '从教学资源库选择',
	'testManage.createNew': '建立新试题',
	'testManage.loadingStudent': '载入学生资料中....',
	'testManage.addNewExercise': '添加课堂练习',
	'testManage.addNewHomework': '添加课后作业',
	'testManage.addhandout': '添加讲义',

	'component.testManage.info': '基本内容',
	'component.testManage.note.1': '新版试卷功能，题目请由教学资源库汇入；您可直接拖曳题目调整顺序',
	'component.testManage.note.2': '若同一题内有多笔测资，请先设定各笔测资配分比例，否则系统将自动平均配分',
	'component.testManage.note.3':
		'请注意：非解题型题目，例如 microbit / pygame / video 等，汇入时将自动过滤不显示在考卷中',
	'component.testManage.import.questions': '汇入题目或题组',
	'component.testManage.import.question': '汇入题目',
	'component.testManage.preview.question': '预览题目',
	'component.testManage.question.step': '试卷题目',
	'component.testManage.event.step': '试卷设定',
	'component.testManage.repeat.exercise': '题目重复',
	'component.testManage.repeat.exercise.btn.confirm': '停留此页',
	'component.testManage.repeat.exercise.btn.next': '继续下一步',
	// 'component.testManage.repeat.info':'已侦测到下列题目为重复内容，请确认是否要继续设定试卷',
	'component.testManage.repeat.info': '已侦测到下列题目为重复内容，请重复设定试卷',
	'component.testManage.repeat.number': '重复题目编号',

	'component.StartTestAlertModal.title': '考试活动已开始',
	'component.StartTestAlertModal.content': '教师所举办的考试活动已经开始啰！抓紧时间前往考试吧！',

	'component.EndTestAlertModal.title': '考试活动已结束',
	'component.EndTestAlertModal.content': '教师所举办的考试活动已经结束了！',

	'component.studentNotification.endTest': '您的考试活动已经结束啰！前往检视您的成绩吧！',
};

const lessonAppendExercise = {
	'lessonAppendExercise.selectLang': '请在试卷资料选择程式语言',
	'lessonAppendExercise.onlyShow': '仅显示{{codeType}}的题目',
	'lessonAppendExercise.please': '请透过',
	'lessonAppendExercise.please_2': '上传',
	'lessonAppendExercise.addToCourse': '练习题新增至课程',
	'lessonAppendExercise.noMatch': '没有符合搜寻条件的练习题',
	'lessonAppendExercise.alert.hw': '这是课后练习的习题，无法取消!',
	'lessonAppendExercise.alert.course': '这是课堂练习的习题，无法取消!',

	'lessonAppendExercise.forceSort': '依照标题排序',
	'lessonAppendExercise.sortingExercise': '重新排序中，请耐心等候',
	'lessonAppendExercise.subtitle.amountcount': '已选择 {{amountcount}} 个项目',
	'lessonAppendExercise.repeated.alert': '请注意！请避免汇入重复的题目(例如不同题组里有相同题目)，系统会阻挡喔！ ',
	'lessonAppendExercise.existed.alert': '您选取的题目/题组，有部分题目已存在单元内容或课后作业，请取消选择',
	'lessonAppendExercise.material': '讲义将从教学资源库中汇入，请先将资料上传至',
	'lessonAppendExercise.material.note2': '',

	'lessonAppendExercise.no.exercise': '教学资源库尚未有任何习题',
	'lessonAppendExercise.no.video': '教学资源库尚未有任何影片',
	'lessonAppendExercise.no.exerciseGroup': '教学资源库尚未有任何题组',

	'lessonAppendExercise.noSearch.exercise': '没有符合搜寻的习题',
	'lessonAppendExercise.noSearch.video': '没有符合搜寻的影片',
	'lessonAppendExercise.noSearch.exerciseGroup': '没有符合搜寻的题组',

	'lessonAppendMaterial.no.material': '教学资源库尚未有任何讲义',
	'lessonAppendMaterial.noSearch.material': '没有符合搜寻的讲义',
};

const testRatioInfo = {
	'testRatioInfo.none': '尚无练习题',
	'testRatioInfo.note.1': '请设定各题分数，若点击自动分配则系统自动平均配分 (总分 100)，',
	'testRatioInfo.note.1.sub': '自动配分采四舍五入，差额的分数会累计在最后一题!',
	'testRatioInfo.avg': '自动配分',
	'testRatioInfo.sum': '目前分数合计',
	'testRatioInfo.individual.allocation': '个别配分',
};

const testPreview = {
	'testPreview.content': '内容',
	'testPreview.lang': '试卷程式语言',
	'testPreview.details': '试卷内容',

	'testPreview.highestscore': '最高成绩',
	'testPreview.testamountofenter': '挑战次数',
	'testPreview.lastscore': '近一次成绩',

	'testPreview.gototest': '前往考试',
	'testPreview.gotomock': '前往挑战',
	'testPreview.handintest': '已提交',
	'testPreview.commingsoon': '即将开放',
	'testPreview.viewtestdetail': '检视考试内容',
	'testPreview.testscore': '考试成绩',
	'testPreview.testamountofquestion': '试卷题数',
	'testPreview.testspendingtime': '你花费的时间',
	'testPreview.testendgap': '距离活动结束',
};

const addMaterial = {
	'addMaterial.success': '成功新增',
	'addMaterial.fail': '新增失败',
};

const course = {
	'course.backToMyCourses': '回到我的课程',
	'course.newTip': '新增课程',
	'course.all': '所有课程',
	'course.allLessons': '所有单元',
	'course.ready': '即将开始',
	'course.ongoing': '现正开课',
	'course.notyet': '尚未开始',
	'course.end': '已经结束',
	'course.package-ongoing': '套装课程',
	'course.package-past': '套装课程(过期)',
	'course.self-ongoing': '自建课程',
	'course.self-past': '自建课程(结束)',
	'course.teacher': '{{teacher}}老师',

	'course.buymyself.banner1': '购买套装课程，或增加人数上限',
	'course.buymyself.banner2': '让您教学更简单！',
	'course.buymyself.banner3':
		'每个教师帐号拥有200个免费学生座位，若购买套装课程，就能增加学生人数上限，让您教学更简单！',
	'course.buymyself.peopleused': '已用数量',
	'course.buymyself.peoplemax': '个人免费上限',
	'course.buyunit.peoplemax': '单位剩余数量',
	'course.banner.contactus': '联络 CodingBar',
	'course.banner.checklimit': '查询可用人数',

	'course.modal.modalTitle.studentRemainingUse': '可用学生数',
	'course.modal.modalBody.studentRemainingUse':
		'您可用的免费使用学生帐号：{{teacherLimit}} (上限)\n\n贵单位共享，可用学生帐号数量：{{unitLimit}} (上限)',

	'course.sign': '已经报名',
	'course.no': '尚无课程',
	'course.name': '课程名称',
	'course.cid': '课程代码',

	'course.num': '个课程',
	'course.noclass': '目前非上课时间',
	'course.nosearch': '无相关课程',
	'course.search': '输入关键字或课程代码',
	'course.enterPractice': '快速进入习题',
	'course.students': '位学生',
	'course.cancelCodeEnter': '取消班级代码加入',
	'course.activeCodeEnter': '允许班级代码加入',
	'course.codeEnter': '透过班级代码加入新班级',
	'course.copyCode': '复制班级代码',
	'course.openCode': '班级代码已开启',
	'course.delete': '删除此课程',
	'course.endCourse': '结束此课程',
	'course.template': '复制课程',
	'course.use': '以',
	'course.templateConfirm': '为范本建立新课程？',
	'course.templateAlert': '复制课程后，请为课程设定代码及上课时程。',
	'course.endEarly': '确认提早结束课程？',
	'course.deleteConfirm': '确认删除此课程？',
	'course.endMsg': '课程结束时间将改变成为今日，您可以在「已经结束」内找到此课程资讯。',
	'course.deleteMsg': '课程删除后，您将无法查询此课程相关的任何资讯。 \n此项操作无法回复，请确认后再执行删除。',

	'course.deadline': '截止日：',

	'course.saveFailed': '课程 储存失败!',
	'course.deleteSuccess': '成功删除课程！',
	'course.copying': '复制课程资讯中...',
	'course.noExercise': '目前没有课堂练习',

	'course.handout': '上课讲义',
	'course.exercise': '课堂练习',
	'course.homework': '课后作业',
	'course.allpract': '所有练习',
	'course.collection': '我的收藏',

	'course.live': '线上教室',
	'live.class': '上课模式',
	'live.test': '测验模式',
	'live.nounits': '无其他单元',
	'course.attachment': '补充教材',
	'course.norelease': '作业尚未释出',
	'course.no_homework': '目前没有课后作业',

	'course.endMsg2': '本课程已结束',
	'course.addhandoutFailed': '新增讲义至课程{{course}}储存失败',
	'course.updateExerciseFailed': '练习题清单失败!',
	'course': '课程',
	'course.test.start': '开始测验',
	'course.test.end': '结束课程',
	'course.test.end2': '测验',
	'course.test.startFailed': '开始课程试卷失败!',
	'course.test.endFailed': '结束课程试卷失败!',
	'course.test.setduration': '选择考试时间长度',
	'course.testIng': '测验中',
	'course.test.handin': '交卷',
	'course.test.not.handin': '尚未交卷',

	'course.test.noticeTitle': '模拟考注意事项',
	'course.test.noticeContext': `本次测验为 150 分钟，分为「观念 20 题」与「实作 2 题」，测验中请勿关闭浏览器或中断网路，或离开考试接口，否则将无法储存你的答题结果。\n
	开始考试时，点击未完成项目的第一题，即可进入 compiler 环境开始考试；\n(实作题环境提供 Python、C、C++ 语言作答，请透过左侧设定进行切换)\n
	每一题作答时，请务必确保记得按下方提交键，可透过左侧图示检查已提交答案题目，\n
	考试完成时，您可按下交捲结束考试，或时间结束后由系统自动交捲；交捲后，您将于 5 分钟内收到一份测验结果与简单分析，作为正式考前的参考依据。\n
	点击下方按钮，就开始计时考试了，祝您考试顺利！ ; )`,

	'course.test.EndTitle': '模拟考注意事项',
	'course.test.EndContext': `请注意，在结束测验前，请确认已将全部题目的程式码/答案都「提交」系统，否则将无法计分\n结束后，五分钟内我们将把测验结果寄到你的注册信箱！`,
	'course.test.HandinTitle': '您确定要交卷？',
	'course.test.HandinContext': `您尚有 {{unsubmitquestion}} 题未提交（试卷共含 {{totalquestion}} 题），并有 {{markquestion}} 题仍标注待检查。
	一旦交考卷，将离开考试活动、无法继续作答。此动作不可回复，请再三确认后再执行此动作！`,

	'course.test.notitle': '无测验题目',
	'course.test.name': '测验名称',
	'course.test.tried': '尝试过的题目',
	'course.test.notfinished': '尚无完成的测验题目',
	'course.test.placeholder': '随堂测验, 期中考...',
	'course.test.time': '测验时间',
	'course.endTest': '结束测验',

	'course.endTest2': '提交考卷',
	'course.returnTest': '回到考试',
	'course.timesUp': '！测验时间结束！',

	'course.test.title': '测验题目',
	'course.test.tip': '选择测验试卷后，可以在这里看到试卷里面的题目',
	'course.test.choose': '请选择...',

	'course.test.commonModalTitle': '测验注意事项',
	'course.mock.noticeContext1': '在开始模拟测验前，请注意：',
	'course.mock.noticeContext2':
		'测验中请勿关闭浏览器或中断网路，或离开考试介面，否则将无法储存你的答题结果，另外超过考试规定时间将无法作答。',
	'course.mock.noticeContext3': '测验完成时，您可按下「交卷」，或时间结束后由系统自动交卷；交卷后，将不能更改答案。',
	'course.mock.noticeContext4': '您可重复进行挑战同一份考卷，但在检讨模式下，系统将只保留最后一次的作答内容',
	'course.mock.noticeContext5': '点击「开始作答」将开始计时，祝你刷题刷爽爽！',

	'course.test.noticeContext1': '在开始考试前，请注意：',
	'course.test.noticeContext2':
		'测验过程中如有：关闭浏览器、网路断线或离开考试介面等情形，考试仍持续计时，超过考试规定时间将无法作答。',
	'course.test.noticeContext3': '测验完成时，您可按下「交卷」，或时间结束后由系统自动交卷；交卷后，将不能更改答案。',
	'course.test.noticeContext4': '若您的试题经过乱数排序，在检讨模式下的题目序号可能有所差异。',
	'course.test.noticeContext5': '点击“开始作答”将开始计时，祝您考试顺利！ ; )',

	'course.retest': '重考',
	'course.retest2': '重考试卷试卷',
	'course.addTesttoCourse': '试卷新增至课程',
	'course.lessonEnd': '此单元都已结束',
	'course.notstarted': '此课堂单元尚未开始',
	'course.time': '本周上课时间',
	'course.timeFormat': '上课时间',
	'course.nextTime': '下次上课时间',
	'course.alreadyEnd': '此课堂已结束',
	'course.nohandout': '目前没有讲义',
	'course.noExercise': '目前没有练习',
	'course.noHomework': '目前没有课后作业',

	'course.removeExercise': '移除此练习题',
	'course.sync': '同步教学资源',

	'course.content': '课程的内容描述',

	'course.videoError': '非常抱歉 影片载入发生错误',
	'course.videoTutorial': '影片操作教学',
	'course.vidTutorial1': '1.影片播放中按下键盘⇨键可以往前快转',
	'course.vidTutorial2': '2.影片播放中按下键盘⇦键可以往回快转',
	'course.vidTutorial3': '3.长按⇨键或⇦键可以持续的快转影片',
	'course.vidTutorial4': '4.影片控制区里的齿轮符号可以调整影片画质(Quality)和播放速度(Speed)',
	'course.videoDontShow': '不再显示',

	'course.deleteConfirmMsg': '单元以及其所有的设定删除后将无法再复原',
	'course.thisweek': '本周单元',
	'course.lesson': '单元',
	'course.a.lesson': '单元',

	'course.add': '加入课程',
	'course.addSuccess': '试卷新增至课程 {{title}} 储存成功!',
	'course.addFail': '试卷新增至课程 {{title}} 储存失败!',

	'classunit.D': '确认并班整课程单元时间与内容',
	'classunit.editClass': '编辑班级',
	'classunit.newClass': '新增课程',
	'classunitlist.lessonTitle': '课程标题',
	'classunitlist.day': '上课日期',
	'classunitlist.startTime': '开始时间',
	'classunitlist.endTime': '结束时间',
	'classunitlist.content': '单元内容',
	'classunitlist.add': '新增另一组规则',
	'classunitlist.courseday': '选择上课周期',

	'classinfo.begin': '初级班',
	'classinfo.medium': '中级班',
	'classinfo.advanced': '进阶班',
	'classinfo.A': '新增程式语言与难度',
	'classinfo.lantype': '语言类型',
	'classinfo.MainLanType': '主要使用语言',
	'classinfo.level': '难易程度',
	'classinfo.Amount': '课程堂数',
	'classinfo.CourseCustomLabel': '自订标签',
	'classinfo.CourseCustomLabelDescription': '自订标签可帮助之后搜寻更加快速',
	'classinfo.weeks': '上课周数',
	'classinfo.startDate': '开始上课日期',
	'classinfo.B': '新增班级代码与名称',
	'classinfo.code': '班级代码',
	'classinfo.title': '班级名称',
	'classinfo.CourseTitle': '输入课程名称',
	'classinfo.CourseTitleDescription': '课程名称应不得超过 20 个字',
	'classinfo.teacher': '授课老师',
	'classinfo.mainpoint': '课程主旨',
	'classinfo.CourseIntro': '输入课程简介或附注',
	'classinfo.C': '指定每周上课日',
	'classinfo.idplaceholder': '6-8位英数',
	'classinfo.CourseAmountLimit': '请输入整数数字',
	'classinfo.nameplaceholder': '最多15个字元，包含中英',
	'classinfo.description': '描述本课程主旨、范围或适用班级对象',

	'course.exerciseFinished': '练习都已完成',
	'course.moreExercise': '完成拉？ 来跟老师领....更多练习',
	'course.exercise.nocollection': '尚无收藏',
	'course.exercise.collectionTips': '透过选项，可以将该练习题加入收藏哦！',

	'course.entercode': '输入课程代码',
	'course.code': '课程代码',
	'coursecode.placeholder': '请输入课程代码',
	'coursecode.change': '更改此课堂代码的座号',
	'coursecode.hint': '您可以透过学校老师提供的班级代码，加入课程。',
	'coursecode.nocourse': '查无课程，请确认输入的班级代码',

	'course.nostudent': '学生列表是空的，确定要更新课程吗?',

	'course.placeholder.searchKeywordOrQuestion': '输入关键字或题号',
	'course.placeholder.searchKeywordOrLesson': '输入关键字或堂数',

	'course.labels.inProgress': '进行中',
	'course.labels.notStarted': '未开始',
	'course.labels.ended': '已结束',
	'course.labels.live': 'Live!',

	'course.clone.copyCourseAsPackage': '复制为套装课程',
	'course.clone.copyToOtherUnit': '复制至其他单位',
	'course.clone.copyCourse': '分享课程及教学资源',
	'course.clone.copyAssignUnit': '指派课程',
	'course.export.exercise': '汇出课程',
	'course.clone.currentUnit': '目前单位',
	'course.clone.currentCourseId': '目前课程ID',
	'course.clone.targetUnit': '目标单位',
	'course.clone.targetTeacher': '目标教师帐号',
	'course.clone.placeholder': '可不填，自动生成',
	'course.clone.teacherHasCourse': '老师已有该课程',
	'course.lesson.notSetTime': '尚未设定时间',

	'course.cloneToolTips.cloneResource': '复制课程资源',
	'course.cloneToolTips.exerciseAndVideo': '题目/影片',
	'course.cloneToolTips.cloneHandout': '讲义',
	'course.cloneToolTips.cloneTest': '试捲',
	'course.cloneToolTips.cloneGroupExercise': '题组',
	'course.cloneToolTips.editTest': '编辑试捲',
	'course.cloneToolTips.createTest': '建立新考试活动',
	'course.cloneToolTips.cloneToClone': '将副本课程分享他人',

	'course.package.addPackage': '汇入套装课程',
	'course.package.copyCourseAsPackage.title1': '将',
	'course.package.copyCourseAsPackage.title2': '复制为套装课程？',
	'course.package.copyCourseAsPackage.confirm':
		'复制为套装课程后，您无法编辑、修订该套装课程内容；请先确认目前内容后再执行。 \n套装课程的指派请由课务管理系统操作。',

	'course.transform.asGlobalTest': '转为公开模拟考',
	'course.global.mock.exam': '公开模拟考',
	'course.transform.globalTestConfirm': '此课程已为公开模拟考，是否取消转变？',

	'course.duplicate': '复制',
	'course.today': '本日課程',
	'course.delete.lesson': '删除此单元',
	'course.sync.exercise.fail': '同步习题失败',
};

const studentlist = {
	'role.students': '学生',
	'role.teachers': '老师',
	'role.mentors': '教师',
	'role.staffs': '员工',
	'role.staffs1': '行政人员',
	'role.admin': '管理员',
	'role.other': '其他',

	'title.teacherMaintenance': '师资维护',
	'title.studentMngmt': '学生管理',
	'title.staff': '行政管理',
	'title.crm': 'CRM 名单管理',
	'title.unknown': '未知',

	'label.teacher': '老师名单',
	'label.student': '学生名单',
	'label.admin': '行政名单',
	'label.crm': 'CRM 名单',
	'label.staff': '非 CRM 名单',
	'label.courseScore': '班级成绩',
	'notloginyet': '尚未登入过',
	'totalNum': '总共人数',
	'studentlist.import': '汇入学生',
	'studentlist.confirmImport': '确认汇入',
	'studentlist.confirmModal.title': '重复学生',
	'studentlist.confirmModal.exist.in.unit': '已存在目前单位',
	'studentlist.confirmModal.exist.in.course': '已存在课程中',
	'studentlist.confirmModal.exist.teacher': '已存在的老师帐号',
	'studentlist.confirmModal.exist.other.unit': '已存在其他单位',
	'studentlist.confirmModal.block': '汇入列表当中以下学生项目仍有问题，请编辑汇入学生资讯或移除。',
	'studentlist.confirmModal.content':
		'汇入列表中有 “已存在目前单位” 或 "已存在课程中" 的学生项目，汇入将更新资讯，请确认是否要汇入？ ',
	'studentlist.loading': '正在载入学生列表中, 请等待载入结果',
	'studentlist.waiting.course.update.result': '正在等待学生列表更新结果, 请不要重复点击',

	'import.noNew': '没有新学生加入！',
	'import.student.title': '汇入学生至',
	'import.selectInstruction': '你现在可以透过下方搜寻列快速选择已经报名学生，加入班级。',
	'import.searchDropdownName': '学生姓名',
	'import.searchDropdownClass': '学生班级',
	'import.searchPlaceholder': '输入关键字搜寻',
	'import.backPage': '回到上一页',
	'import.selectFile': '选择档案',
	'import.selectFileLabel': '尚未汇入档案',
	'import.downloadTemplate': '学生档案范本',

	// 'import.downloadNotes-1': '(1). 您可点击右下方的「下载 csv 范本」下载范例档案，建立学生资料后，汇入自动产生学生帐号；若开启 csv 范本为乱码，请将',
	// 'import.downloadNotes-2': '编码变更为 unicode，或使用 google 试算表',
	// 'import.downloadNotes-3': '开启。',
	// 'import.downloadNotes-4': '(2). 学生帐号预设密码为',
	// 'import.downloadNotes-5': '小写英文字元「airabbi」',
	// 'import.downloadNotes-6': '；若平台内已有相同的学生帐号(email信箱)时，您汇入的学生资料将不会被建立。',

	'import.downloadNotes-1': '(1) 请参考 ',
	'import.downloadNotes-2': '范本档案',
	'import.downloadNotes-3': '，依格式将名单制作为 csv 档并汇入，系统自动产生学生帐号；若开启 csv 范本为乱码，请将',
	'import.downloadNotes-4': '编码变更为 unicode，或使用 google 试算表',
	'import.downloadNotes-5': '开启。',
	'import.downloadNotes-6': '(2) 学生帐号预设密码为',
	'import.downloadNotes-7': '小写英文字符「airabbi」',
	'import.downloadNotes-8': '；若平台内已有相同的学生帐号(email 信箱)时，请编辑汇入学生 Email 或 取消勾选。',

	'import.downloadCsvTemplate': '下载 csv 范例档案',
	'import.defaultPasswd': '学生预设密码为',
	'import.defaultPasswd2': '(七个小写英文字元)',
	'import.later': '稍后再加入学生',
	'import.fromCSV': '从 .CSV 汇入',
	'import.fromResource': '从 平台 汇入',
	'import.fromCSV.wrongEmailWarning': '信箱栏位有误，请重新上传档案',
	'import.fromCSV.wrongRow': '行数',
	'import.fromCSV.warning.studentExist': '已存在课程中，汇入将更新学生帐号',
	'import.fromCSV.warning.studentExist.unit': '已存在于单位中，汇入将更新学生帐号',
	'import.fromCSV.warning.studentExistInOtherUnit-1': '此',
	'import.fromCSV.warning.studentExistInOtherUnit-2': '帐号已存在于',
	'import.fromCSV.warning.studentExistInOtherUnit-3': '，汇入将覆盖成学生帐号',
	'import.fromCSV.role.teacher': '老师',
	'import.fromCSV.role.student': '学生',
	'import.studentLimit.passedStudentImportLimit': '总学生人数已超过{{limit}}人限制',

	'userlistview.confirmDelete': '确定要删除?',
	'userlistview.unset': '尚未建立资料',
	'userlistview.notFound': '找不到资料',
	'userlistview.noparentData': '没有家长资料',
	'userlistview.notel': '没有联络电话',
	'userlistview.notification': '发送通知',

	'userlistview.newStudent': '新增学生资料',
	'userlistview.newTeacher': '新增老师资料',
	'userlistview.newBatchTeacher': '新增批次老师资料',
	'userlistview.newBatchCSFCStudent': '新增 CSFC 学生资料',
	'userlistview.newStaff': '新增行政人员资料',
	'userlistview.deleteUser': '删除使用者？',
	'list.backToStudentList': '回到学生名单列表',
	'list.backToPrevious': '回到上层',
	'import.package.course.student.alert': '请注意！套装课程的学生一但汇入后超过反悔期，将无法被移除，请再三确认',
};

const test = {
	'test.title': '测验试卷',
	'test.no': '尚无测验试卷',
	'test.nopractice': '尚无模拟练习',
	'test.notitle': '尚无测验题目',
	'test.noresult': '尚无测验结果',
	'test.nottakeyet': '本试卷尚未测验',
	'test.alltest': '考试',
	'test.allpractice': '模拟练习',
	'test.tested': '已测验',
	'test.unchangable': '无法修改',
	'test.add.tip': '课程试卷',
	'test.add.select': '选择测验试卷',
	'test.add.new': '新增测验试卷',

	'test.append.title': '选择试卷',
	'test.append.lang3': '的试卷',

	'test.append.lang': '课程语言为{{codeType}}, 仅显示{{codeType}}的试卷',

	'test.nomatch': '没有符合搜寻条件的试卷',

	'test.classTest': '随堂测验',
	'test.num': '测验人数',
	'test.finished': '完成测验',
	'test.leftTime': '测验剩余时间',

	'test.time': '测试时间',
	'test.infotitle': '试卷题目',
	'test.student': '受测学生',
	'test.fail': '不及格',
	'test.pass': '及格',
	'test.absents': '缺考学生',
	'test.score': '得分',
	'test.corrNum': '答对题数',
	'test.wrongNum': '答错题数',
	'test.studentScore': '学生成绩',
	'test.ended': '测验已结束',

	'test.solvingTime': '时间',
	'test.videoTime': '影片时间',
	'test.runtime': '执行',

	'test.finishedpeople': '加油！有{{progress}}位同学已完成',
	'test.be1': '抢先成为第一个完成这个练习的同学！',

	'test.correct': '本题作答正确',
	'test.incorrect': '本题作答错误',

	'test.confirmRetake': '确定要重新挑战本题吗？',
	'test.openFolder': '开启工作目录',

	'test.hasSubmitted': '已提交答案',
	'test.tryNextExercise': '继续挑战下一题吧！',

	'test.alert.preventCopyPaste': '考试中请不要尝试复制贴上...',
	'test.finished': '测验结束',
	'test.alert.testFinished': '测验已结束！！！！',
	'event.score.sum': '总分',
};

const testEvent = {
	'test.event.type': '活动类型',
	'test.event.course.test': '课堂考试',
	'test.event.mock.test': '模拟练习',
	'test.event.is.start.now': '是否即刻开始',
	'test.event.course': '课程班级',
	'test.event.title': '考试名称',
	'test.event.duration': '考试时间（分钟）',
	'test.event.start.date': '开放日期',
	'test.event.start.time': '开放时间',
	'test.event.end.date': '截止日期',
	'test.event.end.time': '截止时间',
	'test.event.test.info': '测验说明',
	'test.event.test.result': '显示答题结果',
	'test.event.test.import': '汇入试卷',
	'test.event.placeholder.test.title': '输入考试活动名称',
	'test.event.placeholder.duration': '输入分钟数',
	'test.event.duration.alert': '时间应介于1~9999分钟',
	'test.event.not.set.yet': '尚未指定',
	'test.event.step.one.note1':
		'预先排定考试时间，系统将于指定时间自动开放测验活动；选择「学生练习」可让学生重复模拟考试，但不列入学生成绩',
	'test.event.step.one.note2': '请由教学资源库汇入试卷，一场考试仅含一张试卷',
	'test.event.step.one.note3': '可依不同需求，自由设定测资开放(若题目有包含测资)、解答、提示显示或乱数排序题目等',
	'test.event.settings.mode.title': '模式设定',
	'test.event.settings.assist.title': '试卷辅助资讯',
	'test.event.settings.vd.title': '测资显示设定',
	'test.event.settings.other.title': '其他设定',

	'test.event.settings.mode.custom': '自订',
	'test.event.settings.vd.vdResults': '显示测资结果',
	'test.event.settings.vd.vdContent': '显示测资内容',
	'test.event.settings.other.random': '乱数排列',
	'test.event.settings.other.copyPaste': '允许複製贴上',

	'test.event.settings.vd.tip': '第一笔测资提示',
	'test.event.settings.vd.length': '测资数：{{num}}',

	'review.test.modal.title': '检讨测资开放设定',
	'test.event.placeholder.releaseVdLength': '开放测资数',
	'review.test.setting.title': '检讨资源设定',
	'review.test.setting.note':
		'您可以由此开放相关资源，学生将能在检讨模式中查看完整解答、提示、测资等，重新练习、提交、修正观念。',
	'review.test.event.info': '考试活动资讯',
	'test.event.settings.chose.begin.date': '请选择开放日期',
	'test.event.settings.chose.end.date': '请选择截止日期',
	'test.event.settings.alert.end.date': '截止日期不能早于开放日期',
	'test.event.settings.alert.end.time': '截止时间不能早于开放时间',
	'test.event.settings.alert.duration': '考试开放期间不能小于考试时间',
	'test.event.review.students.note': '检视学生成绩',
	'test.event.review.students.note.download.csv': '下载测验状态.CSV',

	'test.event.show.advanced.setting': '显示进阶选项',
	'test.event.hide.advanced.setting': '隐藏进阶选项',
	'test.event.have.not.select.test.yet': '尚未汇入试卷',
	'test.event.settings.assist.vd.type': '测资题型辅助资讯',
	'test.event.settings.assist.none.vd.type': '非测资题型辅助资讯',

	'test.detail.page.reset.student.exercise.status': '重置学生作答记录',
	'test.detail.page.reset.student.exercise.status.note':
		'当您更改教学资源库多元题型的题目内容，并且同步到课程与试卷时，已作答过的学生纪录会与更新过的题目内容不符。 \n重置学生作答记录会将学生的纪录与分数情除，您确定要重置这场考试该题的所有学生作答记录吗？ ',
	'reset.studentStatus.success': '重置成功 ',
	'reset.studentStatus.fail': '重置失败 ',

	'csfc.test.event': 'CSFC 考试场次',
	'csfc.test.event.not.add.yet': '尚未建立场次',
	'csfc.test.event.create.modal.title': '建立 CSFC 考试场次',
	'csfc.test.event.edit.modal.title': '编辑 CSFC 考试场次',
	'csfc.test.event.delete.modal': '删除 CSFC 考试场次',
	'csfc.test.event.delete.modal.body': '确认是否删除 CSFC 考试场次',
	'csfc.test.event.delete.modal.toast.success': '成功删除 CSFC 考试场次',
	'csfc.test.event.preview.modal.title': '预览 CSFC 考试场次',
	'csfc.test.event.form.subject': '考试场次项目',
	'csfc.test.event.form.level': '考试场次等级',
	'csfc.test.event.form.concept.test.duration': '观念题考试时间（分钟）',
	'csfc.test.event.form.implementation.test.duration': '实作题考试时间（分钟）',
	'csfc.test.event.form.implementation.earlier.than.concept.alert': '实作题开始时间应大于观念题结束时间',
	'csfc.test.event.form.can.not.set.past.test.alert': '不可设定过去时间',
	'csfc.test.event.form.note.1': '预先排定考试时间，系统将于指定时间自动开放测验活动',
	'csfc.test.event.form.note.2': '请由教学资源库汇入试卷，一场考试仅含一张试卷',
	'csfc.test.event.form.concept.note': '第一场考试 (根据 CSFC 简章，请设置观念题)',
	'csfc.test.event.form.implementation.note': '第二场考试 (根据 CSFC 简章，请设置实作题；初级考试不需设置第二场考试)',
	'csfc.delete.course': '删除 CSFC 课程',
	'csfc.delete.course.confirmation.body': '确认是否删除 CSFC 课程',
	'csfc.delete.course.and.event.toast.success': '成功删除 CSFC 考试场次与课程',
	'csfc.delete.course.alert.user.already.exist': '已汇入学生无法删除 CSFC 课程',
	'csfc.delete.event.alert.user.already.exist': '已汇入学生无法删除 CSFC 场次',
	'csfc.edit.event.alert.user.already.exist': '已汇入学生无法编辑 CSFC 场次',
};

const score = {
	'score.title': '学习成绩',
	'score.avg': '平均',
	'score.sum': '合计',
	'score.none': '尚无评量资料',
	'score.download': '下载成绩单(CSV格式)',
};

const student = {
	'student.learningreport': '学习报告',
	'student.none': '您尚未汇入学生',
	'student.placeholder': '输入学生名字/电子信箱',
	'student.placeholder2': '输入帐号或姓名关键字',
	'student.sendreport': '寄送成绩报告',
	'student.sendreport.confirm': '确定要寄送成绩报告? 所有成绩都填写好了吗?',
	'student.downloadReport': '下载成绩报告',
	'student.fillReport': '填写成绩报告',
	'student.advanced': '高年级',
	'student.removeConfirmTitle': '移除学生',
	'student.confirmation1': '确定要移除学生:',
	'student.reAddStudent.confirm.title': '重新加回',
	'student.reAddStudent.confirm.content': '确定要重新加回学生?',
	'student.confirmation2': '再次确认！',
	'student.lastShown': '上次出现:',
	'student.reportSendTime': '成绩于{{time}}寄出',
	'student.sta': '统计数据',
	'student.finishQs': '完成题目数量',
	'student.exrciseTime': '本周课后练习时间',
	'student.avgTipUsage': '平均使用提示数',
	'student.question': '本周问问题数量',
	'student.info': '学生基本资料',
	'student.sex': '性别',
	'student.male': '男',
	'student.female': '女',
	'student.unknown': '尚未填写',
	'student.school': '就读学校',
	'student.grade': '就读年级',
	'student.summary': '学习摘要',
	'student.currentFeedback': '最近的老师评语',
	'student.editFeedback': '编辑评语',
	'student.sendCareMsg': '寄送关怀信',
	'student.courseProgress': '课程进度',
	'student.courseTime': '此处显示课程时间',
	'student.futureandthisweekCourse': '本周及尚未开始的单元',
	'student.showCourseNameandProgress': '此处显示课程单元名称以及完成进度',
	'student.unitEnd': '已经结束的单元',
	'student.allFeedback': '所有的评语',
	'student.courseinfo': '课程资讯',
	'student.nocourseinfo': '没有课程资讯',
	'student.timeuse': '使用时间',
	'student.teacherFeedBack': '教师评语',
	'student.basicinfo': '基本资料',

	'student.sendCareMsgTitle': '寄送关怀信给',
	'student.care.emal': '请输入学生E-mail',
	'student.care.emalTitle': '学生E-mail',
	'student.care.parentemal': '请输入家长E-mail',
	'parent.care.emalTitle': '家长E-mail',
	'studentMsgTitle': '学生Email标题',
	'parentMsgTitle': '家长Email标题',
	'studentMsgTitle.placeholder': '请输入学生Email标题',
	'parentMsgTitle.placeholder': '请输入家长Email标题',
	'asktimes.placeholder': '请输入问问题次数',
	'asktimes': '问问题次数',

	'tutorialvids': '教学影片',
	'tutorialvids.fin': '影片完成数量',
	'tutorialvids.total': '影片总数量',
	'exercise.fin': '练习题完成数量',
	'exercise.correct': '练习题答对数量',
	'exercise.total': '练习题总数量',

	'hw.fin': '作业完成数量',
	'hw.correct': '作业答对数量',
	'hw.total': '作业总数量',

	'studentcomments': '学生评语',
	'parentComments': '家长评语',

	'student.continue.course': '续续上课',
};

const exercise = {
	'exercise.exercise': '课堂练习',
	'exercise.homework': '课后作业',
	'exercise.status': '此练习题开放功能与状态',
	'exercise.studentSummary': '学生摘要',
	'exercise.ans': '解题答案',
	'exercise.student': '线上学生',
	'exercise.mainpoint': '重点观察',
	'exercise.help': '需要帮助',
	'exercise.idlestate': '闲置状态',
	'exercise.finished': '完成练习',
	'exercise.time': '练习时间',
	'exercise.simcheck': '相似度检查',
	'exercise.simcheckTitle': '程式码相似度',
	'exercise.finishRate': '完成率',
	'exercise.readTips': '已查看的拆解提示',
	'exercise.realtimecode': '即时的程式码',
	'exercise.codeLastTime': '上一次执行的程式码',
	'exercise.codeLanguage': '使用语言',
	'exercise.helperSettings': '辅助功能',
	'exercise.openCodeHelper': '启用程式小助手',
	'exercise.editorSettings': '编辑器设定',
	'exercise.editorLanguage': '编辑器语言',
	'exercise.fontSize': '字体大小',
	'exercise.notRunyet': '尚未执行过',
	'exercise.checkDetails': '点我看详细内容',
	'exercise.progress': '学生进度',
	'exercise.resource': '资源下载',
	'exercise.result': '执行结果',
	'exercise.exampleformat': '范例格式',
	'exercise.input': '输入',
	'exercise.output': '输出',
	'exercise.newFn': '*新功能*',
	'exercise.newFn1': '1. 执行: 单纯执行程式码，不进行测资验证。',
	'exercise.newFn2': '2. 提交: 进行测资验证。',
	'exercise.gotit': '知道了',
	'exercise.closeTip': '下次不需提醒',
	'exercise.fillinMode': '确定要切换填空题?',
	'exercise.errorExist': '错误还没有修正，确定还是要提交程式码吗？',
	'exercise.run': '执行',
	'exercise.submit': '提交',
	'exercise.noVds': '无测资',
	'exercise.testfail': '测试失败',
	'exercise.runfail': '执行失败',
	'exercise.pass': '测试通过',
	'exercise.stop': '停止',
	'exercise.testinput': '测资',
	'exercise.tips': '提示',
	'exercise.answers': '答案',

	'exercise.reset': '重置',
	'exercise.refresh': '重新整理',
	'exercise.needhelp': '需要帮助',
	'exercise.idle': '闲置',
	'exercise.student-exercise': '学生\\习题',

	'exercise.repeatExercise': '再做一次',
	'exercise.returnExercise': '返回作答',
	'exercise.nextExercise': '下一题',
	'exercise.understandMore': '点我查看',
	'exercise.whichTestInput1': '第',
	'exercise.whichTestInput2': '项测资',
	'exercise.whichTestInput': '第{{number}}项测资',
	'exercise.testInputPassed': '通过',
	'exercise.testInputFailed': '错误',
	'exercise.testInput.resultMsgSuccess': '提交通过，太棒了！',
	'exercise.testInput.resultMsgFail': '提交错误，快检查！',
	'exercise.testInput.details': '查看测资详情',
	'exercise.testInput.backToDetails': '回到测资详情',
	'exercise.testInput.yourOutput': '您的output',
	'exercise.testInput.correctOutput': '正确output',
	'exercise.testInput.viewDetails': '查看说明',
	'exercise.testInput.previousPage': '回到上页',

	'exercise.result.success': '太棒了！',
	'exercise.result.successMsg': '你能完成这题，表示前面的观念都有学到\n\n继续挑战下一题吧！',
	'exercise.result.fail': '错误！',
	'exercise.result.failMsg': '诶...执行程式结果，看起来有错误呀\n\n快去检查一下吧',

	'exercise.result.lintError': '独眼怪提示尚未修正',
	'exercise.result.lintErrorMsg': '独眼怪提示尚未修正，请先修正提示错误，再次提交哦～',

	'exercise.playground.enterCode': '在此输入Code',
	'exercise.playground.outputResult': '输出结果',

	'exercise.executionFinished': '执行完毕',
	'exercise.executing': '执行中',
	'exercise.executingInput': '执行中，请输入资料',

	'exercise.addExercise.selectType': '题型选择',
	'exercise.addExercise.selectLanguage': '语言类型',
	'exercise.addExercise.title': '习题标题',
	'exercise.addExercise.tags': '自订标签(选填)',

	'exercise.tips.noTipsOrUnreleased': '暂无提示或尚未开放',
	'exercise.tips.noAnswersOrUnreleased': '暂无详解或尚未开放',

	'exercise.tips.title': '提示清单',
	'exercise.answer.title': '习题答案',
	'exercise.answers.title': '解答列表',
	'exercise.materials.title': '资源下载',

	'exercise.testInput.msg.success': '厉害唷，测资通过了！点击下方按钮进入下一题吧！',
	'exercise.testInput.msg.fail': '你提交的答案有部分不正确，检查程式码并重新修正吧！',
	'exercise.testInput.msg.fail.nonFreecode': '你提交的答案不正确，快点回去检查看看有什么地方写错了吧！',
	'exercise.testInput.msg.success.nonFreecode': '太棒了！答题正确！点击下方按钮进入下一题吧！',
	'exercise.testInput.msg.success.step': '厉害唷，测资通过了！点击下方按钮进入下一步吧！',
	'exercise.testInput.msg.success.nonFreecode.step': '太棒了！答题正确！点击下方按钮进入下一步吧！',
	'exercise.runInput.msg.fail': '你的程式码有写错的地方！查看提示，再检查看看哪边写错吧！',
	'exercise.config.fontSize': '字级',
	'exercise.config.editorSetting': '编译器设定',
	'exercise.config.otherSetting': '其它设定',
	'exercise.config.otherSetting.error.hint': '独眼怪自动侦错',

	'exercise.systemLimit.overTimeLimit': '系统执行时限倒数',

	'exercise.killReason.overTimeLimit': '执行时间超时！请确认输入测资或修正演算法。',
	'exercise.killReason.overSystemCPU': '系统忙碌中，自动终止执行程序，请稍后再试。',
	'exercise.killReason.overProcessLimitTime': '执行时间超过测资限制！请改善您的演算法。',
	'exercise.killReason.overCpuUsage': '执行佔用 CPU 超过系统限制！请改善您的演算法。',
};
const question = {
	'student.question.title': '我的问题集',
	'student.question.hint': '点击左边问题，查看问题与回覆',
	'student.question.placeholder': '搜寻学生问题',
	'student.question.fin': '是否完成此题',
	'student.question.allquestions': '练习问题总览',

	'student.createQuestionError': '发送问题错误',
	'student.createQuestion': '建立问题',
	'student.enterQuestionPlaceholder': '请输入标题或内容',
	'studnet.questionContent': '问题内容',
	'studnet.questionContentPlaceholder': '请输入问题内容...',

	'student.questionTab.unanswered': '待回答',
	'student.questionTab.allCourses': '所有课程',
	'student.questionTab.myCourses': '我的课程',

	'student.question.noQuestions': '没有任何问题',
	'student.question.untagged': '未标注',
	'student.question.tagged': '已标注',
	'student.question.askQuestion': '我要问问题',
	'student.question.tagAddSuccess': '建立 Tag: {{tag}} 成功！',
	'student.question.alert.removeTag': '确定要移除 {{tag}} ?',
	'student.question.alert.tagRemoved': '{{tag}} 已移除!',
	'student.question.createNewTag': '建立新标签',
	'student.question.tagCategory': '分类标签',
	'student.question.questionTitle': '问题题目：',
	'student.question.questionContent': '问题内容：',

	'student.question.question': '问题',
	'student.question.NewQuestion': '新问题',
	'student.question.Constructing': '正在送出问题',
	'student.question.noTitle': '请输入标题',
	'student.question.noContent': '请输入内容',
};

const feedback = {
	'feedback.title': '问题与反馈',
	'feedback.subject': '标题',
	'feedback.subjectPlaceholder': '填写主旨',
	'feedback.description': '备注',
	'feedback.descriptionPlaceholder': '简单说明情况',
	'feedback.screenshot': '当前画面撷取',
	'feedback.withScreenshot': '连同截图一并送出',
	'feedback.msg': '谢谢您提供的问题与反馈',
	'feedback.enterDescription': '请输入问题描述',
	'feedback.screenshotError': '萤幕截图发生问题, 请联络CodingBar',
	'feedback.submit': '传送',

	'feedback.additional.agree1': '一经传送本报告，即代表您同意 CodingBar 在遵循',
	'feedback.additional.agree2': '下，有权存取本问题所需的相关资料。',
	'feedback.additional.privatePolicy': '隐私权政策',
	'feedback.issue.unable.to.submit.code': '无法执行/提交程式码',
	'feedback.issue.loading.no.response': '画面载入中，无回应',
	'feedback.issue.unable.to.open.courses.units.topics': '无法打开课程/单元/题目',
	'feedback.issue.others': '其它',
	'feedback.issue.default': '请选择可能的问题',
};

const addTA = {
	'addTA.new': '新增助教',
	'addTA.placeholder': '请输入老师姓名或信箱',
	'addTA.alert': '助教已加入老师清单',
};

const courseManage = {
	'courseManage.langAlert': '请选择程式语言类型',
	'courseManage.mainlangAlert': '请选择主要使用语言',
	'courseManage.diffAlert': '请选择课程难易度',
	'courseManage.teacherAlert': '请选择老师',
	'courseManage.codeAlert': '请输入课程代码',
	'courseManage.nameAlert': '请输入课程名称',
	'courseManage.AmountAlert': '请输入课程堂数',
	'courseManage.descAlert': '请输入课程简介',
	'courseManage.dateAlert': '请选择上课日以及时间',
	'courseManage.edit/newSuccess': '课程 {{courseName}} 已经新增/修改成功!',
	'courseManege.addFail': '建立课程失败! {{err}}',
	'courseManege.editFail': '修改课程失败!',
	'courseManage.editSuc': '课程 {{courseName}} 已经修改成功!',
	'courseManage.newSuc': '课程建立成功',
	'courseManage.creating.course': '课程建立中...',
	'courseManage.duplicating.course': '课程复制中...',
	'courseManage.deleting.course': '删除课程中...',
	'courseManage.lesson.time.setting': '时程设定',
	'courseManage.no.weekday': '尚未选择星期',
	'courseManage.lesson.length.not.less.than': '不可少于原本单元数',
	'courseManage.release.lesson.by.schedule.notice':
		'尚未设定单元起迄时间，若您勾选「依上课时间开放单元」，学生将无法进入单元!',
};

const system = {
	'previousChpt': '上一章',
	'nextChpt': '下一章',

	'multipleLogin': '噢！看来你在别的地方登入帐号了',
	'multipleLogin.hint1':
		'为了确保你的资料安全与完整性，CodingBar只能允许一个帐号在一个位置登入；若你在第二个浏览器或其他装置登入时，目前的视窗将被系统自动登出。',
	'multipleLogin.hint2': '请确认你的登入位置与帐号密码安全，若有需要提供协助的地方，请与我们联系。',
	'apiError': '系统错误, 请稍待片刻',
};

const loginPage = {
	'login.welcomeback': '欢迎回来！',
	'login.hint': '请输入帐号和密码，或点选连结帐户快速登入。',
	'login.remember': '记住我的登入资讯',
	'login.forget': '忘记密码？',
	'login.fail': '无法登入，',

	'login.failToContact': '若无法登入，请点此联络客服',
	'login': '登入',
	'login.teacherTrial': '国高中老师试用注册',
	'login.emailPlaceholder': '请输入电子邮件',
	'login.passwordPlaceholder': '请输入密码', //NOSONAR
	'login.failed': '登入失败',
	'login.wrongEmail': '信箱格式错误, 请再次检查您的帐号',
	'login.wrongPassword': '密码错误, 请确认您输入的密码',
	'login.wrongLogin': '帐号或密码错误，请重新输入',
	'login.useGoogle': '请由下方 「使用Google登入」 进行登入',
	'login.tooManyRequests': '您已多次登入失败，目前帐号已锁定，请稍后再试',
	'login.unRegisteredEmail': '此信箱帐号尚未注册过',
	'login.networkRequestFailed': '网路不稳定，登入连线过久，请再试一次',
	'login.contactDev': '请联络 contact@airabbi.com 取得协助',
	'login.signInWithgoogle': '透过 Google 登入',
	'login.selfParent': '我是家长',
	'login.selfNotParent': '我是学生/老师',
	'login.student.carouseltext1': '提升竞争力，最佳的选择',
	'login.student.carouseltext2': '课程多元丰富，内容简单易懂',
	'login.student.carouseltext3': '线上解题问答，学习不孤单，解题不卡关',
	'signup.title': '全面开放，国高中老师注册',
	'signup.hint1': '立即用电子邮件，注册 CodingBar 帐号。',
	'signup.hint2':
		'CodingBar 现在欢迎全国国高中老师试用 CodingBar 程式教育学习系统。只要填上学校相关资讯，就可以免费试用！',
	'email.notnull': '电子邮件栏位不能为空值',
	'signup.setpw': '设定密码',
	'password.notnull': '密码栏位不能为空值', //NOSONAR
	'signup.confirmPw': '确认密码',
	'signup.enterPWtwice': '请再次输入密码',
	'signup.pwrule': '请输入密码，至少6位数，包含英数',
	'signup.pwrule2': '密码必须同时含有英文字母以及数字',
	'signup.len': '密码长度必须大于6位元',
	'signup.twicediff': '与第一次输入之密码不相同，请再确认一次',
	'signup.agree': '点击继续，代表您同意',
	'signup.agree2': '使用者条款与隐私权',
	'signup.policy': '政策。',
	'signup.term': '使用者条款',
	'signup.privatePolicy': '隐私权政策',

	'signup.enterNewPW': '请输入新密码',
	'signup.enterNewPWtwice': '请再次输入新密码',

	'signup.setPWsets': '请设定一组登入密码',
	'signup.pwrule2': '密码至少8位数，包含英文字母及数字。',

	'login.resetPassword.title': '重设密码',
	'login.resetPassword.content': '请输入您的 CodingBar Email 帐号，我们将发送密码重设信给您。',
	'login.resetPassword.placeholder': '请输入您的电子信箱',
	'login.resetPassword.sendEmail': '发送密码重设信件',
	'login.resetPassword.alert.unRegisteredEmail': '此帐号尚未注册过，请输入正确的email信箱',
	'login.resetPassword.alert.wrongEmail': '格式错误，请输入正确的email信箱',
	'login.resetPassword.resetTitle': '重设信件已寄送',
	'login.resetPassword.resetContent': '重设密码已寄送至您的信箱，请至信箱查看，并依照指引重新设定密码。',
	'login.resetPassword.gotit': '确认',
	'login.apply.trial': '申请试用',
	'login.apply.trial.title': '申请教育试用版',
	'login.apply.trial.note': '使用 .edu 网域信箱，并填写基本资料，即可开通 14 天免费试用体验！ ',
	'login.apply.trial.unit': '您的学校单位',
	'login.apply.trial.unit.note': '请填入完整名称，如「台北市立第一女子高级中学」',
	'login.apply.trial.name': '您的姓名',
	'login.apply.trial.phone': '您的手机号码(纯数字)',
	'login.apply.trial.email': '您的信箱',
	'login.apply.trial.email.note': '请用 .edu 信箱，作为系统平台的教师帐号',
	'login.apply.trial.alert.phone': '您的电话格式错误',
	'login.apply.trial.alert.email': '您的信箱不是 .edu 网域，请重新填写',
	'login.apply.trial.alert.different.unit': '帐号已存在于其他单位!',
	'login.apply.trial.alert.nothing.to.add': '您已申请过所有的试用课程',
};

const loginEvent = {
	'loginExam1': 'APCS免费模拟考',
	'loginExam2': '立即注册！',

	'signup.globalExam': `我们将陆续推出完整的免费检测平台，让喜欢挑战 APCS 或 ZeroJudge 的你，有一个简单上手、友善使用的 compiler 环境。\n
	诚挚邀请您在使用过程中，不吝与我们分享您的建议与心得，让这个平台可以更完善！\n
	*测试版仅提供 Google 第三方登入，成功注册后可由 CodingBar 官网直接登入平台\n\n`,
	'signup.incognito': '*请勿使用无痕模式登入',

	'loginFreeTrial1': '茶水间的程序妙招',
	'loginFreeTrial2': '立即注册，免费体验！',

	'signup.freeTrial': `动手写程序，完成人生大小事！透过 google 帐号即可免费登入、体验各种程序小技巧。

	体验版仅提供 Google 第三方登入，成功注册后可由CodingBar 官网直接登入平台囉！\n\n`,
};

const freeTrialPage = {
	'freeTrialPage.NPBN.loadingProgress': '请稍候，正在为您开通帐号中...',

	'freeTrialPage.NPBN.createAccountFail.title': '开通帐号失败！此帐号已被建立',
	'freeTrialPage.NPBN.createAccountFail.content':
		'此帐号侦测已建立为 {{userInDBRole}} 角色，\n请回到网页输入其他信箱，始得开通帐号。',
	'freeTrialPage.NPBN.createAccountFail.btn': '回到官网',

	'freeTrialPage.NPBN.errorCrash': '哪里出错了！请洽客服人员联系处理',
	'freeTrialPage.NPBN.contactUs.btn': '联系客服',

	'freeTrialPage.NPBN.sendEmailTitle': 'CodingBar 免费体验课程开通',

	'freeTrialPage.NPBN.alreadyDone.title': '此帐号已申请过试用课程',
	'freeTrialPage.NPBN.alreadyDone.content':
		'此帐号侦测已申请过免费体验课程，\n请直接点选下方连结登入平台后体验，\n如体验时间已结束，请于官网购买课程。',

	'freeTrialPage.NPBN.done.title': '开通帐号完成！',
	'freeTrialPage.NPBN.done.content':
		'您的体验课程已开通，请点选下方连结后进行课程体验！\n系统将于 14 天后结束体验，如需继续使用请于官网购买课程',
	'freeTrialPage.NPBN.redirect2App': '前往 CodingBar 平台',
};

const parentloginPage = {
	'login.parent': '家长登入',
	'login.bannertext': '科技教育专业品牌',
	'login.parent.carouseltext1': '全方面分析，学习状况一手掌握',
	'login.parent.carouseltext2': '会员专属，抢先获得课程优惠',
	'login.parent.carouseltext3': '多元课程，陪孩子一起规划学习路径',
	'login.parent.failed': '此处资讯与报名表提供的资讯不符，请再试一次',

	'login.parent.welcomeText':
		'请输入您为孩子报名课程时所留下的联络电话，登入平台查看孩子学习的详细数据，让我们一起见证孩子的成长吧！',
};

const parentHomePage = {
	'parent.leftside.title': '孩子的学习摘要',
	'parent.lessontext': '当前课程',
	'parent.courseprocess': '课程进度',
	'parent.learntime': '学习时间',
	'parent.exercise': '课堂练习',
	'parent.homework': '作业完成',
	'parent.exerciseamount': '习题',
	'parent.view': '查看',
	'parent.fullreport': '的完整报告',
	'parent.advertisement.title1': '程式教育',
	'parent.advertisement.title2': '领航专家',
	'parent.advertisement.slogan': 'Let’s learn to  </code>',
};

const parentFullReport = {
	'parent.tablab.performance': '课程表现',

	'parent.tablab.courseprogress': '课程进度',
	'parent.title.fullReport': '整体学习概况',
	'parent.title.detailData': '详细数据分析',

	'parent.lessonContent.innerText': '本次上课内容',
	'parent.lessonContent.homework': '回家作业',
	'parent.lessonContent.homeworkComplete': '回家作业完成度',
	'parent.lessonContent.teacherComment': '讲师的话',

	'parent.lessonContent.completeRate': '，共完成',

	'parent.lessonContent.viewAllContent': '查看完整资讯',
};

const csfcLoginPage = {
	'login.csfc': '进入检测',
	'login.csfc.bannertext': 'CSFC 资讯科学能力检定',
	'login.mail': '电子信箱',
	// 'login.mail.placeholder': '请输入电子信箱',
	'login.csfc.failed': '报名资料中查无相符email，请再试一次',
	'login.csfc.welcomeText': '请输入报考检测时提供的电子信箱并点击进入，以进行身份验证。',
	'enter': '进入',
};

const staff = {
	'teacherTrialManage': '师资试用管理',
	'courseMaintenance': '课程维护',
	'staffMaintenance': '行政人员维护',
	'formManagement': '报名表管理',
	'couponManage': '折扣码管理',
	'allSignupManage': '所有报名表管理',
	'signupInfoManageV2_1': 'v2.1报名表管理',
	'signupInfoDownloadV2_1': 'v2.1报名表下载',
	'signupInfoDownloadOld': '旧报名表下载',
	'signupInfoDownloadAncient': '很久以前报名表',
	'permissionManage': '权限管理',
	'systemTutorial': '系统教学文件',

	'staff.user': '使用者',
	'staff.cumulativeusage': '累积使用者人数',
	'staff.distribute': '使用者分布',
	'staff.schoolStat': '学校单位统计',
	'staff.dailyUser': '每日上线人数',

	'staff.system': '系统',
	'staff.nasSystem': 'Nas系统使用',
	'staff.kernelStatus': 'Kernel状态',
	'staff.systemFlow': '系统使用流量',
	'staff.coursetime': '课程时间',
	'staff.courseId': '课程 ID',
	'staff.systemUsage': '系统 Usage',

	'staff.history': '历史纪录',
	'staff.signupStat': '课程报名统计',
	'staff.codesubmit': '每小时 Code Submit 次数',
	'staff.codesubmitStat': 'Code Submit统计',
	'staff.vidwatch': '影片观看纪录',

	'previousYear': '上一年',
	'nextYear': '下一年',

	'staff.totalUser': '总人数',
	'staff.openclass': '开课班级',
	'staff.exerciseNum': '习题数量',

	'staff.sharedCourse': '共同课程',
	'staff.settingPermissions': '设定权限',

	'staff.num': '编号',
	'staff.course': '教授课程',
	'staff.applytime': '申请时间',
	'staff.accountStatus': '帐号启用状态',

	'staff.teachernum': '老师人数',
	'staff.studentnum': '学生人数',
	'staff.coursenum': '课程数',

	'staff.search': '关键字搜寻',
	'staff.manipulate': '操作',
	'staff.chooseSchool': '请选择学校单位',
	'staff.loadtime': '载入时间',

	'staff.confirmDelete': '确定要删除',
	'staff.confirmDelete2': '再次确认! 确定要删除',

	'staff.previousweek': '上周',
	'staff.nextweek': '下周',

	'staff.day': '天',
	'staff.week': '周',

	'staff.previousDay': '上一天',
	'staff.nextDay': '下一天',

	'staff.signupinfos': '报名资料统计',
	'staff.timeWrong': '时间有误!',

	'staff.assign': '指定',
	'staff.month': '月',

	'staff.previousMonth': '上一个月',
	'staff.nextMonth': '下一个月',

	'staff.sincePlaceholder': '请输入开始日期',
	'staff.toPlaceholder': '请输入结束日期',
	'staff.since': '从',
	'staff.to': '到',

	'staff.deleteForm': '确定要删除报名表',
	'staff.deleteForm2': '再次确认! 确定要删除报名表',
	'staff.delFormSuc': '成功删除报名表!!',
	'staff.formManage': '报名表管理',
	'staff.newForm': '新增报名表',
	'staff.signuplist': '课程报名列表',
	'staff.signupForm': '报名表',
	'staff.infoNum': '笔资料',
	'staff.download': '下载 CSV 档案',
	'staff.update': '更新栏位',
	'staff.nodata': '没有资料',
	'staff.formAlert': '请选择课程报名表单!',

	'staff.newDoc': '新增文件',
	'staff.doc.proceed': '上一次修改尚未储存！ \n确定要继续?',
	'staff.docedit': '文件编辑',
	'staff.incorrectDoc': '不正确的文件',
	'staff.createChpt': '建立章节',
	'staff.delChapter': '确定要删除此章节',
	'staff.enterTitle': '请输入标题',
	'staff.insertImg': '插入图片',
	'staff.sourceCode': '开启原始码',
	'staff.clodeCode': '关闭原始码',
	'staff.courseSetting': '课程设定',
	'staff.courseChallenge': '课后挑战',
	'staff.challengeA': '挑战 A 单元',
	'staff.challengeB': '挑战 B 单元',
	'staff.challengeC': '挑战 C 单元',
	'staff.copyFormSuc': '报名表复制成功',
	'staff.copyForm': '复制表单',
	'staff.deleteForm': '删除表单',
	'staff.form.basic': '基本资讯',
	'staff.form.course': '课程选项',
	'staff.form.studentSetting': '学生资讯设定',
	'staff.form.payment': '付费设定',
	'staff.form.mail': '邮件设定',
	'staff.form.insertGA': '插入GA-Code',
	'staff.form.insertFA': '插入FA-Code',
	'staff.form.workShop': '工作坊',
	'staff.form.camp': '营队',
	'staff.form.adult': '成人活动',
	'staff.form.nomal': '常态课程',
	'staff.form.teacher': '老师征选',
	'staff.form.teacher2': '老师征选第二阶段',
	'staff.form.high': '高中',
	'staff.form.eleJu': '国中小',
	'staff.form.adult': '成人',
	'staff.activity': '活动类型',
	'staff.attendGroup': '活动参加族群',
	'staff.formId': '报名表ID',
	'staff.placeholder': '请输入报名表ID',
	'staff.link': '表单连结',
	'staff.form.title': '报名表标题',
	'staff.form.titlePlaceholder': '请输入报名表标题',
	'staff.formSlogan': '报名表标语',
	'staff.formSloganPlaceholder': '请输入报名表标语',
	'staff.foreword': '前言',
	'staff.policyTitle': '条款标题',
	'staff.policyTitlePlaceholder': '请输入条款标题',
	'staff.policyLink': '条款连结',
	'staff.policyLinkPlaceholder': '请输入条款连结',
	'staff.signupFin': '报名完成页面内容',
	'staff.notInList': '课程名称不存在于课程选项中，请检查空格或是排版',
	'staff.courseTime': '输入课程场次',
	'staff.getCourseFail': '取得课程失败',
	'staff.earlybirdDue': '早鸟截止日期',
	'staff.earlybirdDuePlaceholder': '输入早鸟截止日期',
	'staff.courseSelection': '课程选项标题',
	'staff.courseSelectionPlaceholder': '输入客制课程输入标题',
	'staff.courseOptions': '课程选项',
	'staff.newCourseOption': '插入新的课程选项',
	'staff.notInList2': '输入的梯次名称不在营队类别里喔！！请检查全型半形或是不小心的空格',
	'staff.courseOptionsOpen': '启用课程选项',
	'staff.campCategory': '营队类别',
	'staff.campCategoryPlaceholder': '插入新的营队类别选项',
	'staff.batch': '梯次',
	'staff.batchPlaceholder': '插入课程并管理课程梯次',
	'staff.groupornot': '是否为团报课程',
	'staff.studentCareer': '职业类别选项',
	'staff.studentCareerPlaceholder': '插入新的职业类别选项',
	'staff.ageSelection': '年龄选项',
	'staff.ageSelectionPlaceholder': '插入新的年龄',
	'staff.gradeSelection': '年级选项',
	'staff.gradeSelectionPlaceholder': '插入新的年级',
	'staff.infoSource': '资讯来源选项',
	'staff.infoSourcePlaceholder': '插入新的资讯来源选项',
	'staff.alumnusDiscount': '启用旧生优惠',
	'staff.alumnusPrice': '旧生优惠价钱',
	'staff.alumnusPricePlaceholder': '请输入旧生优惠价钱',
	'staff.combinePeriod': '合并期数(1期以上才会启用)',
	'staff.combinePeriodPlaceholder': '请输入合并期数',
	'staff.combinePrice': '合并价钱',
	'staff.combinePricePlaceholder': '请输入合并价钱',
	'staff.alumnusCombinePrice': '旧生合并价钱',
	'staff.alumnusCombinePricePlaceholder': '请输入旧生合并价钱',
	'staff.pricePlan1': '方案ㄧ (原价)',
	'staff.pricePlan2': '方案二 (九折)',
	'staff.pricePlan3': '方案三 (七折)',
	'staff.earlybirdPrice': '早鸟付款金额',
	'staff.earlybirdPricePlaceholder': '输入早鸟付款金额',
	'staff.normalPrice': '正常价付款金额',
	'staff.normalPricePlaceholder': '输入早鸟付款金额',
	'staff.pricePlanTitle': '请输入方案名称及价格',
	'staff.pricePlanTitlePlaceholder': '请输入方案标题',
	'staff.pricePlanPricePlaceholder': '价格',
	'staff.activatePricePlan': '启用自订价格方案',
	'staff.pricePlanHint': '*启用后，上述两种付款设定不需再输入*',
	'staff.signupConfirmMail': '报名确认信',
	'staff.paymentConfirmMail': '缴费确认信',
	'staff.addtoList': '加入列表',
	'staff.columnNum': '个栏位',
	'staff.removeAll': '全部移除',
	'staff.addAll': '全部加入',
	'staff.lastUpdate': '上次更新',
	'staff.order': '序号',
	'staff.school': '学校',
	'staff.studentId': '学生身分证号',
	'staff.studentName': '学生姓名',
	'staff.studentBD': '学生生日',
	'staff.addr': '地址',
	'staff.age': '年龄层',
	'staff.parentTel': '家长电话',
	'staff.studentPhone': '学生电话',
	'staff.note': 'Note',
	'staff.infoSource': '消息来源',
	'staff.groupSign': '团报',
	'staff.isEarly': '早鸟',
	'staff.payNum': '缴费编号',
	'staff.paid': '已缴费',
	'staff.signupDate': '报名日期',
	'staff.paymentAmount': '报名金额',
	'staff.transaction': '交易序号',
	'staff.transactionAmount': '交易金额',
	'staff.transactionTime': '交易时间',
	'staff.paymentTimes': '付款次数',
	'staff.paymentTime': '付款时间',
	'staff.paymentMethod': '付款方式',
	'staff.combination': '合并购买',
	'staff.currentStudent': '是否为旧生',
	'staff.createuser': '建立 {{username}} 使用者帐号',
	'staff.createAccount': '创建帐号',

	//
	'staff.liveCourseDashboard': 'Live课程与人数',
	'staff.isLive': '实际上线',
	'staff.shouldBeLive': '应上线',
	'staff.liveStudentCount': '学生上线人数',
	'staff.liveTeacherCount': '讲师上线人数',
	'staff.liveUnitCount': '单位上线人数',
	'staff.liveCourseCount': 'Live课程数',
	'staff.liveUnitsCount': '使用单位数',
	'staff.liveCourse': 'Live课程',

	'staff.permissionsEdit.isRegistrationAllowed': '报名表 管理',
	'staff.permissionsEdit.isUnitDomainAllowed': '单位网域 管理',
	'staff.permissionsEdit.isBetaUnitAllowed': 'Beta单位 管理',
	'staff.permissionsEdit.isCRMAllowed': 'CRM 管理',
	'staff.permissionsEdit.isDashboardAllowed': '系统仪表板',
	'staff.permissionsEdit.isCouponAllowed': '折扣码 管理',
	'staff.permissionsEdit.isAnnounceAllowed': '公告 管理',
	'staff.permissionsEdit.isPackageCourseAllowed': '套装课程 管理',
	'staff.permissionsEdit.isTranslationAllowed': '翻译课程 管理',
	'staff.permissionsEdit.isSyncSheetAllowed': '资料同步 管理',
	'staff.permissionsEdit.isSystemDocumentAllowed': '系统文件 管理',
	'staff.permissionsEdit.isStaffAsDevAllowed': 'Airabbi 教师权限',
	'staff.permissionsEdit.isTeacherScheduleAllowed': '教师行事历',
	'staff.permissionsEdit.isUnitPermissionsAllowed': '单位功能权限',
	'staff.permissionsEdit.canAdjustWorkspaces': '使用者工作区',

	//
	'staff.codeSubmitTimeRange': '时段 Code Sumbit 次数',
	'staff.timeRange': '时间区间',
	'staff.byFiveMin': '五分钟',
};

const signUpPage = {
	'signup.welcome': '注册CodingBar',
	'signup.email': '请输入电子邮件',
	'signup.password': '请输入密码', //NOSONAR
	'signup.confirmPassword': '请再输入一次密码',
	'signup.submit': '注册新会员',
	'signup.failed': '注册失败',
	'signup.passwordNotSame': '两次密码不一致', //NOSONAR
	'signup.useThirdParty': '使用 Google 或 Facebook 帐号注册',
	'signup.useGoogle': '使用 Google 注册',
	'signup.useFacebook': '使用 Facebook 注册',
	'signup.userAlreadyUsed': '此信箱已注册！',

	'signup.loggingIn': '登入中...',
	'signup.cannotResetPW': '无法重设密码!',
	'signup.enterPWAgain': '请再次输入一次新密码',
	'signup.PWMismatch': '新密码不一致!',
	'signup.resetPWSuccess': '重设密码成功!',
	'signup.PWTooWeak': '密码强度不足！',
	'signup.resendPWEmail': '此重设密码信件无法使用，请至忘记密码页重新寄送。',
	'signup.resetPWFail': '重设密码失败！',
	'signup.findPW': '找回您的密码',
	'signup.findParentPW': '找回您的家长密码',
	'signup.retrievePW': '取回我的密码',
	'signup.enterEmailToResetPW':
		'请填入您的注册信箱，我们会立即将您的密码信函发送到您的收件夹中，若有任何问题，也请透过Line@与我们联络',
	'signup.enterEmail': '请输入您的电子邮件',
	'signup.contactLine': '加入Line @',
	'signup.checkInbox': '请至收件夹取回密码',
	'signup.passwordEmailSent': '密码信已发送到注册信箱，请到您的邮件收件夹查看，就能取得您的密码！', //NOSONAR
	'signUpPage.signup': '注册',
	'signUpPage.signup.get.verification.code': '取得验证码',
	'signUpPage.signup.reget.verification.code': '重新取得验证码',
	'signUpPage.signup.verification.code': '验证码',
	'signUpPage.signup.verification.code.placeholder': '请输入验证码',
	'signUpPage.signup.verify.code': '验证',
	'signUpPage.signup.verify.code.error.incorrectCode': '验证码错误，请重新确认',
	'signUpPage.signup.verify.code.error.noCodeReceived': '尚未取得验证码',
	'signUpPage.signup.name.placeholder': '请输入您的姓名',
	'signUpPage.signup.user.name.alert': '使用者名称仅可包含 a-z 、0-9、底线(_)、半形句号(.)',
	'signUpPage.signup.industry': '您的产业',
	'signUpPage.signup.industry.placeholder': '请选择最能描述您所在产业的项目',
	'signUpPage.signup.position': '您的职务',
	'signUpPage.signup.position.placeholder': '请选择最能描述您职务的项目',
	'signUpPage.signup.purpose': '想用 AImate 解决什么问题?',
	'signUpPage.signup.purpose.placeholder': '请选择最能描述您应用情境的项目',
	'signUpPage.signup.Finance/Insurance': '金融/保险',
	'signUpPage.signup.Real Estate': '房地产',
	'signUpPage.signup.Education/Research': '教育/研究',
	'signUpPage.signup.Trade': '贸易',
	'signUpPage.signup.Construction/Manufacturing': '建筑/营造制造',
	'signUpPage.signup.Information Technology': '资讯科技',
	'signUpPage.signup.Service': '服务',
	'signUpPage.signup.Healthcare': '医疗保健',
	'signUpPage.signup.Law Related': '法律相关',
	'signUpPage.signup.Retail': '零售',
	'signUpPage.signup.Transportation/Travel': '交通/运输/旅游',
	'signUpPage.signup.Entertainment/Publishing': '娱乐/出版',
	'signUpPage.signup.Communication/PR/Advertising/Marketing': '传播/公共关系/广告/行销',
	'signUpPage.signup.Arts/Design': '艺术/设计',
	'signUpPage.signup.Agriculture/Fishing': '农渔牧',
	'signUpPage.signup.Housekeeping': '家管',
	'signUpPage.signup.Student': '学生',
	'signUpPage.signup.Others': '其他',
	'signUpPage.position.CEO/Company Leader': '执行长/公司负责人',
	'signUpPage.position.Manager/Leader': '主管/领导者',
	'signUpPage.position.General Staff': '一般职员',
	'signUpPage.position.Others': '其他',
	'signUpPage.purpose.Work': '工作',
	'signUpPage.purpose.Personal': '个人',
	'signUpPage.purpose.Education': '教育',
	'signUpPage.activate.Aimate.title': '启用产品服务',
	'signUpPage.activate.Aimate.content': '此帐号已加入 CodingBar Workshop，点击下方按钮即可启用 AImate 产品服务。 ',
	'signUpPage.activate.Aimate.button': '启用 AImate',
	'signUpPage.signup.success': '注册成功',
	'signUpPage.downloadAimate.content':
		'您已成功注册 CodingBar Workshop，并启用 AImate 产品服务。下载安装 AImate 应用程式，享有存取电脑本机档案、离线保存功能。 ',
	'signUpPage.downloadAimate.ios': 'iOS 用户下载',
	'signUpPage.downloadAimate.windows': 'Windows 用户下载',
	'signUpPage.downloadAimate.continue.web': '继续使用网页版',
};

const notes = {
	'notes.title': '上课笔记列表',
	'notes.subtitle': '欢迎使用新版的笔记功能！你可以在右上角随时新增一则新的上课笔记，并且在此页找寻先前的笔记本。',
	'notes.newNotes': '新笔记',

	'notes.addNewNotes': '新增笔记',
	'notes.saveNotes': '储存笔记',
	'notes.confirmDeleteNote': '确定要删除笔记:',
	'notes.deleteNote': '删除笔记',

	'notes.placeholder.addNotes': '请输入笔记内容',
	'notes.placeholder.addNotesByButton': '请按右下角按钮以新增笔记',
	'notes.not.note.yet': '尚未有笔记',
	'notes.no.note': '透过「新增」建立笔记',
};

const announcement = {
	'announcement.sidebar.name': '公告清单',
	'announcement.tab.studentAncm': '学生公告',
	'announcement.tab.teacherAncm': '教师公告',
	'announcement.tab.publicAncm': '全域公告',

	'announcement.addNewAncm': '新增公告',
	'announcement.viewAncm': '检视公告',
	'announcement.deleteThisAncm': '删除此公告',
	'announcement.deleteAncm': '删除公告',
	'announcement.writeAncm': '填写公告内容',
	'announcement.publishAncm': '发布公告',
	'announcement.viewAncm': '检视公告',
	'announcement.closeAncm': '关闭视窗',
	'announcement.settingMsg1': '新增公告请注意以下事项：',
	'announcement.settingMsg2': '(1) 发布公告的对象为 学生、教师 或 全域发布',
	'announcement.settingMsg3': '(2) 公告的类型为 同意型(Agreement)、提醒型(Annoucement) 或 警告 (Alert)',
	'announcement.writeAncmMsg': '请填写公告标题与内容',
	'announcement.submitAncmConfirm': '确认新增公告',
	'announcement.submitAncmMsg': '所有选取单位的使用者都会被影响，确定要新增吗？',
	'announcement.deleteAncmConfirm': '确认删除公告',
	'announcement.deleteAncmMsg': '所有选取单位的使用者都会被影响，确定要删除吗？',
	'announcement.selectAll': '全选',
	'announcement.dontShowAgain': '下次不再显示',
	'announcement.agree': '同意',
	'announcement.disagree': '不同意',
	'announcement.understood': '我理解了',
	'announcement.confirmClose': '确认并关闭',

	'announcement.dropdown.title.type': '选择公告类型',
	'announcement.dropdown.title.unit': '选择公告单位',
	'announcement.dropdown.title.receiver': '选择公告对象',
	'announcement.dropdown.title.startDate': '发布日期',
	'announcement.dropdown.title.startTime': '开始时间',
	'announcement.dropdown.title.endDate': '结束日期',
	'announcement.dropdown.title.endTime': '结束时间',

	'announcement.dropdown.label.agreement': '同意型',
	'announcement.dropdown.label.announcement': '提醒型',
	'announcement.dropdown.label.alert': '警告',

	'announcement.dropdown.label.student': '学生',
	'announcement.dropdown.label.teacher': '教师',
	'announcement.dropdown.label.all': '全域',

	'announcement.content.title': '公告标题',
	'announcement.content.content': '输入公告内容',
	'announcement.content.btnText': '主要按钮内文',
	'announcement.content.placeholder': '必填，不可为空白',
	'announcement.content.placeholder.content': '必填，不可为空白，文字过长请换行处理，不换行后果自负。',
	'announcement.content.placeholder.btn': '最长 15 字符，空白即为预设文字「确认并关闭」',

	'announcement.view.type': '公告类型',
	'announcement.view.unit': '公告单位',
	'announcement.view.receiver': '公告对象',
	'announcement.view.content': '公告内容',

	'announcement.view.allUnits': '所有单位',
	'announcement.announcementList.noAnnounce': '暂无公告',

	'announcement.addNewAncm.tabTitle.stepOne': '设定公告范围',
	'announcement.addNewAncm.tabTitle.stepTwo': '设定公告内文',
};

const lintErrorHints = {
	'lint.msgLine1': '你在',
	'lint.msgLine2': '行时，',
	'lint.btn.checkHint': '查看提示',
	'lint.btn.askQuestion': '我想提问',
	'lint.btn.understood': '我知道了',
	'lint.title.stuck': '卡关了吗？',
	'lint.title.fixed': '已解决！',
	'lint.msg.errorsFixed': '非常棒，现在所有的错误已经修正了',
	'lint.msg.noHint': '暂无提示',

	'lint.msg.error.hint': '第 {{line}} 行有错，快点检查',

	'lint.msg.chat.lose.miss.me': '唉唷，输了一屁股才想到我？',
	'lint.msg.chat.no.show.up.order': '董仔很久没出现了，今天想来点什么？',
	'lint.msg.chat.holiday.ask.dictionary': '眼怪仔我今天放假，有问题自己查字典',
	'lint.msg.chat.no.more.service': '眼怪仔智商发展中，暂时无法提供更多服务',
	'lint.msg.chat.click.on.and.off': '点了又关掉，关掉又点开，有事ㄇ？',
	'lint.msg.chat.speechless.stay.tuned': '没台词了下个月才有新梗，敬请(?)期待',
	'lint.msg.chat.course.easter.egg': '记得报名 CodingBar 课程，看更多彩蛋',
	'lint.msg.chat.SSR.hang.in.there': '抽到 SSR 现在没空，大家先撑住！',
	'lint.msg.chat.ask.question.by.yourself': '要问问题戳我干嘛? 点左边的问题讨论啊!(／‵口′)／~╧╧',

	'lint.msg.chat.problem.solved.nice': '看起来解决了！不错唷！',
	'lint.msg.chat.problem.solved.awesome': '没想到你还挺厉害的嘛！',
	'lint.msg.chat.problem.solved.succeed': '居然让你成功解决，看来我太小看你了！',
	'lint.msg.chat.problem.solved.challenge': '那么厉害，等下再找出错误让你挑战，哈哈哈',
};

const studyResult = {
	'studyResult.completeRate': '完成度',
	'studyResult.courseScore': '课程成绩',
	'studyResult.completeTime': '花费时间',

	'studyResult.courseScore': '表现等级',
	'studyResult.course.status': '学习概况',

	'studyResult.course.status.details': '详细统计',
	'studyResult.course.download.certificate': '下载证书',
	'studyResult.course.best.performance': '最佳表现',
	'studyResult.certificate.completed.course': '已修毕',
	'studyResult.certificate.completed.course.proof': '特颁此结业证书以兹证明',
	'studyResult.certificate.course.duration': '课程期间',
	'studyResult.certificate.course.total.hours': '课程时数',
	'studyResult.certificate.taiwan': '中华民国',
	'studyResult.certificate.year': '年',
};

const unitDomainManage = {
	'unitDomain.sidebar.name': '单位网域管理',
	'unitDomain.tab.allUnits': '所有单位',
	'unitDomain.tab.appUnits': 'App网域单位',
	'unitDomain.tab.schoolUnits': 'School网域单位',
	'unitDomain.iconMenu.switchDomain': '切换单位网域',
	'unitDomain.listView.total': '总数',
	'unitDomain.confirmModal.title': '单位网域切换',
	'unitDomain.confirmModal.content':
		'{{unitName}} 目前使用的平台网域为 {{currentDomain}}；\n 请确认是否将该单位的平台网域切换为 {{switchToDomain}}',
	'unitDomain.alert.success': '网域切换成功',
	'unitDomain.alert.fail': '网域切换失败',
};

const betaUnitManage = {
	'betaUnit.sidebar.name': 'Beta单位管理',

	'betaUnit.tab.allUnits': '所有单位',
	'betaUnit.tab.betaUnits': 'Beta测试单位',
	'betaUnit.tab.notBetaUnits': '原版系统单位',

	'betaUnit.listView.total': '总数',

	'betaUnit.confirmModal.title': '切换单位',
	'betaUnit.confirmModal.content':
		'{{unitName}} 目前使用的平台系统为 {{current}}；\n 请确认是否将该单位切换为 {{switchTo}}',

	'betaUnit.alert.success': '单位切换成功',
	'betaUnit.alert.fail': '单位切换失败',

	'betaUnit.version.beta': 'Beta 版',
	'betaUnit.version.origin': '原版系统',
};

const AnnouncementListModal = {
	'announcementList.title': '公告列表',
};

const zoom = {
	'zoom.list': '视讯列表',
	'zoom.zoomCourse': 'CodingBar 视讯课程',
	'zoom.selectCourse': '请选择要上的视讯课程!',
	'zoom.selectCodeAndPW': '请填入正确的课程编号、会议号码与密码!',
	'zoom.retrievingInfo': '正在取得课程资讯...',
	'zoom.courseCode': '课程编号',
	'zoom.meetingId': '会议 ID',
	'zoom.startCourse': '开始视讯课程',
	'zoom.endCourse': '停止视讯课程',
};

const desktopDownload = {
	'desktopDownload.title': '下载 CodingBar 软体',
	'desktopDownload.description':
		'CodingBar 桌机版可支援 Micro:bit、Meowbit、爬虫资料科学以及其他需要读取硬体的课程，请注意您的作业系统版本，以及需要上课的类型，安装适合您的版本！',
	'desktopDownload.download': '立即下载',
	'desktopDownload.instructions': '下方安装指引',
	'desktopDownload.step': '步骤',

	'desktopDownload.mac.title': 'Mac 系统',
	'desktopDownload.mac.desc1': 'Mac 系统只支援 桌机/笔记型电脑装置，不适用于 ipad 等平板电脑产品；请注意是否拥有',
	'desktopDownload.mac.desc2': '插孔，或是自备转换插槽。\n\n若有安装问题，请参考',
	'desktopDownload.mac.version11up': 'Mac OS 11以上',
	'desktopDownload.mac.version11down': 'Mac OS 10.13以上，不支援11版',

	'desktopDownload.mac.stepTitle': 'Mac 安装指引',
	'desktopDownload.mac.step1': '首先，将下载下来的档案点击滑鼠左键两下开启。',
	'desktopDownload.mac.step2': '稍作等待，应该可以看到程式会跳出一个视窗。',
	'desktopDownload.mac.step3':
		'依据跳出视窗指示，滑鼠左键点击左边的 CodingBar 图示，按住不放，将其拖曳至右边的资料夹上放开，等待安装。',
	'desktopDownload.mac.step4':
		'安装完成之后，开启 Finder资料夹 中左侧的 「应用程式」，您应该就可以看见 CodingBar 的 APP，点击两下开启 CodingBar APP。',
	'desktopDownload.mac.step5': '首次安装需要进行安全性设定，点选「好」',
	'desktopDownload.mac.step6': '滑鼠左键点选桌面左上角的苹果图示开启电脑选单。',
	'desktopDownload.mac.step7': '滑鼠左键点选「系统偏好设定」。',
	'desktopDownload.mac.step8': '在「系统偏好设定」的视窗，滑鼠左键点选「安全性与隐私权」。',
	'desktopDownload.mac.step9': '在「安全性与隐私权」的视窗，滑鼠左键点选「强制打开」。',
	'desktopDownload.mac.step10': '在跳出的强制打开确认视窗，点选左边的打开。',
	'desktopDownload.mac.step11': '每一次连线 CodingBar 时都会出现连线许可确认，请点选「允许」',
	'desktopDownload.mac.step12': '就可以成功开启 CodingBar 桌机版啰！',

	'desktopDownload.windows.title': 'Windows 系统',
	'desktopDownload.windows.desc1': 'Windows 系统需 64 位元以上，请注意若需要使用micro:bit 等硬体时，需拥有',
	'desktopDownload.windows.desc2': '插孔，或是自备转换插槽。\n\n若有安装问题，请参考',
	'desktopDownload.windows.version': 'Windows 系统 64位元以上',

	'desktopDownload.windows.stepTitle': 'Windows 安装指引',
	'desktopDownload.windows.step1': '首先，将下载下来的档案点击滑鼠左键两下开启。\n\n*Windows 版本只支援64位元系统',
	'desktopDownload.windows.step2': 'Window-2 Windows 系统本身会开启安全性警告，滑鼠左键点选执行。',
	'desktopDownload.windows.step3': '接着就可以看到 CodingBar 的安装画面，请依照指示安装，滑鼠左键点选下一步。',
	'desktopDownload.windows.step4': '请继续滑鼠左键点选下一步。\n\n*请勿更动预设安装路径，以免造成程式执行的读取问题',
	'desktopDownload.windows.step5': '请继续滑鼠左键点选安装以开始。',
	'desktopDownload.windows.step6': '请稍待安装过程，在过程中请勿按下取消，以免安装失败导致程式档案不完整。',
	'desktopDownload.windows.step7': '当安装完成时，滑鼠左键按下完成结束安装程式。',
	'desktopDownload.windows.step8':
		'桌面应该会出现 CodingBar 程式的捷径，或是从档案路径 C://Program Filse (x86)/Airabbi/CodingBar/，也可以找到程式路径。滑鼠左键点击两下 CodingBar.exe 即可开启程式。\n\n*请勿擅自将程式路径内的任一档案移动或删除，会导致程式档案不完整。',
	'desktopDownload.windows.step9': '就可以成功开启 CodingBar 桌机版啰！',
};

const studentDetailPage = {
	'page.tab.basicview': '基本能力评估',
	'page.tab.overview': '表现摘要',
	'page.tab.personalInfo': '个人资料',
	'page.tab.studentScore': '成绩检视',

	'page.blockHeader.assessment': '能力评估',
	'page.blockHeader.keyPerformance': '关键表现',

	'page.btn.checkDetails': '查看细节',
};

const teacherCourseLessonPage = {
	'lessonPage.card.title': '课程基本资讯',
	'lessonPage.course.content': '课程内容',
	'lessonPage.course.view.content': '检视课程',
	'lessonPage.course.viewScoreOnly': '检视成绩',
	'lessonPage.confirm.edit.course.title': '变更课程内容',
	'lessonPage.confirm.edit.course.content':
		'变更课程单元数量、上课时间、单元顺序等，将覆写当前设定状态，请先确认再点击确认变更\n注意，此项操作无法回复',
	'lessonPage.card.button.editCourseInfo': '编辑课程',

	'lessonPage.confirm.editCancel.course.title': '取消变更课程内容',
	'lessonPage.confirm.editCancel.course.content':
		'请确认是否要取消编辑课程内容，方才的编辑更动将会全部遗失\n请再三确认是否要取消编辑。',

	'lessonPage.card.fieldTitle.codeType': '主要使用语言',
	'lessonPage.card.fieldTitle.lessonLength': '课程堂数',
	'lessonPage.card.fieldTitle.lessonLength.number': '课程堂数(单元数)',

	'lessonPage.card.fieldTitle.tags': '自订标签',
	'lessonPage.card.fieldTitle.content': '课程简介或附注',
	'lessonPage.card.fieldTitle.teacher': '主要授课教师',
	'lessonPage.card.fieldTitle.ta': '课堂助教',

	'lessonPage.card.fieldTitle.lessonTitle': '课程单元标题',
	'lessonPage.card.fieldTitle.lessonContent': '课程单元说明文字',
	'lessonPage.card.lessonContent.placeholder': '请输入单元简介',

	'lessonPage.title': '第 {{i}} 单元',
	'lessonPage.entryoflesson': '进入教学模式',
	'course.short.questionGroup': '题组',

	'lessonPage.exercise.batch.setting': '课程单元批次设定',
	'lessonPage.exercise.test.cases': '测资',
	'lessonPage.exercise.video': '影片开放',
	'lessonPage.abbrev.test.cases': '测资',
	'lessonPage.abbrev.exercise': '题目',
	'lessonPage.abbrev.answer': '解答',

	'course.short.handout': '讲义',
	'course.short.exercise': '练习',
	'course.short.homework': '作业',
	'course.short.tests': '考试',
	'course.short.exam': '试卷',
	'course.short.video': '影片',
	'course.short.coursePackage': '套装课程',
	'course.short.AddLessonFromResource': '从资源库汇入',
	'course.time.notSet': '未指定时间',
	'course.page.title.stuManage': '课堂学生管理',
	'course.page.removePackageStudentAlert': '套装课程不允许移除学生',
	'lessonPage.no.content.yet': '您尚未添加任何内容',

	'course.time.finishedLessons': '已完成 {{currentLevel}} 堂课（全 {{totalLevel}} 堂）',
};

const coursePackageManage = {
	'CPManage.sidebar.name': '课务管理',

	'CPManage.info.beginTime': '生效',
	'CPManage.info.endTime': '截止',
	'CPManage.info.packageType': '课程类型',
	'CPManage.info.useNum': '课程人数',
	'CPManage.info.maintainer': '负责业务',
	'CPManage.info.unitContact': '单位联络人',
	'CPManage.info.unitContactEmail': '联络人信箱',
	'CPManage.info.contactPhone': '联络电话',
	'CPManage.info.contactAddress': '教室地址',

	'CPManage.info.noContactInfo': '暂无单位联络人资料',
	'CPManage.info.noUseLimit': '无限制',

	'CPManage.iconMenu.editSetting': '编辑设定',
	'CPManage.iconMenu.deletePackage': '删除课程',

	'CPManage.modal.addPackage': '新增外派课程',
	'CPManage.modal.editPackage': '编辑外派课程',
	'CPManage.modal.steps.basicSettings': '基本资讯',
	'CPManage.modal.steps.importCourse': '汇入课程',
	'CPManage.modal.steps.importCourse.confirm': '汇入课程',
	'CPManage.modal.chose.course.resource': '选择套装课程',
	'CPManage.modal.steps.timeSettings': '时间设定',

	'CPManage.modal.stepOne.title.courseType': '课程类型',
	'CPManage.modal.stepOne.title.maintainer': 'CodingBar联络人',
	'CPManage.modal.stepOne.title.useLimit': '课程总人数上限',
	'CPManage.modal.stepOne.title.assignedUnit': '指派单位',
	'CPManage.modal.stepOne.title.assignedContact': '单位联络人',
	'CPManage.modal.stepOne.title.beginTime': '课程生效日',
	'CPManage.modal.stepOne.title.endTime': '课程截止日',
	'CPManage.modal.stepOne.title.enableActiveUserNotification': '输出活跃人数给 CodingBar 联络人',
	'CPManage.modal.stepOne.title.courseTitle': '输入课程名称',
	'CPManage.modal.stepOne.title.courseTitlePreview': '预览课程名称',
	'CPManage.modal.stepOne.title.desc': '输入课程简介或附注',
	'CPManage.modal.stepOne.title.codeType': '主要使用语言',
	'CPManage.modal.stepOne.title.assignedTeacher': '授课教师',
	'CPManage.modal.stepOne.title.assignedTA': '课堂助教',
	'CPManage.modal.stepOne.title.unit': '开课单位',
	'CPManage.modal.stepOne.title.chatGPT': '开启 ChatGPT 功能',
	'CPManage.modal.stepOne.title.toggleAllowedEdit': '开启 允许编辑 功能',

	'CPManage.modal.stepThree.title.courseBeginTime': '起始日期',
	'CPManage.modal.stepThree.title.courseLessonLength': '预计课程堂数',
	'CPManage.modal.stepThree.title.packageCourseEndDate': '课程有效截止日',
	'CPManage.modal.stepThree.title.autoReleaseCourse': '自动开放课程',
	'CPManage.release.lesson.by.schedule': '依上课时间开放单元',

	'CPManage.modal.stepOne.placeholder.maintainer': '输入业务名称',
	'CPManage.modal.stepOne.placeholder.useLimit': '输入0，则为不限制',
	'CPManage.modal.stepOne.placeholder.assignedContact': '若无自动填入资料，可手动选填',

	'CPManage.modal.errorText.endBeforeStart': '您不能违反宇宙原理',
	'CPManage.modal.errorText.notStartBetweenPackageTime': '日期不在外派课程时效内',

	'CPManage.modal.alert.lessonDatesError': '课程日期为：{{dates}}，部分日期不在课程时效内',
	'CPManage.modal.alert.lessonDates': '课程日期为：{{dates}}',

	'CPManage.modal.confirm.titleTeacher': '汇入套装课程?',
	'CPManage.modal.confirm.bodyTeacher':
		'请注意，当您使用并建立套装课程后，便将开始计算使用人数，您将无法直接删除此课程。\n请确认后点击建立课程。',
	'CPManage.modal.confirm.titleDev': '建立外派套装课程？',
	'CPManage.modal.confirm.bodyDev': '请确认是否将套装课程外派至相关单位或老师。',
	'CPManage.modal.confirmDelete.titleDev': '删除外派套装课程？',
	'CPManage.modal.confirmDelete.bodyDev': '请确认是否删除外派课程，相关单位及老师将会受影响。',

	'CPManage.modal.AssignCourseStepTwo.note':
		'新功能！不用设定课程各单元的起讫日与上课周期，也能直接使用，但系统将不会提醒您当日的课程单元！ ',
	'CPManage.modal.alert.import.lessonDatesError':
		'目前课程规划为：{{dates}}\n您的套装课程有效期限为{{dueDate}}，部分日期不在有效期限内，请调整您的课程时间。 ',
	'CPManage.modal.alert.import.lessonDatesError.title': '请调整课程时间',
};

const comments = {
	'comments.btn.addComment': '新增评语',
	'comments.btn.addAnotherComment': '+新增另一组评语',
	'comments.btn.removeThisComment': '-删除此评语',

	'comments.addComment.tab.basicAbility': '基本能力评估',
	'comments.addComment.tab.addComment': '评语撰写',

	'comments.addComment.info1': '观念力与实作力',
	'comments.addComment.info2': '总和为 100',
	'comments.addComment.info3': '，您可以直接输入其中一个栏位，另一栏位系统将自动帮您填入',

	'comments.field.conceptScore': '观念力',
	'comments.field.implementScore': '实作力',
	'comments.field.difficultyScore': '接受度',
	'comments.field.starScore': '星等评价',
	'comments.field.commentShort': '简易评语选择',
	'comments.field.comment': '评语内容',

	'comments.placeholder.noComment': '老师目前还没写其他评语',
};

const studentInfo = {
	'studentInfo.field.unit': '单位',
	'studentInfo.field.name': '学生姓名',
	'studentInfo.field.gender': '性别',
	'studentInfo.field.phone': '联络电话',
	'studentInfo.field.identityCardNumber': '学生身分证号',
	'studentInfo.field.birthday': '出生年月日',
	'studentInfo.field.age': '年龄',
	'studentInfo.field.email': '电子邮件',
	'studentInfo.field.password': '密码', //NOSONAR
	'studentInfo.field.educationStage': '学级',
	'studentInfo.field.contactName': '联络人姓名',
	'studentInfo.field.contactPhone': '联络人电话',
	'studentInfo.field.useOldMember': '会员身份',
	'studentInfo.field.contactEmail': '电子邮件',
	'studentInfo.field.contactAddress': '联络地址',

	'studentInfo.placeholder.inputPhone': '请输入电话',
	'studentInfo.placeholder.inputIdentityCardNumber': '请输入学生身份证',
	'studentInfo.placeholder.resetPassword': '寄发重设密码信',
	'studentInfo.placeholder.inputSchool': '请输入学校',
	'studentInfo.placeholder.inputStudentClass': '请输入班级',
	'studentInfo.placeholder.inputSeatNo': '请输入座号 (纯数字)',
	'studentInfo.placeholder.inputContactName': '请输入联络人姓名',
	'studentInfo.placeholder.inputContactPhone': '请输入联络人电话',
	'studentInfo.placeholder.inputContactAddress': '请输入联络地址',

	'studentInfo.grade.first': '一年级',
	'studentInfo.grade.second': '二年级',
	'studentInfo.grade.third': '三年级',
	'studentInfo.grade.fourth': '四年级',
	'studentInfo.grade.fifth': '五年级',
	'studentInfo.grade.sixth': '六年级',
	'studentInfo.grade.seventh': '七年级',
	'studentInfo.grade.eighth': '八年级',
	'studentInfo.grade.ninth': '九年级',
	'studentInfo.grade.tenth': '高一',
	'studentInfo.grade.eleventh': '高二',
	'studentInfo.grade.twelfth': '高三',

	'studentInfo.grade.elementary': '国小',
	'studentInfo.grade.junior': '国中',
	'studentInfo.grade.senior': '高中',
	'studentInfo.grade.uni': '大学',

	'studentInfo.field.member': '会员',

	'studentInfo.confirm.updateStudentInfo.title': '更改学生资料？',
	'studentInfo.confirm.updateStudentInfo.body': '您将更改学生资料，请确认资料是否正确。',
};

const studentPerformance = {
	'studentPer.rowTitle.lessonVideos': '单元影片',
	'studentPer.rowTitle.examResults': '测验成绩',
	'studentPer.rowTitle.videoViewed': '影片观看',
	'studentPer.rowTitle.homeworkFinished': '作业完成',
	'studentPer.rowTitle.questionsAsked': '主动提问',
	'studentPer.rowTitle.checkedTips': '查看提示',
	'studentPer.rowTitle.submitTimes': '错误提交',
	'studentPer.rowTitle.finishedTime': '完成时间',
	'studentPer.rowTitle.runtimeEfficiency': '执行效率',
	'studentPer.rowTitle.classRanking': '排名名次',
	'studentPer.rowTitle.taggedPerf': '标记优秀',
	'studentPer.rowTitle.exerciseTime': '练习时间',
	'studentPer.rowTitle.exercisePerf': '答题表现',

	'studentPer.rowTitle.lessonInfo': '课程快讯',

	'studentPer.blockSubtitle.perfomance': '表现评价',
	'studentPer.blockSubtitle.classAverage': '全班平均',
	'studentPer.blockSubtitle.finishedLength': '完成数量',
	'studentPer.blockSubtitle.askedLength': '提出次数',
	'studentPer.blockSubtitle.checkedTimes': '查看次数',
	'studentPer.blockSubtitle.totalLength': '总计数量',
	'studentPer.blockSubtitle.personalTime': '个人时间',
	'studentPer.blockSubtitle.personalPerf': '个人表现',
	'studentPer.blockSubtitle.bestRecord': '最佳纪录',
	'studentPer.blockSubtitle.worstRecord': '最低纪录',
	'studentPer.blockSubtitle.overallPerformance': '综合表现',
	'studentPer.blockSubtitle.difficultySelect': '接受度',
	'studentPer.blockSubtitle.testAverage': '测验平均',
	'studentPer.blockSubtitle.exerciseCompletion': '习题完成率',

	'studentPer.barSubtitle.classAverage': '其他同学平均',
	'studentPer.barSubtitle.videos': '你观看的影片',
	'studentPer.barSubtitle.exercises': '你练习的题目',
	'studentPer.barSubtitle.exerciseTime': '你练习的时间',
	'studentPer.barSubtitle.homework': '你完成的作业',
	'studentPer.barSubtitle.questions': '你提问的次数',
	'studentPer.barSubtitle.tips': '你查看的次数',
	'studentPer.barSubtitle.submit': '你提交的次数',
	'studentPer.barSubtitle.time': '你花费的时间',
	'studentPer.barSubtitle.rank': '你的排名表现',
	'studentPer.barSubtitle.perf': '你的优秀次数',

	'studenPer.headerText.results': '成绩统计',
	'studenPer.headerText.selflearn': '自主学习',
	'studenPer.headerText.careful': '细心度',
	'studenPer.headerText.preformance': '效率表现',
	'studenPer.headerText.other': '其他表现',

	'studentPer.work': '作品',
	'studentPer.overall.evaluation': '整体评估',
	'studentPer.works': '精彩作品节录',
	'studentPer.feedback': '学生心得',
	'studentPer.attachments': '作品附件 / 活动相簿',
	'studentPer.learningAttitude': '学习态度',
	'studentPer.programmingAbility': '程式能力',
	'studentPer.attendance.rate': '出席率',
	'studentPer.homework.completion.rate': '作业完成率',
	'studentPer.participation': '参与度',
	'studentPer.programming.syntax.familiarity': '程式语法熟悉度',
	'studentPer.programming.logic.understanding': '程式逻辑理解度',
	'studentPer.problem.solving.skills': '应用问题解决能力',
	'studentPer.organizational.skills': '组织架构思维能力',
	'studentPer.creativity': '创意与创造力',
	'studentPer.first.work': '作品一',
	'studentPer.look.forward.to.amazing.work': '精彩作品敬请期待',
	'studentPer.attachments.link': '作品连结',
	'studentPer.albums.link': '课程活动相簿',
};

const parentPage = {
	'parentPage.expandAll': '展开全部状态',
	'parentPage.closeAll': '隐藏详细资料',

	'parentPage.selectCourse.placeholder': '选择课程',
};

const exerciseEditor = {
	'exerciseEditor.sidebar.exerciseList': '题目选单',
	'exerciseEditor.sidebar.questions': '我要提问',
	'exerciseEditor.sidebar.exerciseContent': '内容',
	'exerciseEditor.sidebar.exerciseQuestions': '题目',
	'exerciseEditor.sidebar.exerciseAnswers': '解答',
	'exerciseEditor.sidebar.exerciseResources': '资源',
	'exerciseEditor.sidebar.noInfoTitle': 'Oops !!',
	'exerciseEditor.sidebar.noInfo': '没有内容可以显示\n应该是老师没有开放唷！',

	'exerciseEditor.sidebar.answer.record': '答题记录',
	'exerciseEditor.answer.record.choice': '选择项目',
	'exerciseEditor.answer.record.correct.answer': '正确解答',
	'exerciseEditor.answer.record.rearrangement.result': '排序结果',
	'exerciseEditor.answer.record.your.answer': '你的答案',
	'exerciseEditor.answer.record.your.didnt.answer': '你未作答',

	'exerciseEditor.handIn.globalTest.announce.title': '计算成绩',
	'exerciseEditor.handIn.globalTest.announce.content': '请稍候，我们正在为您计算成绩',
};

const questionGroupModal = {
	'questionGroupModal.addQuestionGroup': '建立题组',
	'questionGroupModal.editQuestionGroup': '编辑题组',
	'questionGroupModal.previewQuestionGroup': '预览题组',
	'questionGroupModal.stepOne.basicContent': '基本内容',
	'questionGroupModal.stepTwo.importExercise': '汇入题目',
	'questionGroupModal.deleteQuestionGroup': '删除题组',

	'questionGroupModal.stepOne.info1': '1. 新版题组功能，您可将数个题目依目的或题型组成题组',
	'questionGroupModal.stepOne.info2': '2. 题目请由教学资源库汇入；您可直接拖曳题目调整顺序',

	'questionGroupModal.stepOne.title': '题组名称',
	'questionGroupModal.stepOne.titleholder': '请输入题组名称',
	'questionGroupModal.stepOne.hashtag': '自订标签',

	'questionGroupModal.stepTwo.selectFrom': '由教学资源库汇入',
	'questionGroupModal.stepTwo.amountcount': '已选择 {{amountcount}} 个项目',
	'questionGroupModal.resources.exercise': '习题',
	'questionGroupModal.resources.video': '影片',
};

const disconnetPage = {
	'disconnetPage.title': 'Sorry...系统暂时无法回应',
	'disconnetPage.text': '可能为伺服器或其他连线出了点问题',
};

const teacherCourseTestPage = {
	'testPage.header.title': '考试活动',
	'testPage.header.text':
		'我们推出了预先排定考试活动的功能，协助您预先规划。\n预举办一个考试活动，您可以选择教学资源库中的任何考卷，无需再至单元内汇入特定考卷后才能启动考卷。快来试试吧！',

	'testPage.endMockAlert': '此模拟练习已结束!',
	'testPage.endTestAlert': '此考试已结束!',

	'testPage.tabs.isScheduled': '已排定',
	'testPage.tabs.isEnded': '已测验',
	'testPage.tabs.isMockTest': '模拟练习',

	'testPage.list.isTestOngoing': '开放中',
	'testPage.list.testDate': '考试日期',
	'testPage.list.testDuration': '考试长度',
	'testPage.list.editTest': '编辑考试活动',
	'testPage.list.deleteTest': '删除此考试活动',
	'testPage.list.viewTest.setting': '检视考试设定',
	'testPage.list.endTest': '终止考试',
	'testPage.list.viewTestScore': '检视考试成绩',
	'testPage.list.openTestResource': '检讨资源开放',
	'testPage.list.deleteTestData': '删除此考试资料',
	'testPage.list.deleteMockTest': '删除此模拟测验',
	'testPage.list.noTests': '您还未建立任何考试活动',

	'testPage.modal.deleteTest.title': '您确定要删除此项考试活动？',
	'testPage.modal.deleteTest.content':
		'一旦删除考试活动，将无法在指定时间或课堂中发起此项考试。\n\n此动作不可回復，请再三确认后再执行此动作！',
	'testPage.modal.deleteTest.btn': '删除考试活动',

	'testPage.modal.endTest.title': '您确定要终止此项考试活动？',
	'testPage.modal.endTest.content':
		'考试活动进行中！\n\n一旦终止考试活动，学生将无法再继续作答，系统将自动开始计算考试成绩。\n\n此动作不可回復，请再三确认后再执行此动作！',
	'testPage.modal.endTest.btn': '终止考试活动',

	'testPage.fab.addTest': '建立考试活动',

	'testPage.alert.leaveTest': '离开试卷将不会储存您的答题结果，确定要离开了吗？',
	'testPage.alert.csfc.leaveTest': '离开试卷视同交卷，离开前请先提交试卷。 ',
};
const testInfo = {
	'testInfo.starttest': '开始作答',
	'testInfo.description': '测验描述',
	'testInfo.duration': '测验时间',

	'testInfo.viewtest': '检视答题内容',
	'testInfo.view5recent': '检视近 5 次成绩',

	'testInfo.recent5score': '近五次挑战成绩',

	'testInfo.mockDone': '已挑战过的模拟练习',
	'testInfo.allchallengetime': '总挑战时间',
};

const studentTestPage = {
	'studentTestPage.timeUp': '时间到，请停止考试',
	'studentTestPage.autoHandIn': '考试时间已到，系统已自动为您提交考卷。',
	'studentTestPage.countdowninfo': '测验进行中，距离结束',
	'studentTestPage.markquestion': '标注',
};
const loadingProcessBar = {
	'loadingProcessBar.createExam.title': '建立考试活动中...',
	'loadingProcessBar.createExamDone.title': '您指定的考试活动已经开始啰！',
	'loadingProcessBar.resetExam.title': '重建考试活动中...',
	'loadingProcessBar.endExam.title': '终止考试活动中...',
	'loadingProcessBar.endExamDone.title': '您指定的考试活动已经终止啰！',

	'loadingProcessBar.endExamDone.ok': '检视成绩',
	'loadingProcessBar.endExamDone.cancel': '稍后再看',
};

const globalExamPage = {
	'globalExamAd.line1': '模考加码送完整解题影片！',
	'globalExamAd.line2': '赶快点击选单为考APCS备战',
	'globalExamAd.cancelBtn': '我考过啦',

	'globalExamPage.EntranceAnnounce.title': '免费 APCS 模拟考，欢迎体验挑战！',
	'globalExamPage.EntranceAnnounce.content': `每一场模拟考分为「观念 20 题」与 「实作 2 题」，我们将常考的观念分开出卷，让你能有目标地确认各种观念是否准备齐全。

	测验中，请勿关闭浏览器或中断网路，或者离开考试介面，否则将无法储存你的答题结果；实作题环境提供 Python、Ｃ、C++ 语言作答，你可透过介面左侧的设定切换不同语言；
	作答时，请务必按下方的「提交」按钮，并透过左侧题目选单确认是否提交答案；

	完成考试，按下「交卷」按钮结束考试，或时间结束后由系统自动交卷；你将于五分钟内收到一份测验结果与简单分析，并附上解题参考影片。

	我们正开发新版软体功能，任何关于题目内容、平台功能的建议，都欢迎在我们粉丝团、IG 留言关注，
	帮助我们将这平台变得更好，让我们一起 Coding 吧！

	CodingBar 开发团队`,
};

const translationPage = {
	'translation.selectCourse': '请选择左侧课程以进行翻译',
	'translation.toBeTranslated': '待翻译课程列表',
	'translation.submitTranslation': '提交翻译',

	'translation.formTitle.courseInfo': '课程资讯',
	'translation.formTitle.lessonInfo': '单元资讯',
	'translation.formTitle.materialInfo': '讲义资讯',
	'translation.formTitle.exerciseInfo': '习题资讯',

	'translation.lesson.title': '单元名称',

	'translation.exercise.option': '题目选项',
	'translation.exercise.answer': '题目解答',
	'translation.exercise.text': '题目文字',

	'translation.exercise.cloze.row': '克漏字题目单行内容',
	'translation.exercise.dragdrop.row': '拖曳题目单行内容',
	'translation.exercise.cloze.text': '克漏字题目文字',
	'translation.exercise.cloze.slot': '克漏字填空答案',
	'translation.exercise.cloze.option': '克漏字选项',

	'translation.exercise.slot.content': '填空内容',

	'translation.test.noTest': '目前没有试捲',

	'translation.course.assignCourse': '指派给翻译者',
	'translation.course.assignCourse.success': '指派课程成功',
	'translation.course.assignCourse.fail': '指派给课程失败',

	'translation.translationManage.sidebar': '翻译课程管理',
	'translation.translationManage.noCourse': '目前尚无相关课程',
	'translation.translationManage.assignedTeacher': '指派教师',
	'translation.translationManage.assignedTimestamp': '指派日期',
	'translation.translationManage.submittedTimestamp': '提交日期',

	'translation.translationManage.tab.ongoing': '翻译中',
	'translation.translationManage.tab.ended': '翻译完成',

	'translation.translationManage.edit.title': '编辑指派翻译课程',

	'translation.translationManage.delete.title': '删除指派翻译课程',
	'translation.translationManage.delete.content': '请确认是否删除指派翻译课程，翻译人员将无法再存取该课程。',
};

const shareCourseModal = {
	'shareCourse.modal.confirm.titleDev': '分享课程？',
	'shareCourse.modal.confirm.bodyDev': '请确认是否将课程分享至相关单位或老师。',
};

const dataSyncPage = {
	'dataSync.page.sidebar': '资料同步管理',
	'dataSync.page.headerInfo': '跟报名表/CRM/家长平台相关串连的表单，都列在下方，请点击同步资料按钮',

	'dataSync.btn.syncData': '同步资料',
};

const crm = {
	'crm.header': 'CRM学生资料管理',

	'crm.searchResult': '搜寻结果：共 {{resultLength}} 笔 "{{searchTerm}}" 相符客户资料',

	'crm.action.sendEmail': '寄送信件',
	'crm.action.on.sendEmail': '寄送信件中',
	'crm.action.sendEmail.success': '寄送信件成功！ ',
	'crm.action.sendEmail.fail': '寄送信件失败！ ',
	'crm.action.sendSms': '发送简讯',

	'crm.email.title.addEmail': '新增信件',
	'crm.email.tab.template': '模板选择',

	'crm.email.modal.chooseTemplate': '选择模板',
	'crm.email.modal.chooseRegisForm': '课程名称与报名表代码',
	'crm.email.modal.chooseRegisClass': '班级名称',
	'crm.email.modal.bring': '携带物品',
	'crm.email.modal.bring.device': '准备设备',
	'crm.email.modal.preparation': '课前准备',
	'crm.email.modal.image': '登入范例图片',
	'crm.email.modal.note': '注意事项',
	'crm.email.modal.videoNote': '视讯软体使用教学',
	'crm.email.modal.videoDoc': '上传视讯软体教学文件',
	'crm.email.modal.teachBasic': '基本教学',
	'crm.email.modal.stepsDoc': '步骤教学文件',
	'crm.email.modal.emailHeading': '大标题',
	'crm.email.placeholder.emailHeading': '请输入大标题',
	'crm.email.modal.emailContent': '信件内容',
	'crm.email.placeholder.emailContent': '请输入信件内容',
	'crm.email.modal.isSendCampCertificate': '营队证书下载',
	'crm.email.modal.download.CampCertificate': '下载参加证书',
	'crm.email.advanced.settings': '进阶设定',
	'crm.email.modal.isSendCampAlbums': '活动相簿',
	'crm.email.modal.campAlbumLink': '营队活动照片相簿连结',
	'crm.email.modal.checkout.campAlbumLink': '查看活动照片',
	'crm.email.placeholder.campAlbumLink': '输入相簿连结',
	'crm.email.modal.courseRecommend': '选择推荐课程(至多两项)',
	'crm.email.modal.signature': '签名档设定',
	'crm.email.modal.continuation': '选择续班课程(至多一项)',
	'crm.email.modal.testName': '测验名称',
	'crm.email.modal.certificateLink': '检定证书连结',
	'crm.email.placeholder.certificateLink': '请贴上 URL',
	'crm.email.modal.emailTitle': '信件主旨',
	'crm.email.placeholder.emailTitle': '输入信件主旨',
	'crm.email.modal.testEmail': '测试信箱',
	'crm.email.modal.course.description': '课程介绍',
	'crm.email.modal.course.features': '课程特点',
	'crm.email.modal.picture.description': '图片说明文字',
	'crm.email.modal.registration.link': '报名表连结',
	'crm.email.placeholder.testEmail': '输入测试信箱',

	'crm.email.type.onSiteCourseNotice': '实体开课通知',
	'crm.email.type.offSiteCourseNotice': '远距开课通知',
	'crm.email.type.accountNotice': '自学帐号开通通知',
	'crm.email.type.campAchievement': '营队成果',
	'crm.email.type.courseAchievement': '学习报告评价',
	'crm.email.type.courseContinuation': '续班通知',
	'crm.email.type.certificate': '测验证书',
	'crm.email.type.notice': '讯息公告',

	'crm.email.type.onSiteCourseNotice.subLabel': '若需要上传教室位置图片，每张图片建议尺寸宽度为 480px',
	'crm.email.type.offSiteCourseNotice.subLabel': '请确认视讯软体的安装说明文件连结是否正确，或需要更新内容',
	'crm.email.type.accountNotice.subLabel': '请确认步骤教学文件等内容是否正确',
	'crm.email.type.campAchievement.subLabel': '请确认营队相簿连结，以及相关证书是否备齐，再发送信件',
	'crm.email.type.courseAchievement.subLabel':
		'1. 发送学习报告前，请先确认该课程已完成教师评语审核流程\n2. 若学生无该课程，或该课程尚未有学习报告时，该学生的信件将自动略过不寄',
	'crm.email.type.courseContinuation.subLabel': '选择续班班级时，可检视内容',
	'crm.email.type.certificate.subLabel': '选择续班班级时，可检视内容',
	'crm.email.type.notice.subLabel': '本模板为自由填写的空白公告，请谨慎使用',

	'crm.email.regis.lowestPrice': '单人最低价',
	'crm.email.otherSetting.subLabel': '设定完毕后，请先点击下方的「寄送测试信」，确认无误后再寄送给指定的客户们',
	'crm.email.send.testMail': '寄送测试信',

	'crm.email.type.onSiteCourseNotice.subject': '【CodingBar】 开课通知信',
	'crm.email.type.offSiteCourseNotice.subject': '【CodingBar】 远距开课通知信',
	'crm.email.type.accountNotice.subject': '【CodingBar】 线上自学帐号开通通知',
	'crm.email.type.campAchievement.subject': '【CodingBar】 营队成果/活动照片',
	'crm.email.type.courseAchievement.subject': '【CodingBar】 学习报告/评价',
	'crm.email.type.courseContinuation.subject': '【CodingBar】 续班通知',
	'crm.email.type.certificate.subject': '【CodingBar】 测验通过证书',
	'crm.email.type.notice.subject': '【CodingBar】 讯息公告通知',

	'crm.email.title.coming.course': '即将开课，请注意',
	'crm.email.title.active.account': '帐号已开通，请注意',
	'crm.email.title.study.hard': '努力不懈，用心学习',
	'crm.email.title.keep.learning': '持续学习',
	'crm.email.title.fruitful.result': '努力不懈，成果丰硕！ ',

	'crm.email.alert.course.not.created': '此課程尚未被建立!',
};

const manualEmailTemplate = {
	'manualEmailTemplate.linkButton.login': '登入 CodingBar',
	'manualEmailTemplate.linkButton.step': '查看步骤教学',
	'manualEmailTemplate.linkButton.register.now': '立即报名',

	'manualEmailTemplate.sectionRecommendedCourse.note.1': '精选课程推荐',
	'manualEmailTemplate.sectionRecommendedCourse.note.2': '本次活动课程带给',
	'manualEmailTemplate.sectionRecommendedCourse.note.3': '满满收获！ ',
	'manualEmailTemplate.sectionRecommendedCourse.note.4':
		'以下，将为您推荐适合的其它后续课程，请参考下方连结，或透过 Line@ 取得更多资讯！ ',

	'manualEmailTemplate.recommendedCourseBlock.linkButton.text': '查看更多',

	'manualEmailTemplate.mailAddress.class': '报名班别',
	'manualEmailTemplate.mailAddress.classRoom': '教室地点',

	'manualEmailTemplate.studentInfo.studentEmail': '学生帐号',

	'manualEmailTemplate.thankRegisterContent.note.1': '感谢您报名',
	'manualEmailTemplate.thankRegisterContent.note.2':
		'，您可直接用报名的email 直接登入 CodingBar 学习平台，便可开始程式学习之旅！在开始使用之前，请阅读以下事项：',

	'manualEmailTemplate.lineContact.note.1':
		'感谢您的参与和支持，如有任何问题，请于上班时间电洽 (02) 7717-9386 ，或透过官方 Line@ 与我们联络！ ',
	'manualEmailTemplate.lineContact.note.2': '立即加入Line@',
	'manualEmailTemplate.lineContact.note.3': '此为系统信件，请勿直接回覆',
	'manualEmailTemplate.lineContact.default.join':
		'您可点击下方按钮，下载关于 CodingBar 平台系统的使用教学文件，若有其他使用上的问题，也可加入 Line@ 获得更多资讯！ ',

	'manualEmailTemplate.toBring.default.computer.1':
		'若您是自备笔记型电脑，请注意 Windows 系统需 64 位元，Mac 系统需 os 10.13 以上系统',
	'manualEmailTemplate.toBring.default.computer.2':
		'请注意电脑系统若采用 Windows 系统需 64 位元，Mac 系统需 os 10.13 以上版本',
	'manualEmailTemplate.toBring.default.pad': '若您采用平板装置，请备妥搭配的键盘，以利课程中方便输入程式码进行学习',
	'manualEmailTemplate.toBring.default.other': '笔、水杯、个人餐具及个人药品',

	'manualEmailTemplate.toPrepare.default.chrome': '使用 Chrome 浏览器进入 CodingBar 平台',
	'manualEmailTemplate.toPrepare.default.system.account': '系统帐号将于',
	'manualEmailTemplate.toPrepare.default.system.test.login.1':
		'开通，请于开始前先登入系统测试，如有问题请加入 Line@ 询问客服人员',
	'manualEmailTemplate.toPrepare.default.system.test.login.2':
		'开通，请于课程前确认您能顺利登入我们的教学网页，如有问题请于 line@ 提出',
	'manualEmailTemplate.toPrepare.default.zoom.1': '上课需使用 zoom 会议系统，请',
	'manualEmailTemplate.toPrepare.default.zoom.2': '提前 5 分钟',
	'manualEmailTemplate.toPrepare.default.zoom.3': '进入 zoom 会议室测试系统避免影响上课权益',
	'manualEmailTemplate.toPrepare.default.zoom.4': '请事先测试麦克风与视讯镜头',
	'manualEmailTemplate.toPrepare.default.zoom.5': '为了辨识上课学员身份，请您务必',
	'manualEmailTemplate.toPrepare.default.zoom.6': '使用本名进入会议室',

	'manualEmailTemplate.toPrepare.default.video.1': '您可以透过两种方式加入会议',
	'manualEmailTemplate.toPrepare.default.video.2': '您的电脑已安装 Zoom 软体，可直接开启软体并加入会议',
	'manualEmailTemplate.toPrepare.default.video.3': '无论您是否有安装Zoom软体，都可以透过浏览器连线进入',

	'manualEmailTemplate.toNote.default.mask': '学生于课堂营队中，须全程佩戴口罩',
	'manualEmailTemplate.toNote.default.fever':
		'请配合工作人员测量体温及消毒措施，若有相关接触史或超过 37.5 度与发烧症状，将协助办理退费',

	'manualEmailTemplate.emailContent.default.dear': '亲爱的',
	'manualEmailTemplate.emailContent.default.course.end.1':
		'这次的课程营队圆满结束，感谢对CodingBar的支持，我们提供给您',
	'manualEmailTemplate.emailContent.default.course.end.2': '的活动证书在下方连结，同时也提供本次课程营队的照片给您参考',
	'manualEmailTemplate.emailContent.default.course.end.3': '有任何需要进一步了解的地方，欢迎随时让我们知道喔！ ',

	'manualEmailTemplate.emailContent.default.course.about.to.end.1': '已进入尾声，讲师针对 ',
	'manualEmailTemplate.emailContent.default.course.about.to.end.2':
		'的课程表现与学习建议建议如下，提供给您参考；同时，我们也在下方提供了一份学习报告连结，若有任何需要进一步了解的地方，请与我们联络喔～',

	'manualEmailTemplate.emailContent.default.congratulation.1': '恭喜完成',
	'manualEmailTemplate.emailContent.default.congratulation.2':
		'的课程！在学习的旅途中，我们提供六阶段渐进式课程，让学生分段具备微软 MTA 和 aAPCS 等认证实力！下一期',
	'manualEmailTemplate.emailContent.default.congratulation..3': '将带领孩子继续学习，欢迎报名。 ',

	'manualEmailTemplate.emailContent.default.signature': '学生关怀部',

	'manualEmailTemplate.emailContent.default.notice.1':
		'感谢您在学习的道路上努力不懈，也在课程最后参与检定测验来检视您的学习成果！ ',
	'manualEmailTemplate.emailContent.default.notice.2':
		'下方测验成绩供您参考，同时我们提供检定证书予以鼓励，欢迎透过下方连结下载。 ',
};

const parentEmailTemplate = {
	'parentEmailTemplate.signUp': '报名',
	'parentEmailTemplate.waiting': '候补',

	'parentEmailTemplate.x1.title': '报名成功，欢迎加入',
	'parentEmailTemplate.x2.title': '缴费完成，敬请期待',
	'parentEmailTemplate.x3.title': '请认证您的信箱',
	'parentEmailTemplate.x4.title': '重设密码',
	'parentEmailTemplate.x5.title': '名额有限，请勿错过',
	'parentEmailTemplate.x6.title': '候补完成，静候佳音',

	'parentEmailTemplate.x1.innerTextBottom1':
		'我们提供多种付款方式，课程名额将视缴费顺序进行保留，请点选下方缴费连结，并于',
	'parentEmailTemplate.x1.innerTextBottom2': '三天之内',
	'parentEmailTemplate.x1.innerTextBottom3': '缴费完毕，以确保您的上课权益。',
	'parentEmailTemplate.x1.innerTextBottom4':
		'完成缴费后，您将收到缴费确认信，请将 service@codingbar.ai 设为常用联络人，才不会漏掉重要通知唷！',
	'parentEmailTemplate.x1.innerTextBottom5': '请将 service@codingbar.ai 设为常用联络人，才不会漏掉重要通知唷！',

	'parentEmailTemplate.x1_simple.innerTextBottom1':
		'此外，别忘记点击下方连结，填写地址与学生资料，包含姓名、邮件信箱、学生联络电话等相关资讯，以利行政作业程序！',

	'parentEmailTemplate.x1.linkButton': '立即前往缴费',
	'parentEmailTemplate.x1_simple.linkButton': '填写学生资讯',
	'parentEmailTemplate.x3.linkButton': '认证信箱',
	'parentEmailTemplate.x4.linkButton': '重设密码',
	'parentEmailTemplate.x5.linkButton': '立即前往缴费',

	'parentEmailTemplate.x2.innerTextTop1': '您已完成',
	'parentEmailTemplate.x2.innerTextTop2': '的报名缴费程序，我们已收到课程款项，将于',
	'parentEmailTemplate.x2.innerTextTop3': '开课前一周',
	'parentEmailTemplate.x2.innerTextTop4':
		'寄发开课通知；请务必将 service@codingbar.ai 设为常用联络人，避免遗漏后续重要通知。',

	'parentEmailTemplate.x3.innerTextBottom1': '欢迎您注册 CodingBar 与我们一起开始程序学习的道路！',
	'parentEmailTemplate.x3.innerTextBottom2':
		'请点击下方连结认证并开通帐号，就能开始享受 CodingBar 程序学习平台的强大功能唷。',

	'parentEmailTemplate.x4.innerTextTop1':
		'请点击下方连结重新设定您的 CodingBar 帐号密码，若您没有申请过重新设定密码，请忽略此封信。',

	'parentEmailTemplate.x5.innerTextTop1': '感谢您报名',
	'parentEmailTemplate.x5.innerTextTop2': '，由于名额有限，请参考下列缴费资讯，并敬请于',
	'parentEmailTemplate.x5.innerTextTop3':
		'前完成缴费，届时如未缴费CodingBar将会释出您的名额给其他候补学员，不另行通知，敬请见谅。',

	'parentEmailTemplate.x6.innerTextTop1': '您已完成',
	'parentEmailTemplate.x6.innerTextTop2':
		'的候补程序，并排入候补名单中，若候补成功，将有专人与您联络；请务必将 service@codingbar.ai 设为常用联络人，避免遗漏后续重要通知。',
};

const registrationDownloadPage = {
	'registration.download.tablabel.open': '课程(开放)',
	'registration.download.tablabel.closed': '课程(关闭)',
	'registration.download.tablabel.tutorOpen': '家教(开放)',
	'registration.download.tablabel.tutorClosed': '家教(关闭)',

	'registration.download.sortlabel.name': '名称',
	'registration.download.sortlabel.formId': '报名表代码',
	'registration.download.sortlabel.formAttributeSelected': '报名表种类',

	'registration.download.headerlabel.name': '班级名称',
	'registration.download.headerlabel.courseCid': '班级代码',
	'registration.download.headerlabel.placeSelected': '班级地区',
	'registration.download.headerlabel.classSelected': '班级序号',
	'registration.download.headerlabel.courseStartAndEndDate': '班级日期',
	'registration.download.headerlabel.classTime': '班级时间',

	'registration.download.iconMenu.view': '检视资料',
	'registration.download.searchBox.placeholder': '输入关键字或报名表代码',

	'registration.download.dumpBtn.text': '下载资料',
	'registration.download.settingModal.confirmBtn.text': '确认下载',

	'registration.download.settingModal.title': '下载设定',
	'registration.download.settingModal.note1': '下载档案为 CSV 格式，请汇入 google sheet 使用',
	'registration.download.settingModal.note2': '多份报名表合并下载时，会以不同档案形式呈现',
	'registration.download.settingModal.note3': '可设定报名/缴费时间区间',
	'registration.download.settingModal.basicSettings': '基本设定',
	'registration.download.settingModal.basicSettings.paid': '已缴费',
	'registration.download.settingModal.basicSettings.oldmember': '是否为旧生',
	'registration.download.settingModal.payMethodSelected': '缴费种类',
	'registration.download.settingModal.payMethodSelected.credit': '信用卡',
	'registration.download.settingModal.payMethodSelected.atm': 'ATM',
	'registration.download.settingModal.payMethodSelected.cvs': '超商',
	'registration.download.settingModal.timeSelected.title': '时间区间属性',
	'registration.download.settingModal.timeSelected.paid': '已缴费',
	'registration.download.settingModal.timeSelected.registed': '已报名',

	'registration.download.settingModal.begin.date': '请选择起始日期',
	'registration.download.settingModal.end.date': '请选择截止日期',
	'registration.download.settingModal.begin.time': '请选择起始时间',
	'registration.download.settingModal.end.time': '请选择截止时间',
	'registration.download.settingModal.alert.end.date': '截止日期不能早于起始日期',
	'registration.download.settingModal.alert.end.time': '截止时间不能早于起始时间',

	'registration.download.settingModal.clearBeginTime': '清空起始欄位',
	'registration.download.settingModal.clearCutoffTime': '清空截止欄位',

	'registration.download.switch.courseCid': '依班级代码',
	'registration.download.switch.formId': '依报名表代码',
};

const crmManage = {
	'crmManage.main.sidebar.crm-student': 'CRM 报名资料',
	'crmManage.main.sidebar.crm': 'CRM 资料管理',

	'crmAccountManage.sidebar.name': 'CRM 帐号管理',
	'crmAccountManage.iconMenu.switch': '切换 CRM 权限',
	'crmAccountManage.confirm.title': '切换成 CRM 权限？',
	'crmAccountManage.confirm.body': '请确认是否将此帐号\n{{uid}}\n切换为具有 CRM 权限(此操作无法还原)',
};

const internalMailManage = {
	'internalMailManage.sidebar.label': '员工寄信管理',

	'internalMailManage.page.headerInfo': '内部人员相关寄信需求，请自行填写加入',
	'internalMailManage.editmail.btn': '编辑收信人',
	'internalMailManage.createmail.btn': '新增收信名单',

	'internalMailManage.key.title': '请输入 key',
	'internalMailManage.title.title': '请输入标题',
	'internalMailManage.emailContent.title': '请输入 email 列表',
};

const toolsPage = {
	'toolsPage.tools': '常用小工具',
	'toolsPage.tools.CSFC.download.title': 'CSFC 证书制作及下载',
	'toolsPage.tools.CSFC.download.subtitle': '请参照CSV格式制作名单，汇入名单至系统并制作为证书。 ',
	'toolsPage.tools.CSFC': '制作证书',
	'toolsPage.CertificateGenerator.title': '证书设定及下载',
	'toolsPage.CertificateGenerator.note.1': '学生名单请参考',
	'toolsPage.CertificateGenerator.note.2': '范本档案',
	'toolsPage.CertificateGenerator.note.3':
		'，依格式将名单制作为 csv 档并汇入。若开启 csv 范本为乱码，请将编码变更为 unicode，或使用 google 试算表开启。 ',
	'toolsPage.CertificateGenerator.form.exam': '检定项目',
	'toolsPage.CertificateGenerator.form.exam.date': '检定日期',
	'toolsPage.CertificateGenerator.form.exam.level': '等级',
	'toolsPage.CertificateGenerator.form.exam.certificate.date': '发证日期',
	'toolsPage.CertificateGenerator.form.exam.names': '证书颁发名单',
	'toolsPage.CertificateGenerator.form.exam.names.placeholder': '请输入学生姓名，每位学生姓名为一行',
	'toolsPage.CertificateGenerator.import.CSV': 'CSV 档案汇入名单',
	'toolsPage.CertificateGenerator.download.Certificate': '下载证书',
	'toolsPage.tools.certificate.download.title': '结业证书制作及下载',

	'toolsPage.CertificateGenerator.download.completed': '下载完成',
	'toolsPage.CertificateGenerator.download.completed.note': '证书已下载完成，请至下载资料夹查看。 ',
	'toolsPage.CertificateGenerator.generating': '生成中...',

	'toolsPage.tools.createAccount.school.title': '校园授权方案开通',
	'toolsPage.tools.createAccount.school.subtitle': '请根据申请试用表单，填写对应资料并生成课程。',
	'toolsPage.tools.createAccount': '开通帐号',
	'toolsPage.tools.createAccount.school.completed': '已开通帐号',
	'toolsPage.tools.createAccount.school.completed.body': '帐号与课程已建立，系统已寄送通知信件。',

	'toolsPage.tools.createAccount.info.email': '申请者 Email',
	'toolsPage.tools.createAccount.info.unit': '申请者单位',
	'toolsPage.tools.createAccount.info.displayName': '申请者姓名',
	'toolsPage.tools.createAccount.info.phone': '申请者联络电话',
	'toolsPage.tools.createAccount.info.trialPlan': '申请方案',
	'toolsPage.schoolAuthorizeTrial.info.cloneCourse': '复制课程来源',

	'toolsPage.schoolAuthorizeTrial.note.1': '请填写申请者相关资讯并选取方案，将根据不同方案提供对应课程与服务内容。',
	'toolsPage.schoolAuthorizeTrial.info.planFree': '免费',
	'toolsPage.schoolAuthorizeTrial.info.plan1': '方案1',
	'toolsPage.schoolAuthorizeTrial.info.plan2': '方案2',

	'toolsPage.schoolAuthorizeTrial.generating': '正在创建帐号课程中，请耐心等候',
	'toolsPage.schoolAuthorizeTrial.account.exists': '此帐号已存在',
	'toolsPage.schoolAuthorizeTrial.inValidCourseId': '请输入正确的课程 Id',

	'toolsPage.schoolAuthorizeTrial.sendEmailTitle': 'CodingBar 校园授权方案免费体验开通',
	'toolsPage.schoolAuthorizeTrial.apply.success': 'CodingBar 校园授权方案试用申请成功',
	'toolsPage.schoolAuthorizeTrial.apply.success.content': '请至信箱收信，并且启用帐号。 ',
	'toolsPage.schoolAuthorizeTrial.generating.fail': 'CodingBar 校园授权方案试用开通失败',
	'toolsPage.schoolAuthorizeTrial.note.2':
		'此按钮将更新"复制课程来源"栏位资料至资料库，套用在"校园方案试用"自动与手动流程。 ',
	'toolsPage.schoolAuthorizeTrial.note.3':
		'只有 courseId 为"006f40ef-abe0-4c4a-b07d-c51e6d14f597-1662540906827" 会创建 dummy 学生作答记录',
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db': '更新复制课程来源 DB',
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db.note':
		'是否要将目前"复制课程来源"栏位资料更新至资料库，请再三确认!!!',
	'toolsPage.schoolAuthorizeTrial.unit.alert': '单位不可为空或 airabbi!',
	'schoolAuthorizeTrial.alreadyApplied.content':
		'此帐号侦测已申请过免费体验课程，请直接点选下方连结登入平台后体验，如体验时间已结束，请联络客服。 ',
	'schoolAuthorizeTrial.done.content':
		'您的体验课程已开通，请点选下方连结后进行课程体验！系统将于 14 天后结束体验，如需正式版请联络客服。 ',
	'schoolAuthorizeTrial.userExistInOtherUnit.content': '帐号已存在于其他单位! 请洽客服人员。 ',

	'toolsPage.schoolCAGenerator.title': '校园 Usb 凭证制作',
	'toolsPage.schoolCAGenerator.subtitle': '请根据栏位填写对应资料并生成凭证资料。',
	'toolsPage.schoolCAGenerator.btn': '制作凭证',
	'toolsPage.schoolCAGenerator.completed': '凭证完成',
	'toolsPage.schoolCAGenerator.completed.note': '凭证已下载完成，请由下载资料夹将档案放至 Usb 内。',

	'toolsPage.schoolCAGenerator.note.1': '请根据栏位填写对应资料并生成凭证资料。',

	'toolsPage.schoolCAGenerator.info.email': '凭证教师 Email',
	'toolsPage.schoolCAGenerator.info.unit': '凭证教师单位',
	'toolsPage.schoolCAGenerator.info.displayName': '凭证教师姓名',
	'toolsPage.schoolCAGenerator.info.phone': '凭证教师联络电话',

	'toolsPage.schoolCAGenerator.generating': '正在创建凭证中，请耐心等候',

	'toolsPage.financialReportTransfer.title': '绿界每月财务入帐金额统计',
	'toolsPage.financialReportTransfer.subtitle': '请将绿界报表上传并确认开课班级管理表资料后进行转换。',
	'toolsPage.financialReportTransfer.btn': '上传报表',
	'toolsPage.financialReportTransfer.completed': '转换完成',
	'toolsPage.financialReportTransfer.completed.note': '报表已转换完成，请开启下载资料夹观看档案。',

	'toolsPage.financialReportTransfer.generating': '正在转换报表中，请耐心等候',

	'toolsPage.financialReportTransfer.note.1': '请上传绿界报表(.xlsx) 并确认',
	'toolsPage.financialReportTransfer.note.2': '开课班级管理表',
	'toolsPage.financialReportTransfer.note.3': '资料中，使用到的 课程row 于栏位 BE, BF 中有内容。',
	'toolsPage.financialReportTransfer.dataIsLoading': '载入外部资料【开课班级管理表】中...',
	'toolsPage.financialReportTransfer.dataLoadingDone': '载入完成！',

	'toolsPage.dataError': '资料有误',
	'toolsPage.LaborReportGenerator.title': '劳报单产生器',
	'toolsPage.LaborReportGenerator.generating': '正在产生 Pdf 并寄送，请耐心等候',
	'toolsPage.LaborReportGenerator.subtitle':
		'请使用 A1-006 每个月汇出的CSV档，汇入此处后自动生成对应的劳报单，并自动寄送到对应信箱中',
	'toolsPage.LaborReportGenerator.subtitle2': '1. 图片档案格式为 jpg, 请转档后压缩 ',
	'toolsPage.LaborReportGenerator.subtitle3': '2. 上传档案名称结尾请符合格式 YYYY-MM',
	'toolsPage.LaborReportGenerator.done': '操作完成',

	'toolsPage.teacherSchedule': '教师行事历',
	'toolsPage.autoJoinCourses.title': '自动开通课程名单设定',
	'toolsPage.autoJoinCourses.subtitle':
		'线上自学课程、CBA 课程，缴费完后，根据 10 码对应到的课程 ID ，自动开通课程到学生的帐号。',
	'toolsPage.autoJoinCourses.note':
		'请将开课班级管理表 B栏位 10码与对应的Course ID 贴入下方 textfield ，并以半形逗号分开',
	'toolsPage.autoJoinCourses.title.classId': '班级代码 (B栏位)',
	'toolsPage.AutoJoinCoursesManage.delete.title': '删除课程名单',
	'toolsPage.AutoJoinCoursesManage.delete.content': '确认是否删除课程名单: {{key}}',
	'toolsPage.AutoJoinCoursesManage.delete.content2': '注意！课程时限设定也将一并删除!',
	'toolsPage.CourseValidityPeriod.title': '课程时限设定',
	'toolsPage.CourseValidityPeriod.subtitle': '开课班级管理表 B 栏位 10 码的时限方案设定',
	'toolsPage.CourseValidityPeriod.note': '选择 B 栏位 10 码后设定时限，以天为单位',
	'toolsPage.CourseValidityPeriod.delete.title': '删除课程时限',
	'toolsPage.CourseValidityPeriod.delete.content': '确认是否删除课程时限: {{key}}',
	'toolsPage.CourseValidityPeriod.confirmation.title': '课程方案到期通知',
	'toolsPage.CourseValidityPeriod.confirmation.content':
		'您购买的课程 {{title}} 已于 {{expiryTime}} 到期，\n如欲延长课程时间，请联络客服，谢谢！ ',
};

const batchTeacherAccountModal = {
	'batchTeacherAccountModal.content':
		'请汇入 .xlsx 档案，第一栏为姓名，第二栏为 email，并注意工作表名称应设定为 sheet1',
	'batchTeacherAccountModal.generating': '正在批次创建帐号中，请耐心等候',
};

const globalTestModal = {
	'globalTest.score.sendEmailComplete':
		'完成！您现在可以点击确认按键离开了，\n提醒您，成绩结果请稍候于您的信箱内检视，如未收到信件，请先确认您的垃圾信箱。',
	'globalTest.score.progressBar.label1': '正在计算考试成绩...',
	'globalTest.score.progressBar.label2': '正在产生成绩报表...',
	'globalTest.score.progressBar.label3': '正在寄送结果信件...',
	'globalTest.score.progressBar.complete': '完成！',
};

const csfcStudentAccountModal = {
	'csfcStudentAccountModal.title': '汇入 CSFC 报名资讯',
	'csfcStudentAccountModal.warning.repeatEmail': '已有报名纪录资料，将不再重复报名。',
	'csfcStudentAccountModal.warning.testId.not.exist': '您汇入的 csv 档有误，请再次确认档案内容。 ',
	'csfcStudentAccountModal.warning.wrong.testId': '该场次不存在，请再次确认档案内容。 ',
	'csfcStudentAccountModal.warning.wrong.unit': '所属单位有误或未填写，请再次确认档案内容。 ',
	'csfcStudentAccountModal.import.downloadNotes-2': '(1) 请下载对应的报名场次 CSV 档案',
	'csfcStudentAccountModal.import.downloadNotes-3':
		'，依格式将名单制作为 csv 档并汇入，系统产生学生注册资料；若开启 csv 范本为乱码，请将',
	'csfcStudentAccountModal.import.downloadNotes-8': '；若该学生已报名同一场次时，将无法勾选汇入。',

	'csfcStudentAccountModal.sendEmailTitle': '【CSFC 检测系统报名】',

	'csfcVerifyPage.success.title': 'CSFC 检测报名成功！',
	'csfcVerifyPage.success.content':
		'您好，您已确认报名 CSFC 检测成功，请记得于检测时间进入检测系统(https://exam.csfcasia.org)，如有问题请点选下方 line 与我们联系，谢谢。',

	'csfcVerifyPage.fail.title': 'CSFC 检测报名资料错误',
	'csfcVerifyPage.fail.content':
		'您好，您的 CSFC 检测连结似乎有误，请再次确认您是否点选正确连结，或是透过下方 line 与我们联系，谢谢。',

	'csfcStudentAccountModal.toast.importDone': '帐号全数创建完成！',
	'csfcStudentAccountModal.test.evet.time': '场次时间',
	'csfcStudentAccountModal.test.evet.title': '场次项目',
	'csfcStudentAccountModal.test.evet.csv.file': 'CSV 报名档',
	'csfcStudentAccountModal.tab1.label': '下载报名场次 CSV 档案',
	'csfcStudentAccountModal.tab2.label': '汇入 CSV',
	'csfcStudentAccountModal.manual.handIn': '手动交卷',
	'csfcStudentAccountModal.manual.handIn.success': '手动交卷成功',
	'csfcStudentAccountModal.manual.handIn.confirmation.title': '手动交卷 CSFC 检测结果',
	'csfcStudentAccountModal.manual.handIn.confirmation.content': '该学生尚未交卷，手动交卷将纪录检测结果并寄送给学生。 ',
	'CSFCExamPage.info': '应考人资讯',
	'CSFCExamPage.exam.note': 'CSFC 资讯科学能力检定考试注意事项：',
	'CSFCExamPage.exam.note.guidebook': 'CSFC 考生指引手册',
	'CSFCExamPage.exam.note.content0': '请在测验开始前详细阅读',
	'CSFCExamPage.exam.note.content1': '作答时，请务必按下方的「提交」按钮，并透过左侧题目选单确认是否提交答案',
	'CSFCExamPage.exam.note.content2':
		'完成测验，按下「交卷」按钮结束测验，或测验时间到将由系统自动交卷；你将于五分钟内收到一份测验结果。 ',
	'CSFCExamPage.exam.note.content3': '若有网路中断或其他状况发生，可于测验结束前回到平台继续作答。 ',
	'CSFCExamPage.exam.events': '考试场次',
	'CSFCExamPage.exam.result.concept': '观念题考试',
	'CSFCExamPage.exam.result.implement': '实作题考试',
	'CSFCExamPage.exam.result.concept': '观念',
	'CSFCExamPage.exam.result.implement': '实作',
	'CSFCExamPage.exam.test.time': '考试时间',
	'CSFCExamPage.exam.button.status.doing': '进行中',
	'CSFCExamPage.exam.button.status.start': '开始测验',
	'CSFCExamPage.exam.button.status.done': '已交卷',
	'CSFCExamPage.exam.button.status.invalid': '尚未验证',
	'CSFCExamPage.exam.button.status.countdown1': '',
	'CSFCExamPage.exam.button.status.countdown2': '后开始',
	'CSFCExamPage.exam.failed': '未通过',
	'CsfcStudentManage.modal.title': '检视 CSFC 学生资料',
	'CsfcStudentManage.modal.title.validation': '报名验证',
	'CsfcStudentManage.modal.title.row.score': '分数(观念,实作)',
	'CsfcStudentManage.student.valid': '已验证',
	'CsfcStudentManage.no.students': '尚未汇入 CSFC 学生',
};

const chatGPT = {
	'UnitPermissionsManagePage.title': '单位功能权限管理',
	'chatGPTManage.modal.preview.selectedCourse': '检视已选课程',
	'chatGPTManage.modal.preview.selectedCourse.undo': '取消检视已选课程',
	'chatGPTManage.modal.preview.selectedCourse.no.courseIds': '尚未选择任何课程',
	'chatGPTManage.modal.title': 'chatGPT 权限管理',
	'chatGPTManage.confirmation.modal.title': '编辑 chatGPT 权限',
	'chatGPTManage.confirmation.modal.content': '请确认是否编辑该单位的 chatGPT 权限',
	'chatGPTManage.authorizationType.title': '权限设定',
	'chatGPTManage.authorizationType.unit': '单位',
	'chatGPTManage.authorizationType.course': '课程',
	'chatGPTManage.authorizationType.none': '不开放',
	'chatGPTManage.authorizationType': 'chatGPT 类型',
	'chatGPTManage.authorizationType.toast.title': 'chatGPT 权限, {{status}}',
	'chatGPTManage.noPromptLimit': 'Prompt 無限制',
	'exerciseAssistant.header.label': '独眼怪 AI',
	'exerciseAssistant.chatinput.placeholder': '来问独眼怪小助手吧！ ',
	'exerciseAssistant.chatinput.loading': '...',
	'exerciseAssistant.messages.placeholder': '你好，跟程式有关的问题，我都能尽可能回答你喔！ ',
	'exerciseAssistant.messages.preset': '预设',
	'exerciseAssistant.messages.tokens.alert': '您的输入超过 tokens 使用上限',
	'multiExerciseTypeManage.title': '编辑多元题型权限',
	'multiExerciseTypeManage.type': '多元题型',
	'multiExerciseTypeManage.confirmation.content': '请确认是否编辑该单位的多元题型权限',
	'multiExerciseTypeManage.type.title': '开放设定',
	'multiExerciseTypeManage.type.all': '全选',
	'multiExerciseTypeManage.type.basic': '基本',
	'multiExerciseTypeManage.type.paid': '付费',
	'multiExerciseTypeManage.toast.title': '编辑多元题型权限, {{status}}',
	'multiExerciseTypeManage.exercise.type.basic': '基本题型选择',
	'multiExerciseTypeManage.exercise.type.advanced': '进阶题型选择',
	'chatGPT.nothing.to.delete': '没有对话内容！',
};

const general = {
	'AF': '阿富汗',
	'AX': '奥兰',
	'AL': '阿尔巴尼亚',
	'DZ': '阿尔及利亚',
	'AS': '美属萨摩亚',
	'AD': '安道尔',
	'AO': '安哥拉',
	'AI': '安圭拉',
	'AQ': '南极洲',
	'AG': '安提瓜和巴布达',
	'AR': '阿根廷',
	'AM': '亚美尼亚',
	'AW': '阿鲁巴',
	'AU': '澳大利亚',
	'AT': '奥地利',
	'AZ': '阿塞拜疆',
	'BS': '巴哈马',
	'BH': '巴林',
	'BD': '孟加拉国',
	'BB': '巴巴多斯',
	'BY': '白俄罗斯',
	'BE': '比利时',
	'BZ': '伯利兹',
	'BJ': '贝宁',
	'BM': '百慕大',
	'BT': '不丹',
	'BO': '玻利维亚',
	'BQ': '荷兰加勒比区',
	'BA': '波黑',
	'BW': '博茨瓦纳',
	'BV': '布韦岛',
	'BR': '巴西',
	'IO': '英属印度洋领地',
	'BN': '文莱',
	'BG': '保加利亚',
	'BF': '布基纳法索',
	'BI': '布隆迪',
	'KH': '柬埔寨',
	'CM': '喀麦隆',
	'CA': '加拿大',
	'CV': '佛得角',
	'KY': '开曼群岛',
	'CF': '中非',
	'TD': '乍得',
	'CL': '智利',
	'CN': '中国',
	'CX': '圣诞岛',
	'CC': '科科斯（基林）群岛',
	'CO': '哥伦比亚',
	'KM': '科摩罗',
	'CG': '刚果共和国',
	'CD': '刚果民主共和国',
	'CK': '库克群岛',
	'CR': '哥斯达黎加',
	'CI': '科特迪瓦',
	'HR': '克罗地亚',
	'CU': '古巴',
	'CW': '库拉索',
	'CY': '塞浦路斯',
	'CZ': '捷克',
	'DK': '丹麦',
	'DJ': '吉布提',
	'DM': '多米尼克',
	'DO': '多米尼加',
	'EC': '厄瓜多尔',
	'EG': '埃及',
	'SV': '萨尔瓦多',
	'GQ': '赤道几内亚',
	'ER': '厄立特里亚',
	'EE': '爱沙尼亚',
	'ET': '埃塞俄比亚',
	'FK': '福克兰群岛',
	'FO': '法罗群岛',
	'FJ': '斐济',
	'FI': '芬兰',
	'FR': '法国',
	'GF': '法属圭亚那',
	'PF': '法属波利尼西亚',
	'TF': '法属南部和南极领地',
	'GA': '加蓬',
	'GM': '冈比亚',
	'GE': '格鲁吉亚',
	'DE': '德国',
	'GH': '加纳',
	'GI': '直布罗陀',
	'GR': '希腊',
	'GL': '格陵兰',
	'GD': '格林纳达',
	'GP': '瓜德罗普',
	'GU': '关岛',
	'GT': '危地马拉',
	'GG': '根西',
	'GN': '几内亚',
	'GW': '几内亚比绍',
	'GY': '圭亚那',
	'HT': '海地',
	'HM': '赫德岛和麦克唐纳群岛',
	'VA': '梵蒂冈',
	'HN': '洪都拉斯',
	'HK': '香港',
	'HU': '匈牙利',
	'IS': '冰岛',
	'IN': '印度',
	'ID': '印度尼西亚',
	'IR': '伊朗',
	'IQ': '伊拉克',
	'IE': '爱尔兰',
	'IM': '马恩岛',
	'IL': '以色列',
	'IT': '意大利',
	'JM': '牙买加',
	'JP': '日本',
	'JE': '泽西',
	'JO': '约旦',
	'KZ': '哈萨克斯坦',
	'KE': '肯尼亚',
	'KI': '基里巴斯',
	'KP': '朝鲜',
	'KR': '韩国',
	'KW': '科威特',
	'KG': '吉尔吉斯斯坦',
	'LA': '老挝',
	'LV': '拉脱维亚',
	'LB': '黎巴嫩',
	'LS': '莱索托',
	'LR': '利比里亚',
	'LY': '利比亚',
	'LI': '列支敦士登',
	'LT': '立陶宛',
	'LU': '卢森堡',
	'MO': '澳门',
	'MK': '北马其顿',
	'MG': '马达加斯加',
	'MW': '马拉维',
	'MY': '马来西亚',
	'MV': '马尔代夫',
	'ML': '马里',
	'MT': '马耳他',
	'MH': '马绍尔群岛',
	'MQ': '马提尼克',
	'MR': '毛里塔尼亚',
	'MU': '毛里求斯',
	'YT': '马约特',
	'MX': '墨西哥',
	'FM': '密克罗尼西亚联邦',
	'MD': '摩尔多瓦',
	'MC': '摩纳哥',
	'MN': '蒙古',
	'ME': '黑山',
	'MS': '蒙特塞拉特',
	'MA': '摩洛哥',
	'MZ': '莫桑比克',
	'MM': '缅甸',
	'NA': '纳米比亚',
	'NR': '瑙鲁',
	'NP': '尼泊尔',
	'NL': '荷兰',
	'NC': '新喀里多尼亚',
	'NZ': '新西兰',
	'NI': '尼加拉瓜',
	'NE': '尼日尔',
	'NG': '尼日利亚',
	'NU': '纽埃',
	'NF': '诺福克岛',
	'MP': '北马里亚纳群岛',
	'NO': '挪威',
	'OM': '阿曼',
	'PK': '巴基斯坦',
	'PW': '帕劳',
	'PS': '巴勒斯坦',
	'PA': '巴拿马',
	'PG': '巴布亚新几内亚',
	'PY': '巴拉圭',
	'PE': '秘鲁',
	'PH': '菲律宾',
	'PN': '皮特凯恩群岛',
	'PL': '波兰',
	'PT': '葡萄牙',
	'PR': '波多黎各',
	'QA': '卡塔尔',
	'RE': '留尼汪',
	'RO': '罗马尼亚',
	'RU': '俄罗斯',
	'RW': '卢旺达',
	'BL': '圣巴泰勒米',
	'SH': '圣赫勒拿、阿森松和特里斯坦-达库尼亚',
	'KN': '圣基茨和尼维斯',
	'LC': '圣卢西亚',
	'MF': '法属圣马丁',
	'PM': '圣皮埃尔和密克隆',
	'VC': '圣文森特和格林纳丁斯',
	'WS': '萨摩亚',
	'SM': '圣马力诺',
	'ST': '圣多美和普林西比',
	'SA': '沙特阿拉伯',
	'SN': '塞内加尔',
	'RS': '塞尔维亚',
	'SC': '塞舌尔',
	'SL': '塞拉利昂',
	'SG': '新加坡',
	'SX': '荷属圣马丁',
	'SK': '斯洛伐克',
	'SI': '斯洛文尼亚',
	'SB': '所罗门群岛',
	'SO': '索马里',
	'ZA': '南非',
	'GS': '南乔治亚和南桑威奇群岛',
	'SS': '南苏丹',
	'ES': '西班牙',
	'LK': '斯里兰卡',
	'SD': '苏丹',
	'SR': '苏里南',
	'SJ': '斯瓦尔巴和扬马延',
	'SZ': '斯威士兰',
	'SE': '瑞典',
	'CH': '瑞士',
	'SY': '叙利亚',
	'TW': '台湾',
	'TJ': '塔吉克斯坦',
	'TZ': '坦桑尼亚',
	'TH': '泰国',
	'TL': '东帝汶',
	'TG': '多哥',
	'TK': '托克劳',
	'TO': '汤加',
	'TT': '特立尼达和多巴哥',
	'TN': '突尼斯',
	'TR': '土耳其',
	'TM': '土库曼斯坦',
	'TC': '特克斯和凯科斯群岛',
	'TV': '图瓦卢',
	'UG': '乌干达',
	'UA': '乌克兰',
	'AE': '阿联酋',
	'GB': '英国',
	'US': '美国',
	'UM': '美国本土外小岛屿',
	'UY': '乌拉圭',
	'UZ': '乌兹别克斯坦',
	'VU': '瓦努阿图',
	'VE': '委内瑞拉',
	'VN': '越南',
	'VG': '英属维尔京群岛',
	'VI': '美属维尔京群岛',
	'WF': '瓦利斯和富图纳',
	'EH': '西撒哈拉',
	'YE': '也门',
	'ZM': '赞比亚',
	'ZW': '津巴布韦',
	//
	'taiwanCities.TaipeiCity': '台北市',
	'taiwanCities.NewTaipeiCity': '新北市',
	'taiwanCities.TaoyuanCity': '桃园市',
	'taiwanCities.TaichungCity': '台中市',
	'taiwanCities.TainanCity': '台南市',
	'taiwanCities.KaohsiungCity': '高雄市',
	'taiwanCities.KeelungCity': '基隆市',
	'taiwanCities.HsinchuCity': '新竹市',
	'taiwanCities.ChiayiCity': '嘉义市',
	'taiwanCities.HsinchuCounty': '新竹县',
	'taiwanCities.MiaoliCounty': '苗栗县',
	'taiwanCities.ChanghuaCounty': '彰化县',
	'taiwanCities.NantouCounty': '南投县',
	'taiwanCities.YunlinCounty': '云林县',
	'taiwanCities.ChiayiCounty': '嘉义县',
	'taiwanCities.PingtungCounty': '屏东县',
	'taiwanCities.YilanCounty': '宜兰县',
	'taiwanCities.HualienCounty': '花莲县',
	'taiwanCities.TaitungCounty': '台东县',
	'taiwanCities.PenghuCounty': '澎湖县',
	'taiwanCities.KinmenCounty': '金门县',
	'taiwanCities.LienchiangCounty': '连江县',
};

const finalResult = Object.assign(
	{},
	common,
	header,
	teacherExercise,
	sidebar,
	teacherCourseDetail,
	InformationPage,
	notificationPages,
	teacherResourcePage,
	exerciseManageRectangle,
	testManageRectangle,
	materialManage,
	materialUploadFile,
	exerciseManage,
	exerciseAndTest,
	exerciseTipInfo,
	exerciseAnswerInfo,
	exerciseTestInfo,
	testManage,
	lessonAppendExercise,
	testRatioInfo,
	testPreview,
	addMaterial,
	course,
	studentlist,
	test,
	score,
	student,
	exercise,
	question,
	feedback,
	addTA,
	courseManage,
	system,
	loginPage,
	loginEvent,
	freeTrialPage,
	parentloginPage,
	parentHomePage,
	parentFullReport,
	csfcLoginPage,
	staff,
	signUpPage,
	notes,
	announcement,
	lintErrorHints,
	studyResult,
	unitDomainManage,
	betaUnitManage,
	AnnouncementListModal,
	zoom,
	desktopDownload,
	studentDetailPage,
	teacherCourseLessonPage,
	coursePackageManage,
	comments,
	studentInfo,
	studentPerformance,
	parentPage,
	exerciseEditor,
	questionGroupModal,
	disconnetPage,
	teacherCourseTestPage,
	questionGroupManageRectangle,
	testEvent,
	testInfo,
	studentTestPage,
	loadingProcessBar,
	globalExamPage,
	translationPage,
	shareCourseModal,
	dataSyncPage,
	crm,
	manualEmailTemplate,
	parentEmailTemplate,
	registrationDownloadPage,
	crmManage,
	internalMailManage,
	toolsPage,
	batchTeacherAccountModal,
	globalTestModal,
	csfcStudentAccountModal,
	chatGPT,
	general
);

// console.log("finalResult", finalResult)

export default finalResult;

//{t('lessonAppendExercise.onlyShow', {codeType:this.props.codeType})}
