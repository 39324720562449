import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import { codingbarApi } from 'codingbar-api';
import { getUUID } from 'shared/util/utils';

import * as cons from '../constants/app';
import { API_ENV } from '../../settings';

const initialState = fromJS({
	init: false,
	initSuc: false,
	initErr: false,
	user: undefined,
	signIn: false,
	signInSuc: false,
	signInErr: false,
	multiLoginAlertShow: false,
	announcements: undefined, // show as modal popup
	ancmList: undefined, // show in 通知中心
	showPlayGround: false, //show freecode
	onlineStatus: undefined, //Status true To be Ok
	isBetaUser: false, // ---- FOR BETA ----
	betaUserList: undefined, // ---- FOR BETA ----
	isFeatureTrialUnit: undefined, // beta 過渡期
	// isShowSlowQuery: false,
	isSchoolDomainUser: false,
	unitPermissions: undefined,
	AImateSubscriptionPlans: undefined,
	AImateUserPlan: undefined,
	// isShowToast: false,
	// toastSetting: undefined,
	currentSubmitting: undefined,
	toasts: [],
	csfcUser: undefined,
});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case cons.INIT: {
			return state.merge({
				init: true,
				initSuc: false,
				initErr: false,
			});
		}

		case cons.INIT_SUC: {
			return state.merge({
				init: false,
				initSuc: true,
				initErr: false,
			});
		}

		case cons.INIT_ERR: {
			return state.merge({
				init: false,
				initSuc: false,
				initErr: true,
			});
		}

		case cons.SIGNIN: {
			return state.merge({
				user: undefined,
				signIn: true,
				signInSuc: false,
				signInErr: false,
			});
		}

		case cons.SIGNIN_SUC: {
			return state.merge({
				user: action.user,
				signIn: false,
				signInSuc: true,
				signInErr: false,
			});
		}

		case cons.SIGNIN_ERR: {
			return state.merge({
				user: undefined,
				signIn: false,
				signInSuc: false,
				signInErr: true,
				signInErrorInfo: action.error,
			});
		}

		case cons.SIGNOUT: {
			return state.merge({
				user: undefined,
				signIn: false,
				signInSuc: false,
				signInErr: false,
				multiLoginAlertShow: false,
				showPlayGround: false,
			});
		}

		case cons.MULTI_LOGIN_ALERT: {
			return state.merge({
				multiLoginAlertShow: true,
			});
		}

		case cons.GETUSER: {
			return state.merge({
				user: action.user,
			});
		}

		case cons.GET_ANNOUNCEMENT: {
			return state.merge({
				announcements: action.announcements,
			});
		}

		case cons.GET_ANNOUNCEMENT_LIST: {
			return state.merge({
				ancmList: action.ancmList,
			});
		}

		case cons.OPEN_PLAYGROUND_MODAL: {
			return state.merge({
				showPlayGround: true,
			});
		}

		case cons.CLOSE_PLAYGROUND_MODAL: {
			return state.merge({
				showPlayGround: false,
			});
		}
		case cons.SET_CURRENT_SUBMITTING: {
			const currentSubmitting = action.payload;
			return state.merge({
				currentSubmitting,
			});
		}
		case cons.CLEAR_CURRENT_SUBMITTING: {
			const currentSubmitting = action.payload;
			return state.merge({
				currentSubmitting,
			});
		}
		// ---- FOR BETA ----
		case cons.SET_BETA_USER: {
			return state.merge({
				isBetaUser: true,
			});
		}

		case cons.REMOVE_BETA_USER: {
			return state.merge({
				isBetaUser: false,
			});
		}

		case cons.SET_FEATURE_TRIAL_UNIT: {
			return state.merge({
				isFeatureTrialUnit: true,
			});
		}

		case cons.REMOVE_FEATURE_TRIAL_UNIT: {
			return state.merge({
				isFeatureTrialUnit: false,
			});
		}

		case cons.BETA_USER_LIST: {
			return state.merge({
				betaUserList: action.betaUserList,
			});
		}

		case cons.SET_ONLINE: {
			return state.merge({
				onlineStatus: true,
			});
		}

		case cons.SET_OFFLINE: {
			return state.merge({
				onlineStatus: false,
			});
		}

		/*
		case cons.SET_SHOW_TOAST: {
			return state.merge({
				isShowToast: true,
				toastSetting: action.payload,
			});
		}

		case cons.SET_CLOSE_TOAST: {
			return state.merge({
				isShowToast: false,
				toastSetting: {},
			});
		}
		*/

		case cons.ADD_TOAST: {
			return state.merge({
				toasts: [...state.get('toasts'), { ...action.payload, id: getUUID() }],
			});
		}

		case cons.REMOVE_TOAST: {
			// remove the first item
			return state.merge({
				toasts: state.get('toasts').slice(1),
			});
		}

		case cons.SET_SCHOOL_DOMAIN_USER: {
			return state.merge({
				isSchoolDomainUser: true,
			});
		}

		case cons.UNSET_SCHOOL_DOMAIN_USER: {
			return state.merge({
				isSchoolDomainUser: false,
			});
		}

		case cons.SET_CSFC_USER: {
			return state.merge({
				csfcUser: action.payload,
			});
		}

		case cons.REMOVE_CSFC_USER: {
			return state.merge({
				csfcUser: undefined,
			});
		}

		case cons.SET_UNIT_PERMISSIONS: {
			return state.merge({
				unitPermissions: action.payload,
			});
		}

		case cons.SCHOOL_DOMAIN_LIST: {
			return state.merge({
				schoolDomainList: action.schoolDomainList,
			});
		}

		case cons.SET_AI_MATE_SUBSCRIPTION_PLANS: {
			return state.merge({
				AImateSubscriptionPlans: action.payload,
			});
		}

		case cons.SET_AI_MATE_USER_PLAN: {
			return state.merge({
				AImateUserPlan: action.payload,
			});
		}

		default:
			return state;
	}
}

export function initAction() {
	return (dispatch, getState) => {
		dispatch({ type: cons.INIT });

		codingbarApi
			.init(API_ENV, 'airabbi')
			.then(() => {
				codingbarApi.getAuthService().setConnectionListener({
					onConnectionChanged(status) {
						// console.log('initAction# onConnectionChanged# status:', status);
						const state = getState();
						const isInitSuc = state.app.get('initSuc');

						if (!isInitSuc) {
							dispatch({ type: cons.INIT_SUC });
						}
					},
					onUserChanged(user) {
						// console.log('initAction# onUserChanged# user:', user);
						const state = getState();
						const isInitSuc = state.app.get('initSuc');

						dispatch({ type: cons.GETUSER, user });

						if (!isInitSuc) {
							dispatch({ type: cons.INIT_SUC });
						}
					},
					onReAuthNeeded() {
						// console.log('initAction# onReAuthNeeded#');
						const state = getState();
						const isInitSuc = state.app.get('initSuc');

						dispatch({ type: cons.SIGNOUT });

						if (!isInitSuc) {
							dispatch({ type: cons.INIT_SUC });
						}
					},
					onMultiLogin() {
						// console.log('onMultiLogin');
						dispatch({ type: cons.MULTI_LOGIN_ALERT });

						// codingbarApi.getAuthService().signOut();
						// dispatch({ type: cons.SIGNOUT });
					},
					onPushBulletin() {
						codingbarApi
							.getBulletinService()
							.getAvailableBulletin()
							.then((res) => {
								const currentPageUrl = window.location.href.toString().split(window.location.host)[1];
								const regisPath = '/registration';
								const downloadPath = '/desktop/select';
								if (currentPageUrl.startsWith(regisPath) || currentPageUrl.startsWith(downloadPath)) return;

								const state = getState();
								const user = state.app.get('user');
								const now = Date.now();
								const ancms = res.filter((ancm) => {
									return (
										ancm.startTime <= now &&
										now < ancm.endTime &&
										ancm.endTime > 1704038399000 && // 2023 以前的公告不要了
										(ancm.receiver === 'all' || ancm.receiver === user.get('role')) &&
										(ancm.unitList.length === 0 || ancm.unitList.includes(user.get('unit'))) &&
										!(ancm.markAsReadUidList && ancm.markAsReadUidList.includes(user.get('uid')))
									);
								});

								const allPastAncmList = res.filter((ancm) => {
									return (
										// ancm.type === 'announcement' && 應該所有類型的公告都要在公告列表
										ancm.startTime <= now &&
										ancm.endTime > 1704038399000 && // 2023 以前的公告不要了
										(ancm.receiver === 'all' || ancm.receiver === user.get('role')) &&
										(ancm.unitList.length === 0 || ancm.unitList.includes(user.get('unit')))
									);
								});

								dispatch({ type: cons.GET_ANNOUNCEMENT, announcements: ancms });
								dispatch({ type: cons.GET_ANNOUNCEMENT_LIST, ancmList: allPastAncmList });
							})
							.catch((err) => console.error(err));
					},
				});
			})
			.catch((e) => {
				console.error('init codingbarApi error', e);
				dispatch(cons.INIT_ERR);
			});
	};
}

export function openPlayGround() {
	return {
		type: cons.OPEN_PLAYGROUND_MODAL,
	};
}
export function closePlayGround() {
	return {
		type: cons.CLOSE_PLAYGROUND_MODAL,
	};
}

export function setBetaUser() {
	return {
		type: cons.SET_BETA_USER,
	};
}

export function removeBetaUser() {
	return {
		type: cons.REMOVE_BETA_USER,
	};
}
// export function closeStudentTestAlert() {
// 	return {
// 	  type: cons.CLOSE_STUDENT_TEST_ALERT,
// 	}
//   }

export function setOnline() {
	localStorage.setItem('ConnectNetWork', new Date());

	return {
		type: cons.SET_ONLINE,
	};
}

function saveLog() {
	const user = codingbarApi.getAuthService().getUser();
	const url = location.href;

	const saveFile = async (blob) => {
		const a = document.createElement('a');
		a.download = 'CodingBar-Log.txt';
		a.href = URL.createObjectURL(blob);
		a.addEventListener('click', (e) => {
			setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
		});
		a.click();
	};

	const obj = {
		userInfo: user,
		locationUrl: url,
		'disconnect-time': new Date(),
	};
	const blob = new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });

	saveFile(blob);
}

export function setOffline() {
	localStorage.setItem('DisconnectNetWork', new Date());

	// saveLog();

	return {
		type: cons.SET_OFFLINE,
	};
}
// (playground or exercise) is submitting code
export function setCurrentSubmitting(data) {
	return {
		type: cons.SET_CURRENT_SUBMITTING,
		payload: data,
	};
}

export function clearCurrentSubmitting() {
	return {
		type: cons.CLEAR_CURRENT_SUBMITTING,
		payload: { status: 'idle' },
	};
}

/*
export function setShowToast(setting) {
	return {
		type: cons.SET_SHOW_TOAST,
		payload: setting,
	};
}
*/

export function addToast(toastObj) {
	return {
		type: cons.ADD_TOAST,
		payload: toastObj,
	};
}

export function removeToast() {
	return {
		type: cons.REMOVE_TOAST,
	};
}

export function setSchoolDomainUser() {
	return {
		type: cons.SET_SCHOOL_DOMAIN_USER,
	};
}

export function unsetSchoolDomainUser() {
	return {
		type: cons.UNSET_SCHOOL_DOMAIN_USER,
	};
}

export function setCSFCUser(userData) {
	return {
		type: cons.SET_CSFC_USER,
		payload: userData,
	};
}

export function removeCSFCUser() {
	return {
		type: cons.REMOVE_CSFC_USER,
	};
}

export function setUnitPermissions(data) {
	return {
		type: cons.SET_UNIT_PERMISSIONS,
		payload: data,
	};
}

export function setAiMateSubscriptionPlans(data) {
	return {
		type: cons.SET_AI_MATE_SUBSCRIPTION_PLANS,
		payload: data,
	};
}

export function setAiMateUserPlan(data) {
	return {
		type: cons.SET_AI_MATE_USER_PLAN,
		payload: data,
	};
}

export const signInAction = createAction(cons.SAGA_SIGNIN);
export const signOutAction = createAction(cons.SAGA_SIGNOUT);
export const getUserAction = createAction(cons.SAGA_GETUSER);
