import i18n from 'lang/i18n';
import moment from 'moment';

function pad(num) {
	return ('0' + num).slice(-2);
}

export const sleep = (milliseconds) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function mmss(secs) {
	const minutes = Math.floor(secs / 60);
	const _secs = secs % 60;
	return `${pad(minutes)}:${pad(_secs)}`;
}

export function hhmmss(secs, labelH, labelM, labelS) {
	let minutes = Math.floor(secs / 60);
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	let theSecs = Math.floor(secs % 60);
	if (hours == 0) {
		return `00:${pad(minutes < 0 ? 0 : minutes)}${labelM ? labelM : ':'}${pad(theSecs < 0 ? 0 : theSecs)}${
			labelS ? labelS : ''
		}`;
	}

	let days = 0;
	if (hours >= 24) {
		days = Math.floor(hours / 24);
		hours = hours % 24;
	}

	return `${days > 0 ? `${days}天` : ''} ${hours < 0 ? 0 : hours}${labelH ? labelH : ':'}${pad(
		minutes < 0 ? 0 : minutes
	)}${labelM ? labelM : ':'}${pad(theSecs < 0 ? 0 : theSecs)}${labelS ? labelS : ''}`;
}
/**
 * Convert secs to a hhmmss string. "02:03:56" if hour>24 return "99:99:99" *PADDED*
 */
export function hhmmssPadded(secs, labelH, labelM, labelS) {
	let minutes = Math.floor(secs / 60);
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	let theSecs = Math.floor(secs % 60);
	if (hours == 0) {
		return `00:${pad(minutes < 0 ? 0 : minutes)}${labelM ? labelM : ':'}${pad(theSecs < 0 ? 0 : theSecs)}${
			labelS ? labelS : ''
		}`;
	}
	if (hours >= 24) {
		return `99${labelH ? labelH : ':'}99${labelM ? labelM : ':'}99${labelS ? labelS : ''}`;
	}
	return `${hours < 0 ? 0 : hours}${labelH ? labelH : ':'}${pad(minutes < 0 ? 0 : minutes)}${
		labelM ? labelM : ':'
	}${pad(theSecs < 0 ? 0 : theSecs)}${labelS ? labelS : ''}`;
}

export function addOneZeroPrefix(num) {
	return num < 10 ? `0${num}` : `${num}`;
}

export function getNotificationTime(timestamp) {
	const date = new Date(timestamp);
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());

	return `${date.getFullYear()}年${month}月${date2}日 ${hours}:${mins}`;
}

export function time2Text(timestamp) {
	const date = new Date(timestamp);
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());
	const secs = addOneZeroPrefix(date.getSeconds());
	return `${date.getFullYear()}/${month}/${date2} ${hours}:${mins}:${secs}`;
}

export function MMDD(timestamp) {
	if (!timestamp) {
		return '';
	}
	const date = new Date(timestamp);
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());

	return `${month}${i18n.t('common.month')}${date2}${i18n.t('common.day')}`;
}
/**
 * Convert timestamp to a date string (month and day), "1月1日"||"1/1"||"01月01日"||"01/01"
 * @param {number} timestamp
 * @param {boolean} IsPadded pad month and day
 */
export function convertTimestampToMD(timestamp, IsPadded = false) {
	if (!timestamp) {
		return '';
	}
	const date = new Date(timestamp);
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (IsPadded) {
		month = addOneZeroPrefix(month);
		day = addOneZeroPrefix(day);
	}
	return `${month}${i18n.t('common.month')}${day}${i18n.t('common.day')}`;
}

export function toMMDD(timestamp) {
	if (!timestamp) {
		return '';
	}
	const date = new Date(timestamp);
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());

	return `${month}/${date2}`;
}

export function toYYYYMMDDhhmmss(timestamp) {
	if (!timestamp) {
		return '';
	}
	const date = new Date(timestamp);
	const year = addOneZeroPrefix(date.getFullYear());
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());
	const secs = addOneZeroPrefix(date.getSeconds());

	return `${year}-${month}-${date2} ${hours}:${mins}:${secs}`;
}

/**
 * Convert timestamp to a local date string (YYMMDD),
 * @param {number} timestamp
 * @return  {string} local string 2000年01月01日 ||2000-01-01
 */
export function getYYYYMMDDByLanguage(timestamp) {
	if (!timestamp) {
		return '';
	}
	const year = moment(timestamp).format('YYYY');
	const month = moment(timestamp).format('MM');
	const date = moment(timestamp).format('DD');
	return `${year}${i18n.t('teacher.resources.year')}${month}${i18n.t('teacher.resources.month')}${date}${i18n.t(
		'teacher.resources.day'
	)}
  `;
}

export function getYYYYMMDD(timestamp) {
	if (!timestamp) {
		return '';
	}
	const date = new Date(timestamp);
	const year = addOneZeroPrefix(date.getFullYear());
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const date2 = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());

	return `${year}-${month}-${date2}`;
}

export function getTimeText() {
	const date = new Date();
	const year = addOneZeroPrefix(date.getFullYear());
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const day = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());
	const secs = addOneZeroPrefix(date.getSeconds());

	return `${year}/${month}/${day} ${hours}:${mins}:${secs}`;
}

export function getNoteTimeText() {
	const date = new Date();
	const year = addOneZeroPrefix(date.getFullYear());
	const month = addOneZeroPrefix(date.getMonth() + 1);
	const day = addOneZeroPrefix(date.getDate());
	const hours = addOneZeroPrefix(date.getHours());
	const mins = addOneZeroPrefix(date.getMinutes());
	const secs = addOneZeroPrefix(date.getSeconds());

	return `${month}月${day}日 ${hours}:${mins}`;
}

export function getMondayBeginTime(timestamp) {
	const d = new Date(timestamp);
	const day = d.getDay();
	const diff = d.getDate() - day + (day == 0 ? -6 : 1);
	d.setDate(diff);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	return new Date(d).getTime();
}

export function getWeekBeginTime(timestamp) {
	const d = new Date(timestamp);
	const day = d.getDay();
	const diff = d.getDate() - day + (day == 0 ? -6 : 1);
	d.setDate(diff);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	return new Date(d).getTime();
}

export function getMonthBeginTime(timestamp) {
	const d = new Date(timestamp);
	return Date.parse(`${d.getFullYear()}-${d.getMonth() + 1}-01`);
}

export function dateBeginTime(timestamp) {
	const date = new Date(timestamp);
	const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
	return Date.parse(dateText);
}

export function dateEndTime(timestamp) {
	const date = new Date(timestamp);
	const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 00:00:00`;
	return Date.parse(dateText);
}

export function todayBeginTime() {
	const date = new Date();
	const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
	return Date.parse(dateText);
}

export function todayEndTime() {
	const date = new Date();
	const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 00:00:00`;
	return Date.parse(dateText);
}

export function getDateText(timestamp) {
	const date = new Date(timestamp);
	return addOneZeroPrefix(date.getMonth() + 1) + '/' + addOneZeroPrefix(date.getDate());
}

export function getWeekDay(timestamp) {
	const date = new Date(timestamp);
	return getWeekText(date.getDay() - 1);
}

export function getWeekText(index) {
	switch (index) {
		case 0:
			return i18n.t('common.monday');
		case 1:
			return i18n.t('common.tuesday');
		case 2:
			return i18n.t('common.wednesday');
		case 3:
			return i18n.t('common.thursday');
		case 4:
			return i18n.t('common.friday');
		case 5:
			return i18n.t('common.saturday');
		case 6:
			return i18n.t('common.sunday');
	}
	return 'Unknown';
}

export function getWeekdayIndex(weekday) {
	switch (weekday) {
		case 'Monday':
			return 1;
		case 'Tuesday':
			return 2;
		case 'Wednesday':
			return 3;
		case 'Thursday':
			return 4;
		case 'Friday':
			return 5;
		case 'Saturday':
			return 6;
		case 'Sunday':
			return 7;

		default:
			break;
	}
}

export function getWeekTextEn(index) {
	switch (index) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		case 6:
			return 'Sunday';
	}
	return 'Unknown';
}

export function getMonthTextEn(index) {
	switch (index) {
		case 1:
			return 'January';
		case 2:
			return 'February';
		case 3:
			return 'March';
		case 4:
			return 'April';
		case 5:
			return 'May';
		case 6:
			return 'June';
		case 7:
			return 'July';
		case 8:
			return 'August';
		case 9:
			return 'September';
		case 10:
			return 'October';
		case 11:
			return 'November';
		case 12:
			return 'December';
	}
	return 'Unknown';
}

export function getIsoWeekdayTextEn(index) {
	switch (index) {
		case 1:
			return 'Monday';
		case 2:
			return 'Tuesday';
		case 3:
			return 'Wednesday';
		case 4:
			return 'Thursday';
		case 5:
			return 'Friday';
		case 6:
			return 'Saturday';
		case 7:
			return 'Sunday';
	}
}

export function getWeekLabels(timestamp) {
	const labels = [];
	const mondayTime = getMondayBeginTime(timestamp);
	const durationOfOneDay = 1000 * 60 * 60 * 24;
	for (let i = 0; i < 7; i++) {
		labels.push(`${getWeekText(i)} ${getDateText(mondayTime + durationOfOneDay * i)}`);
	}
	return labels;
}

export function getMonthLabels(timestamp) {
	const labels = [];
	const beginTime = getMonthBeginTime(timestamp);
	const beginDate = new Date(beginTime);
	for (let i = 0; i < getDaysInMonth(timestamp); i++) {
		labels.push(`${beginDate.getMonth() + 1}/${i + 1}`);
	}
	return labels;
}

export function getMonthEndTime(timestamp) {
	const beginTime = getMonthBeginTime(timestamp);
	return beginTime + getDaysInMonth(timestamp) * 24 * 60 * 60 * 1000;
}

export function getWeekEndTime(timestamp) {
	return getMondayBeginTime(timestamp) + 7 * 24 * 60 * 60 * 1000;
}

export function getDaysInMonth(timestamp) {
	return getMonth(timestamp).getDate();
}

export function getMonth(timestamp) {
	let date = new Date(timestamp);
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getYearMonthString(year) {
	const months = [];
	for (let i = 1; i <= 12; i++) {
		const timeString = `${year}/${pad(i)}`;
		months.push(timeString);
	}
	return months;
}

export function getYearMonthsTimeInfos(year) {
	const months = getYearMonthString(year);
	const monthInfos = [];
	months.forEach((monthString) => {
		const timeStamp = getTime(monthString);
		monthInfos.push({
			label: monthString,
			beginTime: getMonthBeginTime(timeStamp),
			endTime: getMonthEndTime(timeStamp),
		});
	});
	return monthInfos;
}

export function getTime(timeString) {
	//"2019/01/01 00:00:00"
	return new Date(timeString).getTime();
}

export function getTimeOptions() {
	const timeOptions = [];
	for (let i = 0; i < 48; i++) {
		const hour = Math.trunc(i / 2);

		timeOptions.push({
			value: i * 0.5,
			label: i % 2 === 0 ? `${hour < 10 ? '0' + hour : hour}:00` : `${hour < 10 ? '0' + hour : hour}:30`,
		});
	}

	return timeOptions;
}

export function getWeekTextString(text) {
	switch (text) {
		case 1:
			return i18n.t('common.Wmonday');
		case 2:
			return i18n.t('common.Wtuesday');
		case 3:
			return i18n.t('common.Wwednesday');
		case 4:
			return i18n.t('common.Wthursday');
		case 5:
			return i18n.t('common.Wfriday');
		case 6:
			return i18n.t('common.Wsaturday');
		case 0:
			return i18n.t('common.Wsunday');
	}
	return '';
}

export function getIsoWeekdayTextString(text) {
	switch (text) {
		case 1:
			return i18n.t('common.Wmonday');
		case 2:
			return i18n.t('common.Wtuesday');
		case 3:
			return i18n.t('common.Wwednesday');
		case 4:
			return i18n.t('common.Wthursday');
		case 5:
			return i18n.t('common.Wfriday');
		case 6:
			return i18n.t('common.Wsaturday');
		case 7:
			return i18n.t('common.Wsunday');
	}
	return '';
}

export function getTodayDate() {
	const d = new Date();
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	return getYYYYMMDD(d);
}

// 民國 time method

/**
 * Returns 民國紀年 number
 * @param {number} timestamp
 * @return {number} ie. (111)
 */
export function getROCEra(timestamp) {
	return new Date(timestamp).getFullYear() - 1911;
}

/**
 * Returns 民國紀年完整時間字串
 * @param {number} timestamp
 * @return {string} ie. (111年10月12日)
 */
export function getROCEraYYYMMDDString(timestamp) {
	if (!timestamp) return '';
	return `${getROCEra(timestamp)} ${i18n.t('studyResult.certificate.year')} ${MMDD(timestamp)}`;
}

/**
 * Returns a duration string with ROCEra
 * @param {number} num1 beginTime
 * @param {number} num2 endTime
 * @return {string} ie. (110年 10/23 ~ 10/45)||(110年 10/23 ~ 111年 01/23)
 */
export function getROCEraDurationString(beginTime, endTime) {
	const beginDate = beginTime ? toMMDD(beginTime) : '';
	const endDate = endTime ? toMMDD(endTime) : '';
	const beginROCEra = beginTime ? `${getROCEra(beginTime)} ${i18n.t('studyResult.certificate.year')} ` : '';
	const endROCEra = endTime ? `${getROCEra(endTime)} ${i18n.t('studyResult.certificate.year')} ` : '';

	if (beginROCEra === endROCEra) {
		return `${beginROCEra} ${beginDate} ~ ${endDate}`;
	}
	return `${beginROCEra} ${beginDate} ~ ${endROCEra} ${endDate}`;
}
