import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { ModalHeader, ModalBody, ModalFooter, Button, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import SystemLoading from 'design/images/gif/system_loading.gif';

const DefaultStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: '#0000000',
		border: 'none',
	},
	overlay: {
		zIndex: 140,
	},
};
@translate('translations')
class ProgressDialog extends React.Component {
	static propTypes = {
		message: PropTypes.string,
		title: PropTypes.string,
		type: PropTypes.string, // 'systemLoading'
	};

	onOkClick() {
		this.props.onOk();
	}

	onCloseClick() {
		this.props.onClose();
	}
	renderBody = () => {
		switch (this.props.type) {
			case 'systemLoading':
				return (
					<Fragment>
						<h3 style={{ 'position': 'absolute', 'top': '32px', 'left': '110px' }}>{this.props.message}</h3>
						<ModalBody>
							<img style={{ width: '500px' }} src={SystemLoading} />
						</ModalBody>
					</Fragment>
				);

			default:
				return (
					<Fragment>
						{this.props.title && (
							<ModalHeader>
								<Modal.Title>{this.props.title}</Modal.Title>
							</ModalHeader>
						)}
						<ModalBody>
							<div>
								<h3>{this.props.message}</h3>
								<ProgressBar striped bsStyle='warning' style={{ width: '500px', height: '35px' }} active now={100} />
							</div>
						</ModalBody>
					</Fragment>
				);
		}
	};

	render() {
		const { t } = this.props;

		return (
			<Modal
				isOpen={this.props.show}
				style={this.props.customStyle ? this.props.customStyle : DefaultStyles}
				className={this.props.className}
				overlayClassName={this.props.overlayClassName}
			>
				{this.renderBody()}
				{this.props.onOk || this.props.onClose ? (
					<ModalFooter>
						{this.props.onOk ? (
							<Button bsStyle='success' onClick={this.onOkClick.bind(this)}>
								{t('common.confirm')}
							</Button>
						) : (
							''
						)}
						{this.props.onClose ? (
							<Button bsStyle='danger' onClick={this.onCloseClick.bind(this)}>
								{t('common.close')}
							</Button>
						) : (
							''
						)}
					</ModalFooter>
				) : (
					''
				)}
			</Modal>
		);
	}
}

export default ProgressDialog;
