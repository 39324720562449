import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import './CodingbarThemeProvider.scss';
// import '../../index.scss'
// import { CssBaseline } from '@material-ui/core'

function createMuiThemeSequence(...args) {
	const theme = args.reduce((prevTheme, arg) => {
		const option = typeof arg === 'function' ? arg(prevTheme) : arg;
		return createMuiTheme({ ...prevTheme, ...option });
	}, createMuiTheme());
	return theme;
}

const theme = createMuiThemeSequence(
	(prevTheme) => ({
		palette: {
			primary: {
				main: '#29354c',
				light: '#f2f2f2',
				// contrastText: 'red'
			},
			accent: {
				main: '#fed602',
			},
			secondary: {
				main: '#2d42c3',
				light: '#dbdbdb',
			},
			error: {
				main: '#fc4f52',
			},
			highlight: {
				main: '#2f80ed',
			},
			disabled: {
				main: '#b8c3d9',
			},
			text: {
				secondary: '#7f7f7f',
			},
			bodyBackground: '#f4f7f9',
			progressBar: {
				main: '#2c41c2',
				light: '#d4e1ee',
			},
		},
		spacing: 2,
	}),

	(prevTheme) => ({
		typography: {
			fontFamily: [
				'"Noto Sans TC"',
				'"Roboto"',
				'PingFangTC',
				'"Microsoft JhengHei"',
				'"微軟正黑體"',
				'sans-serif',
			].join(','),
			useNextVariants: true,
			h6: { fontSize: '24px', color: prevTheme.palette.primary.main, fontWeight: 500 },
			subtitle1: {
				fontSize: '20px',
				color: prevTheme.palette.primary.main,
				fontWeight: 500,
				lineHeight: '29px',
			},
			subtitle2: {
				fontSize: '16px',
				color: prevTheme.palette.secondary.main,
				// fontWeight: 500
			},
			body1: {
				// typhgraphy default
				fontSize: '16px',
				color: prevTheme.palette.primary.main,
				lineHeight: '23px',
			},
			body2: {
				// html default
				fontSize: '14px',
				color: prevTheme.palette.primary.main,
			},
			caption: {
				fontSize: '14px',
				color: prevTheme.palette.text.secondary,
			},
		},
	}),

	(prevTheme) => ({
		props: {
			MuiTypography: {
				variantMapping: {
					h1: 'h2',
					h2: 'h2',
					h3: 'h2',
					h4: 'h2',
					h5: 'h2',
					h6: 'h2',
					subtitle1: 'h2',
					subtitle2: 'h2',
					body1: 'span',
					body2: 'span',
				},
			},
		},
	}),

	(prevTheme) => ({
		overrides: {
			MuiButton: {
				root: {
					textTransform: 'inherit',
				},
			},
			MuiTypography: {
				root: {
					verticalAlign: 'middle',
				},
			},
			MuiTabs: {
				root: {
					height: 32,
					minHeight: 32,
				},
			},
			MuiTab: {
				root: {
					height: 32,
					minHeight: 32,
				},
			},
			MuiSvgIcon: {
				root: {
					width: 24,
					height: 24,
				},
			},
			MuiFormControlLabel: {
				label: {
					fontSize: prevTheme.typography.body2.fontSize,
				},
			},
			MuiBackdrop: {
				root: {
					backgroundColor: 'rgba(0, 0, 0, 0.75)!important',
				},
			},
			// MuiOutlinedInput: {
			//   root: {
			//     color: 'red'
			//   }
			// }
		},
	})
);

const CodingbarThemeProvider = ({ children }) => {
	return (
		<MuiThemeProvider theme={theme}>
			{/* <CssBaseline /> */}
			{children}
		</MuiThemeProvider>
	);
};

export default CodingbarThemeProvider;
