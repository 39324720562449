import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from 'react-router-dom';
import { signInAction } from 'redux/reducers/app';
import { connect } from 'react-redux';
import { codingbarApi } from 'codingbar-api';
import { isAiMate } from 'shared/helper/permission';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isEmailVerified, isBasicInfoFilled } from '../shared/util/userUtils';

@withRouter
@connect(
	(state) => {
		const app = state.app;
		return {
			user: app.get('user'),
		};
	},
	{
		signInAction,
	}
)
export default class RoleChecker extends React.PureComponent {
	static propTypes = {
		user: ImmutablePropTypes.map,
		roles: PropTypes.arrayOf(PropTypes.string).isRequired,
		// React component is wrapped inside asyncComponent function in route.js
		component: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { user, roles, isDemo, component: Component, ...rest } = this.props;
		// console.log("RoleChecker", this.props)

		// This is a workaround, need to fix in the future.
		const url = window.location.href;
		if (url.endsWith('/demo') || url.endsWith('/demo/')) {
			return <Route {...rest} render={(props) => <Component {...props} />} />;
		}

		if (isDemo && !isAuthenticated(user, roles)) {
			if (user && user.get('role') !== 'demo') {
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: this.props.location },
						}}
					/>
				);
			} else if (!user) {
				codingbarApi.getAuthService().loginDemoPage();
			}
			// this.props.signInAction({ email: "CodingbarDemo@airabbi.com", password: "airabbi" });
			return null;
		}
		// When the user has only activated the aiMate feature, they can only access operations within aiMate.
		if (isAiMate('aiMateOnly') && !isAiMate('route')) {
			return (
				<Route
					{...rest}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/AImate/editor',
								state: { from: props.location },
							}}
						/>
					)}
				/>
			);
		}

		if (!isAuthenticated(user, roles)) {
			return (
				<Route
					{...rest}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
							}}
						/>
					)}
				/>
			);
		}

		if (!isEmailVerified(user)) {
			return (
				<Route
					{...rest}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/verify/email-unverified',
								state: { from: props.location },
							}}
						/>
					)}
				/>
			);
		}
		if (!isBasicInfoFilled(user)) {
			return (
				<Route
					{...rest}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/verify/fill-info',
								state: { from: props.location },
							}}
						/>
					)}
				/>
			);
		}
		// console.log('SSS>>>>user:', user.toJS());

		return <Route {...rest} render={(props) => <Component {...props} />} />;
	}
}
