const common = {
	'welcome.message': '<p>{{name}}, 歡迎使用</p><p></p><p>CodingBar 程式語言智慧學習系統</p>',
	'common.edit': '編輯',
	'common.delete': '刪除',
	'common.deleteThis': '刪除此項目',
	'common.cancel': '取消',
	'common.return': '返回',
	'common.confirm': '確認',
	'common.confirm.submission': '確認送出',
	'common.confirmEdit': '確認修改',
	'common.confirmChanges': '確認變更',
	'common.confirmNew': '確認新增',
	'common.confirmAction': '請確認是否要執行此操作',
	'common.share': '分享',
	'common.previous': '上一步',
	'common.next': '下一步',
	'common.preview': '預覽',
	'common.loading': '載入中...',
	'common.success': '成功！',
	'common.update': '更新',
	'common.opened': '已開放',
	'common.notOpen': '尚未開放',
	'common.opening': '開放中',
	'common.commingsoon': '即將到來',
	'common.ended': '已結束',
	'common.finished': '已完成',
	'common.save.button': '儲存',
	'common.saveSuc': '儲存成功!',
	'common.saveFail': '儲存失敗!',
	'common.save': '儲存變更',
	'common.this': '此',
	'common.share.unit': '分享給同單位的人',
	'common.share.all': '分享給所有人',
	'common.copytoresource': '複製到資源庫',
	'common.remove': '移除',
	'common.error': '發生錯誤',
	'common.days': '週/時',
	'common.hours': '時',
	'common.minutes': '分',
	'common.seconds': '秒',
	'common.monday': '星期一',
	'common.tuesday': '星期二',
	'common.wednesday': '星期三',
	'common.thursday': '星期四',
	'common.friday': '星期五',
	'common.saturday': '星期六',
	'common.sunday': '星期日',
	'common.abbrev.monday': '星期一',
	'common.abbrev.tuesday': '星期二',
	'common.abbrev.wednesday': '星期三',
	'common.abbrev.thursday': '星期四',
	'common.abbrev.friday': '星期五',
	'common.abbrev.saturday': '星期六',
	'common.abbrev.sunday': '星期日',
	'common.Wmonday': '週一',
	'common.Wtuesday': '週二',
	'common.Wwednesday': '週三',
	'common.Wthursday': '週四',
	'common.Wfriday': '週五',
	'common.Wsaturday': '週六',
	'common.Wsunday': '週日',
	'common.month': '月',
	'common.day': '日',
	'common.status': '狀態',
	'common.time': '時間',
	'common.nextWeek': '下一週',
	'common.prevWeek': '前一週',
	'common.nowWeek': '回當週',
	'common.24/7': '一天24小時',
	'common.loadingTime': '時間載入中...',
	'common.loadmore': '顯示更多',
	'common.unfinished': '未完成項目',
	'common.solved': '已完成項目',
	'common.personalInfo': '個人資訊',
	'common.belongUnit': '單位',
	'common.belongUnits': '單位列表(單位之間用,隔開)',
	'common.logout': '登出',
	'common.version': '版本',
	'common.submit': '送出',
	'common.home': '首頁',
	'common.copySuccess': '複製成功',
	'common.copyFail': '複製失敗，請重新嘗試',
	'common.info': '資料',
	'common.name': '姓名',
	'common.optional': '(選填)',
	'common.title': '標題',
	'common.content': '內容',
	'common.pleaseFill': '請填寫',
	'common.close': '關閉',
	'common.known': '我知道了',
	'common.notnull': '此欄位不能為空值',
	'common.continue': '繼續',
	'common.minutes': '分鐘',
	'common.10min': '10分鐘',
	'common.15min': '15分鐘',
	'common.20min': '20分鐘',
	'common.30min': '30分鐘',
	'common.40min': '40分鐘',
	'common.45min': '45分鐘',
	'common.50min': '50分鐘',
	'common.60min': '60分鐘',
	'common.70min': '70分鐘',
	'common.80min': '80分鐘',
	'common.90min': '90分鐘',
	'common.120min': '120分鐘',
	'common.180min': '180分鐘',
	'common.24hour': '24小時',
	'common.new': '新增',
	'common.error': '錯誤',
	'common.sendMsg': '傳送訊息',
	'common.create': '建立',
	'common.privacy': '隱私權',
	'common.and': '與',
	'common.perPage': '每頁顯示',
	'common.page': '筆',
	'common.thisPage': '此頁',
	'common.times': '次',
	'common.score': '分',
	'common.open': '開啟',
	'common.executionEnvironment': '執行環境',
	'common.search': '搜尋',
	'common.errorOccured': '發生錯誤...',
	'common.disabledFunctionality': '此功能未開放！',
	'common.preparing': '準備中',
	'common.refresh': '重新整理視窗',
	'common.pleaseWait': '請稍候...',
	'common.noData': '尚無資料',
	'common.noFill': '未填寫',

	'common.not-empty': '不可為空',
	'common.final.edit': '最後編輯',

	'common.yes': '是',
	'common.no': '否',
	'common.save.setting': '儲存設定',
	'common.leave': '離開',
	'common.hour': '小時',

	'common.fillInNumber': '請輸入數字',
	'common.slowQuery': '目前與伺服器連線速度過慢，資料讀取中...',

	'common.airabbi': '瑞比智慧科技股份有限公司',
	'common.selectAll': '全選',
	'common.startDate': '起始日期',
	'common.startTime': '起始時間',
	'common.cutoffDate': '截止日期',
	'common.cutoffTime': '截止時間',
	'common.createdAt': '啟用日',

	'common.reading.data': '資料讀取中...',

	'common.comma': '、',
	'common.add': '加入',
	'common.line.break': '\n',

	'common.unlimited': '無上限',
	'common.StepsTab.Title.Date': '日程設定',
	'common.undefined': '查無資料',
	'common.download': '下載',
	'common.mode': '模式',
	'common.updating': '更新中...',
	'chooseOne.to.select': '擇一進行搜尋：{{selectOptions}}',
};

const header = {
	'header.notification': '通知',
	'header.announcement': '公告',
	'header.notes': '筆記',
	'header.zoom': '視訊',
	'header.bugReport': '回報Bug',
	'header.freecode': 'FreeCode',
	'header.logOut': '登出',
	// TODO:SOL
	'header.account': '我的帳戶',
	'header.preferences': '系統偏好設定',
	'header.account.and.setting': '帳號與設定',
	// 'header.AImate.plan': '計畫方案',
	'header.AImate.plan': '訂閱專業版',
	'header.AImate.bill': '付款與帳單',
	'header.AImate.coin': 'i 幣獎勵金',
	'header.AImate.notification': '通知中心',
};

const teacherExercise = {
	'teacher.exercise.open.exercise': '開放題目',
	'teacher.exercise.open.all': '開放所有的練習？',
	'teacher.exercise.open.all2': '您即將開放所有的練習題目',
	'teacher.exercise.close.exercise': '關閉題目',
	'teacher.exercise.open.exercise.opened': '題目已開放',
	'teacher.exercise.open.hint': '開放提示',
	'exercise.nohint': '尚無提示',
	'exercise.provideans': '請透過左方按鈕提供課程答案',
	'exercise.providehint': '請透過左方按鈕提供課程提示',
	'exercise.hint.noonecheck': '無人查看',
	'exercise.read': '已查看的學生',
	'exercise.betterAns': '建議CodingBar更好的答案',
	'exercise.betterTips': '建議CodingBar更多合適的提示',
	'teacher.exercise.close.hint': '關閉提示',
	'teacher.exercise.open.hint.opened': '提示已開放',
	'teacher.exercise.open.answer': '開放解答',
	'teacher.exercise.close.answer': '關閉解答',
	'teacher.exercise.open.answer.opened': '解答已開放',
	'teacher.exercise.moveUp': '上移此題',
	'teacher.exercise.moveDown': '下移此題',
	'teacher.exercise.mostRecentAnswer': '最後作答結果',
	'teacher.exercise.firstAnswer': '第一次作答結果',
	'teacher.exercise.sortResult': '您的排序結果',
	'teacher.exercise.addToObserveList': '標記為重點觀察名單',
	'teacher.exercise.removeFromObserveList': '取消標記為重點觀察名單',

	'teacher.exercise.lastSubmit': '最後一次提交狀態',
};

const sidebar = {
	'teacher.main.sidebar.welcomeMessage': '老師您好, 歡迎回來！',
	'teacher.main.sidebar.course': '課程管理',
	'teacher.main.sidebar.cir': '學生管理',
	'teacher.main.sidebar.tri': '教學資源庫',
	'teacher.main.sidebar.studentQ': '學生訊息',
	'teacher.main.sidebar.liveCourse': '開課中',
	'teacher.main.sidebar.alert': '【舊版】通知中心',
	'teacher.main.sidebar.heart': '【舊版】個人資料',
	'teacher.main.sidebar.share': '【舊版】教學資源庫-共享區',

	'student.main.sidebar.welcomeMessage': 'Hi, 歡迎回來！',
	'student.main.sidebar.course': '我的課程',
	'student.main.sidebar.question': '我的問題集',
	'student.main.sidebar.notes': '我的筆記',
	'student.main.sidebar.liveCourse': '開課中',
	'student.main.sidebar.learningResults': '學習成果',
	'teacher.main.sidebar.tutorial': '系統教學目錄',
	'student.main.sidebar.globalexam': '模擬考(測試版)',
	'teacher.main.sidebar.importexercise': '匯入習題',
	'teacher.main.sidebar.question-label': '問題標註分類',
	'teacher.main.sidebar.exercise-label': '習題錯誤標註',
	'teacher.main.sidebar.question': '習題',
	'teacher.main.sidebar.questionGroup': '題組',
};

const teacherCourseDetail = {
	'course.sidebar.course': '課程管理',
	'course.sidebar.live': '線上教室',
	'course.sidebar.lesson': '單元列表',
	'course.sidebar.test': '考試與模擬練習',
	'course.sidebar.student': '課堂學生',
	'course.sidebar.score': '學生成績',
	'course.sidebar.exercise': '練習題目',
	'course.sidebar.studentamount': '使用人數',
	'teacher.sidebarFSH.useUnLimit': '無限制',
};

const InformationPage = {
	'information.account.title': '帳號與密碼',
	'information.account.account': '帳號',

	'information.account.certificate.title': '帳號憑證',
	'information.account.certificate.placeholder': '尚未匯入憑證',
	'information.account.certificate.btn': '匯入憑證',
	'information.account.certificate.noCertificate': '憑證無效，請匯入有效憑證',
	'information.account.certificate.deadtime': '憑證到期日',
	'information.account.certificate.overtime': '憑證已到期',

	'information.account.certificate.useLimit': '憑證人數',
	'information.account.certificate.toastAlert.alreadyCertification': '已有憑證！請於憑證到期後再更新憑證',
	'information.account.certificate.toastAlert.alreadyPast': '此憑證已過期！請確認匯入憑證檔案',
	'information.account.certificate.toastAlert.notActive': '此憑證尚未有效！請於憑證啟用時間 {{beginDate}} 後再匯入',
	'information.account.certificate.toastAlert.update': '已更新憑證！請確認憑證資訊',
	'information.account.certificate.toastAlert.error': '憑證錯誤！此憑證已被其他人使用',
	'information.account.certificate.toastAlert.import': '已匯入憑證，未變更憑證資訊',

	'information.account.password': '密碼', //NOSONAR
	'information.account.pre.password': '預設密碼', //NOSONAR
	'information.account.avatar': '個人頭像',
	'information.account.button.changePW': '變更密碼',
	'information.account.button.newPW': '新密碼',
	'information.account.button.confirmPW': '確定變更',
	'information.account.button.cancelNewPW': '取消',
	'information.account.avatar.change': '編輯',
	'information.account.avatar.chooseImage': '選擇照片',
	'information.account.avatar.chooseColor': '變更顏色',

	'information.socialMediaConnection': '社群帳號連結',
	'information.socialMedia.connectAccount': '連結帳號',

	'information.classnum': '班級與座號',
	'information.school': '就讀學校',
	'information.grade': '就讀年級',
	'information.class': '就讀班級',
	'information.num': '座號',
	'information.numPlaceholder': '輸入座號',
	'information.num2': '學號',
	'information.parent': '填寫家長資料',
	'information.parent.name': '家長姓名',

	'information.basic.title': '基本資料',
	'information.basic.modify': '編輯資料',
	'information.basic.school': '學校單位',
	'information.basic.name': '名字',
	'information.basic.class': '班級',
	'information.basic.nickname': '暱稱',
	'information.basic.noNickname': '尚無暱稱',
	'information.basic.tel': '電話',
	'information.basic.email': '電子郵件',
	'information.basic.availableItems': '可授課項目 (複選)',
	'information.basic.noAvailableItems': '尚無選擇授課項目',

	'information.relationContact': '關係聯絡人',
	'information.relation': '關係',

	'information.settings.title': '設定',
	'information.settings.lang': '切換語言',

	'information.notset': '尚未輸入',
	'information.noNum': '尚未有座號',
	'information.noClass': '尚未有班級',

	'information.alertSchool': '學校單位不可為空!',
	'information.alertUnit': '單位不可為空!',
	'information.alertEmail': 'Email不可為空!',
	'information.alertEmail2': 'Email格式不正確!',
	'information.alertName': '姓名不可為空!',
	'information.alertGender': '性別不可為空!',
	'information.alertPW': '密碼不可為空!',
	'information.alertTel': '電話不可為空!',
	'information.alertTel2': '電話格式不正確!',
	'information.alertExistTeacher': '不可以覆蓋已存在的老師帳號!',
	'information.alertExistAccount': '不可以覆蓋已存在的 {{role}} 帳號!',
	'information.alertExistAccount.other.unit': '不可以覆蓋已存在其他單位的帳號!',
	'information.alertExistAccount.cannot.create.other.unit': '無法建立，此{{role}}帳號已存在於 {{unit}} 單位中',
	'information.alertExistAccount.cannot.create.current.unit':
		'無法建立，此{{role}}帳號已存在於 {{unit}} 單位中，請前往編輯。',
	'information.correctPW': '請輸入正確的密碼!',

	'information.modifyTitle': '編輯個人資料',
	'information.modifySchool': '學校單位(不可修改)',
	'information.saveSuc': '個人資料已成功更新',
	'information.upload': '上傳檔案',
	'information.fileLink': '檔案連結',
	'information.avatarPlaceholder': '上傳檔案後將自動填入',
	'information.uploadFin': '上傳完畢',
	'information.uploadProgress': '上傳進度',

	'information.modifyNumber': '編輯座號',
	'information.confitmEnter': '確認加入班級',
	'information.newNumber': '請輸入新的座號。提醒您輸入錯誤的座號，可能會導致成績錯誤。',
	'information.confirmClass': '請確認您欲申請加入的班級，並輸入您在該班級的座號。',

	'information.passwordWarning': '警告！ 需要登出後，再重新登入才可以變更密碼!', //NOSONAR
	'password.new': '請輸入新密碼!', //NOSONAR
	'password.notStrong': '密碼不夠強，容易被竊取!', //NOSONAR
	'password.changeSuccess': '變更密碼成功! 請用新密碼重新登入！', //NOSONAR

	'password.resetByEmail': '請輸入 Email 來重設密碼!', //NOSONAR
	'password.checkMail': '郵件已經送出。 請到 {{email}} 重設密碼!', //NOSONAR
	'password.failtoRest': '重設密碼失敗', //NOSONAR
	'password.entermail': '請輸入您的信箱', //NOSONAR
	'password.sendtoMail': '更改密碼流程將寄至您的信箱。', //NOSONAR
	'password.yourAccount': '您的帳號', //NOSONAR

	'information.changeSuccess': '更新學生資料成功!',
	'information.changeFailed': '更新學生資料失敗!',
	'student.contactInfo': '聯絡人資料',
	'information.loginpage.displayName': '請輸入家長姓名',
	'information.loginpage.Phone': '請輸入聯絡電話',

	'information.account.avatar.changeSuccess': '更新頭像成功',
	'information.account.avatar.changeFail': '更新頭像失敗',
};

const notificationPages = {
	'all.notification.noMessages': '目前無通知訊息',
	'all.notification.unread': '未讀通知',

	'notification.sent': '訊息已經發送給使用者！',
	'notification.sentAll': '訊息已經發送全部使用者！',
	'notification.sending': '訊息正在發送中!',
	'notification.activity': '活動',
	'notification.challenge': '挑戰賽',
	'notification.greeting': '問候',
	'notification.news': '新知',
	'notification.exercise': '課後練習',

	'notification.modalTitle': '發送通知訊息',
	'notification.sendto': '傳給',
	'notification.resetSuccess': '重置所有的通知完成!',
	'notification.notifications': '通知訊息',
	'notification.title': '標題:',
	'notification.time': '時間:',
	'notification.message': '訊息:',

	'fillinTitle': '填寫標題',
	'titleConnotBeNull': '標題不能為空值',
	'Subtitle': '副標題(100字)',
	'fillin.subtitle': '填寫副標題',
	'subtitleCannotBeNull': '副標題不能為空值',
	'contentCannotBeNull': '內容不能為空值',
};

const teacherResourcePage = {
	'teacher.resources.header.title': '教學資源庫',
	'teacher.resources.tab.sorting': '排序',
	'teacher.resources.tab.sorting.name': '名稱',
	'teacher.resources.tab.sorting.time': '修改時間',
	'teacher.resources.tab.searchPlaceholder': '輸入關鍵字',
	'teacher.resources.tabTitle.handout': '講義 ',
	'teacher.resources.tabTitle.exercise': '習題 ',
	'teacher.resources.tabTitle.test': '試卷 ',

	'teacher.resources.tabTitle.questionGroup': '題組',
	'teacher.resources.tab.sorting.questionlength': '題目數',
	'teacher.resources.tab.sorting.hashtag': '標籤',

	'teacher.resources.tab.sorting.displayName': '學生姓名',
	'teacher.resources.tab.sorting.seatNo': '座號',
	'teacher.resources.tab.sorting.completepercent': '完成率',
	'teacher.resources.tab.sorting.lastlogin': '登入時間',
	'teacher.resources.tab.sorting.studentuid': '帳號名稱',

	'teacher.resources.newResource.iconTips': '新增資源',
	'teacher.resources.newResource.newHandout': '新增上課講義',
	'teacher.resources.newResource.newExercise': '新增習題',
	'teacher.resources.newResource.newExerciseOrVideo': '加入習題或影片',
	'teacher.resources.newResource.newTest': '新增試卷',
	'teacher.resources.newResource.newLesson': '新增空白單元',

	'teacher.resources.handout.category': '標準教材',

	'components.handoutManageRectangle.upload': '上傳',
	'components.handoutManageRectangle.download': '下載講義',
	'components.handoutManageRectangle.rename': '重新命名',
	'components.handoutManageRectangle.delete': '刪除講義',
	'components.handoutManageRectangle.deletePopup.title': '移除講義？',
	'components.handoutManageRectangle.deletePopup.revokable': '講義將從此課程移除, 您仍然可以在教學資源中心找到此講義',
	'components.handoutManageRectangle.deletePopup.irrevokable': '移除講義後將無法再復原',
	'components.exercise.deletePopup.revokable': '練習將從課程中移除, 您仍然可以在教學資源中心找到此練習題',
	'components.exercise.deletePopup': '練習刪除後將無法再復原',
	'confirmation.title': '確認訊息標題',
	'confirmation.content': '確認訊息',

	'teacher.resources.mine': '我的資源庫',
	'teacher.resources.unit': '單位共享區',
	'teacher.resources.global': '全域共享區',

	'teacher.resources.nohandout': '您尚未添加上課講義',
	'teacher.resources.noexercise': '尚無自建的習題',
	'teacher.resources.notest': '您尚未添加測驗試卷',

	'teacher.nomatch.handout': '沒有符合搜尋的上課講義',
	'teacherResourcePage.syncToCourse': '同步至自己所有課程與試卷',
	'teacherResourcePage.syncToOtherUnitCourse': '同步至所有單位課程與試卷',
	'resource.shareSuc': '分享成功!',
	'resource.shareFail': '分享失敗!',
	'resource.copySuc': '複製成功!',
	'resource.syncSuc': '同步完成',
	'resource.syncFail': '同步失敗，請稍後再試',
	'resource.copySucc': '複製 {{target}}{{title}} 成功!',
	'resource.copyFailed': '複製 {{target}} {{title}} 發生錯誤：{{err}}',
	'resource.delSuc': '成功刪除: {{title}} 試卷',
	'resource.delFailed': '刪除試卷 {{title}} 發生錯誤: {{err}}',

	'teacher.studentMg': '學生管理',
	'teacher.studentMg.addStudent': '新增學生資料',
	'teacher.studentMg.viewStudent': '檢視學生資料',

	'teacher.resources.header.subtitle':
		'我們推出了新版的教學資源庫，你可以更輕鬆地在 CodingBar 裡建立各種題型的題目，並上傳講義素材，\n還可從單位/公共資源庫下載更多的資源！快來試看看吧！',
	'teacher.resources.fileName': '檔案名稱',

	'teacher.resources.tab.sorting.uploadBy': '上傳者',
	'teacher.resources.tab.sorting.useLimit': '人數上限',
	'teacher.resources.tab.sorting.currentUse': '已用人數',
	'teacher.resources.tab.sorting.remainingUse': '可用餘額',
	'teacher.resources.tab.sorting.expiration': '到期日',
	'teacher.resources.tab.sorting.tags': '分類標籤',
	'teacher.resources.tab.sorting.materials': '內容',

	'teacher.resources.tabTitle.package': '套裝課程',
	'teacher.resources.package.edit': '編輯套裝課程',
	'teacher.resources.package.delete': '刪除套裝課程',
	'teacher.resources.package.deleteSuccess': '成功刪除套裝課程',
	'teacher.resources.package.deleteFail': '刪除套裝課程失敗',
	'teacher.resources.package.deletePopup.title': '刪除套裝課程？',
	'teacher.resources.package.deletePopup.content': '移除套裝課程後，將無法再復原',

	'teacher.resources.newResource.newCoursePackage': '新增套裝課程',
	'teacher.resources.year': '/',
	'teacher.resources.month': '/',
	'teacher.resources.day': '',

	'teacher.studentMg.deleteStudent': '移除此學生',
	'teacher.resources.delete.success': '刪除成功',
	'teacher.resources.delete.fail': '刪除失敗',

	'teacher.rectangle.useLimit': '個人免費上限',
	'teacher.rectangle.unitUseLimit': '單位共享上限',
};

const exerciseManageRectangle = {
	'components.exerciseManageRectangle.deleteTitle': '刪除習題？',
	'components.exerciseManageRectangle.deleteMsg': '習題刪除後將無法再復原!',
	'exercise.deleteSuc': '成功刪除習題',
	'components.exerciseManageRectangle.btn.edit': '編輯',
	'components.exerciseManageRectangle.btn.delete': '刪除此習題',
	'components.exerciseManageRectangle.btn.copy': '複製此習題',
};

const questionGroupManageRectangle = {
	'components.questionGroupManageRectangle.btn.edit': '編輯題組',
	'components.questionGroupManageRectangle.btn.delete': '刪除題組',
	'components.questionGroupManageRectangle.btn.remove': '移除題組',

	'components.QuestionGroupManageRectangle.deleteTitle': '刪除題組',
	'components.QuestionGroupManageRectangle.deleteCheck.irrevokable': '刪除題組後將無法再復原',
	'components.QuestionGroupManageRectangle.deleteCheck.revokable':
		'題組從此課程移除, 您仍然可以在教學資源中心找到此題組',

	'components.QuestionGroupManageRectangle.btn.copy': '複製此題組',
};

const testManageRectangle = {
	'components.testManageRectangle.btn.edit': '編輯',
	'components.testManageRectangle.btn.delete': '刪除',
	'components.testManageRectangle.btn.remove': '移除試卷',
	'components.testManageRectangle.btn.copy': '複製此試卷',
	'components.testManageRectangle.exerciseCount': '題數：',

	'components.testManageRectangle.testDate': '測驗日: ',
	'components.testManageRectangle.notTestYet': '尚未測驗',

	'components.testManageRectangle.deleteTitle': '移除測驗？',
	'components.testManageRectangle.deleteCheck.irrevokable': '刪除試卷後將無法再復原',
	'components.testManageRectangle.deleteCheck.revokable': '試卷從此課程移除, 您仍然可以在教學資源中心找到此試卷',

	'components.testManageRectangle.editTitle': '編輯測驗？',
	'components.testManageRectangle.editTrue': '在教學資源中心更改後，(擁有此試卷的課堂會一併更新?)。',
	'components.testManageRectangle.editFalse': '在此更改後，教學資源中心將同步更新。',
};

const materialManage = {
	'components.materialManage.title': '建立講義',
	'components.materialManage.add': '加入講義',
};

const materialUploadFile = {
	'components.materialUploadFile.title': '新增講義 （上傳檔案）',
	'components.materialUploadFile.selectFile': '選擇檔案...',
	'components.materialUploadFile.setTitle': '講義標題',
	'components.materialUploadFile.lang': '程式語言',
	'components.materialUploadFile.href': '講義超連結',
	'components.materialUploadFile.confirm': '新增',

	'components.materialUploadFile.selected': '選擇的檔案',
	'components.materialUploadFile.loading': '上傳進度',
	'components.materialUploadFile.titlePlaceholder': '請輸入講義名稱',
	'components.materialUploadFile.autoFillPlaceholder': '上傳檔案後將自動填入',

	'components.materialUploadFile.addMaterialSuccess': '成功新增 {{title}}',
	'components.materialUploadFile.addMaterialFail': '新增 {{title}} 失敗. 錯誤資訊: {{err}}',
	'components.materialUploadFile.checkMaterialLink': '請檢查您的講義連結格式',
	'components.materialUploadFile.addMaterial': '新增講義',
	'components.materialUploadFile.addMaterialTitle': '填寫講義標題',
	'components.materialUploadFile.href': '講義超連結',
	'components.materialUploadFile.addMaterialLink': '填寫講義網址',
};

const exerciseManage = {
	'component.exerciseManage.description': '題目概述',
	'component.exerciseManage.title.modify': '修改練習題',
	'component.exerciseManage.title.create': '建立練習題',
	'component.exerciseManage.title.preview': '預覽練習題',
	'component.exerciseManage.title.homework': '建立課後作業',
	'component.exerciseManage.tab.title': '標題內容',
	'component.exerciseManage.tab.freeCode': '程式題',
	'component.exerciseManage.tab.video': '看影片',
	'component.exerciseManage.tab.fillin': '填空題',
	'component.exerciseManage.tab.cloze': '克漏字填空',
	'component.exerciseManage.tab.dnd': '拖曳題',
	'component.exerciseManage.tab.choice': '選擇題',
	'component.exerciseManage.tab.rearrangement': '排序題',
	'component.exerciseManage.tab.step': '步驟題',
	'component.exerciseManage.tab.splitTips': '拆解提示',
	'component.exerciseManage.tab.answer': '解答範例',
	'component.exerciseManage.tab.test': '測試資料',
	'component.exerciseManage.tab.preview': '發布預覽',

	'component.exerciseManage.modal.previous': '上一步',
	'component.exerciseManage.modal.next': '下一步',
	'component.exerciseManage.modal.confirmEdit': '修改資料',
	'component.exerciseManage.modal.confirmCreate': '新增資料',

	'component.exerciseManage.fraction.exercise': '習題',
	'component.exerciseManage.fraction.editSuccess': '更新成功！',
	'component.exerciseManage.fraction.editFail': '更新失敗！',
	'component.exerciseManage.fraction.edit': '修改習題',
	'component.exerciseManage.fraction.fail': '失敗！',
	'component.exerciseManage.fraction.createSuccess': '建立成功！',
	'component.exerciseManage.fraction.createFail': '建立失敗！',
	'component.exerciseManage.fraction.try.again': '，請再試一次。',
	'component.exerciseManage.fraction.create': '創建習題',
	'component.exerciseManage.alert.title': '請輸入習題標題',
	'component.exerciseManage.alert.execute.frequently': '請勿頻繁送出程式碼',

	'component.exerciseManage.alert.choiceNoAnswer': '選擇題未指定正確答案',
	'component.exerciseManage.alert.choiceEmpty': '選擇題不可含空白選項',

	'component.exerciseManage.alert.choiceChooseAnswer': '用滑鼠點選選項為答案選項',
	'component.exerciseManage.alert.addChoice': '新增選項',
	'component.exerciseManage.alert.choiceChooseAlert': '您必須選擇至少一個選項為正確答案',
	'component.exerciseManage.codeSettingsNotSet': '還沒有設定 Code',

	'component.exerciseManage.alert.emptyVideoUrl': '影片網址不得為空白',
	'component.exerciseManage.alert.ErrorVideoUrl': '影片網址錯誤，無法辨識',

	'component.exerciseManage.alert.emptyChoice': '選擇題未設定選項',
	'component.exerciseManage.alert.nullChoice': '選擇題不可含空白選項',

	'component.exerciseManage.alert.emptyStep': '步驟題未設定步驟',
	'component.exerciseManage.addStep': '新增引導解題步驟',
	'component.exerciseManage.confirmAddStep': '新增步驟',

	'component.exerciseManage.correctAnswer': '完全正確',
	'component.exerciseManage.wrongAnswer': '答錯了!',
	'component.exerciseManage.previewAnswer': '預覽',

	'component.exerciseManage.isSlotFilling': '此題為填空題，請點選下方「填空題」按鈕',
	'component.exerciseManage.setSlotFilling.placeholder':
		'填空題設定方式：\n請將完整程式碼貼於此文字編輯欄位\n請勿設計 無法執行/非程式碼 的內容，如有其餘問答需求建議使用克漏字填空題\n請將想要「挖空」的字串，以 ${} 包住\n範例說明\n程式碼正確解答： print("hello world")\n\n若想將 print 變成填空題空格，請將 print 以 ${print} 取代： ${}(“hello world”)\n大括弧內可允許空格，例如 ${ }，空格越多，題目顯示的填空長度就越長',
	'component.exerciseManage.alert.completeStep': '請先完成這一步驟',
	'component.exerciseManage.alert.lastStep': '沒有下一步驟了！',
	'component.exerciseManage.setStepTip':
		'可將此步驟的提示與引導內容內容 python註解語法形式，填寫於此 IDE編輯框內，做為預設的提示資訊 此段文字請於發佈前刪除，並替換成您所要設定的題目程式語法',
	'component.exerciseManage.setFreecodeContent': '設定Free Code題目內容',

	'component.exerciseManage.loadingLonger': '載入中，當題目較多時，可能需要較長時間...',
	'component.exerciseManage.exerciseContent': '題目內容',
	'component.exerciseManage.exerciseDesc': '習題概述',
	'component.exerciseManage.select': '選擇',
	'component.exerciseManage.selectVideo': '選擇影片',
	'component.exerciseManage.selectExerciseType': '題型選擇',
	'component.exerciseManage.exerciseTitle': '習題標題',
	'component.exerciseManage.alert.lastQuestion': '沒有下一題了！',
	'component.exerciseManage.openFiles': '工作目錄',
	'component.exerciseManage.dragDrop.placeOption': '放入選項',
	'component.exerciseManage.noErrors': '沒有錯誤',
	'component.exerciseManage.alert.noCode': '程式碼不可為空!',
	'component.exerciseManage.alert.incorrectData': '資料不正確!',
	'component.exerciseManage.alert.frequencySubmit': '請勿頻繁執行/提交!!',
	'component.exerciseManage.alert.executing': '正在執行!!',
	'component.exerciseManage.alert.other.code.executing': '其他程式碼正在執行中!!',

	'component.exerciseManage.checkDesc': '查看說明',
	'component.exerciseManage.previousPage': '回到上頁',
	'component.exerciseManage.checkHints': '查看提示',
	'component.exerciseManage.moreHints': '找不到哪邊錯嗎？來看看這些可能的問題吧！',
	'component.exerciseManage.exerciseNotAvailable': 'Sorry 目前題目尚未開放...',
	'component.exerciseManage.test.fail': '測試失敗',
	'component.exerciseManage.executionFailed': '執行失敗',
	'component.exerciseManage.executionSuccess': '測試通過',
	'component.exerciseManage.reorder': '您的排序結果',

	'component.exerciseManage.configSetting': '請使用 config 方式設定題目，設定方式如下：',
	'component.exerciseManage.cloze.slot': '設定克漏字填空挖空處，slot tag 中間文字為正確答案 ',
	'component.exerciseManage.cloze.select': '設定下拉式選單挖空處與選項，select tag 中間文字為正確答案 ',
	'component.exerciseManage.jsonFormat': '其中，下拉選項的雙引號內文字（ex. "option 1"）請用JSON格式輸入特殊符號：',
	'component.exerciseManage.jsonFormatExample':
		'雙引號(")請輸入："，反斜線()請輸入：\\；下拉選項的正確答案則無需輸入反斜線',
	'component.exerciseManage.dragDrop.option': '設定拖曳選項，冒號與文字中間無空白；個別選項請各自設定一個#option: ',
	'component.exerciseManage.dragDrop.slot': '設定下拉式選單挖空處與選項，select tag 中間文字為正確答案 ',

	'component.exerciseManage.dragDrop.resetContent1': '警告，應於莫名無法拖曳時使用，',
	'component.exerciseManage.dragDrop.resetContent2': '點擊將重置此題作答，確認重置？',

	'component.exerciseManage.alert.somethingWrong': '有東西出錯了！若重複發生，請填寫問題與反饋',

	'component.exerciseManage.alert.slotFillingContent': '填空題內容應為程式碼\n請寫入正確程式語言的程式碼',
};

const exerciseAndTest = {
	'component.exerciseMainInfo.lang': '習題語言',
	'component.exerciseMainInfo.testlang': '試卷語言',
	'component.exerciseMainInfo.title': '習題標題',
	'component.exerciseMainInfo.testTitle': '試卷標題',
	'component.exerciseMainInfo.hashtag': '自訂標籤（選填）',
	'component.exerciseMainInfo.link': '影片網址',
	'component.exerciseMainInfo.content': '習題內容（圖片大小上傳前須自行調整）',
	'component.exerciseMainInfo.contentShort': '習題內容',
	'component.exerciseMainInfo.testExplainPlaceholder': '填寫試卷說明',
	'component.exerciseMainInfo.titlePlaceholder': '輸入習題標題',
	// 'component.exerciseMainInfo.hashtagPlaceholder': '請用#分開不同標籤',
	'component.exerciseMainInfo.hashtagPlaceholder': '請用半形#字號分隔不同標籤，請勿輸入空格',

	'component.exerciseMainInfo.videoSource': '影片來源',
	'component.exerciseMainInfo.linkInput': '請輸入影片網址 (目前支援 Youtube, Vimeo 影音平台網)',
	'component.exerciseMainInfo.linkSelectFromResource': '或，從既有教學資源庫中選擇影片',
	'component.exerciseMainInfo.insertVideo': '插入影片',
	'component.exerciseMainInfo.testTitlePlaceholder': '請輸入標題，但不建議加入特定考試的名稱',
};

const exerciseTipInfo = {
	'component.exerciseTipInfo.new': '新增拆解提示',
	'component.exerciseTipInfo.title': '提示標題',
	'component.exerciseTipInfo.content': '提示內容',
	'component.exerciseTipInfo.titlePlaceholder': '填寫提示標題',
	'component.exerciseTipInfo.contentPlaceholder': '填寫提示內容',
	'component.exerciseTipInfo.confirmNew': '新增提示',
};

const exerciseAnswerInfo = {
	'component.exerciseAnswerInfo.ans': '新增解答範例',
	'component.exerciseAnswerInfo.title': '解答範例標題',
	'component.exerciseAnswerInfo.titlePlaceholder': '填寫解答範例標題',
	'component.exerciseAnswerInfo.content': '解答範例內容',
	'component.exerciseAnswerInfo.contentPlaceholder': '填寫解答範例內容',
	'component.exerciseAnswerInfo.confirmNew': '新增解答範例',
};

const exerciseTestInfo = {
	'component.exerciseTestInfo.title': '新增測試資料',
	'component.exerciseTestInfo.percentage': '分數百分比',
	'component.exerciseTestInfo.input': '輸入資料 (Input)',
	'component.exerciseTestInfo.inputPlaceholder': '填寫輸入資料',
	'component.exerciseTestInfo.output': '輸出資料 (Output)',
	'component.exerciseTestInfo.outputPlaceholder': '填寫輸出資料',
	'component.exerciseTestInfo.confirmNew': '新增測試資料',
};

const testManage = {
	'component.testManage.alert.title': '請輸入試卷標題',
	'component.testManage.alert.content': '請輸入試卷內容',
	'component.testManage.alert.option': '請選擇試卷題目',
	'component.testManage.alert.portion': '試卷題目總分不為100, 請調整題目比分',
	'component.testManage.alert.score': '請選擇試卷比分',
	'component.testManage.test': '試卷',
	'component.testManage.createTest': '建立試卷',
	'component.testManage.edit': '修改測驗試卷',
	'component.testManage.create': '新增試卷',
	'component.testManage.question': '題目',
	'component.testManage.preview': '預覽試卷',
	'component.testManage.scoring': '分數設定',
	'component.testManage.confirmEdit': '修改試卷',
	'component.testManage.confirmNew': '建立試卷',

	'testManage.previewExercise': '練習題預覽',
	'testManage.closePreview': '關閉預覽',
	'testManage.selectFrom': '從教學資源庫選擇',
	'testManage.createNew': '建立新試題',
	'testManage.loadingStudent': '載入學生資料中....',
	'testManage.addNewExercise': '添加課堂練習',
	'testManage.addNewHomework': '添加課後作業',
	'testManage.addhandout': '添加講義',

	'component.testManage.info': '基本內容',
	'component.testManage.note.1': '新版試卷功能，題目請由教學資源庫匯入；您可直接拖曳題目調整順序',
	'component.testManage.note.2': '若同一題內有多筆測資，請先設定各筆測資配分比例，否則系統將自動平均配分',
	'component.testManage.note.3':
		'請注意：非解題型題目，例如 microbit / pygame / video 等，匯入時將自動過濾不顯示在考卷中',
	'component.testManage.import.questions': '匯入題目或題組',
	'component.testManage.import.question': '匯入題目',
	'component.testManage.preview.question': '預覽題目',
	'component.testManage.question.step': '試卷題目',
	'component.testManage.event.step': '試卷設定',
	'component.testManage.repeat.exercise': '題目重複',
	'component.testManage.repeat.exercise.btn.confirm': '停留此頁',
	'component.testManage.repeat.exercise.btn.next': '繼續下一步',
	// 'component.testManage.repeat.info':'已偵測到下列題目為重複內容，請確認是否要繼續設定試卷',
	'component.testManage.repeat.info': '已偵測到下列題目為重複內容，請重新設定試卷',
	'component.testManage.repeat.number': '重複題目編號',

	'component.StartTestAlertModal.title': '考試活動已開始',
	'component.StartTestAlertModal.content': '教師所舉辦的考試活動已經開始囉！抓緊時間前往考試吧！',

	'component.EndTestAlertModal.title': '考試活動已結束',
	'component.EndTestAlertModal.content': '教師所舉辦的考試活動已經結束了！',

	'component.studentNotification.endTest': '您的考試活動已經結束囉！前往檢視您的成績吧！',
};

const lessonAppendExercise = {
	'lessonAppendExercise.selectLang': '請在試卷資料選擇程式語言',
	'lessonAppendExercise.onlyShow': '僅顯示{{codeType}}的題目',
	'lessonAppendExercise.please': '請透過',
	'lessonAppendExercise.please_2': '上傳',
	'lessonAppendExercise.addToCourse': '練習題新增至課程',
	'lessonAppendExercise.noMatch': '沒有符合搜尋條件的練習題',
	'lessonAppendExercise.alert.hw': '這是課後練習的習題，無法取消!',
	'lessonAppendExercise.alert.course': '這是課堂練習的習題，無法取消!',

	'lessonAppendExercise.forceSort': '依照標題排序',
	'lessonAppendExercise.sortingExercise': '重新排序中，請耐心等候',
	'lessonAppendExercise.subtitle.amountcount': '已選擇 {{amountcount}} 個項目',
	'lessonAppendExercise.repeated.alert': '請注意！請避免匯入重複的題目(例如不同題組裡有相同題目)，系統會阻擋喔！',
	'lessonAppendExercise.existed.alert': '您選取的題目/題組，有部分題目已存在單元內容或課後作業，請取消選擇',
	'lessonAppendExercise.material': '講義將從教學資源庫中匯入，請先將資料上傳至',
	'lessonAppendExercise.material.note2': '',

	'lessonAppendExercise.no.exercise': '教學資源庫尚未有任何習題',
	'lessonAppendExercise.no.video': '教學資源庫尚未有任何影片',
	'lessonAppendExercise.no.exerciseGroup': '教學資源庫尚未有任何題組',

	'lessonAppendExercise.noSearch.exercise': '沒有符合搜尋的習題',
	'lessonAppendExercise.noSearch.video': '沒有符合搜尋的影片',
	'lessonAppendExercise.noSearch.exerciseGroup': '沒有符合搜尋的題組',

	'lessonAppendMaterial.no.material': '教學資源庫尚未有任何講義',
	'lessonAppendMaterial.noSearch.material': '沒有符合搜尋的講義',
};

const testRatioInfo = {
	'testRatioInfo.none': '尚無練習題',
	'testRatioInfo.note.1': '請設定各題分數，若點擊自動分配則系統自動平均配分 (總分 100)，',
	'testRatioInfo.note.1.sub': '自動配分採四捨五入，差額的分數會累計在最後一題！',
	'testRatioInfo.avg': '自動配分',
	'testRatioInfo.sum': '目前分數合計',
	'testRatioInfo.individual.allocation': '個別配分',
};

const testPreview = {
	'testPreview.content': '內容',
	'testPreview.lang': '試卷程式語言',
	'testPreview.details': '試卷內容',

	'testPreview.gototest': '前往考試',
	'testPreview.gotomock': '前往挑戰',
	'testPreview.handintest': '已提交',
	'testPreview.commingsoon': '即將開放',
	'testPreview.viewtestdetail': '檢視考試內容',
	'testPreview.testscore': '考試成績',
	'testPreview.testamountofquestion': '試卷題數',
	'testPreview.highestscore': '最高成績',
	'testPreview.testamountofenter': '挑戰次數',
	'testPreview.lastscore': '近一次成績',
	'testPreview.testspendingtime': '你花費的時間',
	'testPreview.testendgap': '距離活動結束',
};

const addMaterial = {
	'addMaterial.success': '成功新增',
	'addMaterial.fail': '新增失敗',
};

const course = {
	'course.backToMyCourses': '回到我的課程',
	'course.newTip': '新增課程',
	'course.all': '所有課程',
	'course.allLessons': '所有單元',
	'course.ready': '即將開始',
	'course.ongoing': '現正開課',
	'course.notyet': '尚未開始',
	'course.end': '已經結束',
	'course.package-ongoing': '套裝課程',
	'course.package-past': '套裝課程(過期)',
	'course.self-ongoing': '自建課程',
	'course.self-past': '自建課程(結束)',
	'course.teacher': '{{teacher}}老師',

	'course.buymyself.banner1': '購買套裝課程，或增加人數上限',
	'course.buymyself.banner2': '讓您教學更簡單！',
	'course.buymyself.banner3':
		'自 2022 年 9 月起，CodingBar 僅提供學校教師新帳號免費 50 位學生額度，既有帳號權益不受影響喔！',
	'course.buymyself.peopleused': '已用數量',
	'course.buymyself.peoplemax': '個人免費上限',
	'course.buyunit.peoplemax': '單位剩餘數量',
	'course.banner.contactus': '聯絡 CodingBar',
	'course.banner.checklimit': '查詢使用人數',

	'course.modal.modalTitle.studentRemainingUse': '使用學生數',
	'course.modal.modalBody.studentRemainingUse':
		'您個人的免費使用學生帳號數量：{{teacherLimit}} (上限)\n\n貴單位共享，使用學生帳號數量：{{unitLimit}} (上限)',

	'course.sign': '已經報名',
	'course.no': '尚無課程',
	'course.name': '課程名稱',
	'course.cid': '課程代碼',

	'course.num': '個課程',
	'course.noclass': '目前非上課時間',
	'course.nosearch': '無相關課程',
	'course.search': '輸入關鍵字或課程代碼',
	'course.enterPractice': '快速進入習題',
	'course.students': '位學生',
	'course.cancelCodeEnter': '取消班級代碼加入',
	'course.activeCodeEnter': '允許班級代碼加入',
	'course.codeEnter': '透過班級代碼加入新班級',
	'course.copyCode': '複製班級代碼',
	'course.openCode': '班級代碼已開啟',
	'course.delete': '刪除此課程',
	'course.endCourse': '結束此課程',
	'course.template': '複製課程', // 原本(以此當範本建立)
	'course.use': '以',
	'course.templateConfirm': '為範本建立新課程？',
	'course.templateAlert': '複製課程後，請為課程設定代碼及上課時程。',
	'course.endEarly': '確認提早結束課程？',
	'course.deleteConfirm': '確認刪除此課程？',
	'course.endMsg': '課程結束時間將改變成為今日，您可以在「已經結束」內找到此課程資訊。',
	'course.deleteMsg': '課程刪除後，您將無法查詢此課程相關的任何資訊。\n此項操作無法回復，請確認後再執行刪除。',

	'course.deadline': '截止日：',

	'course.saveFailed': '課程 儲存失敗!',
	'course.deleteSuccess': '成功刪除課程！',
	'course.copying': '複製課程資訊中...',
	'course.noExercise': '目前沒有課堂練習',

	'course.handout': '上課講義',
	'course.exercise': '課堂練習',
	'course.homework': '課後作業',
	'course.allpract': '所有練習',
	'course.collection': '我的收藏',

	'course.live': '線上教室',
	'live.class': '上課模式',
	'live.test': '測驗模式',
	'live.nounits': '無其他單元',
	'course.attachment': '補充教材',
	'course.norelease': '作業尚未釋出',
	'course.no_homework': '目前沒有課後作業',

	'course.endMsg2': '本課程已結束',
	'course.addhandoutFailed': '新增講義至課程{{course}}儲存失敗',
	'course.updateExerciseFailed': '練習題清單失敗!',
	'course': '課程',
	'course.test.start': '開始測驗',
	'course.test.end': '結束課程',
	'course.test.end2': '測驗',
	'course.test.startFailed': '開始課程試卷失敗!',
	'course.test.endFailed': '結束課程試卷失敗!',
	'course.test.setduration': '選擇考試時間長度',
	'course.testIng': '測驗中',
	'course.test.handin': '交卷',
	'course.test.not.handin': '尚未交卷',

	'course.test.noticeTitle': '模擬考注意事項',
	'course.test.noticeContext': `本次測驗為 150 分鐘，分為「觀念 20 題」與「實作 2 題」，測驗中請勿關閉瀏覽器或中斷網路，或離開考試介面，否則將無法儲存你的答題結果。\n
	開始考試時，點擊未完成項目的第一題，即可進入 compiler 環境開始考試；\n(實作題環境提供 Python、C、C++ 語言作答，請透過左側設定進行切換)\n
	每一題作答時，請務必確保記得按下方提交鍵，可透過左側圖示檢查已提交答案題目，\n
	考試完成時，您可按下交卷結束考試，或時間結束後由系統自動交卷；交卷後，您將於 5 分鐘內收到一份測驗結果與簡單分析，作為正式考前的參考依據。\n
	點擊下方按鈕，就開始計時考試了，祝您考試順利！ ; )`,

	'course.test.EndTitle': '即將結束測驗',
	'course.test.EndContext': `請注意，在結束測驗前，請確認已將全部題目的程式碼/答案都「提交」系統，否則將無法計分\n
	結束後，五分鐘內我們將把測驗結果寄到你的註冊信箱！`,

	'course.test.HandinTitle': '您確定要交卷？',
	'course.test.HandinContext': `您尚有 {{unsubmitquestion}} 題未提交（試卷共含 {{totalquestion}} 題），並有 {{markquestion}} 題仍標註待檢查。
	一旦交考卷，將離開考試活動、無法繼續作答。此動作不可回復，請再三確認後再執行此動作！`,
	'course.test.submit.success': '成功提交',
	'course.test.submit.success.go.next': '成功提交，點擊下方按鈕進入下一題',
	'course.test.submit.success.go.next.step': '成功提交此步驟，點擊下方按鈕進入下一步',

	'course.test.notitle': '無測驗題目',
	'course.test.name': '測驗名稱',
	'course.test.tried': '嘗試過的題目',
	'course.test.notfinished': '尚無完成的測驗題目',
	'course.test.placeholder': '隨堂測驗, 期中考...',
	'course.test.time': '測驗時間',
	'course.endTest': '結束測驗',

	'course.endTest2': '提交考卷',
	'course.returnTest': '回到考試',
	'course.timesUp': '！測驗時間結束！',

	'course.test.title': '測驗題目',
	'course.test.tip': '選擇測驗試卷後，可以在這裡看到試卷裡面的題目',
	'course.test.choose': '請選擇...',

	'course.test.commonModalTitle': '測驗注意事項',
	'course.mock.noticeContext1': '在開始模擬測驗前，請注意：',
	'course.mock.noticeContext2':
		'測驗中請勿關閉瀏覽器或中斷網路，或離開考試介面，否則將無法儲存你的答題結果，另外超過考試規定時間將無法作答。',
	'course.mock.noticeContext3': '測驗完成時，您可按下「交卷」，或時間結束後由系統自動交卷；交卷後，將不能更改答案。',
	'course.mock.noticeContext4': '您可重複進行挑戰同一份考卷，但在檢討模式下，系統將只保留最後一次的作答內容',
	'course.mock.noticeContext5': '點擊「開始作答」將開始計時，祝你刷題刷爽爽！',

	'course.test.noticeContext1': '在開始考試前，請注意：',
	'course.test.noticeContext2':
		'測驗過程中如有：關閉瀏覽器、網路斷線或離開考試介面等情形，考試仍持續計時，超過考試規定時間將無法作答。',
	'course.test.noticeContext3': '測驗完成時，您可按下「交卷」，或時間結束後由系統自動交卷；交卷後，將不能更改答案。',
	'course.test.noticeContext4': '若您的試題經過亂數排序，在檢討模式下的題目序號可能有所差異。',
	'course.test.noticeContext5': '點擊「開始作答」將開始計時，祝您考試順利！ ; )',

	'course.retest': '重考',
	'course.retest2': '重考試卷試卷',
	'course.addTesttoCourse': '試卷新增至課程',
	'course.lessonEnd': '此單元都已結束',
	'course.notstarted': '此課堂單元尚未開始',
	'course.time': '本週上課時間',
	'course.timeFormat': '上課時間',
	'course.nextTime': '下次上課時間',
	'course.alreadyEnd': '此課堂已結束',
	'course.nohandout': '目前沒有講義',
	'course.noExercise': '目前沒有練習',
	'course.noHomework': '目前沒有課後作業',

	'course.removeExercise': '移除此練習題',
	'course.sync': '同步教學資源',

	'course.content': '課程的內容描述',

	'course.videoError': '非常抱歉 影片載入發生錯誤',
	'course.videoTutorial': '影片操作教學',
	'course.vidTutorial1': '1.影片播放中按下鍵盤⇨鍵可以往前快轉',
	'course.vidTutorial2': '2.影片播放中按下鍵盤⇦鍵可以往回快轉',
	'course.vidTutorial3': '3.長按⇨鍵或⇦鍵可以持續的快轉影片',
	'course.vidTutorial4': '4.影片控制區裡的齒輪符號可以調整影片畫質(Quality)和播放速度(Speed)',
	'course.videoDontShow': '不再顯示',

	'course.deleteConfirmMsg': '單元以及其所有的設定刪除後將無法再復原',
	'course.thisweek': '本週單元',
	'course.lesson': '單元',
	'course.a.lesson': '單元',

	'course.add': '加入課程',
	'course.addSuccess': '試卷新增至課程 {{title}} 儲存成功!',
	'course.addFail': '試卷新增至課程 {{title}} 儲存失敗!',

	'classunit.D': '確認並班整課程單元時間與內容',
	'classunit.editClass': '編輯班級',
	'classunit.newClass': '新增課程',
	'classunitlist.lessonTitle': '課程標題',
	'classunitlist.day': '上課日期',
	'classunitlist.startTime': '開始時間',
	'classunitlist.endTime': '結束時間',
	'classunitlist.content': '單元內容',
	'classunitlist.add': '新增另一組規則',
	'classunitlist.courseday': '選擇上課週期',

	'classinfo.begin': '初級班',
	'classinfo.medium': '中級班',
	'classinfo.advanced': '進階班',
	'classinfo.A': '新增程式語言與難度',
	'classinfo.lantype': '語言類型',
	'classinfo.MainLanType': '主要使用語言',
	'classinfo.level': '難易程度',
	'classinfo.Amount': '課程堂數',
	'classinfo.CourseCustomLabel': '自訂標籤',
	'classinfo.CourseCustomLabelDescription': '自訂標籤可幫助之後搜尋更加快速',
	'classinfo.weeks': '上課週數',
	'classinfo.startDate': '開始上課日期',
	'classinfo.B': '新增班級代碼與名稱',
	'classinfo.code': '班級代碼',
	'classinfo.title': '班級名稱',
	'classinfo.CourseTitle': '輸入課程名稱',
	'classinfo.CourseTitleDescription': '課程名稱應不得超過 20 個字',
	'classinfo.teacher': '授課老師',
	'classinfo.mainpoint': '課程主旨',
	'classinfo.CourseIntro': '輸入課程簡介或附註',
	'classinfo.C': '指定每週上課日',
	'classinfo.idplaceholder': '6-8位英數',
	'classinfo.CourseAmountLimit': '請輸入整數數字',
	'classinfo.nameplaceholder': '最多15個字元，包含中英',
	'classinfo.description': '描述本課程主旨、範圍或適用班級對象',

	'course.exerciseFinished': '練習都已完成',
	'course.moreExercise': '完成拉？ 來跟老師領....更多練習',
	'course.exercise.nocollection': '尚無收藏',
	'course.exercise.collectionTips': '透過選項，可以將該練習題加入收藏哦！',

	'course.entercode': '輸入課程代碼',
	'course.code': '課程代碼',
	'coursecode.placeholder': '請輸入課程代碼',
	'coursecode.change': '更改此課堂代碼的座號',
	'coursecode.hint': '您可以透過學校老師提供的班級代碼，加入課程。',
	'coursecode.nocourse': '查無課程，請確認輸入的班級代碼',

	'course.nostudent': '學生列表是空的，確定要更新課程嗎?',

	'course.placeholder.searchKeywordOrQuestion': '輸入關鍵字或題號',
	'course.placeholder.searchKeywordOrLesson': '輸入關鍵字或堂數',
	'course.placeholder.searchKeywordOrHashtag': '輸入關鍵字或標籤',

	'course.labels.inProgress': '進行中',
	'course.labels.notStarted': '未開始',
	'course.labels.ended': '已結束',
	'course.labels.live': 'Live!',

	'course.clone.copyCourseAsPackage': '複製為套裝課程',
	'course.clone.copyToOtherUnit': '複製至其他單位',
	'course.clone.copyCourse': '分享課程及教學資源',
	'course.clone.copyAssignUnit': '指派課程', // 原本是('分享並指派課程')
	'course.export.exercise': '匯出課程',
	'course.clone.currentUnit': '目前單位',
	'course.clone.currentCourseId': '目前課程ID',
	'course.clone.targetUnit': '目標單位',
	'course.clone.targetTeacher': '目標教師帳號',
	'course.clone.placeholder': '可不填，自動生成',
	'course.clone.teacherHasCourse': '老師已有該課程',
	'course.lesson.notSetTime': '尚未設定時間',

	'course.cloneToolTips.cloneResource': '複製課程資源',
	'course.cloneToolTips.exerciseAndVideo': '題目/影片',
	'course.cloneToolTips.cloneHandout': '講義',
	'course.cloneToolTips.cloneTest': '試卷',
	'course.cloneToolTips.cloneGroupExercise': '題組',
	'course.cloneToolTips.editTest': '編輯試卷',
	'course.cloneToolTips.createTest': '建立新考試活動',
	'course.cloneToolTips.cloneToClone': '將副本課程分享他人',

	'course.package.addPackage': '匯入套裝課程',
	'course.package.copyCourseAsPackage.title1': '將',
	'course.package.copyCourseAsPackage.title2': '複製為套裝課程？',
	'course.package.copyCourseAsPackage.confirm':
		'複製為套裝課程後，您無法編輯、修訂該套裝課程內容；請先確認目前內容後再執行。\n套裝課程的指派請由課務管理系統操作。',

	'course.transform.asGlobalTest': '轉為公開模擬考', // 原本是(轉變成公開模擬考)
	'course.global.mock.exam': '公開模擬考',
	'course.transform.globalTestConfirm': '此課程已為公開模擬考，是否取消轉變？',

	'course.duplicate': '複製',
	'course.today': '本日課程',
	'course.delete.lesson': '刪除此單元',
	'course.sync.exercise.fail': '同步習題失敗',
};

const studentlist = {
	'role.students': '學生',
	'role.teachers': '老師',
	'role.mentors': '教師',
	'role.staffs': '員工',
	'role.staffs1': '行政人員',
	'role.admin': '管理員',
	'role.other': '其他',

	'title.teacherMaintenance': '師資維護',
	'title.studentMngmt': '學生管理',
	'title.staff': '行政管理',
	'title.crm': 'CRM 名單管理',
	'title.unknown': '未知',

	'label.teacher': '老師名單',
	'label.student': '學生名單',
	'label.admin': '行政名單',
	'label.crm': 'CRM 名單',
	'label.staff': '非 CRM 名單',
	'label.courseScore': '班級成績',
	'notloginyet': '尚未登入過',
	'totalNum': '總共人數',
	'studentlist.import': '匯入學生',
	'studentlist.confirmImport': '確認匯入',
	'studentlist.confirmModal.title': '重複學生',
	'studentlist.confirmModal.exist.in.unit': '已存在目前單位',
	'studentlist.confirmModal.exist.in.course': '已存在課程中',
	'studentlist.confirmModal.exist.teacher': '已存在的老師帳號',
	'studentlist.confirmModal.exist.other.unit': '已存在其他單位',
	'studentlist.confirmModal.block': '匯入列表當中以下學生項目仍有問題，請編輯匯入學生資訊或移除。',
	'studentlist.confirmModal.content':
		'匯入列表中有 “已存在目前單位” 或 "已存在課程中" 的學生項目，匯入將更新資訊，請確認是否要匯入？',
	'studentlist.loading': '正在載入學生列表中, 請等待載入結果',
	'studentlist.waiting.course.update.result': '正在等待學生列表更新結果, 請不要重複點擊',

	'import.noNew': '沒有新學生加入！',
	'import.student.title': '匯入學生至',
	'import.selectInstruction': '你現在可以透過下方搜尋列快速選擇已經報名學生，加入班級。',
	'import.searchDropdownName': '學生姓名',
	'import.searchDropdownClass': '學生班級',
	'import.searchPlaceholder': '輸入關鍵字搜尋',
	'import.backPage': '回到上一頁',
	'import.selectFile': '選擇檔案',
	'import.selectFileLabel': '尚未匯入檔案',
	'import.downloadTemplate': '學生檔案範本',

	// 'import.downloadNotes-1': '(1). 您可點擊右下方的「下載 csv 範本」下載範例檔案，建立學生資料後，匯入自動產生學生帳號；若開啟 csv 範本為亂碼，請將',
	// 'import.downloadNotes-2': '編碼變更為 unicode，或使用 google 試算表',
	// 'import.downloadNotes-3': '開啟。',
	// 'import.downloadNotes-4': '(2). 學生帳號預設密碼為',
	// 'import.downloadNotes-5': '小寫英文字元「airabbi」',
	// 'import.downloadNotes-6': '；若平台內已有相同的學生帳號(email信箱)時，您匯入的學生資料將不會被建立。',

	'import.downloadNotes-1': '(1) 請參考 ',
	'import.downloadNotes-2': '範本檔案',
	'import.downloadNotes-3': '，依格式將名單製作為 csv 檔並匯入，系統自動產生學生帳號；若開啟 csv 範本為亂碼，請將',
	'import.downloadNotes-4': '編碼變更為 unicode，或使用 google 試算表',
	'import.downloadNotes-5': '開啟。',
	'import.downloadNotes-6': '(2) 學生帳號預設密碼為',
	'import.downloadNotes-7': '小寫英文字元「airabbi」',
	'import.downloadNotes-8': '；若平台內已有相同的學生帳號(email 信箱)時，請編輯匯入學生 Email 或 取消勾選。',

	'import.downloadCsvTemplate': '下載 csv 範例檔案',
	'import.defaultPasswd': '學生預設密碼為',
	'import.defaultPasswd2': '(七個小寫英文字元)',
	'import.later': '稍後再加入學生',
	'import.fromCSV': '從 .CSV 匯入',
	'import.fromResource': '從 平台 匯入',
	'import.fromCSV.wrongEmailWarning': '信箱欄位有誤，請重新上傳檔案',
	'import.fromCSV.wrongRow': '行數',
	// 'import.fromCSV.warning.studentExist': '已存在課程中，匯入將覆蓋學生帳號',
	'import.fromCSV.warning.studentExist': '已存在課程中，匯入將更新學生帳號',
	'import.fromCSV.warning.studentExist.unit': '已存在於單位中，匯入將更新學生帳號',
	'import.fromCSV.warning.studentExistInOtherUnit-1': '此',
	'import.fromCSV.warning.studentExistInOtherUnit-2': '帳號已存在於',
	'import.fromCSV.warning.studentExistInOtherUnit-3': '，匯入將覆蓋成學生帳號',
	'import.fromCSV.role.teacher': '老師',
	'import.fromCSV.role.student': '學生',
	'import.studentLimit.passedStudentImportLimit': '總學生人數已超過{{limit}}人限制',

	'userlistview.confirmDelete': '確定要刪除?',
	'userlistview.unset': '尚未建立資料',
	'userlistview.notFound': '找不到資料',
	'userlistview.noparentData': '沒有家長資料',
	'userlistview.notel': '沒有聯絡電話',
	'userlistview.notification': '發送通知',
	'userlistview.getStudentCourse': '獲取學生課程',

	'userlistview.newStudent': '新增學生資料',
	'userlistview.newTeacher': '新增老師資料',
	'userlistview.newBatchTeacher': '新增批次老師資料',
	'userlistview.newBatchCSFCStudent': '新增 CSFC 學生資料',
	'userlistview.newStaff': '新增行政人員資料',
	'userlistview.deleteUser': '刪除使用者？',
	'list.backToStudentList': '回到學生名單列表',
	'list.backToPrevious': '回到上層',
	'import.package.course.student.alert': '請注意！套裝課程的學生一但匯入後超過反悔期，將無法被移除，請再三確認',
};

const test = {
	'test.title': '測驗試卷',
	'test.no': '尚無測驗試卷',
	'test.nopractice': '尚無模擬練習',
	'test.notitle': '尚無測驗題目',
	'test.noresult': '尚無測驗結果',
	'test.nottakeyet': '本試卷尚未測驗',
	'test.alltest': '考試',
	'test.allpractice': '模擬練習',
	'test.tested': '已測驗',
	'test.unchangable': '無法修改',
	'test.add.tip': '課程試卷',
	'test.add.select': '選擇測驗試卷',
	'test.add.new': '新增測驗試卷',

	'test.append.title': '選擇試卷',
	'test.append.lang3': '的試卷',

	'test.append.lang': '課程語言為{{codeType}}, 僅顯示{{codeType}}的試卷',

	'test.nomatch': '沒有符合搜尋條件的試卷',

	'test.classTest': '隨堂測驗',
	'test.num': '測驗人數',
	'test.finished': '完成測驗',
	'test.leftTime': '測驗剩餘時間',

	'test.time': '測試時間',
	'test.infotitle': '試卷題目',
	'test.student': '受測學生',
	'test.fail': '不及格',
	'test.pass': '及格',
	'test.absents': '缺考學生',
	'test.score': '得分',
	'test.corrNum': '答對題數',
	'test.wrongNum': '答錯題數',
	'test.studentScore': '學生成績',
	'test.ended': '測驗已結束',

	'test.solvingTime': '時間',
	'test.videoTime': '影片時間',
	'test.runtime': '執行',

	'test.finishedpeople': '加油！有{{progress}}位同學已完成',
	'test.be1': '搶先成為第一個完成這個練習的同學！',

	'test.correct': '本題作答正確',
	'test.incorrect': '本題作答錯誤',

	'test.confirmRetake': '確定要重新挑戰本題嗎？',
	'test.openFolder': '開啟工作目錄',

	'test.hasSubmitted': '已提交答案',
	'test.tryNextExercise': '繼續挑戰下一題吧！',

	'test.alert.preventCopyPaste': '考試中請不要嘗試複製貼上...',
	'test.finished': '測驗結束',
	'test.alert.testFinished': '測驗已結束！！！！',
	'event.score.sum': '總分',
};

const testEvent = {
	'test.event.type': '活動類型',
	'test.event.course.test': '課堂考試',
	'test.event.mock.test': '模擬練習',
	'test.event.is.start.now': '是否即刻開始',
	'test.event.course': '課程班級',
	'test.event.title': '考試名稱',
	'test.event.duration': '考試時間（分鐘）',
	'test.event.start.date': '開放日期',
	'test.event.start.time': '開放時間',
	'test.event.end.date': '截止日期',
	'test.event.end.time': '截止時間',
	'test.event.test.info': '測驗說明',
	'test.event.test.result': '顯示答題結果',
	'test.event.test.import': '匯入試卷',
	'test.event.placeholder.test.title': '輸入考試活動名稱',
	'test.event.placeholder.duration': '輸入分鐘數',
	'test.event.duration.alert': '時間應介於1~9999分鐘',
	'test.event.not.set.yet': '尚未指定',
	'test.event.step.one.note1':
		'預先排定考試時間，系統將於指定時間自動開放測驗活動；選擇「學生練習」可讓學生重複模擬考試，但不列入學生成績',
	'test.event.step.one.note2': '請由教學資源庫匯入試卷，一場考試僅含一張試卷',
	'test.event.step.one.note3': '可依不同需求，自由設定測資開放(若題目有包含測資)、解答、提示顯示或亂數排序題目等',
	'test.event.settings.mode.title': '模式設定',
	'test.event.settings.assist.title': '試卷輔助資訊',
	'test.event.settings.vd.title': '測資顯示設定',
	'test.event.settings.other.title': '其他設定',

	'test.event.settings.mode.custom': '自訂',
	'test.event.settings.vd.vdResults': '顯示測資結果',
	'test.event.settings.vd.vdContent': '顯示測資內容',

	'test.event.settings.other.random': '亂數排列',
	'test.event.settings.other.copyPaste': '允許複製貼上',
	'test.event.settings.vd.tip': '第一筆測資提示',

	'test.event.settings.vd.length': '測資數：{{num}}',

	'review.test.modal.title': '檢討測資開放設定',
	'test.event.placeholder.releaseVdLength': '開放測資數',
	'review.test.setting.title': '檢討資源設定',
	'review.test.setting.note':
		'您可以由此開放相關資源，學生將能在檢討模式中查看完整解答、提示、測資等，重新練習、提交、修正觀念。',
	'review.test.event.info': '考試活動資訊',
	'test.event.settings.chose.begin.date': '請選擇開放日期',
	'test.event.settings.chose.end.date': '請選擇截止日期',
	'test.event.settings.alert.end.date': '截止日期不能早於開放日期',
	'test.event.settings.alert.end.time': '截止時間不能早於開放時間',
	'test.event.settings.alert.duration': '考試開放期間不能小於考試時間',
	'test.event.review.students.note': '檢視學生成績',
	'test.event.review.students.note.download.csv': '下載測驗狀態.CSV',
	'test.event.show.advanced.setting': '顯示進階選項',
	'test.event.hide.advanced.setting': '隱藏進階選項',
	'test.event.have.not.select.test.yet': '尚未匯入試卷',
	'test.event.settings.assist.vd.type': '測資題型輔助資訊',
	'test.event.settings.assist.none.vd.type': '非測資題型輔助資訊',

	'test.detail.page.reset.student.exercise.status': '重置學生作答記錄',
	'test.detail.page.reset.student.exercise.status.note':
		'當您更改教學資源庫多元題型的題目內容，並且同步到課程與試卷時，已作答過的學生紀錄會與更新過的題目內容不符。\n重置學生作答記錄會將學生的紀錄與分數情除，您確定要重置這場考試該題的所有學生作答記錄嗎？',
	'reset.studentStatus.success': '重置成功 ',
	'reset.studentStatus.fail': '重置失敗 ',
	'csfc.test.event': 'CSFC 考試場次',
	'csfc.test.event.not.add.yet': '尚未建立場次',
	'csfc.test.event.create.modal.title': '建立 CSFC 考試場次',
	'csfc.test.event.edit.modal.title': '編輯 CSFC 考試場次',
	'csfc.test.event.delete.modal': '刪除 CSFC 考試場次',
	'csfc.test.event.delete.modal.body': '確認是否刪除 CSFC 考試場次',
	'csfc.test.event.delete.modal.toast.success': '成功刪除 CSFC 考試場次',
	'csfc.test.event.preview.modal.title': '預覽 CSFC 考試場次',
	'csfc.test.event.form.subject': '考試場次項目',
	'csfc.test.event.form.level': '考試場次等級',
	'csfc.test.event.form.concept.test.duration': '觀念題考試時間（分鐘）',
	'csfc.test.event.form.implementation.test.duration': '實作題考試時間（分鐘）',
	'csfc.test.event.form.implementation.earlier.than.concept.alert': '實作題開始時間應大於觀念題結束時間',
	'csfc.test.event.form.can.not.set.past.test.alert': '不可設定過去時間',
	'csfc.test.event.form.note.1': '預先排定考試時間，系統將於指定時間自動開放測驗活動',
	'csfc.test.event.form.note.2': '請由教學資源庫匯入試卷，一場考試僅含一張試卷',
	'csfc.test.event.form.concept.note': '第一場考試 (根據 CSFC 簡章，請設置觀念題)',
	'csfc.test.event.form.implementation.note': '第二場考試 (根據 CSFC 簡章，請設置實作題；初級考試不需設置第二場考試)',
	'csfc.delete.course': '刪除 CSFC 課程',
	'csfc.delete.course.confirmation.body': '確認是否刪除 CSFC 課程',
	'csfc.delete.course.and.event.toast.success': '成功刪除 CSFC 考試場次與課程',
	'csfc.delete.course.alert.user.already.exist': '已匯入學生無法刪除 CSFC 課程',
	'csfc.delete.event.alert.user.already.exist': '已匯入學生無法刪除 CSFC 場次',
	'csfc.edit.event.alert.user.already.exist': '已匯入學生無法編輯 CSFC 場次',
};

const score = {
	'score.title': '學習成績',
	'score.avg': '平均',
	'score.sum': '合計',
	'score.none': '尚無評量資料',
	'score.download': '下載成績單(CSV格式)',
};

const student = {
	'student.learningreport': '學習報告',
	'student.none': '您尚未匯入學生',
	'student.placeholder': '輸入學生名字/電子信箱',
	'student.placeholder2': '輸入帳號或姓名關鍵字',
	'student.sendreport': '寄送成績報告',
	'student.sendreport.confirm': '確定要寄送成績報告? 所有成績都填寫好了嗎?',
	'student.downloadReport': '下載成績報告',
	'student.fillReport': '填寫成績報告',
	'student.advanced': '高年級',
	'student.removeConfirmTitle': '移除學生',
	'student.confirmation1': '確定要移除學生:',
	'student.reAddStudent.confirm.title': '重新加回',
	'student.reAddStudent.confirm.content': '確定要重新加回學生?',
	'student.confirmation2': '再次確認！',
	'student.lastShown': '上次出現:',
	'student.reportSendTime': '成績於{{time}}寄出',
	'student.sta': '統計數據',
	'student.finishQs': '完成題目數量',
	'student.exrciseTime': '本週課後練習時間',
	'student.avgTipUsage': '平均使用提示數',
	'student.question': '本週問問題數量',
	'student.info': '學生基本資料',
	'student.sex': '性別',
	'student.male': '男',
	'student.female': '女',
	'student.unknown': '尚未填寫',
	'student.school': '就讀學校',
	'student.grade': '就讀年級',
	'student.summary': '學習摘要',
	'student.currentFeedback': '最近的老師評語',
	'student.editFeedback': '編輯評語',
	'student.sendCareMsg': '寄送關懷信',
	'student.courseProgress': '課程進度',
	'student.courseTime': '此處顯示課程時間',
	'student.futureandthisweekCourse': '本週及尚未開始的單元',
	'student.showCourseNameandProgress': '此處顯示課程單元名稱以及完成進度',
	'student.unitEnd': '已經結束的單元',
	'student.allFeedback': '所有的評語',
	'student.courseinfo': '課程資訊',
	'student.nocourseinfo': '沒有課程資訊',
	'student.timeuse': '使用時間',
	'student.teacherFeedBack': '教師評語',
	'student.basicinfo': '基本資料',

	'student.sendCareMsgTitle': '寄送關懷信給',
	'student.care.emal': '請輸入學生E-mail',
	'student.care.emalTitle': '學生E-mail',
	'student.care.parentemal': '請輸入家長E-mail',
	'parent.care.emalTitle': '家長E-mail',
	'studentMsgTitle': '學生Email標題',
	'parentMsgTitle': '家長Email標題',
	'studentMsgTitle.placeholder': '請輸入學生Email標題',
	'parentMsgTitle.placeholder': '請輸入家長Email標題',
	'asktimes.placeholder': '請輸入問問題次數',
	'asktimes': '問問題次數',

	'tutorialvids': '教學影片',
	'tutorialvids.fin': '影片完成數量',
	'tutorialvids.total': '影片總數量',
	'exercise.fin': '練習題完成數量',
	'exercise.correct': '練習題答對數量',
	'exercise.total': '練習題總數量',

	'hw.fin': '作業完成數量',
	'hw.correct': '作業答對數量',
	'hw.total': '作業總數量',

	'studentcomments': '學生評語',
	'parentComments': '家長評語',

	'student.continue.course': '繼續上課',
};

const exercise = {
	'exercise.exercise': '課堂練習',
	'exercise.homework': '課後作業',
	'exercise.status': '此練習題開放功能與狀態',
	'exercise.studentSummary': '學生摘要',
	'exercise.ans': '解題答案',
	'exercise.student': '線上學生',
	'exercise.mainpoint': '重點觀察',
	'exercise.help': '需要幫助',
	'exercise.idlestate': '閒置狀態',
	'exercise.finished': '完成練習',
	'exercise.time': '練習時間',
	'exercise.simcheck': '相似度檢查',
	'exercise.simcheckTitle': '程式碼相似度',
	'exercise.finishRate': '完成率',
	'exercise.readTips': '已查看的拆解提示',
	'exercise.realtimecode': '即時的程式碼',
	'exercise.codeLastTime': '上一次執行的程式碼',
	'exercise.codeLanguage': '使用語言',
	'exercise.helperSettings': '輔助功能',
	'exercise.openCodeHelper': '啟用程式小助手',
	'exercise.editorSettings': '編輯器設定',
	'exercise.editorLanguage': '編輯器語言',
	'exercise.fontSize': '字體大小',
	'exercise.notRunyet': '尚未執行過',
	'exercise.checkDetails': '點我看詳細內容',
	'exercise.progress': '學生進度',
	'exercise.resource': '資源下載',
	'exercise.result': '執行結果',
	'exercise.exampleformat': '範例格式',
	'exercise.input': '輸入',
	'exercise.output': '輸出',
	'exercise.newFn': '*新功能*',
	'exercise.newFn1': '1. 執行: 單純執行程式碼，不進行測資驗證。',
	'exercise.newFn2': '2. 提交: 進行測資驗證。',
	'exercise.gotit': '知道了',
	'exercise.closeTip': '下次不需提醒',
	'exercise.fillinMode': '確定要切換填空題?',
	'exercise.errorExist': '錯誤還沒有修正，確定還是要提交程式碼嗎？',
	'exercise.run': '執行',
	'exercise.submit': '提交',
	'exercise.noVds': '無測資',
	'exercise.testfail': '測試失敗',
	'exercise.runfail': '執行失敗',
	'exercise.pass': '測試通過',
	'exercise.stop': '停止',
	'exercise.testinput': '測資',
	'exercise.tips': '提示',
	'exercise.answers': '答案',

	'exercise.reset': '重置',
	'exercise.refresh': '重新整理',
	'exercise.needhelp': '需要幫助',
	'exercise.idle': '閒置',
	'exercise.student-exercise': '學生\\習題',

	'exercise.repeatExercise': '再做一次',
	'exercise.returnExercise': '返回作答',
	'exercise.nextExercise': '下一題',
	'exercise.understandMore': '點我查看',
	'exercise.whichTestInput1': '第',
	'exercise.whichTestInput2': '項測資',
	'exercise.whichTestInput': '第{{number}}項測資',
	'exercise.testInputPassed': '通過',
	'exercise.testInputFailed': '錯誤',
	'exercise.testInput.resultMsgSuccess': '提交通過，太棒了！',
	'exercise.testInput.resultMsgFail': '提交錯誤，快檢查！',
	'exercise.testInput.details': '查看測資詳情',
	'exercise.testInput.backToDetails': '回到測資詳情',
	'exercise.testInput.yourOutput': '您的output',
	'exercise.testInput.correctOutput': '正確output',
	'exercise.testInput.viewDetails': '查看說明',
	'exercise.testInput.previousPage': '回到上頁',

	'exercise.result.success': '太棒了！',
	'exercise.result.successMsg': '你能完成這題，表示前面的觀念都有學到\n\n繼續挑戰下一題吧！',
	'exercise.result.fail': '錯誤！',
	'exercise.result.failMsg': '誒...執行程式結果，看起來有錯誤呀\n\n快去檢查一下吧',

	'exercise.result.lintError': '獨眼怪提示尚未修正',
	'exercise.result.lintErrorMsg': '獨眼怪提示尚未修正，請先修正提示錯誤，再次提交哦～',

	'exercise.playground.enterCode': '在此輸入Code',
	'exercise.playground.outputResult': '輸出結果',

	'exercise.executionFinished': '執行完畢',
	'exercise.executing': '執行中',
	'exercise.submitting': '提交中',
	'exercise.executingInput': '請輸入資料',

	'exercise.addExercise.selectType': '題型選擇',
	'exercise.addExercise.selectLanguage': '語言類型',
	'exercise.addExercise.title': '習題標題',
	'exercise.addExercise.tags': '自訂標籤(選填)',

	'exercise.tips.noTipsOrUnreleased': '暫無提示或尚未開放',
	'exercise.tips.noAnswersOrUnreleased': '暫無詳解或尚未開放',

	'exercise.tips.title': '提示清單',
	'exercise.answer.title': '習題答案',
	'exercise.answers.title': '解答列表',
	'exercise.materials.title': '資源下載',

	'exercise.testInput.msg.success': '厲害唷，測資通過了！點擊下方按鈕進入下一題吧！',
	'exercise.testInput.msg.fail': '你提交的答案有部分不正確，檢查程式碼並重新修正吧！',
	'exercise.testInput.msg.fail.nonFreecode': '你提交的答案不正確，快點回去檢查看看有什麼地方寫錯了吧！',
	'exercise.testInput.msg.success.nonFreecode': '太棒了！答題正確！點擊下方按鈕進入下一題吧！',
	'exercise.testInput.msg.success.step': '厲害唷，測資通過了！點擊下方按鈕進入下一步吧！',
	'exercise.testInput.msg.success.nonFreecode.step': '太棒了！答題正確！點擊下方按鈕進入下一步吧！',
	'exercise.runInput.msg.fail': '你的程式碼有寫錯的地方！查看提示，再檢查看看哪邊寫錯吧！',
	'exercise.config.fontSize': '字級',
	'exercise.config.editorSetting': '編譯器設定',
	'exercise.config.otherSetting': '其它設定',
	'exercise.config.otherSetting.error.hint': '獨眼怪自動偵錯',

	'exercise.systemLimit.overTimeLimit': '系統執行時限倒數',

	'exercise.killReason.overTimeLimit': '執行時間超時！請確認輸入測資或修正演算法。',
	'exercise.killReason.overSystemCPU': '系統忙碌中，自動終止執行程式，請稍後再試。',
	'exercise.killReason.overProcessLimitTime': '執行時間超過測資限制！請改善您的演算法。',
	'exercise.killReason.overCpuUsage': '執行佔用 CPU 超過系統限制！請改善您的演算法。',
};

const question = {
	'student.question.title': '我的問題集',
	'student.question.hint': '點擊左邊問題，查看問題與回覆',
	'student.question.placeholder': '搜尋學生問題',
	'student.question.fin': '是否完成此題',
	'student.question.allquestions': '練習問題總覽',

	'student.createQuestionError': '發送問題錯誤',
	'student.createQuestion': '建立問題',
	'student.enterQuestionPlaceholder': '請輸入標題或內容',
	'studnet.questionContent': '問題內容',
	'studnet.questionContentPlaceholder': '請輸入問題內容...',

	'student.questionTab.unanswered': '待回答',
	'student.questionTab.allCourses': '所有課程',
	'student.questionTab.myCourses': '我的課程',

	'student.question.noQuestions': '沒有任何問題',
	'student.question.untagged': '未標註',
	'student.question.tagged': '已標註',
	'student.question.askQuestion': '我要問問題',
	'student.question.tagAddSuccess': '建立 Tag: {{tag}} 成功！',
	'student.question.alert.removeTag': '確定要移除 {{tag}} ?',
	'student.question.alert.tagRemoved': '{{tag}} 已移除!',
	'student.question.createNewTag': '建立新標籤',
	'student.question.tagCategory': '分類標籤',
	'student.question.questionTitle': '問題標題：',
	'student.question.questionContent': '問題內容：',

	'student.question.question': '問題',
	'student.question.NewQuestion': '新問題',
	'student.question.Constructing': '正在送出問題',
	'student.question.noTitle': '請輸入標題',
	'student.question.noContent': '請輸入內容',
};

const feedback = {
	'feedback.title': '回報問題',
	'feedback.subject': '回報問題',
	'feedback.subjectPlaceholder': '填寫主旨',
	'feedback.description': '備註',
	'feedback.descriptionPlaceholder': '簡單說明情況',
	'feedback.screenshot': '當前畫面擷取',
	'feedback.withScreenshot': '連同截圖一併送出',
	'feedback.msg': '謝謝您提供的問題與反饋',
	'feedback.enterDescription': '請輸入問題描述',
	'feedback.screenshotError': '螢幕截圖發生問題, 請聯絡CodingBar',
	'feedback.submit': '傳送',

	'feedback.additional.agree1': '一經傳送本報告，即代表您同意 CodingBar 在遵循',
	'feedback.additional.agree2': '下，有權存取本問題所需的相關資料。',
	'feedback.additional.privatePolicy': '隱私權政策',
	'feedback.issue.unable.to.submit.code': '無法執行/提交程式碼',
	'feedback.issue.loading.no.response': '畫面載入中，無回應',
	'feedback.issue.unable.to.open.courses.units.topics': '無法打開課程/單元/題目',
	'feedback.issue.others': '其它',
	'feedback.issue.default': '請選擇可能的問題',
};
const addTA = {
	'addTA.new': '新增助教',
	'addTA.placeholder': '請輸入老師姓名或信箱',
	'addTA.alert': '助教已加入老師清單',
};

const courseManage = {
	'courseManage.langAlert': '請選擇程式語言類型',
	'courseManage.mainlangAlert': '請選擇主要使用語言',
	'courseManage.diffAlert': '請選擇課程難易度',
	'courseManage.teacherAlert': '請選擇老師',
	'courseManage.codeAlert': '請輸入課程代碼',
	'courseManage.nameAlert': '請輸入課程名稱',
	'courseManage.AmountAlert': '請輸入課程堂數',
	'courseManage.descAlert': '請輸入課程簡介',
	'courseManage.dateAlert': '請選擇上課日以及時間',
	'courseManage.edit/newSuccess': '課程 {{courseName}} 已經新增/修改成功!',
	'courseManege.addFail': '建立課程失敗! {{err}}',
	'courseManege.editFail': '修改課程失敗!',
	'courseManage.editSuc': '課程 {{courseName}} 已經修改成功!',
	'courseManage.newSuc': '課程建立成功',
	'courseManage.creating.course': '課程建立中...',
	'courseManage.duplicating.course': '課程複製中...',
	'courseManage.deleting.course': '刪除課程中...',

	'courseManage.lesson.time.setting': '時程設定',
	'courseManage.no.weekday': '尚未選擇星期',
	'courseManage.lesson.length.not.less.than': '不可少於原本單元數',
	'courseManage.release.lesson.by.schedule.notice':
		'尚未設定單元起迄時間，若您勾選「依上課時間開放單元」，學生將無法進入單元!',
};

const system = {
	'previousChpt': '上一章',
	'nextChpt': '下一章',

	'multipleLogin': '噢！看來你在別的地方登入帳號了',
	'multipleLogin.hint1':
		'為了確保你的資料安全與完整性，CodingBar只能允許一個帳號在一個位置登入；若你在第二個瀏覽器或其他裝置登入時，目前的視窗將被系統自動登出。',
	'multipleLogin.hint2': '請確認你的登入位置與帳號密碼安全，若有需要提供協助的地方，請與我們聯繫。',
	'apiError': '系統錯誤, 請稍待片刻',
};

const loginPage = {
	'login.welcomeback': '歡迎回來！',
	'login.hint': '請輸入帳號和密碼，或點選連結帳戶快速登入。',
	'login.remember': '記住我的登入資訊',
	'login.forget': '忘記密碼？',
	'login.fail': '無法登入，',

	'login.failToContact': '若無法登入，請點此聯絡客服',
	'login': '登入',

	'login.teacherTrial': '國高中老師試用註冊',
	'login.emailPlaceholder': '請輸入電子郵件',
	'login.passwordPlaceholder': '請輸入密碼', //NOSONAR
	'login.failed': '登入失敗',
	'login.wrongEmail': '信箱格式錯誤, 請再次檢查您的帳號',
	'login.wrongPassword': '密碼錯誤, 請確認您輸入的密碼',
	'login.wrongLogin': '帳號或密碼錯誤，請重新輸入',
	'login.useGoogle': '請由下方 「使用Google登入」 進行登入',
	'login.tooManyRequests': '您已多次登入失敗，目前帳號已鎖定，請稍後再試',
	'login.unRegisteredEmail': '此信箱帳號尚未註冊過',
	'login.networkRequestFailed': '網路不穩定，登入連線過久，請再試一次',
	'login.contactDev': '請聯絡 contact@airabbi.com 取得協助',
	'login.signInWithgoogle': '透過 Google 登入',
	'login.selfParent': '我是家長',
	'login.selfNotParent': '我是學生/老師',
	'login.student.carouseltext1': '提升競爭力，最佳的選擇',
	'login.student.carouseltext2': '課程多元豐富，內容簡單易懂',
	'login.student.carouseltext3': '線上解題問答，學習不孤單，解題不卡關',
	'signup.title': '全面開放，國高中老師註冊',
	'signup.hint1': '立即用電子郵件，註冊 CodingBar 帳號。',
	'signup.hint2':
		'CodingBar 現在歡迎全國國高中老師試用 CodingBar 程式教育學習系統。只要填上學校相關資訊，就可以免費試用！',
	'email.notnull': '電子郵件欄位不能為空值',
	'signup.setpw': '設定密碼',
	'password.notnull': '密碼欄位不能為空值', //NOSONAR
	'signup.confirmPw': '確認密碼',
	'signup.enterPWtwice': '請再次輸入密碼',
	'signup.pwrule': '請輸入密碼，至少6位數，包含英數',
	'signup.pwrule2': '密碼必須同時含有英文字母以及數字',
	'signup.len': '密碼長度必須大於6位元',
	'signup.twicediff': '與第一次輸入之密碼不相同，請再確認一次',
	'signup.agree': '點擊繼續，代表您同意',
	'signup.agree2': '使用者條款與隱私權',
	'signup.policy': '政策。',
	'signup.term': '使用者條款',
	'signup.privatePolicy': '隱私權政策',

	'signup.enterNewPW': '請輸入新密碼',
	'signup.enterNewPWtwice': '請再次輸入新密碼',

	'signup.setPWsets': '請設定一組登入密碼',
	'signup.pwrule2': '密碼至少8位數，包含英文字母及數字。',

	'login.resetPassword.title': '重設密碼',
	'login.resetPassword.content': '請輸入您的 Email 帳號，我們將發送密碼重設信給您。',
	'login.resetPassword.placeholder': '請輸入您的電子信箱',
	'login.resetPassword.sendEmail': '發送密碼重設信件',
	'login.resetPassword.alert.unRegisteredEmail': '此帳號尚未註冊過，請輸入正確的email信箱',
	'login.resetPassword.alert.wrongEmail': '格式錯誤，請輸入正確的email信箱',
	'login.resetPassword.resetTitle': '重設信件已寄送',
	'login.resetPassword.resetContent': '重設密碼已寄送至您的信箱，請至信箱查看，並依照指引重新設定密碼。',
	'login.resetPassword.gotit': '確認',
	'login.apply.trial': '申請試用',
	'login.apply.trial.title': '申請教育試用版',
	'login.apply.trial.note': '使用 .edu 網域信箱，並填寫基本資料，即可開通 14 天免費試用體驗！',
	'login.apply.trial.unit': '您的學校單位',
	'login.apply.trial.unit.note': '請填入完整名稱，如「台北市立第一女子高級中學」',
	'login.apply.trial.name': '您的姓名',
	'login.apply.trial.phone': '您的手機號碼(純數字)',
	'login.apply.trial.email': '您的信箱',
	'login.apply.trial.email.note': '請用 .edu 信箱，作為系統平台的教師帳號',
	'login.apply.trial.alert.phone': '您的電話格式錯誤',
	'login.apply.trial.alert.email': '您的信箱不是 .edu 網域，請重新填寫',
	'login.apply.trial.alert.different.unit': '帳號已存在於其他單位!',
	'login.apply.trial.alert.nothing.to.add': '您已申請過所有的試用課程',
};

const loginEvent = {
	'loginExam1': 'APCS免費模擬考',
	'loginExam2': '立即註冊！',

	'signup.globalExam': `我們將陸續推出完整的免費檢測平台，讓喜歡挑戰 APCS 或 ZeroJudge 的你，有一個簡單上手、友善使用的 compiler 環境。\n
	誠摯邀請您在使用過程中，不吝與我們分享您的建議與心得，讓這個平台可以更完善！\n
	*測試版僅提供 Google 第三方登入，成功註冊後可由 CodingBar 官網直接登入平台\n\n`,
	'signup.incognito': '*請勿使用無痕模式登入',

	'loginFreeTrial1': '茶水間的程式妙招',
	'loginFreeTrial2': '立即註冊，免費體驗！',

	'signup.freeTrial': `動手寫程式，完成人生大小事！透過 google 帳號即可免費登入、體驗各種程式小技巧。

	體驗版僅提供 Google 第三方登入，成功註冊後可由CodingBar 官網直接登入平台囉！\n\n`,
};

const freeTrialPage = {
	'freeTrialPage.NPBN.loadingProgress': '請稍候，正在為您開通帳號中...',

	'freeTrialPage.NPBN.createAccountFail.title': '開通帳號失敗！此帳號已被建立',
	'freeTrialPage.NPBN.createAccountFail.content':
		'此帳號偵測已建立為 {{userInDBRole}} 角色，\n請回到網頁輸入其他信箱，始得開通帳號。',
	'freeTrialPage.NPBN.createAccountFail.btn': '回到官網',

	'freeTrialPage.NPBN.errorCrash': '哪裡出錯了！請洽客服人員聯繫處理',
	'freeTrialPage.NPBN.contactUs.btn': '聯繫客服',

	'freeTrialPage.NPBN.sendEmailTitle': 'CodingBar 免費體驗課程開通',

	'freeTrialPage.NPBN.alreadyDone.title': '此帳號已申請過試用課程',
	'freeTrialPage.NPBN.alreadyDone.content':
		'此帳號偵測已申請過免費體驗課程，\n請直接點選下方連結登入平台後體驗，\n如體驗時間已結束，請於官網購買課程。',

	'freeTrialPage.NPBN.done.title': '開通帳號完成！',
	'freeTrialPage.NPBN.done.content':
		'您的體驗課程已開通，請點選下方連結後進行課程體驗！\n系統將於 14 天後結束體驗，如需繼續使用請於官網購買課程',
	'freeTrialPage.NPBN.redirect2App': '前往 CodingBar 平台',
};

const parentloginPage = {
	'login.parent': '家長登入',
	'login.bannertext': '科技教育專業品牌',
	'login.parent.carouseltext1': '全方面分析，學習狀況一手掌握',
	'login.parent.carouseltext2': '會員專屬，搶先獲得課程優惠',
	'login.parent.carouseltext3': '多元課程，陪孩子一起規劃學習路徑',
	'login.parent.failed': '此處資訊與報名表提供的資訊不符，請再試一次',

	'login.parent.welcomeText':
		'請輸入您為孩子報名課程時所留下的聯絡電話，登入平台查看孩子學習的詳細數據，讓我們一起見證孩子的成長吧！',
};

const parentHomePage = {
	'parent.leftside.title': '孩子的學習摘要',
	'parent.lessontext': '當前課程',
	'parent.courseprocess': '課程進度',
	'parent.learntime': '學習時間',
	'parent.exercise': '課堂練習',
	'parent.homework': '作業完成',
	'parent.exerciseamount': '習題',
	'parent.view': '查看',
	'parent.fullreport': '的完整報告',
	'parent.advertisement.title1': '程式教育',
	'parent.advertisement.title2': '領航專家',
	'parent.advertisement.slogan': 'Let’s learn to  </code>',
};

const parentFullReport = {
	'parent.tablab.performance': '課程表現',

	'parent.tablab.courseprogress': '課程進度',
	'parent.title.fullReport': '整體學習概況',
	'parent.title.detailData': '詳細數據分析',

	'parent.lessonContent.innerText': '本次上課內容',
	'parent.lessonContent.homework': '回家作業',
	'parent.lessonContent.homeworkComplete': '回家作業完成度',
	'parent.lessonContent.teacherComment': '講師的話',

	'parent.lessonContent.completeRate': '，共完成',

	'parent.lessonContent.viewAllContent': '查看完整資訊',
};

const csfcLoginPage = {
	'login.csfc': '進入檢測',
	'login.csfc.bannertext': 'CSFC 資訊科學能力檢定',
	'login.mail': '電子信箱',
	// 'login.mail.placeholder': '請輸入電子信箱',
	'login.csfc.failed': '報名資料中查無相符email，請再試一次',
	'login.csfc.welcomeText': '請輸入報考檢測時提供的電子信箱並點擊進入，以進行身份驗證。',
	'enter': '進入',
};

const staff = {
	'teacherTrialManage': '師資試用管理',
	'courseMaintenance': '課程維護',
	'staffMaintenance': '行政人員維護',
	'formManagement': '報名表管理',
	'couponManage': '折扣碼管理',
	'allSignupManage': '所有報名表管理',
	'signupInfoManageV2_1': 'v2.1報名表管理',
	'signupInfoDownloadV2_1': 'v2.1報名表下載',
	'signupInfoDownloadOld': '舊報名表下載',
	'signupInfoDownloadAncient': '很久以前報名表',
	'permissionManage': '權限管理',
	'systemTutorial': '系統教學文件',

	'staff.user': '使用者',
	'staff.cumulativeusage': '累積使用者人數',
	'staff.distribute': '使用者分佈',
	'staff.schoolStat': '學校單位統計',
	'staff.dailyUser': '每日上線人數',
	'staff.timeRangeUser': '時段上線人數(依區間)',
	'staff.timeRangeUserGroupByUnit': '時段上線人數(依單位)',

	'staff.system': '系統',
	'staff.nasSystem': 'Nas系統使用',
	'staff.kernelStatus': 'Kernel狀態',
	'staff.systemFlow': '系統使用流量',
	'staff.coursetime': '課程時間',
	'staff.courseId': '課程 ID',
	'staff.systemUsage': '系統 Usage',

	'staff.history': '歷史紀錄',
	'staff.signupStat': '課程報名統計',
	'staff.codesubmit': '每小時 Code Submit 次數',
	'staff.codesubmitStat': 'Code Submit統計',
	'staff.vidwatch': '影片觀看紀錄',

	'previousYear': '上一年',
	'nextYear': '下一年',

	'staff.totalUser': '總人數',
	'staff.openclass': '開課班級',
	'staff.exerciseNum': '習題數量',

	'staff.sharedCourse': '共同課程',
	'staff.settingPermissions': '設定權限',

	'staff.num': '編號',
	'staff.course': '教授課程',
	'staff.applytime': '申請時間',
	'staff.accountStatus': '帳號啟用狀態',

	'staff.teachernum': '老師人數',
	'staff.studentnum': '學生人數',
	'staff.coursenum': '課程數',

	'staff.search': '關鍵字搜尋',
	'staff.manipulate': '操作',
	'staff.chooseSchool': '請選擇學校單位',
	'staff.loadtime': '載入時間',

	'staff.confirmDelete': '確定要刪除',
	'staff.confirmDelete2': '再次確認! 確定要刪除',

	'staff.previousweek': '上週',
	'staff.nextweek': '下週',

	'staff.day': '天',
	'staff.week': '周',

	'staff.previousDay': '上一天',
	'staff.nextDay': '下一天',

	'staff.signupinfos': '報名資料統計',
	'staff.timeWrong': '時間有誤!',

	'staff.assign': '指定',
	'staff.month': '月',

	'staff.previousMonth': '上一個月',
	'staff.nextMonth': '下一個月',

	'staff.sincePlaceholder': '請輸入開始日期',
	'staff.toPlaceholder': '請輸入結束日期',
	'staff.since': '從',
	'staff.to': '到',

	'staff.deleteForm': '確定要刪除報名表',
	'staff.deleteForm2': '再次確認! 確定要刪除報名表',
	'staff.delFormSuc': '成功刪除報名表!!',
	'staff.formManage': '報名表管理',
	'staff.newForm': '新增報名表',
	'staff.signuplist': '課程報名列表',
	'staff.signupForm': '報名表',
	'staff.infoNum': '筆資料',
	'staff.download': '下載 CSV 檔案',
	'staff.update': '更新欄位',
	'staff.nodata': '沒有資料',
	'staff.formAlert': '請選擇課程報名表單!',

	'staff.newDoc': '新增文件',
	'staff.doc.proceed': '上一次修改尚未儲存！ \n確定要繼續?',
	'staff.docedit': '文件編輯',
	'staff.incorrectDoc': '不正確的文件',
	'staff.createChpt': '建立章節',
	'staff.delChapter': '確定要刪除此章節',
	'staff.enterTitle': '請輸入標題',
	'staff.insertImg': '插入圖片',
	'staff.sourceCode': '開啟原始碼',
	'staff.clodeCode': '關閉原始碼',
	'staff.courseSetting': '課程設定',
	'staff.courseChallenge': '課後挑戰',
	'staff.challengeA': '挑戰 A 單元',
	'staff.challengeB': '挑戰 B 單元',
	'staff.challengeC': '挑戰 C 單元',
	'staff.copyFormSuc': '報名表複製成功',
	'staff.copyForm': '複製表單',
	'staff.deleteForm': '刪除表單',
	'staff.form.basic': '基本資訊',
	'staff.form.course': '課程選項',
	'staff.form.studentSetting': '學生資訊設定',
	'staff.form.payment': '付費設定',
	'staff.form.mail': '郵件設定',
	'staff.form.insertGA': '插入GA-Code',
	'staff.form.insertFA': '插入FA-Code',
	'staff.form.workShop': '工作坊',
	'staff.form.camp': '營隊',
	'staff.form.adult': '成人活動',
	'staff.form.nomal': '常態課程',
	'staff.form.teacher': '老師徵選',
	'staff.form.teacher2': '老師徵選第二階段',
	'staff.form.high': '高中',
	'staff.form.eleJu': '國中小',
	'staff.form.adult': '成人',
	'staff.activity': '活動類型',
	'staff.attendGroup': '活動參加族群',
	'staff.formId': '報名表ID',
	'staff.placeholder': '請輸入報名表ID',
	'staff.link': '表單連結',
	'staff.form.title': '報名表標題',
	'staff.form.titlePlaceholder': '請輸入報名表標題',
	'staff.formSlogan': '報名表標語',
	'staff.formSloganPlaceholder': '請輸入報名表標語',
	'staff.foreword': '前言',
	'staff.policyTitle': '條款標題',
	'staff.policyTitlePlaceholder': '請輸入條款標題',
	'staff.policyLink': '條款連結',
	'staff.policyLinkPlaceholder': '請輸入條款連結',
	'staff.signupFin': '報名完成頁面內容',
	'staff.notInList': '課程名稱不存在於課程選項中，請檢查空格或是排版',
	'staff.courseTime': '輸入課程場次',
	'staff.getCourseFail': '取得課程失敗',
	'staff.earlybirdDue': '早鳥截止日期',
	'staff.earlybirdDuePlaceholder': '輸入早鳥截止日期',
	'staff.courseSelection': '課程選項標題',
	'staff.courseSelectionPlaceholder': '輸入客製課程輸入標題',
	'staff.courseOptions': '課程選項',
	'staff.newCourseOption': '插入新的課程選項',
	'staff.notInList2': '輸入的梯次名稱不在營隊類別裏喔！！請檢查全型半形或是不小心的空格',
	'staff.courseOptionsOpen': '啟用課程選項',
	'staff.campCategory': '營隊類別',
	'staff.campCategoryPlaceholder': '插入新的營隊類別選項',
	'staff.batch': '梯次',
	'staff.batchPlaceholder': '插入課程並管理課程梯次',
	'staff.groupornot': '是否為團報課程',
	'staff.studentCareer': '職業類別選項',
	'staff.studentCareerPlaceholder': '插入新的職業類別選項',
	'staff.ageSelection': '年齡選項',
	'staff.ageSelectionPlaceholder': '插入新的年齡',
	'staff.gradeSelection': '年級選項',
	'staff.gradeSelectionPlaceholder': '插入新的年級',
	'staff.infoSource': '資訊來源選項',
	'staff.infoSourcePlaceholder': '插入新的資訊來源選項',
	'staff.alumnusDiscount': '啟用舊生優惠',
	'staff.alumnusPrice': '舊生優惠價錢',
	'staff.alumnusPricePlaceholder': '請輸入舊生優惠價錢',
	'staff.combinePeriod': '合併期數(1期以上才會啟用)',
	'staff.combinePeriodPlaceholder': '請輸入合併期數',
	'staff.combinePrice': '合併價錢',
	'staff.combinePricePlaceholder': '請輸入合併價錢',
	'staff.alumnusCombinePrice': '舊生合併價錢',
	'staff.alumnusCombinePricePlaceholder': '請輸入舊生合併價錢',
	'staff.pricePlan1': '方案ㄧ (原價)',
	'staff.pricePlan2': '方案二 (九折)',
	'staff.pricePlan3': '方案三 (七折)',
	'staff.earlybirdPrice': '早鳥付款金額',
	'staff.earlybirdPricePlaceholder': '輸入早鳥付款金額',
	'staff.normalPrice': '正常價付款金額',
	'staff.normalPricePlaceholder': '輸入早鳥付款金額',
	'staff.pricePlanTitle': '請輸入方案名稱及價格',
	'staff.pricePlanTitlePlaceholder': '請輸入方案標題',
	'staff.pricePlanPricePlaceholder': '價格',
	'staff.activatePricePlan': '啟用自訂價格方案',
	'staff.pricePlanHint': '*啟用後，上述兩種付款設定不需再輸入*',
	'staff.signupConfirmMail': '報名確認信',
	'staff.paymentConfirmMail': '繳費確認信',
	'staff.addtoList': '加入列表',
	'staff.columnNum': '個欄位',
	'staff.removeAll': '全部移除',
	'staff.addAll': '全部加入',
	'staff.lastUpdate': '上次更新',
	'staff.order': '序號',
	'staff.school': '學校',
	'staff.studentId': '學生身分證號',
	'staff.studentName': '學生姓名',
	'staff.studentBD': '學生生日',
	'staff.addr': '地址',
	'staff.age': '年齡層',
	'staff.parentTel': '家長電話',
	'staff.studentPhone': '學生電話',
	'staff.note': 'Note',
	'staff.infoSource': '消息來源',
	'staff.groupSign': '團報',
	'staff.isEarly': '早鳥',
	'staff.payNum': '繳費編號',
	'staff.paid': '已繳費',
	'staff.signupDate': '報名日期',
	'staff.paymentAmount': '報名金額',
	'staff.transaction': '交易序號',
	'staff.transactionAmount': '交易金額',
	'staff.transactionTime': '交易時間',
	'staff.paymentTimes': '付款次數',
	'staff.paymentTime': '付款時間',
	'staff.paymentMethod': '付款方式',
	'staff.combination': '合併購買',
	'staff.currentStudent': '是否為舊生',
	'staff.createuser': '建立 {{username}} 使用者帳號',
	'staff.createAccount': '創建帳號',

	//
	'staff.liveCourseDashboard': 'Live課程與人數',
	'staff.isLive': '實際上線',
	'staff.shouldBeLive': '應上線',
	'staff.liveStudentCount': '學生上線人數',
	'staff.liveTeacherCount': '講師上線人數',
	'staff.liveUnitCount': '單位上線人數',
	'staff.liveCourseCount': 'Live課程數',
	'staff.liveUnitsCount': '使用單位數',
	'staff.liveCourse': 'Live課程',

	'staff.permissionsEdit.isRegistrationAllowed': '報名表 管理',
	'staff.permissionsEdit.isUnitDomainAllowed': '單位網域 管理',
	'staff.permissionsEdit.isBetaUnitAllowed': 'Beta單位 管理',
	'staff.permissionsEdit.isCRMAllowed': 'CRM 管理',
	'staff.permissionsEdit.isDashboardAllowed': '系統儀表板',
	'staff.permissionsEdit.isCouponAllowed': '折扣碼 管理',
	'staff.permissionsEdit.isAnnounceAllowed': '公告 管理',
	'staff.permissionsEdit.isPackageCourseAllowed': '套裝課程 管理',
	'staff.permissionsEdit.isTranslationAllowed': '翻譯課程 管理',
	'staff.permissionsEdit.isSyncSheetAllowed': '資料同步 管理',
	'staff.permissionsEdit.isSystemDocumentAllowed': '系統文件 管理',
	'staff.permissionsEdit.isStaffAsDevAllowed': 'Airabbi 教師權限',
	'staff.permissionsEdit.isTeacherScheduleAllowed': '教師行事曆',
	'staff.permissionsEdit.isChatGPTAllowed': 'chatGPT 權限',
	'staff.permissionsEdit.isUnitPermissionsAllowed': '單位功能權限',
	'staff.permissionsEdit.canAdjustWorkspaces': '使用者工作區',

	//新版 Dashboard
	'staff.healthCheckTimeRange': 'System Monitor',
	'staff.codeSubmitTimeRange': '時段 Code Sumbit 次數',
	'staff.timeRange': '時間區間',
	'staff.byFiveMin': '五分鐘',
};

const signUpPage = {
	'signup.welcome': '註冊CodingBar',
	'signup.email': '請輸入電子郵件',
	'signup.password': '請輸入密碼', //NOSONAR
	'signup.confirmPassword': '請再輸入一次密碼',
	'signup.submit': '註冊新會員',
	'signup.failed': '註冊失敗',
	'signup.passwordNotSame': '兩次密碼不一致', //NOSONAR
	'signup.useThirdParty': '透過 Google 或 Facebook 帳號註冊',
	'signup.useGoogle': '透過 Google 註冊',
	'signup.useFacebook': '透過 Facebook 註冊',
	'signup.userAlreadyUsed': '此信箱已註冊！',

	'signup.loggingIn': '登入中...',
	'signup.cannotResetPW': '無法重設密碼!',
	'signup.enterPWAgain': '請再次輸入一次新密碼',
	'signup.PWMismatch': '新密碼不一致!',
	'signup.resetPWSuccess': '重設密碼成功!',
	'signup.PWTooWeak': '密碼強度不足！',
	'signup.resendPWEmail': '此重設密碼信件無法使用，請至忘記密碼頁重新寄送。',
	'signup.resetPWFail': '重設密碼失敗！',
	'signup.findPW': '找回您的密碼',
	'signup.findParentPW': '找回您的家長密碼',
	'signup.retrievePW': '取回我的密碼',
	'signup.enterEmailToResetPW':
		'請填入您的註冊信箱，我們會立即將您的密碼信函發送到您的收件夾中，若有任何問題，也請透過Line@與我們聯絡',
	'signup.enterEmail': '請輸入您的電子郵件',
	'signup.contactLine': '加入Line @',
	'signup.checkInbox': '請至收件夾取回密碼',
	'signup.passwordEmailSent': '密碼信已發送到註冊信箱，請到您的郵件收件夾查看，就能取得您的密碼！', //NOSONAR
	'signUpPage.signup': '註冊',
	'signUpPage.signup.get.verification.code': '取得驗證碼',
	'signUpPage.signup.reget.verification.code': '重新取得驗證碼',
	'signUpPage.signup.verification.code': '驗證碼',
	'signUpPage.signup.verification.code.placeholder': '請輸入驗證碼',
	'signUpPage.signup.verify.code': '驗證',
	'signUpPage.signup.verify.code.error.incorrectCode': '驗證碼錯誤，請重新確認',
	'signUpPage.signup.verify.code.error.noCodeReceived': '尚未取得驗證碼',
	'signUpPage.signup.name.placeholder': '請輸入您的姓名',
	'signUpPage.signup.user.name.alert': '使用者名稱僅可包含 a-z 、0-9、底線(_)、半形句號(.)',
	'signUpPage.signup.industry': '您的產業',
	'signUpPage.signup.industry.placeholder': '請選擇最能描述您所在產業的項目',
	'signUpPage.signup.position': '您的職務',
	'signUpPage.signup.position.placeholder': '請選擇最能描述您職務的項目',
	'signUpPage.signup.purpose': '想用 AImate 解決什麼問題?',
	'signUpPage.signup.purpose.placeholder': '請選擇最能描述您應用情境的項目',
	// TODO:SOL
	'signUpPage.signup.country': '您的國家',
	'signUpPage.signup.country.placeholder': '請選擇您的國家',
	'signUpPage.signup.city': '您的城市',
	'signUpPage.signup.city.placeholder': '請選擇您的城市',
	//
	'signUpPage.signup.Finance/Insurance': '金融/保險',
	'signUpPage.signup.Real Estate': '房地產',
	'signUpPage.signup.Education/Research': '教育/研究',
	'signUpPage.signup.Trade': '貿易',
	'signUpPage.signup.Construction/Manufacturing': '建築/營造製造',
	'signUpPage.signup.Information Technology': '資訊科技',
	'signUpPage.signup.Service': '服務',
	'signUpPage.signup.Healthcare': '醫療保健',
	'signUpPage.signup.Law Related': '法律相關',
	'signUpPage.signup.Retail': '零售',
	'signUpPage.signup.Transportation/Travel': '交通/運輸/旅遊',
	'signUpPage.signup.Entertainment/Publishing': '娛樂/出版',
	'signUpPage.signup.Communication/PR/Advertising/Marketing': '傳播/公共關係/廣告/行銷',
	'signUpPage.signup.Arts/Design': '藝術/設計',
	'signUpPage.signup.Agriculture/Fishing': '農漁牧',
	'signUpPage.signup.Housekeeping': '家管',
	'signUpPage.signup.Student': '學生',
	'signUpPage.signup.Others': '其他',
	'signUpPage.position.CEO/Company Leader': '執行長/公司負責人',
	'signUpPage.position.Manager/Leader': '主管/領導者',
	'signUpPage.position.General Staff': '一般職員',
	'signUpPage.position.Others': '其他',
	'signUpPage.purpose.Work': '工作',
	'signUpPage.purpose.Personal': '個人',
	'signUpPage.purpose.Education': '教育',
	'signUpPage.activate.Aimate.title': '啟用產品服務',
	'signUpPage.activate.Aimate.content': '此帳號已加入 CodingBar Workshop，點擊下方按鈕即可啟用 AImate 產品服務。',
	'signUpPage.activate.Aimate.button': '啟用 AImate',
	'signUpPage.signup.success': '註冊成功',
	'signUpPage.downloadAimate.content':
		'您已成功註冊 CodingBar Workshop，並啟用 AImate 產品服務。下載安裝 AImate 應用程式，享有存取電腦本機檔案、離線保存功能。',
	'signUpPage.downloadAimate.ios': 'Mac 用戶下載',
	'signUpPage.downloadAimate.windows': 'Windows 用戶下載',
	'signUpPage.downloadAimate.continue.web': '繼續使用網頁版',
};

const notes = {
	'notes.title': '上課筆記列表',
	'notes.subtitle': '歡迎使用新版的筆記功能！你可以在右上角隨時新增一則新的上課筆記，並且在此頁找尋先前的筆記本。',
	'notes.newNotes': '新筆記',

	'notes.addNewNotes': '新增筆記',
	'notes.saveNotes': '儲存筆記',
	'notes.confirmDeleteNote': '確定要刪除筆記:',
	'notes.deleteNote': '刪除筆記',

	'notes.placeholder.addNotes': '請輸入筆記內容',
	'notes.placeholder.addNotesByButton': '請按右下角按鈕以新增筆記',
	'notes.not.note.yet': '尚未有筆記',
	'notes.no.note': '透過「新增」建立筆記',
};

const announcement = {
	'announcement.sidebar.name': '公告清單',
	'announcement.tab.studentAncm': '學生公告',
	'announcement.tab.teacherAncm': '教師公告',
	'announcement.tab.publicAncm': '全域公告',

	'announcement.addNewAncm': '新增公告',
	'announcement.viewAncm': '檢視公告',
	'announcement.deleteThisAncm': '刪除此公告',
	'announcement.deleteAncm': '刪除公告',
	'announcement.writeAncm': '填寫公告內容',
	'announcement.publishAncm': '發佈公告',
	'announcement.viewAncm': '檢視公告',
	'announcement.closeAncm': '關閉視窗',
	'announcement.settingMsg1': '新增公告請注意以下事項：',
	'announcement.settingMsg2': '(1) 發佈公告的對象為 學生、教師 或 全域發佈',
	'announcement.settingMsg3': '(2) 公告的類型為 同意型(Agreement)、提醒型(Annoucement) 或 警告 (Alert)',
	'announcement.writeAncmMsg': '請填寫公告標題與內容',
	'announcement.submitAncmConfirm': '確認新增公告',
	'announcement.submitAncmMsg': '所有選取單位的使用者都會被影響，確定要新增嗎？',
	'announcement.deleteAncmConfirm': '確認刪除公告',
	'announcement.deleteAncmMsg': '所有選取單位的使用者都會被影響，確定要刪除嗎？',
	'announcement.selectAll': '全選',
	'announcement.dontShowAgain': '下次不再顯示',
	'announcement.agree': '同意',
	'announcement.disagree': '不同意',
	'announcement.understood': '我理解了',
	'announcement.confirmClose': '確認並關閉',

	'announcement.dropdown.title.type': '選擇公告類型',
	'announcement.dropdown.title.unit': '選擇公告單位',
	'announcement.dropdown.title.receiver': '選擇公告對象',
	'announcement.dropdown.title.startDate': '發佈日期',
	'announcement.dropdown.title.startTime': '開始時間',
	'announcement.dropdown.title.endDate': '結束日期',
	'announcement.dropdown.title.endTime': '結束時間',

	'announcement.dropdown.label.agreement': '同意型',
	'announcement.dropdown.label.announcement': '提醒型',
	'announcement.dropdown.label.alert': '警告',

	'announcement.dropdown.label.student': '學生',
	'announcement.dropdown.label.teacher': '教師',
	'announcement.dropdown.label.all': '全域',

	'announcement.content.title': '公告標題',
	'announcement.content.content': '輸入公告內容',
	'announcement.content.btnText': '主要按鈕內文',
	'announcement.content.placeholder': '必填，不可為空白',
	'announcement.content.placeholder.content': '必填，不可為空白，文字過長請換行處理，不換行後果自負。',
	'announcement.content.placeholder.btn': '最長 15 字元，空白即為預設文字「確認並關閉」',

	'announcement.view.type': '公告類型',
	'announcement.view.unit': '公告單位',
	'announcement.view.receiver': '公告對象',
	'announcement.view.content': '公告內容',

	'announcement.view.allUnits': '所有單位',
	'announcement.announcementList.noAnnounce': '暫無公告',

	'announcement.addNewAncm.tabTitle.stepOne': '設定公告範圍',
	'announcement.addNewAncm.tabTitle.stepTwo': '設定公告內文',
};

const lintErrorHints = {
	'lint.msgLine1': '你在',
	'lint.msgLine2': '行時，',
	'lint.btn.checkHint': '查看提示',
	'lint.btn.askQuestion': '我想提問',
	'lint.btn.understood': '我知道了',
	'lint.title.stuck': '卡關了嗎？',
	'lint.title.fixed': '已解決！',
	'lint.msg.errorsFixed': '非常棒，現在所有的錯誤已經修正了',
	'lint.msg.noHint': '暫無提示',

	'lint.msg.error.hint': '第 {{line}} 行有錯，快點檢查',

	'lint.msg.chat.lose.miss.me': '唉唷，輸了一屁股才想到我？',
	'lint.msg.chat.no.show.up.order': '董仔很久沒出現了，今天想來點什麼？',
	'lint.msg.chat.holiday.ask.dictionary': '眼怪仔我今天放假，有問題自己查字典',
	'lint.msg.chat.no.more.service': '眼怪仔智商發展中，暫時無法提供更多服務',
	'lint.msg.chat.click.on.and.off': '點了又關掉，關掉又點開，有事ㄇ？',
	'lint.msg.chat.speechless.stay.tuned': '沒台詞了下個月才有新梗，敬請(?)期待',
	'lint.msg.chat.course.easter.egg': '記得報名 CodingBar 課程，看更多彩蛋',
	'lint.msg.chat.SSR.hang.in.there': '抽到 SSR 現在沒空，大家先撐住！',
	'lint.msg.chat.ask.question.by.yourself': '要問問題戳我幹嘛? 點左邊的問題討論啊!(／‵口′)／~╧╧',

	'lint.msg.chat.problem.solved.nice': '看起來解決了！不錯唷！',
	'lint.msg.chat.problem.solved.awesome': '沒想到你還挺厲害的嘛！',
	'lint.msg.chat.problem.solved.succeed': '居然讓你成功解決，看來我太小看你了！',
	'lint.msg.chat.problem.solved.challenge': '那麼厲害，等下再找出錯誤讓你挑戰，哈哈哈',
};

const studyResult = {
	'studyResult.completeRate': '完成度',
	'studyResult.courseScore': '課程成績',
	'studyResult.completeTime': '花費時間',

	'studyResult.courseScore': '表現等級',
	'studyResult.course.status': '學習概況',

	'studyResult.course.status.details': '詳細統計',
	'studyResult.course.download.certificate': '下載證書',
	'studyResult.course.best.performance': '最佳表現',
	'studyResult.certificate.completed.course': '已修畢',
	'studyResult.certificate.completed.course.proof': '特頒此結業證書以茲證明',
	'studyResult.certificate.course.duration': '課程期間',
	'studyResult.certificate.course.total.hours': '課程時數',
	'studyResult.certificate.taiwan': '中華民國',
	'studyResult.certificate.year': '年',
};

const unitDomainManage = {
	'unitDomain.sidebar.name': '單位網域管理',
	'unitDomain.tab.allUnits': '所有單位',
	'unitDomain.tab.appUnits': 'App網域單位',
	'unitDomain.tab.schoolUnits': 'School網域單位',
	'unitDomain.iconMenu.switchDomain': '切換單位網域',
	'unitDomain.listView.total': '總數',
	'unitDomain.confirmModal.title': '單位網域切換',
	'unitDomain.confirmModal.content':
		'{{unitName}} 目前使用的平台網域為 {{currentDomain}}；\n 請確認是否將該單位的平台網域切換為 {{switchToDomain}}',
	'unitDomain.alert.success': '網域切換成功',
	'unitDomain.alert.fail': '網域切換失敗',
};

const betaUnitManage = {
	'betaUnit.sidebar.name': 'Beta單位管理',

	'betaUnit.tab.allUnits': '所有單位',
	'betaUnit.tab.betaUnits': 'Beta測試單位',
	'betaUnit.tab.notBetaUnits': '原版系統單位',

	'betaUnit.listView.total': '總數',

	'betaUnit.confirmModal.title': '切換單位',
	'betaUnit.confirmModal.content':
		'{{unitName}} 目前使用的平台系統為 {{current}}；\n 請確認是否將該單位切換為 {{switchTo}}',

	'betaUnit.alert.success': '單位切換成功',
	'betaUnit.alert.fail': '單位切換失敗',

	'betaUnit.version.beta': 'Beta 版',
	'betaUnit.version.origin': '原版系統',
};

const AnnouncementListModal = {
	'announcementList.title': '公告列表',
};

const zoom = {
	'zoom.list': '視訊列表',
	'zoom.zoomCourse': 'CodingBar 視訊課程',
	'zoom.selectCourse': '請選擇要上的視訊課程!',
	'zoom.selectCodeAndPW': '請填入正確的課程編號、會議號碼與密碼!',
	'zoom.retrievingInfo': '正在取得課程資訊...',
	'zoom.courseCode': '課程編號',
	'zoom.meetingId': '會議 ID',
	'zoom.startCourse': '開始視訊課程',
	'zoom.endCourse': '停止視訊課程',
};

const desktopDownload = {
	'desktopDownload.title': '下載 CodingBar 軟體',
	'desktopDownload.description':
		'CodingBar 桌機版可支援 Micro:bit、Meowbit、爬蟲資料科學以及其他需要讀取硬體的課程，請注意您的作業系統版本，以及需要上課的類型，安裝適合您的版本！',
	'desktopDownload.download': '立即下載',
	'desktopDownload.instructions': '下方安裝指引',
	'desktopDownload.step': '步驟',

	'desktopDownload.mac.title': 'Mac 系統',
	'desktopDownload.mac.desc1': 'Mac 系統只支援 桌機/筆記型電腦裝置，不適用於 ipad 等平板電腦產品；請注意是否擁有',
	'desktopDownload.mac.desc2': '插孔，或是自備轉換插槽。\n\n若有安裝問題，請參考',
	'desktopDownload.mac.version11up': 'Mac OS 11以上',
	'desktopDownload.mac.version11down': 'Mac OS 10.13以上，不支援11版',

	'desktopDownload.mac.stepTitle': 'Mac 安裝指引',
	'desktopDownload.mac.step1': '首先，將下載下來的檔案點擊滑鼠左鍵兩下開啟。',
	'desktopDownload.mac.step2': '稍作等待，應該可以看到程式會跳出一個視窗。',
	'desktopDownload.mac.step3':
		'依據跳出視窗指示，滑鼠左鍵點擊左邊的 CodingBar 圖示，按住不放，將其拖曳至右邊的資料夾上放開，等待安裝。',
	'desktopDownload.mac.step4':
		'安裝完成之後，開啟 Finder資料夾 中左側的 「應用程式」，您應該就可以看見 CodingBar 的 APP，點擊兩下開啟 CodingBar APP。',
	'desktopDownload.mac.step5': '首次安裝需要進行安全性設定，點選「好」',
	'desktopDownload.mac.step6': '滑鼠左鍵點選桌面左上角的蘋果圖示開啟電腦選單。',
	'desktopDownload.mac.step7': '滑鼠左鍵點選「系統偏好設定」。',
	'desktopDownload.mac.step8': '在「系統偏好設定」的視窗，滑鼠左鍵點選「安全性與隱私權」。',
	'desktopDownload.mac.step9': '在「安全性與隱私權」的視窗，滑鼠左鍵點選「強制打開」。',
	'desktopDownload.mac.step10': '在跳出的強制打開確認視窗，點選左邊的打開。',
	'desktopDownload.mac.step11': '每一次連線 CodingBar 時都會出現連線許可確認，請點選「允許」',
	'desktopDownload.mac.step12': '就可以成功開啟 CodingBar 桌機版囉！',

	'desktopDownload.windows.title': 'Windows 系統',
	'desktopDownload.windows.desc1': 'Windows 系統需 64 位元以上，請注意若需要使用micro:bit 等硬體時，需擁有',
	'desktopDownload.windows.desc2': '插孔，或是自備轉換插槽。\n\n若有安裝問題，請參考',
	'desktopDownload.windows.version': 'Windows 系統 64位元以上',

	'desktopDownload.windows.stepTitle': 'Windows 安裝指引',
	'desktopDownload.windows.step1': '首先，將下載下來的檔案點擊滑鼠左鍵兩下開啟。\n\n*Windows 版本只支援64位元系統',
	'desktopDownload.windows.step2': 'Window-2 Windows 系統本身會開啟安全性警告，滑鼠左鍵點選執行。',
	'desktopDownload.windows.step3': '接著就可以看到 CodingBar 的安裝畫面，請依照指示安裝，滑鼠左鍵點選下一步。',
	'desktopDownload.windows.step4': '請繼續滑鼠左鍵點選下一步。\n\n*請勿更動預設安裝路徑，以免造成程式執行的讀取問題',
	'desktopDownload.windows.step5': '請繼續滑鼠左鍵點選安裝以開始。',
	'desktopDownload.windows.step6': '請稍待安裝過程，在過程中請勿按下取消，以免安裝失敗導致程式檔案不完整。',
	'desktopDownload.windows.step7': '當安裝完成時，滑鼠左鍵按下完成結束安裝程式。',
	'desktopDownload.windows.step8':
		'桌面應該會出現 CodingBar 程式的捷徑，或是從檔案路徑 C://Program Filse (x86)/Airabbi/CodingBar/，也可以找到程式路徑。滑鼠左鍵點擊兩下 CodingBar.exe 即可開啟程式。\n\n*請勿擅自將程式路徑內的任一檔案移動或刪除，會導致程式檔案不完整。',
	'desktopDownload.windows.step9': '就可以成功開啟 CodingBar 桌機版囉！',

	// TODO:SOL
	'desktopDownload.AImate.title': '下載 AImate 軟體',
	'desktopDownload.AImate.content': '下載安裝 AImate 應用程式，享有存取電腦本機檔案、離線保存功能。',
	'desktopDownload.AImate.mac.desc1': 'Mac 系統只支援 桌機/筆記型電腦裝置，不適用於 ipad 等平板電腦產品。',
	'desktopDownload.AImate.mac.desc2':
		'如果您在Mac上安裝軟件時遇到問題，可以通過點擊屏幕左上角的蘋果標誌，進入「系統偏好設定」，在「安全性與隱私」選項中，選擇「強制打開」功能，這樣就可以順利完成 AImate 桌面版的安裝囉！',
	'desktopDownload.AImate.windows.desc1': '支援 Windows 64 位元以上的系統。',
	'desktopDownload.AImate.windows.desc2':
		'在安裝過程中若出現安全警告，選擇「執行」以繼續，跟隨安裝指南操作，並避免在安裝過程中點擊「取消」，以確保程序完整安裝!',
};

const studentDetailPage = {
	'page.tab.basicview': '基本能力評估',
	'page.tab.overview': '表現摘要',
	'page.tab.personalInfo': '個人資料',
	'page.tab.studentScore': '成績檢視',

	'page.blockHeader.assessment': '能力評估',
	'page.blockHeader.keyPerformance': '關鍵表現',

	'page.btn.checkDetails': '查看細節',
};

const teacherCourseLessonPage = {
	'lessonPage.card.title': '課程基本資訊',
	'lessonPage.course.content': '課程內容',
	'lessonPage.course.view.content': '檢視課程',
	'lessonPage.course.viewScoreOnly': '檢視成績',
	'lessonPage.confirm.edit.course.title': '變更課程內容',
	'lessonPage.confirm.edit.course.content':
		'變更課程單元數量、上課時間、單元順序等，將覆寫當前設定狀態，請先確認再點擊確認變更\n注意，此項操作無法回復',
	'lessonPage.card.button.editCourseInfo': '編輯課程',

	'lessonPage.confirm.editCancel.course.title': '取消變更課程內容',
	'lessonPage.confirm.editCancel.course.content':
		'請確認是否要取消編輯課程內容，方才的編輯更動將會全部遺失\n請再三確認是否要取消編輯。',

	'lessonPage.card.fieldTitle.codeType': '主要使用語言',
	'lessonPage.card.fieldTitle.lessonLength': '課程堂數',
	'lessonPage.card.fieldTitle.lessonLength.number': '課程堂數(單元數)',
	'lessonPage.card.fieldTitle.tags': '自訂標籤',
	'lessonPage.card.fieldTitle.content': '課程簡介或附註',
	'lessonPage.card.fieldTitle.teacher': '主要授課教師',
	'lessonPage.card.fieldTitle.ta': '課堂助教',

	'lessonPage.card.fieldTitle.lessonTitle': '課程單元標題',
	'lessonPage.card.fieldTitle.lessonContent': '課程單元說明文字',
	'lessonPage.card.lessonContent.placeholder': '請輸入單元簡介',

	'lessonPage.title': '第 {{i}} 單元',
	'lessonPage.entryoflesson': '進入教學模式',
	'course.short.questionGroup': '題組',

	'lessonPage.exercise.batch.setting': '課程單元批次設定',
	'lessonPage.exercise.test.cases': '測資',
	'lessonPage.exercise.video': '影片開放',
	'lessonPage.abbrev.test.cases': '測資',
	'lessonPage.abbrev.exercise': '內容',
	'lessonPage.abbrev.answer': '解答',

	'course.short.handout': '講義',
	'course.short.exercise': '練習',
	'course.short.homework': '作業',
	'course.short.tests': '考試',
	'course.short.exam': '試卷',
	'course.short.video': '影片',
	'course.short.coursePackage': '套裝課程',
	'course.short.AddLessonFromResource': '從資源庫匯入',
	'course.time.notSet': '未指定時間',
	'course.page.title.stuManage': '課堂學生管理',
	'course.page.removePackageStudentAlert': '套裝課程不允許移除學生',
	'lessonPage.no.content.yet': '您尚未添加任何內容',

	'course.time.finishedLessons': '已完成 {{currentLevel}} 堂課（全 {{totalLevel}} 堂）',
};

const coursePackageManage = {
	'CPManage.sidebar.name': '課務管理',

	'CPManage.info.beginTime': '生效',
	'CPManage.info.endTime': '截止',
	'CPManage.info.packageType': '課程類型',
	'CPManage.info.useNum': '課程人數',
	'CPManage.info.maintainer': '負責業務',
	'CPManage.info.unitContact': '單位聯絡人',
	'CPManage.info.unitContactEmail': '聯絡人信箱',
	'CPManage.info.contactPhone': '聯絡電話',
	'CPManage.info.contactAddress': '教室地址',

	'CPManage.info.noContactInfo': '暫無單位聯絡人資料',
	'CPManage.info.noUseLimit': '無限制',

	'CPManage.iconMenu.editSetting': '編輯設定',
	'CPManage.iconMenu.deletePackage': '刪除課程',

	'CPManage.modal.addPackage': '新增外派課程',
	'CPManage.modal.editPackage': '編輯外派課程',
	'CPManage.modal.steps.basicSettings': '基本資訊',
	'CPManage.modal.steps.importCourse': '匯入課程',
	'CPManage.modal.steps.importCourse.confirm': '匯入課程',
	'CPManage.modal.chose.course.resource': '選擇套裝課程',
	'CPManage.modal.steps.timeSettings': '時間設定',

	'CPManage.modal.stepOne.title.courseType': '課程類型',
	'CPManage.modal.stepOne.title.maintainer': 'CodingBar聯絡人',
	'CPManage.modal.stepOne.title.useLimit': '課程總人數上限',
	'CPManage.modal.stepOne.title.assignedUnit': '指派單位',
	'CPManage.modal.stepOne.title.assignedContact': '單位聯絡人',
	'CPManage.modal.stepOne.title.beginTime': '課程生效日',
	'CPManage.modal.stepOne.title.endTime': '課程截止日',
	'CPManage.modal.stepOne.title.enableActiveUserNotification': '輸出活躍人數給 CodingBar 聯絡人',
	'CPManage.modal.stepOne.title.courseTitle': '輸入課程名稱',
	'CPManage.modal.stepOne.title.courseTitlePreview': '預覽課程名稱',
	'CPManage.modal.stepOne.title.desc': '輸入課程簡介或附註',
	'CPManage.modal.stepOne.title.codeType': '主要使用語言',
	'CPManage.modal.stepOne.title.assignedTeacher': '授課教師',
	'CPManage.modal.stepOne.title.assignedTA': '課堂助教',
	'CPManage.modal.stepOne.title.unit': '開課單位',
	'CPManage.modal.stepOne.title.chatGPT': '開啟 ChatGPT 功能',
	'CPManage.modal.stepOne.title.toggleAllowedEdit': '開啟 允許編輯 功能',

	'CPManage.modal.stepThree.title.courseBeginTime': '起始日期',
	'CPManage.modal.stepThree.title.courseLessonLength': '預計課程堂數',
	'CPManage.modal.stepThree.title.packageCourseEndDate': '課程有效截止日',
	'CPManage.modal.stepThree.title.autoReleaseCourse': '自動開放課程',
	'CPManage.release.lesson.by.schedule': '依上課時間開放單元',

	'CPManage.modal.stepOne.placeholder.maintainer': '輸入業務名稱',
	'CPManage.modal.stepOne.placeholder.useLimit': '輸入0，則為不限制',
	'CPManage.modal.stepOne.placeholder.assignedContact': '若無自動填入資料，可手動選填',

	'CPManage.modal.errorText.endBeforeStart': '您不能違反宇宙原理',
	'CPManage.modal.errorText.notStartBetweenPackageTime': '日期不在外派課程時效內',

	'CPManage.modal.alert.lessonDatesError': '課程日期為：{{dates}}，部分日期不在課程時效內',
	'CPManage.modal.alert.lessonDates': '課程日期為：{{dates}}',

	'CPManage.modal.confirm.titleTeacher': '匯入套裝課程?',
	'CPManage.modal.confirm.bodyTeacher':
		'請注意，當您使用並建立套裝課程後，便將開始計算使用人數，您將無法直接刪除此課程。\n請確認後點擊建立課程。',
	'CPManage.modal.confirm.titleDev': '建立外派套裝課程？',
	'CPManage.modal.confirm.bodyDev': '請確認是否將套裝課程外派至相關單位或老師。',
	'CPManage.modal.confirmDelete.titleDev': '刪除外派套裝課程？',
	'CPManage.modal.confirmDelete.bodyDev': '請確認是否刪除外派課程，相關單位及老師將會受影響。',

	'CPManage.modal.AssignCourseStepTwo.note':
		'新功能！不用設定課程各單元的起訖日與上課週期，也能直接使用，但系統將不會提醒您當日的課程單元！',
	'CPManage.modal.alert.import.lessonDatesError':
		'目前課程規劃為：{{dates}}\n您的套裝課程有效期限為{{dueDate}}，部分日期不在有效期限內，請調整您的課程時間。',
	'CPManage.modal.alert.import.lessonDatesError.title': '請調整課程時間',
};

const comments = {
	'comments.btn.addComment': '新增評語',
	'comments.btn.addAnotherComment': '+新增另一組評語',
	'comments.btn.removeThisComment': '-刪除此評語',

	'comments.addComment.tab.basicAbility': '基本能力評估',
	'comments.addComment.tab.addComment': '評語撰寫',

	'comments.addComment.info1': '觀念力與實作力',
	'comments.addComment.info2': '總和為 100',
	'comments.addComment.info3': '，您可以直接輸入其中一個欄位，另一欄位系統將自動幫您填入',

	'comments.field.conceptScore': '觀念力',
	'comments.field.implementScore': '實作力',
	'comments.field.difficultyScore': '接受度',
	'comments.field.starScore': '星等評價',
	'comments.field.commentShort': '簡易評語選擇',
	'comments.field.comment': '評語內容',

	'comments.placeholder.noComment': '老師目前還沒寫其他評語',
};

const studentInfo = {
	'studentInfo.field.unit': '單位',
	'studentInfo.field.name': '學生姓名',
	'studentInfo.field.gender': '性別',
	'studentInfo.field.phone': '聯絡電話',
	'studentInfo.field.identityCardNumber': '學生身分證號',
	'studentInfo.field.birthday': '出生年月日',
	'studentInfo.field.age': '年齡',
	'studentInfo.field.email': '電子郵件',
	'studentInfo.field.password': '密碼', //NOSONAR
	'studentInfo.field.educationStage': '學級',
	'studentInfo.field.contactName': '聯絡人姓名',
	'studentInfo.field.contactPhone': '聯絡人電話',
	'studentInfo.field.useOldMember': '會員身份',
	'studentInfo.field.contactEmail': '電子郵件',
	'studentInfo.field.contactAddress': '聯絡地址',

	'studentInfo.placeholder.inputPhone': '請輸入電話',
	'studentInfo.placeholder.inputIdentityCardNumber': '請輸入學生身份證',
	'studentInfo.placeholder.resetPassword': '寄發重設密碼信',
	'studentInfo.placeholder.inputSchool': '請輸入學校',
	'studentInfo.placeholder.inputStudentClass': '請輸入班級',
	'studentInfo.placeholder.inputSeatNo': '請輸入座號 (純數字)',
	'studentInfo.placeholder.inputContactName': '請輸入聯絡人姓名',
	'studentInfo.placeholder.inputContactPhone': '請輸入聯絡人電話',
	'studentInfo.placeholder.inputContactAddress': '請輸入聯絡地址',

	'studentInfo.grade.first': '一年級',
	'studentInfo.grade.second': '二年級',
	'studentInfo.grade.third': '三年級',
	'studentInfo.grade.fourth': '四年級',
	'studentInfo.grade.fifth': '五年級',
	'studentInfo.grade.sixth': '六年級',
	'studentInfo.grade.seventh': '七年級',
	'studentInfo.grade.eighth': '八年級',
	'studentInfo.grade.ninth': '九年級',
	'studentInfo.grade.tenth': '高一',
	'studentInfo.grade.eleventh': '高二',
	'studentInfo.grade.twelfth': '高三',

	'studentInfo.grade.elementary': '國小',
	'studentInfo.grade.junior': '國中',
	'studentInfo.grade.senior': '高中',
	'studentInfo.grade.uni': '大學',

	'studentInfo.field.member': '會員',

	'studentInfo.confirm.updateStudentInfo.title': '更改學生資料？',
	'studentInfo.confirm.updateStudentInfo.body': '您將更改學生資料，請確認資料是否正確。',
};

const studentPerformance = {
	'studentPer.rowTitle.lessonVideos': '單元影片',
	'studentPer.rowTitle.examResults': '測驗成績',
	'studentPer.rowTitle.videoViewed': '影片觀看',
	'studentPer.rowTitle.homeworkFinished': '作業完成',
	'studentPer.rowTitle.questionsAsked': '主動提問',
	'studentPer.rowTitle.checkedTips': '查看提示',
	'studentPer.rowTitle.submitTimes': '錯誤提交',
	'studentPer.rowTitle.finishedTime': '完成時間',
	'studentPer.rowTitle.runtimeEfficiency': '執行效率',
	'studentPer.rowTitle.classRanking': '排名名次',
	'studentPer.rowTitle.taggedPerf': '標記優秀',
	'studentPer.rowTitle.exerciseTime': '練習時間',
	'studentPer.rowTitle.exercisePerf': '答題表現',

	'studentPer.rowTitle.lessonInfo': '課程快訊',

	'studentPer.blockSubtitle.perfomance': '表現評價',
	'studentPer.blockSubtitle.classAverage': '全班平均',
	'studentPer.blockSubtitle.finishedLength': '完成數量',
	'studentPer.blockSubtitle.askedLength': '提出次數',
	'studentPer.blockSubtitle.checkedTimes': '查看次數',
	'studentPer.blockSubtitle.totalLength': '總計數量',
	'studentPer.blockSubtitle.personalTime': '個人時間',
	'studentPer.blockSubtitle.personalPerf': '個人表現',
	'studentPer.blockSubtitle.bestRecord': '最佳紀錄',
	'studentPer.blockSubtitle.worstRecord': '最低紀錄',
	'studentPer.blockSubtitle.overallPerformance': '綜合表現',
	'studentPer.blockSubtitle.difficultySelect': '接受度',
	'studentPer.blockSubtitle.testAverage': '測驗平均',
	'studentPer.blockSubtitle.exerciseCompletion': '習題完成率',

	'studentPer.barSubtitle.classAverage': '其他同學平均',
	'studentPer.barSubtitle.videos': '你觀看的影片',
	'studentPer.barSubtitle.exercises': '你練習的題目',
	'studentPer.barSubtitle.exerciseTime': '你練習的時間',
	'studentPer.barSubtitle.homework': '你完成的作業',
	'studentPer.barSubtitle.questions': '你提問的次數',
	'studentPer.barSubtitle.tips': '你查看的次數',
	'studentPer.barSubtitle.submit': '你提交的次數',
	'studentPer.barSubtitle.time': '你花費的時間',
	'studentPer.barSubtitle.rank': '你的排名表現',
	'studentPer.barSubtitle.perf': '你的優秀次數',

	'studenPer.headerText.results': '成績統計',
	'studenPer.headerText.selflearn': '自主學習',
	'studenPer.headerText.careful': '細心度',
	'studenPer.headerText.preformance': '效率表現',
	'studenPer.headerText.other': '其他表現',

	'studentPer.work': '作品',
	'studentPer.overall.evaluation': '整體評估',
	'studentPer.works': '精彩作品節錄',
	'studentPer.feedback': '學生心得',
	'studentPer.attachments': '作品附件 / 活動相簿',
	'studentPer.learningAttitude': '學習態度',
	'studentPer.programmingAbility': '程式能力',
	'studentPer.attendance.rate': '出席率',
	'studentPer.homework.completion.rate': '作業完成率',
	'studentPer.participation': '參與度',
	'studentPer.programming.syntax.familiarity': '程式語法熟悉度',
	'studentPer.programming.logic.understanding': '程式邏輯理解度',
	'studentPer.problem.solving.skills': '應用問題解決能力',
	'studentPer.organizational.skills': '組織架構思維能力',
	'studentPer.creativity': '創意與創造力',
	'studentPer.first.work': '作品一',
	'studentPer.look.forward.to.amazing.work': '精彩作品敬請期待',
	'studentPer.attachments.link': '作品連結',
	'studentPer.albums.link': '課程活動相簿',
};

const parentPage = {
	'parentPage.expandAll': '展開全部狀態',
	'parentPage.closeAll': '隱藏詳細資料',

	'parentPage.selectCourse.placeholder': '選擇課程',
};

const exerciseEditor = {
	'exerciseEditor.sidebar.exerciseList': '題目選單',
	'exerciseEditor.sidebar.questions': '我要提問',
	'exerciseEditor.sidebar.exerciseContent': '內容',
	'exerciseEditor.sidebar.exerciseQuestions': '題目',
	'exerciseEditor.sidebar.exerciseAnswers': '解答',
	'exerciseEditor.sidebar.exerciseResources': '資源',
	'exerciseEditor.sidebar.noInfoTitle': 'Oops !!',
	'exerciseEditor.sidebar.noInfo': '沒有內容可以顯示\n應該是老師沒有開放唷！',

	'exerciseEditor.sidebar.answer.record': '答題記錄',
	'exerciseEditor.answer.record.choice': '選擇項目',
	'exerciseEditor.answer.record.correct.answer': '正確解答',
	'exerciseEditor.answer.record.rearrangement.result': '排序結果',
	'exerciseEditor.answer.record.your.answer': '你的答案',
	'exerciseEditor.answer.record.your.didnt.answer': '你未作答',

	'exerciseEditor.handIn.globalTest.announce.title': '計算成績',
	'exerciseEditor.handIn.globalTest.announce.content': '請稍候，我們正在為您計算成績',
};

const questionGroupModal = {
	'questionGroupModal.addQuestionGroup': '建立題組',
	'questionGroupModal.editQuestionGroup': '編輯題組',
	'questionGroupModal.previewQuestionGroup': '預覽題組',
	'questionGroupModal.stepOne.basicContent': '基本內容',
	'questionGroupModal.stepTwo.importExercise': '匯入題目',
	'questionGroupModal.deleteQuestionGroup': '刪除題組',

	'questionGroupModal.stepOne.info1': '1. 新版題組功能，您可將數個題目依目的或題型組成題組',
	'questionGroupModal.stepOne.info2': '2. 題目請由教學資源庫匯入；您可直接拖曳題目調整順序',

	'questionGroupModal.stepOne.title': '題組名稱',
	'questionGroupModal.stepOne.titleholder': '請輸入題組名稱',
	'questionGroupModal.stepOne.hashtag': '自訂標籤',

	'questionGroupModal.stepTwo.selectFrom': '由教學資源庫匯入',
	'questionGroupModal.stepTwo.amountcount': '已選擇 {{amountcount}} 個項目',
	'questionGroupModal.resources.exercise': '習題',
	'questionGroupModal.resources.video': '影片',
};

const disconnetPage = {
	'disconnetPage.title': 'Sorry...系統暫時無法回應',
	'disconnetPage.text': '可能為伺服器或其他連線出了點問題',
};
const teacherCourseTestPage = {
	'testPage.header.title': '考試活動',
	'testPage.header.text':
		'我們推出了預先排定考試活動的功能，協助您預先規劃。\n預舉辦一個考試活動，您可以選擇教學資源庫中的任何考卷，無需再至單元內匯入特定考卷後才能啟動考卷。快來試試吧！',

	'testPage.endMockAlert': '此模擬練習已結束!',
	'testPage.endTestAlert': '此考試已結束!',

	'testPage.tabs.isScheduled': '已排定',
	'testPage.tabs.isEnded': '已測驗',
	'testPage.tabs.isMockTest': '模擬練習',

	'testPage.list.isTestOngoing': '開放中',
	'testPage.list.testDate': '開放日期',
	'testPage.list.testDuration': '考試長度',
	'testPage.list.editTest': '編輯考試活動',
	'testPage.list.deleteTest': '刪除此考試活動',
	'testPage.list.viewTest.setting': '檢視考試設定',
	'testPage.list.endTest': '終止考試',
	'testPage.list.viewTestScore': '檢視考試成績',
	'testPage.list.openTestResource': '檢討資源開放',
	'testPage.list.deleteTestData': '刪除此考試資料',
	'testPage.list.deleteMockTest': '刪除此模擬測驗',
	'testPage.list.noTests': '您還未建立任何考試活動',

	'testPage.modal.deleteTest.title': '您確定要刪除此項考試活動？',
	'testPage.modal.deleteTest.content':
		'一旦刪除考試活動，將無法在指定時間或課堂中發起此項考試。\n\n此動作不可回復，請再三確認後再執行此動作！',
	'testPage.modal.deleteTest.btn': '刪除考試活動',

	'testPage.modal.endTest.title': '您確定要終止此項考試活動？',
	'testPage.modal.endTest.content':
		'考試活動進行中！\n\n一旦終止考試活動，學生將無法再繼續作答，系統將自動開始計算考試成績。\n\n此動作不可回復，請再三確認後再執行此動作！',
	'testPage.modal.endTest.btn': '終止考試活動',

	'testPage.fab.addTest': '建立考試活動',

	'testPage.alert.leaveTest': '離開試卷將不會儲存您的答題結果，確定要離開了嗎？',
	'testPage.alert.csfc.leaveTest': '離開試卷視同交卷，離開前請先提交試卷。',
};

const testInfo = {
	'testInfo.starttest': '開始作答',
	'testInfo.description': '測驗描述',
	'testInfo.duration': '測驗時間',

	'testInfo.viewtest': '檢視答題內容',
	'testInfo.view5recent': '檢視近 5 次成績',

	'testInfo.recent5score': '近五次挑戰成績',

	'testInfo.mockDone': '已挑戰過的模擬練習',
	'testInfo.allchallengetime': '總挑戰時間',
};

const studentTestPage = {
	'studentTestPage.timeUp': '時間到，請停止考試',
	'studentTestPage.autoHandIn': '考試時間已到，系統已自動為您提交考卷。',
	'studentTestPage.countdowninfo': '測驗進行中，距離結束',

	'studentTestPage.markquestion': '標註',
};
const loadingProcessBar = {
	'loadingProcessBar.createExam.title': '建立考試活動中...',
	'loadingProcessBar.createExam.csfc.title': '建立 CSFC 考試場次中...',
	'loadingProcessBar.createExamDone.title': '您指定的考試活動已經開始囉！',
	'loadingProcessBar.resetExam.title': '重建考試活動中...',
	'loadingProcessBar.endExam.title': '終止考試活動中...',
	'loadingProcessBar.endExamDone.title': '您指定的考試活動已經終止囉！',

	'loadingProcessBar.endExamDone.ok': '檢視成績',
	'loadingProcessBar.endExamDone.cancel': '稍後再看',
};

const globalExamPage = {
	'globalExamAd.line1': '模考加碼送完整解題影片！',
	'globalExamAd.line2': '趕快點擊選單為考APCS備戰',
	'globalExamAd.cancelBtn': '我考過啦',

	'globalExamPage.EntranceAnnounce.title': '免費 APCS 模擬考，歡迎體驗挑戰！',
	'globalExamPage.EntranceAnnounce.content': `每一場模擬考分為「觀念 20 題」與 「實作 2 題」，我們將常考的觀念分開出卷，讓你能有目標地確認各種觀念是否準備齊全。

	測驗中，請勿關閉瀏覽器或中斷網路，或者離開考試介面，否則將無法儲存你的答題結果；實作題環境提供 Python、Ｃ、C++ 語言作答，你可透過介面左側的設定切換不同語言；
	作答時，請務必按下方的「提交」按鈕，並透過左側題目選單確認是否提交答案；

	完成考試，按下「交卷」按鈕結束考試，或時間結束後由系統自動交卷；你將於五分鐘內收到一份測驗結果與簡單分析，並附上解題參考影片。

	我們正開發新版軟體功能，任何關於題目內容、平台功能的建議，都歡迎在我們粉絲團、IG 留言關注，
	幫助我們將這平台變得更好，讓我們一起 Coding 吧！

	CodingBar 開發團隊`,
};

const translationPage = {
	'translation.selectCourse': '請選擇左側課程以進行翻譯',
	'translation.toBeTranslated': '待翻譯課程列表',
	'translation.submitTranslation': '提交翻譯',

	'translation.formTitle.courseInfo': '課程資訊',
	'translation.formTitle.lessonInfo': '單元資訊',
	'translation.formTitle.materialInfo': '講義資訊',
	'translation.formTitle.exerciseInfo': '習題資訊',

	'translation.lesson.title': '單元名稱',

	'translation.exercise.option': '題目選項',
	'translation.exercise.answer': '題目解答',
	'translation.exercise.text': '題目文字',

	'translation.exercise.cloze.row': '克漏字題目單行內容',
	'translation.exercise.dragdrop.row': '拖曳題目單行內容',
	'translation.exercise.cloze.text': '克漏字題目文字',
	'translation.exercise.cloze.slot': '克漏字填空答案',
	'translation.exercise.cloze.option': '克漏字選項',

	'translation.exercise.slot.content': '填空內容',

	'translation.test.noTest': '目前沒有試卷',

	'translation.course.assignCourse': '指派給翻譯者',
	'translation.course.assignCourse.success': '指派課程成功',
	'translation.course.assignCourse.fail': '指派給課程失敗',

	'translation.translationManage.sidebar': '翻譯課程管理',
	'translation.translationManage.noCourse': '目前尚無相關課程',
	'translation.translationManage.assignedTeacher': '指派教師',
	'translation.translationManage.assignedTimestamp': '指派日期',
	'translation.translationManage.submittedTimestamp': '提交日期',

	'translation.translationManage.tab.ongoing': '翻譯中',
	'translation.translationManage.tab.ended': '翻譯完成',

	'translation.translationManage.edit.title': '編輯指派翻譯課程',

	'translation.translationManage.delete.title': '刪除指派翻譯課程',
	'translation.translationManage.delete.content': '請確認是否刪除指派翻譯課程，翻譯人員將無法再存取該課程。',
};

const shareCourseModal = {
	'shareCourse.modal.confirm.titleDev': '分享課程？',
	'shareCourse.modal.confirm.bodyDev': '請確認是否將課程分享至相關單位或老師。',
};

const dataSyncPage = {
	'dataSync.page.sidebar': '資料同步管理',
	'dataSync.page.headerInfo': '跟報名表/CRM/家長平台相關串連的表單，都列在下方，請點擊同步資料按鈕',

	'dataSync.btn.syncData': '同步資料',
};

const crm = {
	'crm.header': 'CRM學生資料管理',

	'crm.searchResult': '搜尋結果：共 {{resultLength}} 筆 "{{searchTerm}}" 相符客戶資料',

	'crm.action.sendEmail': '寄送信件',
	'crm.action.on.sendEmail': '寄送信件中',
	'crm.action.sendEmail.success': '寄送信件成功！',
	'crm.action.sendEmail.fail': '寄送信件失敗！',
	'crm.action.sendSms': '發送簡訊',

	'crm.email.title.addEmail': '新增信件',
	'crm.email.tab.template': '模板選擇',

	'crm.email.modal.chooseTemplate': '選擇模板',
	'crm.email.modal.chooseRegisForm': '課程名稱與報名表代碼',
	'crm.email.modal.chooseRegisClass': '班級名稱',
	'crm.email.modal.bring': '攜帶物品',
	'crm.email.modal.bring.device': '準備設備',
	'crm.email.modal.preparation': '課前準備',
	'crm.email.modal.image': '登入範例圖片',
	'crm.email.modal.note': '注意事項',
	'crm.email.modal.videoNote': '視訊軟體使用教學',
	'crm.email.modal.videoDoc': '上傳視訊軟體教學文件',
	'crm.email.modal.teachBasic': '基本教學',
	'crm.email.modal.stepsDoc': '步驟教學文件',
	'crm.email.modal.emailHeading': '大標題',
	'crm.email.placeholder.emailHeading': '請輸入大標題',
	'crm.email.modal.emailContent': '信件內容',
	'crm.email.placeholder.emailContent': '請輸入信件內容',
	'crm.email.modal.isSendCampCertificate': '營隊證書下載',
	'crm.email.modal.download.CampCertificate': '下載參加證書',
	'crm.email.advanced.settings': '進階設定',
	'crm.email.modal.isSendCampAlbums': '活動相簿',
	'crm.email.modal.campAlbumLink': '營隊活動照片相簿連結',
	'crm.email.modal.checkout.campAlbumLink': '查看活動照片',
	'crm.email.placeholder.campAlbumLink': '輸入相簿連結',
	'crm.email.modal.courseRecommend': '選擇推薦課程(至多兩項)',
	'crm.email.modal.signature': '簽名檔設定',
	'crm.email.modal.continuation': '選擇續班課程(至多一項)',
	'crm.email.modal.testName': '測驗名稱',
	'crm.email.modal.certificateLink': '檢定證書連結',
	'crm.email.placeholder.certificateLink': '請貼上 URL',
	'crm.email.modal.emailTitle': '信件主旨',
	'crm.email.placeholder.emailTitle': '輸入信件主旨',
	'crm.email.modal.testEmail': '測試信箱',
	'crm.email.modal.course.description': '課程介紹',
	'crm.email.modal.course.features': '課程特點',
	'crm.email.modal.picture.description': '圖片說明文字',
	'crm.email.modal.registration.link': '報名表連結',
	'crm.email.placeholder.testEmail': '輸入測試信箱',

	'crm.email.type.onSiteCourseNotice': '實體開課通知',
	'crm.email.type.offSiteCourseNotice': '遠距開課通知',
	'crm.email.type.accountNotice': '自學帳號開通通知',
	'crm.email.type.campAchievement': '營隊成果',
	'crm.email.type.courseAchievement': '學習報告評價',
	'crm.email.type.courseContinuation': '續班通知',
	'crm.email.type.certificate': '測驗證書',
	'crm.email.type.notice': '訊息公告',

	'crm.email.type.onSiteCourseNotice.subLabel': '若需要上傳教室位置圖片，每張圖片建議尺寸寬度為 480px',
	'crm.email.type.offSiteCourseNotice.subLabel': '請確認視訊軟體的安裝說明文件連結是否正確，或需要更新內容',
	'crm.email.type.accountNotice.subLabel': '請確認步驟教學文件等內容是否正確',
	'crm.email.type.campAchievement.subLabel': '請確認營隊相簿連結，以及相關證書是否備齊，再發送信件',
	'crm.email.type.courseAchievement.subLabel':
		'1. 發送學習報告前，請先確認該課程已完成教師評語審核流程\n2. 若學生無該課程，或該課程尚未有學習報告時，該學生的信件將自動略過不寄',
	'crm.email.type.courseContinuation.subLabel': '選擇續班班級時，可檢視內容',
	'crm.email.type.certificate.subLabel': '選擇續班班級時，可檢視內容',
	'crm.email.type.notice.subLabel': '本模板為自由填寫的空白公告，請謹慎使用',

	'crm.email.regis.lowestPrice': '單人最低價',
	'crm.email.otherSetting.subLabel': '設定完畢後，請先點擊下方的「寄送測試信」，確認無誤後再寄送給指定的客戶們',
	'crm.email.send.testMail': '寄送測試信',

	'crm.email.type.onSiteCourseNotice.subject': '【CodingBar】 開課通知信',
	'crm.email.type.offSiteCourseNotice.subject': '【CodingBar】 遠距開課通知信',
	'crm.email.type.accountNotice.subject': '【CodingBar】 線上自學帳號開通通知',
	'crm.email.type.campAchievement.subject': '【CodingBar】 營隊成果/活動照片',
	'crm.email.type.courseAchievement.subject': '【CodingBar】 學習報告/評價',
	'crm.email.type.courseContinuation.subject': '【CodingBar】 續班通知',
	'crm.email.type.certificate.subject': '【CodingBar】 測驗通過證書',
	'crm.email.type.notice.subject': '【CodingBar】 訊息公告通知',

	'crm.email.title.coming.course': '即將開課，請注意',
	'crm.email.title.active.account': '帳號已開通，請注意',
	'crm.email.title.study.hard': '努力不懈，用心學習',
	'crm.email.title.keep.learning': '持續學習',
	'crm.email.title.fruitful.result': '努力不懈，成果豐碩！',

	'crm.email.alert.course.not.created': '此課程尚未被建立!',
};

const manualEmailTemplate = {
	'manualEmailTemplate.linkButton.login': '登入 CodingBar',
	'manualEmailTemplate.linkButton.step': '查看步驟教學',
	'manualEmailTemplate.linkButton.register.now': '立即報名',

	'manualEmailTemplate.sectionRecommendedCourse.note.1': '精選課程推薦',
	'manualEmailTemplate.sectionRecommendedCourse.note.2': '本次活動課程帶給',
	'manualEmailTemplate.sectionRecommendedCourse.note.3': '滿滿收穫！',
	'manualEmailTemplate.sectionRecommendedCourse.note.4':
		'以下，將為您推薦適合的其它後續課程，請參考下方連結，或透過 Line@ 取得更多資訊！',

	'manualEmailTemplate.recommendedCourseBlock.linkButton.text': '查看更多',

	'manualEmailTemplate.mailAddress.class': '報名班別',
	'manualEmailTemplate.mailAddress.classRoom': '教室地點',

	'manualEmailTemplate.studentInfo.studentEmail': '學生帳號',

	'manualEmailTemplate.thankRegisterContent.note.1': '感謝您報名',
	'manualEmailTemplate.thankRegisterContent.note.2':
		'，您可直接用報名的email 直接登入 CodingBar 學習平台，便可開始程式學習之旅！在開始使用之前，請閱讀以下事項：',

	'manualEmailTemplate.lineContact.note.1':
		'感謝您的參與和支持，如有任何問題，請於上班時間電洽 (02) 7717-9386 ，或透過官方 Line@ 與我們聯絡！',
	'manualEmailTemplate.lineContact.note.2': '立即加入Line@',
	'manualEmailTemplate.lineContact.note.3': '此為系統信件，請勿直接回覆',
	'manualEmailTemplate.lineContact.default.join':
		'您可點擊下方按鈕，下載關於 CodingBar 平台系統的使用教學文件，若有其他使用上的問題，也可加入 Line@ 獲得更多資訊！',

	'manualEmailTemplate.toBring.default.computer.1':
		'若您是自備筆記型電腦，請注意 Windows 系統需 64 位元，Mac 系統需 os 10.13 以上系統',
	'manualEmailTemplate.toBring.default.computer.2':
		'請注意電腦系統若採用 Windows 系統需 64 位元，Mac 系統需 os 10.13 以上版本',
	'manualEmailTemplate.toBring.default.pad': '若您採用平板裝置，請備妥搭配的鍵盤，以利課程中方便輸入程式碼進行學習',
	'manualEmailTemplate.toBring.default.other': '筆、水杯、個人餐具及個人藥品',

	'manualEmailTemplate.toPrepare.default.chrome': '使用 Chrome 瀏覽器進入 CodingBar 平台',
	'manualEmailTemplate.toPrepare.default.system.account': '系統帳號將於',
	'manualEmailTemplate.toPrepare.default.system.test.login.1':
		'開通，請於開始前先登入系統測試，如有問題請加入 Line@ 詢問客服人員',
	'manualEmailTemplate.toPrepare.default.system.test.login.2':
		'開通，請於課程前確認您能順利登入我們的教學網頁，如有問題請於 line@ 提出',
	'manualEmailTemplate.toPrepare.default.zoom.1': '上課需使用 zoom 會議系統，請',
	'manualEmailTemplate.toPrepare.default.zoom.2': '提前 5 分鐘',
	'manualEmailTemplate.toPrepare.default.zoom.3': '進入 zoom 會議室測試系統避免影響上課權益',
	'manualEmailTemplate.toPrepare.default.zoom.4': '請事先測試麥克風與視訊鏡頭',
	'manualEmailTemplate.toPrepare.default.zoom.5': '為了辨識上課學員身份，請您務必',
	'manualEmailTemplate.toPrepare.default.zoom.6': '使用本名進入會議室',

	'manualEmailTemplate.toPrepare.default.video.1': '您可以透過兩種方式加入會議',
	'manualEmailTemplate.toPrepare.default.video.2': '您的電腦已安裝 Zoom 軟體，可直接開啟軟體並加入會議',
	'manualEmailTemplate.toPrepare.default.video.3': '無論您是否有安裝Zoom軟體，都可以透過瀏覽器連線進入',

	'manualEmailTemplate.toNote.default.mask': '學生於課堂營隊中，須全程佩戴口罩',
	'manualEmailTemplate.toNote.default.fever':
		'請配合工作人員測量體溫及消毒措施，若有相關接觸史或超過 37.5 度與發燒症狀，將協助辦理退費',

	'manualEmailTemplate.emailContent.default.dear': '親愛的',
	'manualEmailTemplate.emailContent.default.course.end.1':
		'這次的課程營隊圓滿結束，感謝對CodingBar的支持，我們提供給您',
	'manualEmailTemplate.emailContent.default.course.end.2': '的活動證書在下方連結，同時也提供本次課程營隊的照片給您參考',
	'manualEmailTemplate.emailContent.default.course.end.3': '有任何需要進一步了解的地方，歡迎隨時讓我們知道喔！',

	'manualEmailTemplate.emailContent.default.course.about.to.end.1': '已進入尾聲，講師針對 ',
	'manualEmailTemplate.emailContent.default.course.about.to.end.2':
		'的課程表現與學習建議建議如下，提供給您參考；同時，我們也在下方提供了一份學習報告連結，若有任何需要進一步了解的地方，請與我們聯絡喔～',

	'manualEmailTemplate.emailContent.default.congratulation.1': '恭喜完成',
	'manualEmailTemplate.emailContent.default.congratulation.2':
		'的課程！在學習的旅途中，我們提供六階段漸進式課程，讓學生分段具備微軟 MTA 和 aAPCS 等認證實力！下一期',
	'manualEmailTemplate.emailContent.default.congratulation..3': '將帶領孩子繼續學習，歡迎報名。',

	'manualEmailTemplate.emailContent.default.signature': '學生關懷部',

	'manualEmailTemplate.emailContent.default.notice.1':
		'感謝您在學習的道路上努力不懈，也在課程最後參與檢定測驗來檢視您的學習成果！',
	'manualEmailTemplate.emailContent.default.notice.2':
		'下方測驗成績供您參考，同時我們提供檢定證書予以鼓勵，歡迎透過下方連結下載。',
};

const parentEmailTemplate = {
	'parentEmailTemplate.signUp': '報名',
	'parentEmailTemplate.waiting': '候補',

	'parentEmailTemplate.x1.title': '報名成功，歡迎加入',
	'parentEmailTemplate.x2.title': '繳費完成，敬請期待',
	'parentEmailTemplate.x3.title': '請認證您的信箱',
	'parentEmailTemplate.x4.title': '重設密碼',
	'parentEmailTemplate.x5.title': '名額有限，請勿錯過',
	'parentEmailTemplate.x6.title': '候補完成，靜候佳音',

	'parentEmailTemplate.x1.innerTextBottom1':
		'我們提供多種付款方式，課程名額將視繳費順序進行保留，請點選下方繳費連結，並於',
	'parentEmailTemplate.x1.innerTextBottom2': '三天之內',
	'parentEmailTemplate.x1.innerTextBottom3': '繳費完畢，以確保您的上課權益。',
	'parentEmailTemplate.x1.innerTextBottom4':
		'完成繳費後，您將收到繳費確認信，請將 service@codingbar.ai 設為常用聯絡人，才不會漏掉重要通知唷！',
	'parentEmailTemplate.x1.innerTextBottom5': '請將 service@codingbar.ai 設為常用聯絡人，才不會漏掉重要通知唷！',

	'parentEmailTemplate.x1_simple.innerTextBottom1':
		'此外，別忘記點擊下方連結，填寫地址與學生資料，包含姓名、郵件信箱、學生聯絡電話等相關資訊，以利行政作業程序！',

	'parentEmailTemplate.x1.linkButton': '立即前往繳費',
	'parentEmailTemplate.x1_simple.linkButton': '填寫學生資訊',
	'parentEmailTemplate.x3.linkButton': '認證信箱',
	'parentEmailTemplate.x4.linkButton': '重設密碼',
	'parentEmailTemplate.x5.linkButton': '立即前往繳費',

	'parentEmailTemplate.x2.innerTextTop1': '您已完成',
	'parentEmailTemplate.x2.innerTextTop2': '的報名繳費程序，我們已收到課程款項，將於',
	'parentEmailTemplate.x2.innerTextTop3': '開課前一週',
	'parentEmailTemplate.x2.innerTextTop4':
		'寄發開課通知；請務必將 service@codingbar.ai 設為常用聯絡人，避免遺漏後續重要通知。',

	'parentEmailTemplate.x3.innerTextBottom1': '歡迎您註冊 CodingBar 與我們一起開始程式學習的道路！',
	'parentEmailTemplate.x3.innerTextBottom2':
		'請點擊下方連結認證並開通帳號，就能開始享受 CodingBar 程式學習平台的強大功能唷。',

	'parentEmailTemplate.x4.innerTextTop1':
		'請點擊下方連結重新設定您的 CodingBar 帳號密碼，若您沒有申請過重新設定密碼，請忽略此封信。',

	'parentEmailTemplate.x5.innerTextTop1': '感謝您報名',
	'parentEmailTemplate.x5.innerTextTop2': '，由於名額有限，請參考下列繳費資訊，並敬請於',
	'parentEmailTemplate.x5.innerTextTop3':
		'前完成繳費，屆時如未繳費CodingBar將會釋出您的名額給其他候補學員，不另行通知，敬請見諒。',

	'parentEmailTemplate.x6.innerTextTop1': '您已完成',
	'parentEmailTemplate.x6.innerTextTop2':
		'的候補程序，並排入候補名單中，若候補成功，將有專人與您聯絡；請務必將 service@codingbar.ai 設為常用聯絡人，避免遺漏後續重要通知。',
};

const registrationDownloadPage = {
	'registration.download.tablabel.open': '課程(開放)',
	'registration.download.tablabel.closed': '課程(關閉)',
	'registration.download.tablabel.tutorOpen': '家教(開放)',
	'registration.download.tablabel.tutorClosed': '家教(關閉)',

	'registration.download.sortlabel.name': '名稱',
	'registration.download.sortlabel.formId': '報名表代碼',
	'registration.download.sortlabel.formAttributeSelected': '報名表種類',

	'registration.download.headerlabel.name': '班級名稱',
	'registration.download.headerlabel.courseCid': '班級代碼',
	'registration.download.headerlabel.placeSelected': '班級地區',
	'registration.download.headerlabel.classSelected': '班級序號',
	'registration.download.headerlabel.courseStartAndEndDate': '班級日期',
	'registration.download.headerlabel.classTime': '班級時間',

	'registration.download.iconMenu.view': '檢視資料',
	'registration.download.searchBox.placeholder': '輸入關鍵字或{{mode}}',

	'registration.download.dumpBtn.text': '下載資料',
	'registration.download.settingModal.confirmBtn.text': '確認下載',

	'registration.download.settingModal.title': '下載設定',
	'registration.download.settingModal.note1': '下載檔案為 CSV 格式，請匯入 google sheet 使用',
	'registration.download.settingModal.note2': '多份報名表合併下載時，會以不同檔案形式呈現',
	'registration.download.settingModal.note3': '可設定報名/繳費時間區間',
	'registration.download.settingModal.basicSettings': '基本設定',
	'registration.download.settingModal.basicSettings.paid': '已繳費',
	'registration.download.settingModal.basicSettings.oldmember': '是否為舊生',
	'registration.download.settingModal.payMethodSelected': '繳費種類',
	'registration.download.settingModal.payMethodSelected.credit': '信用卡',
	'registration.download.settingModal.payMethodSelected.atm': 'ATM',
	'registration.download.settingModal.payMethodSelected.cvs': '超商',
	'registration.download.settingModal.timeSelected.title': '時間區間屬性',
	'registration.download.settingModal.timeSelected.paid': '已繳費',
	'registration.download.settingModal.timeSelected.registed': '已報名',

	'registration.download.settingModal.begin.date': '請選擇起始日期',
	'registration.download.settingModal.end.date': '請選擇截止日期',
	'registration.download.settingModal.begin.time': '請選擇起始時間',
	'registration.download.settingModal.end.time': '請選擇截止時間',
	'registration.download.settingModal.alert.end.date': '截止日期不能早於起始日期',
	'registration.download.settingModal.alert.end.time': '截止時間不能早於起始時間',

	'registration.download.settingModal.clearBeginTime': '清空起始欄位',
	'registration.download.settingModal.clearCutoffTime': '清空截止欄位',

	'registration.download.switch.courseCid': '班級代碼',
	'registration.download.switch.formId': '報名表代碼',
};

const crmManage = {
	'crmManage.main.sidebar.crm-student': 'CRM 報名資料',
	'crmManage.main.sidebar.crm': 'CRM 資料管理',

	'crmAccountManage.sidebar.name': 'CRM 帳號管理',
	'crmAccountManage.iconMenu.switch': '切換 CRM 權限',
	'crmAccountManage.confirm.title': '切換成 CRM 權限？',
	'crmAccountManage.confirm.body': '請確認是否將此帳號\n{{uid}}\n切換為具有 CRM 權限(此操作無法還原)',
};

const internalMailManage = {
	'internalMailManage.sidebar.label': '員工寄信管理',

	'internalMailManage.page.headerInfo': '內部人員相關寄信需求，請自行填寫加入',
	'internalMailManage.editmail.btn': '編輯收信人',
	'internalMailManage.createmail.btn': '新增收信名單',

	'internalMailManage.key.title': '請輸入 key',
	'internalMailManage.title.title': '請輸入標題',
	'internalMailManage.emailContent.title': '請輸入 email 列表',
};

const toolsPage = {
	'toolsPage.tools': '常用小工具',
	'toolsPage.tools.CSFC.download.title': 'CSFC 證書製作及下載',
	'toolsPage.tools.CSFC.download.subtitle': '請參照CSV格式製作名單，匯入名單至系統並製作為證書。',
	'toolsPage.tools.CSFC': '製作證書',
	'toolsPage.CertificateGenerator.title': '證書設定及下載',
	'toolsPage.CertificateGenerator.note.1': '學生名單請參考',
	'toolsPage.CertificateGenerator.note.2': '範本檔案',
	'toolsPage.CertificateGenerator.note.3':
		'，依格式將名單製作為 csv 檔並匯入。若開啟 csv 範本為亂碼，請將編碼變更為 unicode，或使用 google 試算表開啟。',
	'toolsPage.CertificateGenerator.form.exam': '檢定項目',
	'toolsPage.CertificateGenerator.form.exam.date': '檢定日期',
	'toolsPage.CertificateGenerator.form.exam.level': '等級',
	'toolsPage.CertificateGenerator.form.exam.certificate.date': '發證日期',
	'toolsPage.CertificateGenerator.form.exam.names': '證書頒發名單',
	'toolsPage.CertificateGenerator.form.exam.names.placeholder': '請輸入學生姓名，每位學生姓名為一行',
	'toolsPage.CertificateGenerator.import.CSV': 'CSV 檔案匯入名單',
	'toolsPage.CertificateGenerator.download.Certificate': '下載證書',
	'toolsPage.tools.certificate.download.title': '結業證書製作及下載',

	'toolsPage.CertificateGenerator.download.completed': '下載完成',
	'toolsPage.CertificateGenerator.download.completed.note': '證書已下載完成，請至下載資料夾查看。',
	'toolsPage.CertificateGenerator.generating': '生成中...',

	'toolsPage.tools.createAccount.school.title': '校園授權方案開通',
	'toolsPage.tools.createAccount.school.subtitle': '請根據申請試用表單，填寫對應資料並生成課程。',
	'toolsPage.tools.createAccount': '開通帳號',
	'toolsPage.tools.createAccount.school.completed': '已開通帳號',
	'toolsPage.tools.createAccount.school.completed.body': '帳號與課程已建立，系統已寄送通知信件。',

	'toolsPage.tools.createAccount.info.email': '申請者 Email',
	'toolsPage.tools.createAccount.info.unit': '申請者單位',
	'toolsPage.tools.createAccount.info.displayName': '申請者姓名',
	'toolsPage.tools.createAccount.info.phone': '申請者聯絡電話',
	'toolsPage.tools.createAccount.info.trialPlan': '申請方案',
	'toolsPage.schoolAuthorizeTrial.info.cloneCourse': '複製課程來源',

	'toolsPage.schoolAuthorizeTrial.note.1': '請填寫申請者相關資訊並選取方案，將根據不同方案提供對應課程與服務內容。',
	'toolsPage.schoolAuthorizeTrial.info.planFree': '免費',
	'toolsPage.schoolAuthorizeTrial.info.plan1': '方案1',
	'toolsPage.schoolAuthorizeTrial.info.plan2': '方案2',

	'toolsPage.schoolAuthorizeTrial.generating': '正在創建帳號課程中，請耐心等候',
	'toolsPage.schoolAuthorizeTrial.account.exists': '此帳號已存在',
	'toolsPage.schoolAuthorizeTrial.inValidCourseId': '請輸入正確的課程 Id',

	'toolsPage.schoolAuthorizeTrial.sendEmailTitle': 'CodingBar 校園授權方案免費試用體驗開通',
	'toolsPage.schoolAuthorizeTrial.apply.success': 'CodingBar 校園授權方案試用申請成功',
	'toolsPage.schoolAuthorizeTrial.apply.success.content': '請至信箱收信，並且啟用帳號。',
	'toolsPage.schoolAuthorizeTrial.generating.fail': 'CodingBar 校園授權方案試用開通失敗',
	'toolsPage.schoolAuthorizeTrial.note.2':
		'此按鈕將更新"複製課程來源"欄位資料至資料庫，套用在"校園方案試用"自動與手動流程。',
	'toolsPage.schoolAuthorizeTrial.note.3':
		'只有 courseId 為"006f40ef-abe0-4c4a-b07d-c51e6d14f597-1662540906827" 會創建 dummy 學生作答記錄',
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db': '更新複製課程來源 DB',
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db.note':
		'是否要將目前"複製課程來源"欄位資料更新至資料庫，請再三確認!!!',
	'toolsPage.schoolAuthorizeTrial.unit.alert': '單位不可為空或 airabbi!',
	'schoolAuthorizeTrial.alreadyApplied.content':
		'此帳號偵測已申請過免費體驗課程，請直接點選下方連結登入平台後體驗，如體驗時間已結束，請聯絡客服。',
	'schoolAuthorizeTrial.done.content':
		'您的體驗課程已開通，請點選下方連結後進行課程體驗！系統將於 14 天後結束體驗，如需正式版請聯絡客服。',
	'schoolAuthorizeTrial.userExistInOtherUnit.content': '帳號已存在於其他單位! 請洽客服人員。',

	'toolsPage.schoolCAGenerator.title': '校園 Usb 憑證製作',
	'toolsPage.schoolCAGenerator.subtitle': '請根據欄位填寫對應資料並生成憑證資料。',
	'toolsPage.schoolCAGenerator.btn': '製作憑證',
	'toolsPage.schoolCAGenerator.completed': '憑證完成',
	'toolsPage.schoolCAGenerator.completed.note': '憑證已下載完成，請由下載資料夾將檔案放至 Usb 內。',

	'toolsPage.schoolCAGenerator.note.1': '請根據欄位填寫對應資料並生成憑證資料。',

	'toolsPage.schoolCAGenerator.info.email': '憑證教師 Email',
	'toolsPage.schoolCAGenerator.info.unit': '憑證教師單位',
	'toolsPage.schoolCAGenerator.info.displayName': '憑證教師姓名',
	'toolsPage.schoolCAGenerator.info.phone': '憑證教師聯絡電話',

	'toolsPage.schoolCAGenerator.info.beginDate': '憑證有效起始日期',
	'toolsPage.schoolCAGenerator.info.endDate': '憑證有效截止日期',
	'toolsPage.schoolCAGenerator.info.useLimit': '憑證有效人數',

	'toolsPage.schoolCAGenerator.info.inValidUseLimit': '請輸入正確的人數數量',

	'toolsPage.schoolCAGenerator.generating': '正在創建憑證中，請耐心等候',

	'toolsPage.financialReportTransfer.title': '綠界每月財務入帳金額統計',
	'toolsPage.financialReportTransfer.subtitle': '請將綠界報表上傳並確認開課班級管理表資料後進行轉換。',
	'toolsPage.financialReportTransfer.btn': '上傳報表',
	'toolsPage.financialReportTransfer.completed': '轉換完成',
	'toolsPage.financialReportTransfer.completed.note': '報表已轉換完成，請開啟下載資料夾觀看檔案。',

	'toolsPage.financialReportTransfer.generating': '正在轉換報表中，請耐心等候',

	'toolsPage.financialReportTransfer.note.1': '請上傳綠界報表(.xlsx) 並確認',
	'toolsPage.financialReportTransfer.note.2': '開課班級管理表',
	'toolsPage.financialReportTransfer.note.3': '資料中，使用到的 課程row 於欄位 BE, BF 中有內容。',
	'toolsPage.financialReportTransfer.dataIsLoading': '載入外部資料【開課班級管理表】中...',
	'toolsPage.financialReportTransfer.dataLoadingDone': '載入完成！',

	'toolsPage.dataError': '資料有誤',
	'toolsPage.LaborReportGenerator.title': '勞報單產生器',
	'toolsPage.LaborReportGenerator.generating': '正在產生 Pdf 並寄送，請耐心等候',
	'toolsPage.LaborReportGenerator.subtitle':
		'請使用 A1-006 每個月匯出的CSV檔，匯入此處後自動生成對應的勞報單，並自動寄送到對應信箱中',
	'toolsPage.LaborReportGenerator.subtitle2': '1. 圖片檔案格式為 jpg, 請轉檔後壓縮 ',
	'toolsPage.LaborReportGenerator.subtitle3': '2. 上傳檔案名稱結尾請符合格式 YYYY-MM',
	'toolsPage.LaborReportGenerator.done': '操作完成',

	'toolsPage.teacherSchedule': '教師行事曆',
	'toolsPage.autoJoinCourses.title': '自動開通課程名單設定',
	'toolsPage.autoJoinCourses.subtitle':
		'線上自學課程、CBA 課程，繳費完後，根據 10 碼對應到的課程 ID ，自動開通課程到學生的帳號。',
	'toolsPage.autoJoinCourses.note':
		'請將開課班級管理表 B 欄位 10 碼與對應的 Course ID 貼入下方 textfield ，並以半形逗號分開',
	'toolsPage.autoJoinCourses.title.classId': '班級代碼 (B 欄位)',
	'toolsPage.AutoJoinCoursesManage.delete.title': '刪除課程名單',
	'toolsPage.AutoJoinCoursesManage.delete.content': '確認是否刪除課程名單: {{key}}',
	'toolsPage.AutoJoinCoursesManage.delete.content2': '注意！課程時限設定也將一併刪除!',
	'toolsPage.CourseValidityPeriod.title': '課程時限設定',
	'toolsPage.CourseValidityPeriod.subtitle': '開課班級管理表 B 欄位 10 碼的時限方案設定',
	'toolsPage.CourseValidityPeriod.note': '選擇 B 欄位 10 碼後設定時限，以天為單位',
	'toolsPage.CourseValidityPeriod.delete.title': '刪除課程時限',
	'toolsPage.CourseValidityPeriod.delete.content': '確認是否刪除課程時限: {{key}}',
	'toolsPage.CourseValidityPeriod.confirmation.title': '課程方案到期通知',
	'toolsPage.CourseValidityPeriod.confirmation.content':
		'您購買的課程 {{title}} 已於 {{expiryTime}} 到期，\n如欲延長課程時間，請聯絡客服，謝謝！',
};

const batchTeacherAccountModal = {
	'batchTeacherAccountModal.content':
		'請匯入 .xlsx 檔案，第一欄為姓名，第二欄為 email，並注意工作表名稱應設定為 sheet1',
	'batchTeacherAccountModal.generating': '正在批次創建帳號中，請耐心等候',
};

const globalTestModal = {
	'globalTest.score.sendEmailComplete':
		'完成！您現在可以點擊確認按鍵離開了，\n提醒您，成績結果請稍候於您的信箱內檢視，如未收到信件，請先確認您的垃圾信箱。',
	'globalTest.score.progressBar.label1': '正在計算考試成績...',
	'globalTest.score.progressBar.label2': '正在產生成績報表...',
	'globalTest.score.progressBar.label3': '正在寄送結果信件...',
	'globalTest.score.progressBar.complete': '完成！',
};

const csfcStudentAccountModal = {
	'csfcStudentAccountModal.title': '匯入 CSFC 報名資訊',
	'csfcStudentAccountModal.warning.repeatEmail': '已有報名紀錄資料，將不再重複報名。',
	'csfcStudentAccountModal.warning.testId.not.exist': '您匯入的 csv 檔有誤，請再次確認檔案內容。',
	'csfcStudentAccountModal.warning.wrong.testId': '該場次不存在，請再次確認檔案內容。',
	'csfcStudentAccountModal.warning.wrong.unit': '所屬單位有誤或未填寫，請再次確認檔案內容。',
	'csfcStudentAccountModal.import.downloadNotes-2': '(1) 請下載對應的報名場次 CSV 檔案',
	'csfcStudentAccountModal.import.downloadNotes-3':
		'，依格式將名單製作為 csv 檔並匯入，系統產生學生註冊資料；若開啟 csv 範本為亂碼，請將',
	'csfcStudentAccountModal.import.downloadNotes-8': '；若該學生已報名同一場次時，將無法勾選匯入。',

	'csfcStudentAccountModal.sendEmailTitle': '【CSFC 檢測系統報名】',

	'csfcVerifyPage.success.title': 'CSFC 檢測報名成功！',
	'csfcVerifyPage.success.content':
		'您好，您已確認報名 CSFC 檢測成功，請記得於檢測時間進入檢測系統(https://exam.csfcasia.org)，如有問題請點選下方 line 與我們聯繫，謝謝。',

	'csfcVerifyPage.fail.title': 'CSFC 檢測報名資料錯誤',
	'csfcVerifyPage.fail.content':
		'您好，您的 CSFC 檢測連結似乎有誤，請再次確認您是否點選正確連結，或是透過下方 line 與我們聯繫，謝謝。',

	'csfcStudentAccountModal.toast.importDone': '帳號全數創建完成！',
	'csfcStudentAccountModal.test.evet.time': '場次時間',
	'csfcStudentAccountModal.test.evet.title': '場次項目',
	'csfcStudentAccountModal.test.evet.csv.file': 'CSV 報名檔',
	'csfcStudentAccountModal.tab1.label': '下載報名場次 CSV 檔案',
	'csfcStudentAccountModal.tab2.label': '匯入 CSV',
	'csfcStudentAccountModal.manual.handIn': '手動交卷',
	'csfcStudentAccountModal.manual.handIn.success': '手動交卷成功',
	'csfcStudentAccountModal.manual.handIn.confirmation.title': '手動交卷 CSFC 檢測結果',
	'csfcStudentAccountModal.manual.handIn.confirmation.content': '該學生尚未交卷，手動交卷將紀錄檢測結果並寄送給學生。',
	'CSFCExamPage.info': '應考人資訊',
	'CSFCExamPage.exam.note': 'CSFC 資訊科學能力檢定考試注意事項：',
	'CSFCExamPage.exam.note.guidebook': 'CSFC 考生指引手冊',
	'CSFCExamPage.exam.note.content0': '請在測驗開始前詳細閱讀',
	'CSFCExamPage.exam.note.content1': '作答時，請務必按下方的「提交」按鈕，並透過左側題目選單確認是否提交答案',
	'CSFCExamPage.exam.note.content2':
		'完成測驗，按下「交卷」按鈕結束測驗，或測驗時間到將由系統自動交卷；你將於五分鐘內收到一份測驗結果。',
	'CSFCExamPage.exam.note.content3': '若有網路中斷或其他狀況發生，可於測驗結束前回到平台繼續作答。',
	'CSFCExamPage.exam.events': '考試場次',
	'CSFCExamPage.exam.result.concept': '觀念題考試',
	'CSFCExamPage.exam.result.implement': '實作題考試',
	'CSFCExamPage.exam.test.time': '考試時間',
	'CSFCExamPage.exam.button.status.doing': '進行中',
	'CSFCExamPage.exam.button.status.start': '開始測驗',
	'CSFCExamPage.exam.button.status.done': '已交卷',
	'CSFCExamPage.exam.button.status.invalid': '尚未驗證',
	'CSFCExamPage.exam.button.status.countdown1': '',
	'CSFCExamPage.exam.button.status.countdown2': '後開始',
	'CSFCExamPage.exam.failed': '未通過',
	'CsfcStudentManage.modal.title': '檢視 CSFC 學生資料',
	'CsfcStudentManage.modal.title.validation': '報名驗證',
	'CsfcStudentManage.modal.title.row.score': '分數(觀念,實作)',
	'CsfcStudentManage.student.valid': '已驗證',
	'CsfcStudentManage.no.students': '尚未匯入 CSFC 學生',
};

const chatGPT = {
	'UnitPermissionsManagePage.title': '單位功能權限管理',
	'chatGPTManage.modal.preview.selectedCourse': '檢視已選課程',
	'chatGPTManage.modal.preview.selectedCourse.undo': '取消檢視已選課程',
	'chatGPTManage.modal.preview.selectedCourse.no.courseIds': '尚未選擇任何課程',
	'chatGPTManage.modal.title': 'chatGPT 權限管理',
	'chatGPTManage.confirmation.modal.title': '編輯 chatGPT 權限',
	'chatGPTManage.confirmation.modal.content': '請確認是否編輯該單位的 chatGPT 權限',
	'chatGPTManage.authorizationType.title': '權限設定',
	'chatGPTManage.authorizationType.unit': '單位',
	'chatGPTManage.authorizationType.course': '課程',
	'chatGPTManage.authorizationType.none': '不開放',
	'chatGPTManage.authorizationType': 'chatGPT 類型',
	'chatGPTManage.authorizationType.toast.title': 'chatGPT 權限, {{status}}',
	'chatGPTManage.noPromptLimit': 'Prompt 無限制',
	'exerciseAssistant.header.label': '獨眼怪 AI',
	'exerciseAssistant.chatinput.placeholder': '來問獨眼怪小助手吧！',
	'exerciseAssistant.chatinput.loading': '...',
	'exerciseAssistant.messages.placeholder': '你好，跟程式有關的問題，我都能盡可能回答你喔！',
	'exerciseAssistant.messages.preset': '預設',
	'exerciseAssistant.messages.tokens.alert': '您的輸入超過 tokens 使用上限',
	'multiExerciseTypeManage.title': '編輯多元題型權限',
	'multiExerciseTypeManage.type': '多元題型',
	'multiExerciseTypeManage.confirmation.content': '請確認是否編輯該單位的多元題型權限',
	'multiExerciseTypeManage.type.title': '開放設定',
	'multiExerciseTypeManage.type.all': '全選',
	'multiExerciseTypeManage.type.basic': '基本',
	'multiExerciseTypeManage.type.paid': '付費',
	'multiExerciseTypeManage.toast.title': '編輯多元題型權限, {{status}}',
	'multiExerciseTypeManage.exercise.type.basic': '基本題型選擇',
	'multiExerciseTypeManage.exercise.type.advanced': '進階題型選擇',
	'chatGPT.nothing.to.delete': '沒有對話內容！',
	'chatGPT.alert.limit': '您輸入的內容已經超過單筆對話的字數上限',
};

const general = {
	'AF': '阿富汗',
	'AX': '奧蘭',
	'AL': '阿爾巴尼亞',
	'DZ': '阿爾及利亞',
	'AS': '美屬薩摩亞',
	'AD': '安道爾',
	'AO': '安哥拉',
	'AI': '安圭拉',
	'AQ': '南極洲',
	'AG': '安地卡及巴布達',
	'AR': '阿根廷',
	'AM': '亞美尼亞',
	'AW': '阿魯巴',
	'AU': '澳洲',
	'AT': '奧地利',
	'AZ': '亞塞拜然',
	'BS': '巴哈馬',
	'BH': '巴林',
	'BD': '孟加拉',
	'BB': '巴貝多',
	'BY': '白俄羅斯',
	'BE': '比利時',
	'BZ': '貝里斯',
	'BJ': '貝南',
	'BM': '百慕達',
	'BT': '不丹',
	'BO': '玻利維亞',
	'BQ': '荷蘭加勒比區',
	'BA': '波士尼亞與赫塞哥維納',
	'BW': '波札那',
	'BV': '布韋島',
	'BR': '巴西',
	'IO': '英屬印度洋領地',
	'BN': '汶萊',
	'BG': '保加利亞',
	'BF': '布吉納法索',
	'BI': '蒲隆地',
	'KH': '柬埔寨',
	'CM': '喀麥隆',
	'CA': '加拿大',
	'CV': '維德角',
	'KY': '開曼群島',
	'CF': '中非',
	'TD': '查德',
	'CL': '智利',
	'CN': '中國',
	'CX': '聖誕島',
	'CC': '科科斯（基林）群島',
	'CO': '哥倫比亞',
	'KM': '葛摩',
	'CG': '剛果共和國',
	'CD': '剛果民主共和國',
	'CK': '庫克群島',
	'CR': '哥斯大黎加',
	'CI': '象牙海岸',
	'HR': '克羅埃西亞',
	'CU': '古巴',
	'CW': '古拉索',
	'CY': '賽普勒斯',
	'CZ': '捷克',
	'DK': '丹麥',
	'DJ': '吉布地',
	'DM': '多米尼克',
	'DO': '多明尼加',
	'EC': '厄瓜多',
	'EG': '埃及',
	'SV': '薩爾瓦多',
	'GQ': '赤道幾內亞',
	'ER': '厄利垂亞',
	'EE': '愛沙尼亞',
	'ET': '衣索比亞',
	'FK': '福克蘭群島',
	'FO': '法羅群島',
	'FJ': '斐濟',
	'FI': '芬蘭',
	'FR': '法國',
	'GF': '法屬圭亞那',
	'PF': '法屬玻里尼西亞',
	'TF': '法屬南部和南極領地',
	'GA': '加彭',
	'GM': '甘比亞',
	'GE': '喬治亞',
	'DE': '德國',
	'GH': '迦納',
	'GI': '直布羅陀',
	'GR': '希臘',
	'GL': '格陵蘭',
	'GD': '格瑞那達',
	'GP': '瓜地洛普',
	'GU': '關島',
	'GT': '瓜地馬拉',
	'GG': '根西',
	'GN': '幾內亞',
	'GW': '幾內亞比索',
	'GY': '蓋亞那',
	'HT': '海地',
	'HM': '赫德島和麥克唐納群島',
	'VA': '梵蒂岡',
	'HN': '宏都拉斯',
	'HK': '香港',
	'HU': '匈牙利',
	'IS': '冰島',
	'IN': '印度',
	'ID': '印度尼西亞',
	'IR': '伊朗',
	'IQ': '伊拉克',
	'IE': '愛爾蘭',
	'IM': '曼島',
	'IL': '以色列',
	'IT': '義大利',
	'JM': '牙買加',
	'JP': '日本',
	'JE': '澤西',
	'JO': '約旦',
	'KZ': '哈薩克',
	'KE': '肯亞',
	'KI': '吉里巴斯',
	'KP': '北韓',
	'KR': '南韓',
	'KW': '科威特',
	'KG': '吉爾吉斯',
	'LA': '寮國',
	'LV': '拉脫維亞',
	'LB': '黎巴嫩',
	'LS': '賴索托',
	'LR': '賴比瑞亞',
	'LY': '利比亞',
	'LI': '列支敦斯登',
	'LT': '立陶宛',
	'LU': '盧森堡',
	'MO': '澳門',
	'MK': '北馬其頓',
	'MG': '馬達加斯加',
	'MW': '馬拉威',
	'MY': '馬來西亞',
	'MV': '馬爾地夫',
	'ML': '馬利',
	'MT': '馬爾他',
	'MH': '馬紹爾群島',
	'MQ': '馬丁尼克',
	'MR': '茅利塔尼亞',
	'MU': '模里西斯',
	'YT': '馬約特',
	'MX': '墨西哥',
	'FM': '密克羅尼西亞聯邦',
	'MD': '摩爾多瓦',
	'MC': '摩納哥',
	'MN': '蒙古',
	'ME': '蒙特內哥羅',
	'MS': '蒙特塞拉特',
	'MA': '摩洛哥',
	'MZ': '莫三比克',
	'MM': '緬甸',
	'NA': '納米比亞',
	'NR': '諾魯',
	'NP': '尼泊爾',
	'NL': '荷蘭',
	'NC': '新喀里多尼亞',
	'NZ': '紐西蘭',
	'NI': '尼加拉瓜',
	'NE': '尼日',
	'NG': '奈及利亞',
	'NU': '紐埃',
	'NF': '諾福克島',
	'MP': '北馬利安納群島',
	'NO': '挪威',
	'OM': '阿曼',
	'PK': '巴基斯坦',
	'PW': '帛琉',
	'PS': '巴勒斯坦',
	'PA': '巴拿馬',
	'PG': '巴布亞紐幾內亞',
	'PY': '巴拉圭',
	'PE': '秘魯',
	'PH': '菲律賓',
	'PN': '皮特凱恩群島',
	'PL': '波蘭',
	'PT': '葡萄牙',
	'PR': '波多黎各',
	'QA': '卡達',
	'RE': '留尼旺',
	'RO': '羅馬尼亞',
	'RU': '俄羅斯',
	'RW': '盧安達',
	'BL': '聖巴瑟米',
	'SH': '聖赫倫那、阿森松和特里斯坦-達庫尼亞',
	'KN': '聖克里斯多福及尼維斯',
	'LC': '聖露西亞',
	'MF': '法屬聖馬丁',
	'PM': '聖皮埃與密克隆群島',
	'VC': '聖文森及格瑞那丁',
	'WS': '薩摩亞',
	'SM': '聖馬利諾',
	'ST': '聖多美普林西比',
	'SA': '沙烏地阿拉伯',
	'SN': '塞內加爾',
	'RS': '塞爾維亞',
	'SC': '塞席爾',
	'SL': '獅子山',
	'SG': '新加坡',
	'SX': '荷屬聖馬丁',
	'SK': '斯洛伐克',
	'SI': '斯洛維尼亞',
	'SB': '索羅門群島',
	'SO': '索馬利亞',
	'ZA': '南非',
	'GS': '南喬治亞和南桑威奇群島',
	'SS': '南蘇丹',
	'ES': '西班牙',
	'LK': '斯里蘭卡',
	'SD': '蘇丹',
	'SR': '蘇利南',
	'SJ': '斯瓦爾巴和揚馬延',
	'SZ': '史瓦帝尼',
	'SE': '瑞典',
	'CH': '瑞士',
	'SY': '敘利亞',
	'TW': '台灣',
	'TJ': '塔吉克',
	'TZ': '坦尚尼亞',
	'TH': '泰國',
	'TL': '東帝汶',
	'TG': '多哥',
	'TK': '托克勞',
	'TO': '東加',
	'TT': '千里達及托巴哥',
	'TN': '突尼西亞',
	'TR': '土耳其',
	'TM': '土庫曼',
	'TC': '特克斯與凱科斯群島',
	'TV': '吐瓦魯',
	'UG': '烏干達',
	'UA': '烏克蘭',
	'AE': '阿聯',
	'GB': '英國',
	'US': '美國',
	'UM': '美國本土外小島嶼',
	'UY': '烏拉圭',
	'UZ': '烏茲別克',
	'VU': '萬那杜',
	'VE': '委內瑞拉',
	'VN': '越南',
	'VG': '英屬維京群島',
	'VI': '美屬維京群島',
	'WF': '瓦利斯和富圖那',
	'EH': '西撒哈拉',
	'YE': '葉門',
	'ZM': '尚比亞',
	'ZW': '辛巴威',
	//
	'taiwanCities.TaipeiCity': '臺北市',
	'taiwanCities.NewTaipeiCity': '新北市',
	'taiwanCities.TaoyuanCity': '桃園市',
	'taiwanCities.TaichungCity': '臺中市',
	'taiwanCities.TainanCity': '臺南市',
	'taiwanCities.KaohsiungCity': '高雄市',
	'taiwanCities.KeelungCity': '基隆市',
	'taiwanCities.HsinchuCity': '新竹市',
	'taiwanCities.ChiayiCity': '嘉義市',
	'taiwanCities.HsinchuCounty': '新竹縣',
	'taiwanCities.MiaoliCounty': '苗栗縣',
	'taiwanCities.ChanghuaCounty': '彰化縣',
	'taiwanCities.NantouCounty': '南投縣',
	'taiwanCities.YunlinCounty': '雲林縣',
	'taiwanCities.ChiayiCounty': '嘉義縣',
	'taiwanCities.PingtungCounty': '屏東縣',
	'taiwanCities.YilanCounty': '宜蘭縣',
	'taiwanCities.HualienCounty': '花蓮縣',
	'taiwanCities.TaitungCounty': '臺東縣',
	'taiwanCities.PenghuCounty': '澎湖縣',
	'taiwanCities.KinmenCounty': '金門縣',
	'taiwanCities.LienchiangCounty': '連江縣',
};

// TODO:SOL
const AImate = {
	'AImatePlanPage-free-trails-day1': '專業方案免費體驗倒數',
	'AImatePlanPage-free-trails-day2': '天',
	'AImatePlanPage-free-trails-day-note': '您正與全球 2+ 億位資訊領航者同步體驗 AI 協作展現職場超能力！',
	'AImatePlanPage-plan-button-basic': '基本計畫',
	'AImatePlanPage-plan-button-upgrade': '升級方案',
	'AImatePlanPage-plan-button-subscribe': '訂閱方案',
	'AImatePlanPage-plan-button-change': '更改方案',
	'AImatePlanPage-plan-button-subscribing': '訂閱中',
	'AImatePlanPage-plan-button-cancel': '取消訂閱',
	'AImatePlanPage-plan-button-free': '免費',
	'AImatePlanPage-plan': '方案',
	'AImatePlanPage-plan-free': '基本',
	'AImatePlanPage-plan-starter': '入門',
	'AImatePlanPage-plan-pro': '專業',
	'AImatePlanPage-plan-feature-chatgpt-version': 'ChatGPT 版本',
	'AImatePlanPage-plan-feature-daily-questions-limit': '每日 {{times}} 次 AI 提問',
	'AImatePlanPage-plan-annualPrice.label': '年繳',
	// 'AImatePlanPage-plan-monthlyPrice.label': '或月繳 ${{price}}',
	'AImatePlanPage-plan-monthlyPrice.label': '月繳',
	'AImatePlanPage-plan-yearly': '年繳方案',
	'AImatePlanPage-plan-monthly': '月繳方案',
	'AImatePlanPage-new-plan': '訂閱新方案',
	'AImate-confirm-upgrade-purchased':
		'您的{{plan}}方案在 {{endDate}} 前仍可使用，若現在訂閱{{newPlan}}方案，原方案將在本次付款成功後失效。',
	'AImatePlanPage-cancel-plan': '取消訂閱方案',
	'AImate-confirm-cancel-purchased': '如果您現在確認並結束訂閱，在 {{endDate}} 前仍可使用訂閱項目，但無法獲得退款。',
	'AImate-confirm-upgrade-trial':
		'專業方案免費體驗在 {{endDate}} 前仍可使用，若現在訂閱{{newPlan}}方案，免費體驗將在本次付款成功後失效。',
	'AImate-ChatGPT-reached.today.limit': '已達今日提問上限',
	'AImatePlanPage-plan-feature-unlimited': '無限',

	'AImatePlanModal-ConfirmBtn-subscribe': '立即訂閱',
	'AImatePlanModal-ConfirmBtn-change': '立即變更',

	'AImatePlanModal-cancelSubscribe-success': '取消訂閱成功！準備將您導引回主畫面......',
	'AImatePlanModal-cancelSubscribe-failure': '取消訂閱失敗，錯誤訊息: {{msg}}',

	'AImateSubscribeResultModal-Title-success': '付款成功！',
	'AImateSubscribeResultModal-Title-failure': '付款失敗',

	'AImateSubscribeResultModal-Text-success': '方案升級費用已由您的信用卡扣除。',
	'AImateSubscribeResultModal-Text-failure': '交易未完成，方案費用尚未由您的信用卡扣除。',

	'AImateSubscribeResultModal-BtnText-success': '前往使用訂閱方案',
	'AImateSubscribeResultModal-BtnText-failure': '取得協助',
};

const finalResult = Object.assign(
	{},
	common,
	header,
	teacherExercise,
	sidebar,
	teacherCourseDetail,
	InformationPage,
	notificationPages,
	teacherResourcePage,
	exerciseManageRectangle,
	testManageRectangle,
	materialManage,
	materialUploadFile,
	exerciseManage,
	exerciseAndTest,
	exerciseTipInfo,
	exerciseAnswerInfo,
	exerciseTestInfo,
	testManage,
	lessonAppendExercise,
	testRatioInfo,
	testPreview,
	addMaterial,
	course,
	studentlist,
	test,
	score,
	student,
	exercise,
	question,
	feedback,
	addTA,
	courseManage,
	system,
	loginPage,
	loginEvent,
	freeTrialPage,
	parentloginPage,
	parentHomePage,
	parentFullReport,
	csfcLoginPage,
	staff,
	signUpPage,
	notes,
	announcement,
	lintErrorHints,
	studyResult,
	unitDomainManage,
	betaUnitManage,
	AnnouncementListModal,
	zoom,
	desktopDownload,
	studentDetailPage,
	teacherCourseLessonPage,
	coursePackageManage,
	comments,
	studentInfo,
	studentPerformance,
	parentPage,
	exerciseEditor,
	questionGroupModal,
	disconnetPage,
	teacherCourseTestPage,
	questionGroupManageRectangle,
	testEvent,
	testInfo,
	studentTestPage,
	loadingProcessBar,
	globalExamPage,
	translationPage,
	shareCourseModal,
	dataSyncPage,
	crm,
	manualEmailTemplate,
	parentEmailTemplate,
	registrationDownloadPage,
	crmManage,
	internalMailManage,
	toolsPage,
	batchTeacherAccountModal,
	globalTestModal,
	csfcStudentAccountModal,
	chatGPT,
	general,
	AImate
);

// console.log("finalResult", finalResult)

export default finalResult;

//{t('lessonAppendExercise.onlyShow', {codeType:this.props.codeType})}
