import { takeEvery, call, put } from 'redux-saga/effects';
import { codingbarApi } from 'codingbar-api';

import * as cons from '../constants/app';

export function* watchInit() {
	// console.log('watchInit');
	yield takeEvery(cons.SAGA_INIT, function*(payload = {}) {
		// console.log('SAGA_INIT');
		try {
			yield put({ type: cons.INIT });
			const course = yield call([codingbarApi, 'init'], 'FUT');

			yield put({ type: cons.INIT_SUC, user: codingbarApi.getAuthService().getUser() });
		} catch (error) {
			yield put({ type: cons.INIT_ERR, error });
			throw error;
		}
	});
}

export function* watchSignIn() {
	// console.log('watchSignIn');
	yield takeEvery(cons.SAGA_SIGNIN, function*(payload = {}) {
		// console.log('signIn#payload', payload);
		try {
			yield put({ type: cons.SIGNIN });
			const email = payload.payload.email;
			const password = payload.payload.password;
			const authService = codingbarApi.getAuthService();
			const user = yield call([authService, 'signIn'], email, password);

			yield put({ type: cons.SIGNIN_SUC, user: user });
		} catch (error) {
			yield put({ type: cons.SIGNIN_ERR, error });
			throw error;
		}
	});
}

export function* watchSignOut() {
	// console.log('watchSignOut');
	yield takeEvery(cons.SAGA_SIGNOUT, function*(payload = {}) {
		// console.log('signOut#payload', payload);
		try {
			yield put({ type: cons.SIGNOUT });
			const authService = codingbarApi.getAuthService();
			const user = yield call([authService, 'signOut']);
			yield put({ type: cons.SIGNOUT });
		} catch (error) {
			yield put({ type: cons.SIGNOUT });
			throw error;
		}
	});
}

export function* watchGetUser() {
	// console.log('[SAGAS] watchGetUser');
	yield takeEvery(cons.SAGA_GETUSER, function*(payload = {}) {
		// console.log('[SAGAS] watchGetUser#payload', payload);
		try {
			yield put({ type: cons.GETUSER });

			// console.log('[SAGAS] watchGetUser, getUser:', codingbarApi.getAuthService().getUser());

			const authService = codingbarApi.getAuthService();
			const user = yield call([authService, 'getUser']);
			// console.log('[SAGAS] watchGetUser, user:', user);
			yield put({ type: cons.GETUSER, user: user });
		} catch (error) {
			yield put({ type: cons.GETUSER });
			throw error;
		}
	});
}

export default [watchSignIn, watchSignOut, watchGetUser];
