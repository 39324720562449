import { codingbarApi } from 'codingbar-api';
import isEmpty from 'lodash/isEmpty';
import { getUnitLabel } from 'shared/util/userUtils';
import { UNIT_PERMISSIONS_COLLECTION } from './collections';
import i18n from 'lang/i18n';

export function fetchAllUnits() {
	return codingbarApi
		.getUserService()
		.getAllUnits()
		.then((units) => {
			if (!units) return [];

			const unitsList = units.reduce(
				(acc, unit) => {
					if (!unit) return acc;

					return [...acc, unit];
				},
				[i18n.t('staff.chooseSchool')]
			);

			const unitOptions = unitsList.reduce((acc, unit) => {
				if ([i18n.t('staff.chooseSchool')].includes(unit)) return acc;
				return [...acc, { value: unit, label: `${unit}/${getUnitLabel(unit)}` }];
			}, []);

			return unitOptions;
		})
		.catch((err) => console.error(err));
}

export const patchUnitPermissions = (data) => {
	if (!data.unit) return Promise.reject(new Error('patchUnitPermissions: unit is empty'));
	if (isEmpty(data)) return Promise.reject(new Error('patchUnitPermissions: data is empty'));

	const filter = {
		unit: data.unit,
	};

	return codingbarApi.getCoreService().patchData(UNIT_PERMISSIONS_COLLECTION, filter, data);
};

export const getAllUnitPermissions = () => {
	return codingbarApi
		.getCoreService()
		.queryData(
			UNIT_PERMISSIONS_COLLECTION,
			{},
			{},
			{
				_id: 0,
				_ip: 0,
				updated: 0,
			},
			''
		)
		.then((res) => {
			if (res.status !== 'success') throw new Error('getAllUnitPermissions: failed to get all unit permissions');
			return res.result || [];
		});
};

export const getUnitPermissions = async (unit, withoutUnitMerge) => {
	const filter = {
		unit,
	};

	const projection = {
		_id: 0,
		_ip: 0,
		updated: 0,
	};

	let res;

	if (withoutUnitMerge) {
		res = await codingbarApi.getCoreService().queryOneWithoutUnitMerge(UNIT_PERMISSIONS_COLLECTION, filter, projection);
	} else {
		res = await codingbarApi.getCoreService().queryOne(UNIT_PERMISSIONS_COLLECTION, filter, projection);
	}

	if (res.status !== 'success') throw new Error('getUnitPermissions: failed to get unit permissions');
	return res.result;
};

export const autoEnableChatGPTPermission = async (unit, courseId) => {
	if (!unit) return Promise.reject(new Error('autoEnableChatGPTPermission: unit is empty'));
	if (!courseId) return Promise.reject(new Error('autoEnableChatGPTPermission: courseId is empty'));
	const unitPermission = await getUnitPermissions(unit);

	const updateData = {
		unit,
		updated: Date.now(),
	};

	if (unitPermission && unitPermission.chatGPT) {
		updateData.chatGPT = {
			...unitPermission.chatGPT,
			courseIds: [...unitPermission.chatGPT.courseIds, courseId],
		};
	} else {
		updateData.chatGPT = {
			type: 'course',
			courseIds: [courseId],
		};
	}

	return patchUnitPermissions(updateData);
};

export const removeChatGPTCourseIdsPermission = async (unit, courseIds) => {
	if (!unit) return Promise.reject(new Error('autoEnableChatGPTPermission: unit is empty'));
	if (isEmpty(courseIds)) return Promise.reject(new Error('autoEnableChatGPTPermission: courseId is empty'));
	const unitPermission = await getUnitPermissions(unit, true);
	if (!unitPermission) return Promise.resolve('nothing to remove');

	const updateData = {
		unit,
		updated: Date.now(),
		chatGPT: {
			...unitPermission.chatGPT,
			courseIds: unitPermission.chatGPT.courseIds.filter((id) => !courseIds.includes(id)),
		},
	};

	return patchUnitPermissions(updateData);
};
